/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react';
import {Outlet, Route, Routes} from 'react-router-dom';
import {Registration} from './components/Registration';
import {ForgotPassword} from './components/ForgotPassword';
import {Login} from './components/Login';
import {toAbsoluteUrl} from '../../../_metronic/helpers';
import {ConfirmEmail} from './components/ConfirmEmail';
import {EmailConfirmed} from './components/EmailConfirmed';
import Unauthorized from './components/Unauthorized';
import {useThemeMode} from '../../../_metronic/partials';
import {ResetPassword} from './components/ResetPassword';
import {ExpiredConfirmationPage} from './components/ExpiredConfirmationPage';
import {useBranding} from '../app/core/BrandingProvider';
import {getOrganizationByDomain} from '../admin/api/organizations-api';
import {getBase64codeForImage, isObjectEmpty, setFavIcon} from '../../../shared/utils';
import {EmailAlreadyConfirmed} from './components/EmailAlreadyConfirmed';
import {FailedPage} from './components/FailedPage';

const AuthLayout = () => {
  const {mode} = useThemeMode();
  const {organization, setOrganization} = useBranding();
  const [loading, setLoading] = useState(false);
  const [orgHasLinks, setOrgHasLinks] = useState(false);
  useEffect(() => {
    document.body.style.backgroundImage = 'none';
    return () => {};
  }, []);

  useEffect(() => {
    const getBrandingByDomain = async () => {
      if (!window.location.hostname) return;

      if (['console.vree.ai', 'localhost', 'test.vree.ai'].includes(window.location.hostname)) {
        document.title = 'VRee AI';
        return;
      }

      setLoading(true);
      const org = await getOrganizationByDomain(window.location.hostname);
      setLoading(false);

      if (!org || isObjectEmpty(org)) return;

      setOrganization(org);
      setOrgHasLinks(
        org.about_link !== undefined ||
          org.contact_link !== undefined ||
          org.privacy_link !== undefined ||
          org.terms_link !== undefined
      );

      document.title = org.window_title || 'VRee AI';

      if (!org.favicon) return;
      setFavIcon(org.favicon);
    };

    setOrganization(undefined);
    getBrandingByDomain();
  }, []);

  if (loading) return <div>loading...</div>;

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl(
          `/media/illustrations/sketchy-1/14${mode === 'dark' ? '-dark' : ''}.png`
        )})`,
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        {organization && organization.dark_logo && (
          <a
            href={organization.id === 0 ? 'https://vree.ai' : ''}
            className='mb-12 theme-light-show'
          >
            <img
              alt='Logo'
              src={getBase64codeForImage(organization.dark_logo)}
              className='h-80px'
            />
          </a>
        )}
        {organization && organization.light_logo && (
          <a
            href={organization.id === 0 ? 'https://vree.ai' : ''}
            className='mb-12 theme-dark-show'
          >
            <img
              alt='Logo'
              src={getBase64codeForImage(organization.light_logo)}
              className='h-80px'
            />
          </a>
        )}
        {!organization && (
          <a href={'https://vree.ai'} className='mb-12'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/colorful-lavendar.svg')}
              className='theme-dark-show h-80px'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/colorful-lavendar.svg')}
              className='theme-light-show h-80px'
            ></img>
          </a>
        )}
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-semibold fs-6'>
          <a
            href={organization?.about_link || 'https://vree.ai/#section_clients'}
            className='text-muted text-hover-primary px-2'
            hidden={orgHasLinks && !organization?.about_link}
            target='_blank'
            rel='noopener noreferrer'
          >
            About
          </a>

          <a
            href={organization?.privacy_link || 'https://vree.ai/privacy.html'}
            className='text-muted text-hover-primary px-2'
            hidden={orgHasLinks && !organization?.privacy_link}
            target='_blank'
            rel='noopener noreferrer'
          >
            Privacy Policy
          </a>

          <a
            href={organization?.terms_link || 'https://vree.ai/terms.html'}
            className='text-muted text-hover-primary px-2'
            hidden={orgHasLinks && !organization?.terms_link}
            target='_blank'
            rel='noopener noreferrer'
          >
            T&C
          </a>

          <a
            href={organization?.contact_link || 'https://vree.ai/#section_contact'}
            className='text-muted text-hover-primary px-2'
            hidden={orgHasLinks && !organization?.contact_link}
            target='_blank'
            rel='noopener noreferrer'
          >
            Contact Us
          </a>
        </div>
      </div>
      {/* end::Footer */}
    </div>
  );
};

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='reset-password' element={<ResetPassword />} />
      <Route path='email-confirmed' element={<EmailConfirmed />} />
      <Route path='expired-email-confirmation' element={<ExpiredConfirmationPage />} />
      <Route path='already-confirmed' element={<EmailAlreadyConfirmed />} />
      <Route path='failed' element={<FailedPage />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
);

export {AuthPage};
