import {ContactShadows, Environment} from '@react-three/drei';
import {useEffect, useState} from 'react';
import {viewerConfig} from '../../viewer-config';
import {useThemeMode} from '../../../../../_metronic/partials';
import {getModelFile} from '../../../../../shared/utils';
import {useAuth} from '../../../auth';
import {Controls} from './components/Controls';
import {Lights} from './components/Lights';
import {Model} from './components/Model';
import useViewerStore from './viewer-store';
import {Occluder} from './components/Occluder';
import Debug from './components/Debug';
import {ViewerToolbar} from './components/ViewerToolbar';
import Camera from './components/Camera';
import {ModelStatusesEnum, ModelStatusesNamesEnum} from '../../../models-management/types';

function LoadingPlaceholder() {
  return (
    <div className='flex-center d-flex h-100 fs-4 flex-column'>
      <div>
        <i className='fa  fa-spinner fa-spin mb-2 fs-2'></i>
      </div>
      <span>Loading ..</span>
    </div>
  );
}

function TextPlaceholder({text}: any) {
  return (
    <div className='flex-center d-flex h-100 fs-4 flex-column'>
      <span>{text}</span>
    </div>
  );
}

const NewViewer = ({path, model}: any) => {
  const {mode} = useThemeMode();
  const auth = useAuth();
  const [modelFile, setModelFile] = useState<any>();
  const {config, setConfig, setModel, reset} = useViewerStore();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const getGLB = async () => {
    setLoading(true);
    setError('');
    try {
      const updatedGlpFile = await getModelFile(path, auth.auth?.api_token);
      setModelFile(updatedGlpFile);
    } catch (error) {
      setError(`Model could not be generated!`);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!model?.category_name) return;
    setConfig(viewerConfig[model.category_name.toLowerCase()]);
    setModel(model);
  }, [model]);

  useEffect(() => {
    getGLB();

    return () => {
      reset();
    };
  }, []);

  if (!config) return <div>Config is not ready!</div>;
  if (loading) return <LoadingPlaceholder />;
  if (error) return <TextPlaceholder text={error} />;
  if (
    [ModelStatusesNamesEnum.PENDING, ModelStatusesNamesEnum.PROCESSING].includes(model.status_name)
  )
    return <TextPlaceholder text='Model is not ready!' />;

  return (
    <>
      <ViewerToolbar category_id={model.category_id} />

      <Camera>
        <Controls />

        <Lights />

        <Debug />

        <group>
          {modelFile && <Model path={modelFile} />}

          {config.occluderPath && <Occluder path={config.occluderPath} />}

          <ContactShadows position={[0, -0.5, 0]} opacity={mode === 'light' ? 0.4 : 0.15} />
          <Environment preset='apartment' />
        </group>
      </Camera>
    </>
  );
};

export default NewViewer;
