import {useState} from 'react';
import {AxesWithLabels} from './AxesWithLabels';
import * as THREE from 'three';

const Debug = () => {
  const [showAxis, setShowAxis] = useState(false);
  const [showGrid, setShowGrid] = useState(false);

  return (
    <>
      {showGrid && <primitive object={new THREE.GridHelper(10, 10)} />}
      {showAxis && <AxesWithLabels />}
    </>
  );
};

export default Debug;
