import clsx from 'clsx';
import React, {useEffect, useState} from 'react';
import './CardsSelector.scss';

interface Item {
  id: number;
  label: string;
  icon: string; // URL or icon class (depends on your icon usage)
}

interface CardDropdownProps {
  options: any[];
  optionLabel: string;
  optionValue: string;
  width?: number;
  height?: number;
  selectedValue?: string;
  onChange: (selectedItem: Item) => void;
}

const CardsSelector: React.FC<CardDropdownProps> = ({
  options,
  optionLabel,
  optionValue,
  width = 100,
  height = 100,
  selectedValue,
  onChange,
}) => {
  const [selectedItem, setSelectedItem] = useState<any | null>(null);

  const handleItemClick = (item: any) => {
    setSelectedItem(item);
    onChange(item); // Return selected item through callback
  };

  useEffect(() => {
    const item = options.find((d) => d[optionValue] === selectedValue);
    item && setSelectedItem(item);
  }, [selectedValue]);

  return (
    <div className='d-flex flex-wrap gap-5'>
      {options.map((item) => (
        <div
          key={item[optionValue]}
          className={clsx(
            `card-item card d-flex flex-center rounded p-1 shadow-sm cursor-pointer text-center`,

            {
              'border border-primary shadow-primary':
                selectedItem && selectedItem[optionValue] === item[optionValue],
            }
          )}
          style={{width: `${width}px`, height: `${height}px`}}
          onClick={() => handleItemClick(item)}
        >
          {item.iconClass && <div className={item.iconClass}></div>}

          {/* <img src={item.icon} alt={`${item.label} icon`} className='icon' /> */}
          <div className='fw-semibold mt-3'>{item.label}</div>
        </div>
      ))}
    </div>
  );
};

export default CardsSelector;
