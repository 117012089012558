import React, {useEffect, useState} from 'react';
import {Slider, SliderChangeEvent} from 'primereact/slider';
import useEditorStore from '../../model-editor.store';
import {randomIntFromInterval} from '../../../../../shared/utils';
import {useDebounce} from 'primereact/hooks';

interface Props {
  field: number;
  onUpdate: (value: number) => void;
}
export function FieldSlider({field, onUpdate}: Props) {
  const [value, debouncedValue, setValue] = useDebounce(field, 400);

  useEffect(() => {
    onUpdate(debouncedValue);
  }, [debouncedValue]);

  useEffect(() => {
    if (field !== value) setValue(field);
  }, [field]);

  return (
    <div className='position-relative'>
      <Slider
        id={randomIntFromInterval(999, 99999) + ''}
        value={value}
        onChange={(e: SliderChangeEvent) => {
          if (value !== +e.value) setValue(+e.value);
        }}
        min={0}
        max={1}
        step={0.1}
        className='w-100'
      />

      <div
        className='slider-handle'
        style={{
          left: `${value * 100}%`,
        }}
      >
        {value}
      </div>
    </div>
  );
}
