/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react';
import {useQuery} from 'react-query';
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  QueryState,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../../../_metronic/helpers';
import {getModels as fakeRequest} from '../../../api/models-api.fake';
import {getModels as realRequest} from '../../../api/models-api';
import {Model, ModelStatusesNamesEnum} from '../../../types';
import useModelStore from '../../single-model/model.store';
import {useQueryRequest} from './QueryRequestProvider';
import {AppLocator} from '../../../../../../app-locator';
import {parseError} from '../../../../../../shared/utils';
import {useAuth} from '../../../../auth';
import dayjs from 'dayjs';

const ENABLE_PAGE_CACHING = true;
const getRequest =
  AppLocator.forceUsingRealApi || process.env.NODE_ENV === 'production' ? realRequest : fakeRequest;

const QueryResponseContext = createResponseContext<Model>(initialQueryResponse);
const CACHE_TIMEOUT = 5000; // in ms
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {currentUser} = useAuth();
  const {state} = useQueryRequest();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
  const [error, setError] = useState('');
  const {setModelsIdsQuery} = useModelStore();

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery]);

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery<any, string>(
    `${QUERIES.USERS_LIST}-${query}`,
    async () => {
      console.log('models fetched', query, state);

      try {
        const {count, models} = await getPage();

        setModelsIdsQuery(query);
        setError('');

        return {
          data: models,
          count,
          payload: buildPayload(state, count),
          error: 'yyyy',
        };
      } catch (error: any) {
        console.error(error);
        setError(parseError(error));
        return {
          data: [],
          count: 0,
          payload: {},
        };
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const getPage = async () => {
    const cacheKey = `models-user${currentUser?.id}-${query}`;

    const cachedResult = sessionStorage.getItem(cacheKey);
    const isFilteredEnabeld = query.includes('filter');
    if (cachedResult && ENABLE_PAGE_CACHING && !isFilteredEnabeld) {
      const cachedObj = JSON.parse(cachedResult);
      // if (dayjs(cachedObj.date).diff(new Date()) > CACHE_TIMEOUT) {
      //   sessionStorage.clear();
      // } else
      return cachedObj.result;
    }

    const result = await getRequest(query, state.page);

    if (
      result.models.find((m) =>
        [ModelStatusesNamesEnum.PROCESSING, ModelStatusesNamesEnum.PENDING].includes(
          m.status_name as any
        )
      )
    )
      return result;

    const cachedObj = {result, date: new Date()};

    try {
      sessionStorage.setItem(cacheKey, JSON.stringify(cachedObj));
    } catch (error: any) {}
    return result;
  };

  return (
    <QueryResponseContext.Provider
      value={{isLoading: isFetching, refetch, response, query, error: error}}
    >
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponseData = () => {
  const {response} = useQueryResponse();
  if (!response) {
    return [];
  }

  return response?.data || [];
};

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  };

  const {response} = useQueryResponse();
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState;
  }

  return response.payload.pagination;
};

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse();
  return isLoading;
};

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
};

export const buildPayload = ({page, items_per_page}: QueryState, allItemsCount: number) => {
  const pagesCount = Math.ceil(allItemsCount / items_per_page);

  const pagesArray = Array.from({length: pagesCount}, (_, i) => i + 1);

  const links = pagesArray.map((p) => {
    return {
      url: `/?page=${p}`,
      label: p + '',
      active: page === p,
      page: p,
    };
  });

  const payload = {
    pagination: {
      page: page,
      links: [
        // {
        //   url: `/?page=${page - 1}`,
        //   label: 'Previous',
        //   active: false,
        //   page: page - 1,
        // },
        ...links,

        // {
        //   url: `/?page=${page + 1}`,
        //   label: 'Next',
        //   active: false,
        //   page: page + 1,
        // },
      ],
      items_per_page: items_per_page,
    },
  };

  return payload;
};
