import React, {useState, useEffect, useRef} from 'react';
import {ProgressBar} from 'primereact/progressbar';
import {Toast} from 'primereact/toast';

interface Props {
  time: number; // The total time in milliseconds for the progress bar to complete
}

const TimedProgressbar = ({time}: Props) => {
  const [value, setValue] = useState<number>(0);
  const toast = useRef<Toast | null>(null);
  const intervalRef = useRef<number | null>(null);

  useEffect(() => {
    const totalSteps = 100; // We're aiming to go from 0% to 100%
    const stepTime = time / totalSteps; // Time for each step to increment

    intervalRef.current = window.setInterval(() => {
      setValue((prevValue) => {
        const newValue = prevValue + 1; // Increment by 1 each time

        if (newValue >= 100) {
          clearInterval(intervalRef.current!); // Stop the interval at 100%
          return 100;
        }
        return newValue;
      });
    }, stepTime); // Interval duration is time/100 for smooth progression

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [time]);

  return <ProgressBar value={value}></ProgressBar>;
};

export default TimedProgressbar;
