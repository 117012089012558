import {Text} from '@react-three/drei';
import {useThree} from '@react-three/fiber';
import React from 'react';
import * as THREE from 'three';

// extend({ THREE.AxesHelper });
export const AxesWithLabels = ({size = 5}) => {
  const {scene} = useThree();

  return (
    <>
      <primitive object={new THREE.AxesHelper(size)} />
      <Text position={[size / 4, 0, 0]} fontSize={0.2} fillOpacity={0.5} color='red'>
        X
      </Text>
      <Text position={[0, size / 4, 0]} fontSize={0.2} fillOpacity={0.5} color='green'>
        Y
      </Text>
      <Text position={[0, 0, size / 4]} fontSize={0.2} fillOpacity={0.5} color='blue'>
        Z
      </Text>
    </>
  );
};
