import {useState, useEffect} from 'react';
import {toast} from 'react-toastify';
import {parseError} from '../../../../../../shared/utils';
import CountWidget from '../../../../shared/components/CountWidget';
import {getAllModelsCount} from '../../../api/users-api';

const TotalModelsWidget = () => {
  const [count, setCount] = useState(0);
  const [error, setError] = useState('');

  useEffect(() => {
    const get = async () => {
      try {
        const counter = await getAllModelsCount();
        setCount(counter.total || 0);
      } catch (err) {
        setError(parseError(err));
        toast.error(error || 'Can not load models count');
      }
    };

    get();
  }, []);

  return (
    <div>
      <CountWidget
        count={count}
        label='Total Models'
        iconPath='/media/icons/duotune/general/gen017.svg'
        iconColor='success'
        link='/dashboard/models'
      />
    </div>
  );
};

export default TotalModelsWidget;
