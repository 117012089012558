import {Html} from '@react-three/drei';
import clsx from 'clsx';
import useEditorStore from '../../model-editor.store';

export const EditorUILabels = ({position, tab}: any) => {
  const {selectedTab, setSelectedTab} = useEditorStore();

  return (
    <Html position={position} center={true}>
      <div
        id={`label${tab.index}`}
        draggable='false'
        className={clsx(`badge cursor-pointer `, {
          'badge-info': tab.index === selectedTab,
          'badge-light-info': tab.index !== selectedTab,
        })}
        onClick={() => setSelectedTab(tab.index)}
      >
        {tab.title}
      </div>
    </Html>
  );
};
