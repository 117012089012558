import React, {useEffect, useState} from 'react';
import {PageTitle} from '../../../../_metronic/layout/core';
import {StickyComponent} from '../../../../_metronic/assets/ts/components';
import useCheckoutStore from './checkout.store';
import {CheckoutSummary} from './components/CheckoutSummary';
import {CheckoutProducts} from './components/CheckoutProducts';
import {CheckoutPaymentMethod} from './components/CheckoutPaymentMethod';
import {useSearchParams} from 'react-router-dom';
import {Package} from '../types';
import {fetchPackages} from '../billing-api';
import {toast} from 'react-toastify';
import {parseError} from '../../../../shared/utils';
import {getPFToken} from '../bluesnap-api';
import './Checkout.scss';
import CheckoutResponse from './components/CheckoutResponse';

const bluesnapJSScriptURL = `https://sandpay.bluesnap.com/web-sdk/5/bluesnap.js`;

const Checkout = () => {
  const {setSelectedPackage, setPFToken, status, reset: resetStore} = useCheckoutStore();
  const [loading, setLoading] = useState(false);

  const [search] = useSearchParams();
  const product = search.get('product');

  const loadBluesnapJS = () => {
    const script = document.createElement('script');
    script.src = bluesnapJSScriptURL;
    // script.async = true;
    document.body.appendChild(script);
  };

  const loadPFToken = async () => {
    try {
      let token = await getPFToken();
      setPFToken(token);
    } catch (error: any) {
      toast.error(
        'Sorry, something wrong happened when initializing the page, please contact support (PF_TOKEN_FAILED) '
      );
    }
  };

  const getPackage = async (id: number) => {
    try {
      setLoading(true);

      const res = await fetchPackages();

      const _packages: Package[] = res.data.packages || [];
      const preSelectedPackage = _packages.filter((p) => p.id === id)[0];
      setSelectedPackage(preSelectedPackage);
    } catch (error) {
      toast.error(parseError(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    resetStore();
    loadBluesnapJS();
    loadPFToken();
  }, []);

  useEffect(() => {
    if (!product) return;

    getPackage(+product);
  }, [product]);

  if (status) return <CheckoutResponse />;

  return (
    <div className='container'>
      <PageTitle
        breadcrumbs={[
          {title: 'Billing', path: 'billing', isActive: false},
          {title: 'Package Purchase', path: 'billing/purchase-package', isActive: true},
        ]}
      >
        Checkout
      </PageTitle>

      <h1 className='page-heading py-7 d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0'>
        Checkout
      </h1>

      <div className='d-flex flex-column flex-lg-row'>
        <div className='flex-lg-row-fluid me-lg-15 order-2 order-lg-1 mb-10 mb-lg-0'>
          <CheckoutProducts />
          <CheckoutPaymentMethod />
        </div>

        <div className='flex-column flex-lg-row-auto w-100 w-lg-250px w-xl-300px mb-10 order-1 order-lg-2'>
          <CheckoutSummary />
        </div>
      </div>
    </div>
  );
};

export default Checkout;
