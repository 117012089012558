import React from 'react';
import {useIntl} from 'react-intl';
import {Navigate, Outlet, Route, Routes} from 'react-router-dom';
import {PageTitle} from '../../../_metronic/layout/core';
import Authorize from '../auth/Authorize';
import NewMediaWizard from './pages/new-media/NewMediaWizard';
import MediaLibrary, {MediaLibraryPage} from './pages/media-library/MediaLibrary';
import VideoEditor from './pages/video-editor/VideoEditor';

const RichMedia = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path='list'
          element={
            <Authorize redirect>
              {/* <PageTitle breadcrumbs={[]}>Catalog</PageTitle> */}
              <MediaLibraryPage />
            </Authorize>
          }
        />

        <Route
          path='generate'
          element={
            <Authorize redirect>
              <PageTitle breadcrumbs={[]}>Generate New Media</PageTitle>
              <NewMediaWizard />
            </Authorize>
          }
        />

        <Route
          path='video/:id'
          element={
            <Authorize redirect>
              <PageTitle breadcrumbs={[]}>Rich Media</PageTitle>
              <NewMediaWizard />
            </Authorize>
          }
        />

        <Route index element={<Navigate to='/rich-media/list' />} />

        <Route
          path='editor/:ids'
          element={
            <Authorize redirect>
              <PageTitle breadcrumbs={[]}>Video Editor</PageTitle>
              <VideoEditor />
            </Authorize>
          }
        />
      </Route>
    </Routes>
  );
};

export default RichMedia;
