import React, {useState} from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import {Link, useSearchParams} from 'react-router-dom';
import {useFormik} from 'formik';
import {requestPassword, resetPassword} from '../core/_requests';
import {PasswordRegEx} from '../../app/types';

const initialValues = {
  password: '',
  confirmPassword: '',
};

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .matches(PasswordRegEx, 'Password must include a mix of letters & numbers')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
});

export function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);

  const [search] = useSearchParams();
  const key = search.get('key');

  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      if (!key) return;

      setLoading(true);
      setHasErrors(undefined);
      resetPassword(key, values.password)
        .then(({data: {result}}) => {
          setHasErrors(false);
          setLoading(false);
        })
        .catch(() => {
          setHasErrors(true);
          setLoading(false);
          setSubmitting(false);
          setStatus('The password reset is failed');
        });
    },
  });

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <header className='text-center mb-10'>
          <h1 className='text-dark mb-3'>Reset your password</h1>

          <div className='text-gray-400 fw-bold fs-4'>Please enter new password</div>
        </header>

        {/* result alert */}
        <div>
          {hasErrors === true && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                Sorry, looks like there are some errors detected, please try again.
              </div>
            </div>
          )}

          {hasErrors === false && (
            <div className='mb-10 bg-light-success p-8 rounded'>
              <div className='text-success'>
                Password successfully reset, please login with new password
              </div>
            </div>
          )}
        </div>

        {/* reset form */}
        {(hasErrors === undefined || hasErrors) && (
          <div>
            <fieldset className='fv-row mb-5'>
              <label className='form-label fw-bolder text-gray-900 fs-6'>Password</label>
              <input
                type='password'
                placeholder=''
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {'is-invalid': formik.touched.password && formik.errors.password},
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </fieldset>

            <fieldset className='fv-row mb-10'>
              <label className='form-label fw-bolder text-gray-900 fs-6'>Confirm password</label>
              <input
                type='password'
                placeholder=''
                autoComplete='off'
                {...formik.getFieldProps('confirmPassword')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword},
                  {
                    'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
                  }
                )}
              />
              {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.confirmPassword}</span>
                  </div>
                </div>
              )}
            </fieldset>

            <fieldset className='d-flex flex-wrap justify-content-center pb-lg-0'>
              <button
                type='submit'
                id='kt_password_reset_submit'
                className='btn btn-lg btn-primary fw-bolder me-4'
              >
                {!loading && <span>Submit</span>}
                {loading && (
                  <span>
                    Saving password...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              <Link to='/auth/login'>
                <button
                  type='button'
                  id='kt_login_password_reset_form_cancel_button'
                  className='btn btn-lg btn-light-primary fw-bolder'
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  Cancel
                </button>
              </Link>{' '}
            </fieldset>
          </div>
        )}

        {/* login again button */}
        {hasErrors === false && (
          <fieldset className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <Link to='/auth/login'>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className='btn btn-lg btn-primary fw-bolder'
              >
                Sign In
              </button>
            </Link>{' '}
          </fieldset>
        )}
      </form>
    </>
  );
}
