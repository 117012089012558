/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {toAbsoluteUrl} from '../../../../_metronic/helpers';
import {useAuth} from '../core/Auth';

const Unauthorized = () => {
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const {logout} = useAuth();
  const from = search.get('from');

  return (
    <div>
      <div
        className='d-flex flex-column flex-root'
        style={{
          backgroundImage: `url(${toAbsoluteUrl('/media/auth/bg7.jpg')})`,
          height: '100vh',
        }}
      >
        {/* begin::Page bg image */}
        {/* <style>
                body { background-image: url('assets/media/auth/bg7.jpg'); } 
                [data-theme="dark"] body { background-image: url('assets/media/auth/bg7-dark.jpg'); }
            </style> */}
        {/* end::Page bg image */}
        {/* begin::Authentication - Signup Welcome Message  */}
        <div className='d-flex flex-column flex-center flex-column-fluid'>
          {/* begin::Content */}
          <div className='d-flex flex-column flex-center text-center p-10'>
            {/* begin::Wrapper */}
            <div className='card card-flush w-lg-650px py-5'>
              <div className='card-body py-15 py-lg-20'>
                {/* begin::Title */}
                <h1 className='fw-bolder fs-2qx text-gray-900 mb-4'>Unauthorized Access</h1>
                {/* end::Title */}
                {/* begin::Text */}
                <div className='fw-semibold fs-6 text-gray-500 mb-7'>
                  You do not have a permission to view this page.
                </div>
                {/* end::Text */}
                {/* begin::Illustration */}
                <div className='mb-11'>
                  <img
                    src={toAbsoluteUrl('/media/illustrations/sigma-1/20.png')}
                    className='mw-100 mh-300px theme-light-show'
                    alt=''
                  />
                  <img
                    src={toAbsoluteUrl('/media/illustrations/sigma-1/20-dark.png')}
                    className='mw-100 mh-300px theme-dark-show'
                    alt=''
                  />
                </div>
                {/* end::Illustration */}
                {/* begin::Link */}
                <div className='mb-0'>
                  <a
                    onClick={() => {
                      navigate(-1);
                    }}
                    className='btn btn-sm btn-primary'
                  >
                    Return Back
                  </a>

                  <a
                    onClick={() => {
                      logout();
                      navigate('/auth/login');
                    }}
                    className='btn btn-sm btn-light-primary ms-2'
                  >
                    Logout
                  </a>
                </div>
                {/* end::Link */}
              </div>
            </div>
            {/* end::Wrapper */}
          </div>
          {/* end::Content */}
        </div>
        {/* end::Authentication - Signup Welcome Message */}
      </div>
    </div>
  );
};

export default Unauthorized;
