import {useEffect, useState} from 'react';
import {Permission} from '../../../../app/types';

export const usePermissionSelector = () => {
  const [groups, setGroups] = useState<string[]>();
  const [allPermissions, setAllPermissions] = useState<Permission[]>([]);

  const [groupedPermissions, setGroupedPermissions] = useState<Map<string, Permission[]>>(
    new Map<string, Permission[]>()
  );

  const getAllPermissions = () => {
    return [...groupedPermissions].reduce(
      (prev, curr) => [...prev, ...curr[1]],
      [] as Permission[]
    );
  };

  const setPermissions = (perms: Permission[]) => {
    const groups = new Set<string>();
    perms.map((d) => groups.add(d.group));

    setGroups([...groups]);

    const _map = new Map<string, Permission[]>();
    perms.map((perm) => {
      const value = _map.get(perm.group) || [];

      _map.set(perm.group, [...value, perm]);
    });

    setGroupedPermissions(_map);
  };

  const deselectAll = () => {
    let allPerms = getAllPermissions();

    allPerms = allPerms.map((d) => {
      return {...d, selected: false};
    });

    setPermissions(allPerms);
  };

  const selectAll = () => {
    let allPerms = getAllPermissions();

    allPerms = allPerms.map((d) => {
      return {...d, selected: true};
    });

    setPermissions(allPerms);
  };

  const toggleSelection = (perm: Permission) => {
    const allPerms = getAllPermissions();
    let p = allPerms.find((d) => d === perm);

    if (!p) return;

    p.selected = !p.selected;

    setPermissions(allPerms);
  };

  const selectPermissions = (orgPermissions: Permission[], allPermissions: Permission[]) => {
    let allPerms = allPermissions;

    const currentPerms = orgPermissions.map((d) => d.code);

    allPerms = allPerms.map((d) => {
      return {
        ...d,
        selected: currentPerms.includes(d.code),
        level: orgPermissions.find((c) => c.code === d.code)?.level,
      };
    });
    setPermissions(allPerms);
  };

  useEffect(() => {
    const all = getAllPermissions();
    setAllPermissions(all);
  }, [groupedPermissions]);

  return {
    groupedPermissions,
    groups,
    allPermissions,
    getAllPermissions,
    setPermissions,
    deselectAll,
    selectAll,
    toggleSelection,
    selectPermissions,
  };
};
