/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import ReactApexChart from 'react-apexcharts';
import {toast} from 'react-toastify';
import {parseError} from '../../../../../../shared/utils';
import {getLoginSessions} from '../../../api/admin-api';
import {LoginSession} from '../../../types';
import CountUp from 'react-countup';
import clsx from 'clsx';
import {ApexOptions} from 'apexcharts';

const LoginSessionsWidget = () => {
  // const series = [
  //   {
  //     name: 'Customers',
  //     data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
  //   },
  //   {
  //     name: 'Admins',
  //     data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
  //   },
  //   // {
  //   //   name: 'Free Cash Flow',
  //   //   data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
  //   // },
  // ];

  const [error, setError] = useState('');
  const [sessions, setSessions] = useState<LoginSession[]>();
  const [daysBackwards, setDaysBackwards] = useState(0);
  const [chartOptions, setChartOptions] = useState<Partial<ApexOptions>>();

  useEffect(() => {
    if (!sessions) return;

    const adminSessions = sessions.filter((d) => d.is_Admin_login);
    const userSessions = sessions.filter((d) => !d.is_Admin_login);

    const data = groupObjectsByDate(adminSessions);
    const dates = Object.keys(data);
    const values = Object.values(data);
    var counts = values.map((d) => d.length);

    const data_user = groupObjectsByDate(userSessions);
    const dates_user = Object.keys(data_user);
    const values_user = Object.values(data_user);
    var counts_user = values_user.map((d) => d.length);

    const options: Partial<ApexOptions> = {
      series: [
        {name: 'Admins', data: counts},
        {name: 'Users', data: counts_user},
      ],
      xaxis: {
        categories: dates,
        // type: 'datetime'
      },

      chart: {
        type: 'bar',
        height: 350,
      },
      colors: ['#009ef7', '#50cd89'],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '10px',
          borderRadius: 8,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },

      yaxis: {
        title: {
          text: 'Logins',
        },
      },
      grid: {
        strokeDashArray: 3,
        borderColor: '#F1F1F2',
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val: any) {
            return val + ' logins';
          },
        },
      },
    };

    setChartOptions(options);
  }, [sessions]);

  useEffect(() => {
    const get = async () => {
      try {
        const sessions: any = await getLoginSessions(daysBackwards);

        setSessions(sessions);
      } catch (err) {
        setError(parseError(err));
        toast.error(error || 'Can not load LoginSessions');
      }
    };

    get();
  }, [daysBackwards]);

  return (
    <>
      <div className='card card-xxl-stretch mb-5 mb-xl-10'>
        <header className='card-header card-header-stretch'>
          <div className='card-title'>
            <h3 className='m-0 text-gray-900'>Security Summary</h3>
          </div>
          <div className='card-toolbar'>
            <ul
              className='nav nav-tabs nav-line-tabs nav-stretch border-transparent fs-5 fw-bold'
              id='kt_security_summary_tabs'
              role='tablist'
            >
              <li className='nav-item' role='presentation'>
                <a
                  onClick={() => setDaysBackwards(0)}
                  className={clsx('nav-link cursor-pointer text-active-primary', {
                    active: daysBackwards === 0,
                  })}
                >
                  Today
                </a>
              </li>
              <li className='nav-item' role='presentation'>
                <a
                  onClick={() => setDaysBackwards(31)}
                  className={clsx('nav-link cursor-pointer text-active-primary', {
                    active: daysBackwards === 31,
                  })}
                >
                  Month
                </a>
              </li>
              <li className='nav-item' role='presentation'>
                <a
                  onClick={() => setDaysBackwards(365)}
                  className={clsx('nav-link cursor-pointer text-active-primary', {
                    active: daysBackwards === 365,
                  })}
                >
                  Year
                </a>
              </li>
            </ul>
          </div>
        </header>

        <section className='card-body pt-7 pb-0 px-0'>
          <div className='tab-content'>
            <div
              className='tab-pane fade active show'
              id='kt_security_summary_tab_pane_hours'
              role='tabpanel'
            >
              <div className='row p-0 mb-5 px-9'>
                <div className='col'>
                  <div className='border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3'>
                    <span className='fs-4 fw-semibold text-success d-block'>User Sign-in</span>
                    <span className='fs-2hx fw-bold text-gray-900 counted'>
                      <CountUp
                        end={sessions?.filter((d) => !d.is_Admin_login).length || 0}
                        duration={1}
                      />
                    </span>
                  </div>
                </div>
                <div className='col'>
                  <div className='border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3'>
                    <span className='fs-4 fw-semibold text-primary d-block'>Admin Sign-in</span>
                    <span className='fs-2hx fw-bold text-gray-900 counted'>
                      <CountUp
                        end={sessions?.filter((d) => d.is_Admin_login).length || 0}
                        duration={1}
                      />
                    </span>
                  </div>
                </div>
                <div className='col'>
                  <div className='border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3'>
                    <span className='fs-4 fw-semibold text-danger d-block'>Failed Attempts</span>
                    <span
                      className='fs-2hx fw-bold text-gray-900 counted'
                      data-kt-countup='true'
                      data-kt-countup-value={291}
                      data-kt-initialized={1}
                    >
                      <CountUp
                        end={sessions?.filter((d) => d.status === -1).length || 0}
                        duration={1}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className='pt-2'>
                <div className='d-flex align-items-center pb-6 px-9'>
                  <h3 className='m-0 text-gray-900 flex-grow-1'>Activity Chart</h3>

                  <ul className='d-none nav nav-pills nav-line-pills border rounded p-1'>
                    <li className='nav-item me-2 my-0'>
                      <a
                        className='nav-link btn btn-active-light btn-active-color-gray-700 btn-color-gray-500 py-2 px-5 fs-6 fw-semibold'
                        id='kt_security_summary_tab_hours_agents'
                      >
                        Orgs
                      </a>
                    </li>
                    <li className='nav-item my-0'>
                      <a
                        className='nav-link btn btn-active-light btn-active-color-gray-700 btn-color-gray-500 py-2 px-5 fs-6 fw-semibold active'
                        id='kt_security_summary_tab_hours_clients'
                      >
                        Clients
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='tab-content px-3'>
                  <div
                    className='tab-pane fade active show'
                    id='kt_security_summary_tab_pane_hours_clients'
                  >
                    <div id='chart' className='h-auto' style={{minHeight: '315px'}}>
                      {chartOptions && (
                        <ReactApexChart
                          options={chartOptions}
                          series={chartOptions.series}
                          type='bar'
                          height={350}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default LoginSessionsWidget;

function groupObjectsByDate(objects: LoginSession[]): {[date: string]: LoginSession[]} {
  const groupedData: {[date: string]: LoginSession[]} = {};

  objects.forEach((item) => {
    // Extract the date portion of the datetime
    const date = new Date(item.time).toLocaleDateString();

    // Create an array for each unique date or add to the existing array
    if (!groupedData[date]) {
      groupedData[date] = [];
    }
    groupedData[date].push(item);
  });

  return groupedData;
}
