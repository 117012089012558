/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react';
import {Link, useSearchParams} from 'react-router-dom';
import {toAbsoluteUrl} from '../../../../_metronic/helpers';

export const ExpiredConfirmationPage = () => {
  const [search] = useSearchParams();
  const msg = search.get('msg');
  const type = search.get('type');

  return (
    <div className='card-body py-15 py-lg-20 text-center'>
      {/* begin::Title */}
      <h1 className='fw-bolder text-gray-900 mb-5'>New Link Sent!</h1>
      {/* end::Title */}

      <div className='fs-5 mb-4'>
        <span className='fw-semibold text-gray-700'>
          Unfortunately, the link you clicked has expired!
          <br />
          <br />
          Not to worry though! We've gone ahead and sent you another email to confirm your email
          address.
          <br />
          <br />
          Kindly keep an eye on your inbox and use the provided link. Thank you for your patience
          and cooperation!{' '}
        </span>
      </div>

      {/* begin::Action */}
      {/* <div className='fs-6 mb-8  '>
        <Link to={`/auth/login`}>
          <a className='btn  btn-link btn-color-info btn-active-color-primary fw-bold'>Login</a>
        </Link>
      </div> */}
      {/* end::Action */}

      {/* begin::Link */}
      {/* <div className='mb-11'>
        <a href='/metronic8/demo1/../demo1/index.html' className='btn btn-sm btn-primary'>
          Skip for now
        </a>
      </div> */}
      {/* end::Link */}

      {/* begin::Illustration */}
      <div className='mb-0'>
        <img
          src={toAbsoluteUrl('/media/illustrations/unitedpalms-1/16.png')}
          className='mw-100 mh-300px theme-light-show'
          alt=''
        />
        <img
          src={toAbsoluteUrl('/media/illustrations/unitedpalms-1/16-dark.png')}
          className='mw-100 mh-300px theme-dark-show'
          alt=''
        />
      </div>
      {/* end::Illustration */}
    </div>
  );
};
