import axios, {AxiosResponse} from 'axios';
import {LoginSession} from '../types';
import {AppLocator} from '../../../../app-locator';

const API_URL =
  AppLocator.forceUsingRealApi || process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_DEV_API_URL;

export const getLoginSessions = (daysBackwards: number): Promise<LoginSession> => {
  return axios
    .get(`${API_URL}/admin/get_logins?days_backwards=${daysBackwards}`)
    .then((d: AxiosResponse<any>) => d.data.logins);
};
