import {FC} from 'react';
import clsx from 'clsx';
import {Link} from 'react-router-dom';
import {useLocation} from 'react-router';
import {checkIsActive, KTIcon, WithChildren} from '../../../helpers';

type Props = {
  to: string;
  title: string;
  icon?: string;
  tooltip?: string;
  fontIcon?: string;
  extText?: string;
  hasBullet?: boolean;
  disabled?: boolean;
};

const AsideMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  tooltip,
  fontIcon,
  extText,
  hasBullet = false,
  disabled = false,
}) => {
  const {pathname} = useLocation();
  const isActive = checkIsActive(pathname, to);

  return (
    <div className='menu-item' title={tooltip}>
      <Link
        className={clsx(
          'menu-link without-sub ',
          {active: isActive},
          {'disabled pointer-events-none opacity-75': disabled}
        )}
        to={to}
      >
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && (
          <span className='menu-icon'>
            <KTIcon iconName={icon} className='fs-2' />
          </span>
        )}
        {fontIcon && <i className={clsx('bi fs-3', fontIcon)}></i>}
        <span className='menu-title'>
          {title}

          <span className='text-muted ms-2 fs-9'>{extText}</span>
        </span>
      </Link>
      {children}
    </div>
  );
};

export {AsideMenuItem};
