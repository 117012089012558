/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import {toAbsoluteUrl} from '../../../_metronic/helpers';
import {Stage, Layer, Image, Transformer} from 'react-konva';
import {CanvasTextEditor} from './components/CanvasTextEditor';
import {convertFileToImageElement, downloadURI, sleep} from '../../../shared/utils';
import './CanvasEditor.scss';
import useCanvasEditorStore, {CanvasText} from './CanvasEditor.store';
import html2canvas from 'html2canvas';
import {Dialog} from 'primereact/dialog';
import {FileUpload, FileUploadHandlerEvent} from 'primereact/fileupload';

const scale = 1; // 0.6;
const defaultWidth = 540 * scale;
const defaultHeight = 960 * scale;

interface Props {
  bg?: string;
  onChanged?: (dataURL: string) => void;
  hideTextAdding?: boolean;
  hideLogoAdding?: boolean;
  addedLogo?: File;
  width?: number;
  height?: number;
}

const mobileScale = 0.8;
const defaultScaling = 0.5;
export const CanvasEditor = ({
  bg,
  onChanged,
  addedLogo,
  hideTextAdding,
  hideLogoAdding,
  width: inputWidth,
  height: inputHeight,
}: Props) => {
  const [background, setBackground] = useState(bg);
  const stageRef = React.useRef<any>(null);
  const {reset, texts, addText, deleteText, updateText, setTexts} = useCanvasEditorStore();
  const [selectedText, setSelectedText] = useState<CanvasText>();
  const [count, setCount] = useState(0);
  const [addingText, setAddingText] = useState(false);
  const [logoFile, setLogoFile] = useState<HTMLOrSVGImageElement>();

  const trRef = React.useRef<any>();

  const [width, setWidth] = useState(inputWidth || defaultWidth);
  const [height, setHeight] = useState(inputHeight || defaultHeight);
  const [scaling, setScaling] = useState(defaultScaling);
  const isMobile = window.innerWidth <= 768;

  const exportImage = () => {
    const uri = stageRef.current.toDataURL();
    downloadURI(uri, 'stage.png');
  };

  const setSelected = async (e: any) => {
    if (!e) {
      trRef.current.nodes([]);
      trRef.current.getLayer().batchDraw();
      return;
    }

    const elem = e.currentTarget || e; //imgRef.current

    if (!elem) return;

    trRef.current.nodes([elem]);
    trRef.current.getLayer().batchDraw();

    // setSelectedText(undefined);
    // await sleep(100);
    // setSelectedText(text);
  };

  const checkDeselect = (e: any) => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      trRef.current.nodes([]);
      trRef.current.getLayer().batchDraw();
    }
  };

  const AddText = () => {
    setAddingText(true);

    setTimeout(() => {
      const newElement = document.createElement('div');
      const body = `<p style="font-size:24px">Add text here...</p>`;
      newElement.innerHTML = body;
      newElement.style.width = 'fit-content';
      newElement.style.background = 'transparent';
      newElement.style.position = 'absolute';
      newElement.style.top = '-1000px';
      document.getElementsByTagName('body')[0].appendChild(newElement);

      html2canvas(newElement, {
        backgroundColor: 'transparent',
      }).then((canvas) => {
        // onSetImage(canvas);
        addText({body: body, image: canvas, x: stageRef.current.width() / 2 - 120 / 2, y: 50});
        setCount((c) => c++);
        // newElement.remove();

        setAddingText(false);
      });
    }, 20);
  };

  const uploadFile = async (event: FileUploadHandlerEvent) => {
    const file = event.files[0];

    const image = await convertFileToImageElement(file);

    const logo: CanvasText = {
      id: 999,

      body: '',
      image,
      x: 20,
      y: 300,
    };

    addText(logo);

    event.options.clear();

    // setLogoFile(image);
  };

  const addFile = async (file: File) => {
    setTimeout(async () => {
      let image = await convertFileToImageElement(file, width, height);
      if (image.width === 0 || image.height === 0) {
        image = await convertFileToImageElement(file, width, height);
      }
      console.log(image.width, image.height, image.clientHeight);

      // image.style.maxWidth = '100px';

      const logo: CanvasText = {
        id: 999,

        body: '',
        image,
        x: width / 2 - +image.width / 2,
        y: height / 2 - +image.height / 2,
      };

      addText(logo);

      setTimeout(() => {
        const stage = stageRef.current;
        const imageElement = stage.findOne('#item-1'); // Use the ID you set
        setSelected(imageElement);
      }, 200);
    }, 100);
  };

  useEffect(() => {
    // return () => reset();
  }, []);

  useEffect(() => {
    if (!onChanged) return;

    setSelected(undefined);
    const dataURL = stageRef.current.toDataURL();
    onChanged(dataURL);
  }, [texts]);

  useEffect(() => {
    if (!addedLogo) return;

    setTexts([]);
    addFile(addedLogo);
  }, [addedLogo]);

  useEffect(() => {
    if (!inputWidth || !inputHeight) return;

    if (inputWidth > inputHeight) {
      // limit height
    } else {
      // limit width
      // const newWidth = 300;
      // const reducingRate = newWidth / inputWidth;
      // setWidth(newWidth);
      // setHeight(reducingRate * inputHeight);
    }

    setWidth(inputWidth);
    setHeight(inputHeight);

    let scale = 1;

    scale = scale * 0.5; // normal screen scale
    if (isMobile) scale = scale * mobileScale;

    if (inputWidth * scale > window.innerWidth) {
      var additionalScale = window.innerWidth / (inputWidth * scale);
      scale = scale * additionalScale;

      console.log(`>> Scale adjusted to match screen size`, scale);
    }

    setScaling(scale);
  }, [inputWidth, inputHeight]);

  return (
    <div
      style={{
        height: background ? '' : `${height * scaling}px`,
        width: background ? '' : `${width * scaling}px`,
      }}
    >
      <section className='toolbar align-items-center mb-2'>
        {!hideTextAdding && (
          <button
            className='btn  btn-primary btn-sm me-2 '
            onClick={() => {
              AddText();
            }}
          >
            {!addingText && <i className='ki-outline ki-plus-square me-2 fs-3'></i>}
            {addingText && <i className='fa  fa-spinner fa-spin me-2 fs-3'></i>}
            Add Text
          </button>
        )}

        {!hideLogoAdding && (
          <FileUpload
            mode='basic'
            name='demo[]'
            accept='image/*'
            maxFileSize={1000000}
            onUpload={() => {}}
            auto
            chooseLabel={logoFile ? 'Change Logo' : 'Add Logo'}
            uploadHandler={uploadFile}
            customUpload
          />
        )}

        {/* <button
          className='btn btn-sm btn-light-primary bg-white me-2'
          onClick={() => exportImage()}
        >
          <i className='ki-outline ki-cloud-download me-2 fs-3'></i>
          Download
        </button> */}
      </section>

      <Stage
        style={{
          // background: `#cecece`,
          background: background ? `url('${background}')` : '',
          backgroundSize: `${width}px ${height}px`,
          backgroundRepeat: background ? 'no-repeat' : 'repeat',
          transform: `scale(${scaling}) translate(-50%,-50%)`,

          width: background ? '' : '0',
          height: background ? '' : '0',
        }}
        width={width}
        height={height}
        ref={stageRef}
        onMouseDown={(e) => checkDeselect(e)}
        className={background ? '' : 'default-bg'}
      >
        <Layer>
          {texts?.map((text) => (
            <Image
              draggable
              id={`item-${text.id}`}
              onClick={(e) => setSelected(e)}
              onDblClick={() => setSelectedText(text)}
              image={text.image}
              x={text.x || 0}
              y={text.y || 0}
              scaleX={text.scaleX || 1}
              scaleY={text.scaleY || 1}
              rotation={text.rotation || 0}
              onDragEnd={(e) => {
                const {x, y, scaleX, scaleY} = e.target.attrs;

                updateText({
                  ...text,
                  x,
                  y,
                  scaleX: scaleX,
                  scaleY: scaleY,
                });
                console.log(`Moved to`, x, y, e.target.attrs);
              }}
              onTransformEnd={(e) => {
                const {rotation} = e.target.attrs;

                updateText({
                  ...text,
                  rotation: rotation,
                });
                console.log(e);
              }}
            />
          ))}

          {/* {isSelected && ( */}
          <Transformer
            ref={trRef}
            flipEnabled={false}
            resizeEnabled={true}
            keepRatio={true}
            boundBoxFunc={(oldBox, newBox) => {
              // limit resize

              // console.log(oldBox, newBox);
              // if (Math.abs(newBox.width) > Math.abs(oldBox.width)) return oldBox;

              return newBox;
            }}
          />
          {/* )} */}
        </Layer>
      </Stage>

      <Dialog
        modal
        dismissableMask
        closable={false}
        visible={!!selectedText}
        onHide={() => setSelectedText(undefined)}
      >
        {selectedText && (
          <div className='card shadow p-5'>
            <header className='d-flex justify-content-between align-items-center'>
              <h2>Edit Text:</h2>
              <button className='btn btn-icon ' onClick={() => setSelectedText(undefined)}>
                <i className='fa fa-times fs-3'></i>
              </button>
            </header>
            <div>
              <div>
                <CanvasTextEditor value={selectedText} onChange={(v) => setSelectedText(v)} />
              </div>
            </div>
            <footer className='text-end mt-3 d-flex justify-content-between'>
              <button
                className='btn btn-light-danger btn-sm'
                onClick={() => {
                  deleteText(selectedText.id);
                  setSelectedText(undefined);
                }}
              >
                Delete
              </button>

              <button
                className='btn btn-primary btn-sm'
                onClick={() => {
                  const elem = document.querySelector('.ql-editor') as HTMLElement;
                  elem.style.width = 'fit-content';
                  elem.style.height = 'auto';
                  elem.style.padding = '0';

                  html2canvas(elem, {
                    backgroundColor: 'transparent',
                  }).then((canvas) => {
                    updateText({...selectedText, image: canvas});
                    setSelectedText(undefined);
                    setSelected(undefined);
                  });
                }}
              >
                Ok
              </button>
            </footer>
          </div>
        )}
      </Dialog>

      {/* <img src={toAbsoluteUrl(`/media/video-thumbnail.jpg`)} style={{maxHeight: '80vh'}} alt='' /> */}
    </div>
  );
};
