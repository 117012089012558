/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FormEvent, useEffect, useState} from 'react';
import {parseError} from '../../../../../../shared/utils';
import {toast} from 'react-toastify';
import {getLoginSessions} from '../../../api/admin-api';
import {LoginSession} from '../../../types';
import dayjs from 'dayjs';
import {KTIcon, toAbsoluteUrl} from '../../../../../../_metronic/helpers';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import {Dropdown} from 'primereact/dropdown';
import {Link} from 'react-router-dom';
import {InputText} from 'primereact/inputtext';
dayjs.extend(relativeTime as any);

const periods = [
  {value: 0, label: 'Today'},
  {value: 7, label: 'Week'},
  {value: 31, label: 'Month'},
];

const LoginSessionTable = () => {
  const [error, setError] = useState('');
  const [sessions, setSessions] = useState<LoginSession[]>([]);
  const [filteredSessions, setFilteredSessions] = useState<LoginSession[]>([]);

  const [daysBackwards, setDaysBackwards] = useState(0);
  const [showFilters, setShowFilters] = useState(false);
  const [searchText, setSearchText] = useState('');

  const search = () => {
    let filtered = sessions;

    if (searchText)
      filtered = filtered.filter((d) =>
        (d.location + d.email + d.source + d.user_name)
          .toLowerCase()
          .includes(searchText.trim().toLowerCase())
      );

    setFilteredSessions(filtered);
  };

  const submit = (e: FormEvent) => {
    e.preventDefault();
    search();
  };

  useEffect(() => {
    const get = async () => {
      try {
        const sessions: any = await getLoginSessions(daysBackwards);

        setSessions(sessions);
        setFilteredSessions(sessions);
      } catch (err) {
        setError(parseError(err));
        toast.error(error || 'Can not load LoginSessions');
      }
    };

    get();
  }, [daysBackwards]);

  return (
    <div>
      <div className='card mb-5 mb-xl-10'>
        <header className='card-header'>
          <div className='card-title'>
            <h3>Login Sessions</h3>
          </div>
          <div className='card-toolbar'>
            <div className='my-1 me-4'>
              <Dropdown
                className='w-150px ms-5 border-0 bg-light'
                value={daysBackwards}
                onChange={(e: any) => setDaysBackwards(e.value)}
                options={periods}
                optionLabel='label'
                optionValue='value'
              />
            </div>
            {/* <a className='btn btn-sm btn-primary my-1' onClick={() => setShowFilters((v) => !v)}>
              Search
            </a> */}

            <form onSubmit={submit} className='d-flex'>
              <div className='position-relative mx-1'>
                <span className='p-input-icon-left'>
                  <i className='pi pi-search' />
                  <InputText
                    placeholder='Search by location, email or IP'
                    onBlur={() => search()}
                    onKeyUp={() => search()}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </span>

                {/* <i className='ki-duotone ki-magnifier fs-2 position-absolute top-50 translate-middle-y ms-4'>
                  <span className='path1' />
                  <span className='path2' />
                </i>{' '}
                <input
                  type='text'
                  className='form-control w-250px fs-7 ps-12'
                  placeholder='Search by location, email or IP'
                  onChange={(e) => setSearchText(e.target.value)}
                  on
                /> */}
              </div>
            </form>
          </div>
        </header>

        {showFilters && (
          <section className=' px-5 py-2'>
            <form onSubmit={submit} className='d-flex'>
              <input
                type='search'
                className='form-control form-control-solid w-300px me-4'
                placeholder='Search by location, email or IP'
                onChange={(e) => setSearchText(e.target.value)}
              />
              <button type='submit' className='btn btn-primary btn-sm' onClick={search}>
                Search
                {/* <i className='fa fa-search ms-1'></i> */}
              </button>
            </form>
          </section>
        )}

        <section className='card-body p-0'>
          {filteredSessions.length === 0 && (
            <div className='text-center pt-10'>
              <div className='fw-semibold py-10'>
                <div className='text-gray-600 fs-3 mb-2'>No data</div>
                <div className='text-muted fs-6'>
                  We can't find the data, try to change the search term or refresh page ...
                </div>
              </div>
              <div className='text-center px-5'>
                <img
                  src={toAbsoluteUrl('/media/illustrations/sketchy-1/5.png')}
                  alt=''
                  className=' h-200px h-sm-325px'
                />
              </div>
            </div>
          )}

          {filteredSessions.length > 0 && (
            <div className='table-responsive scroll-y mh-400px'>
              <table className='table align-middle table-row-bordered table-row-solid gy-4 gs-9'>
                <thead className='border-gray-200 fs-5 fw-semibold bg-lighten'>
                  <tr>
                    <th className='min-w-200px'>Location</th>
                    <th className='min-w-250px'>Email</th>
                    <th className='min-w-100px'>Status</th>
                    <th className='min-w-150px'>Device</th>
                    <th className='min-w-150px'>IP Address</th>
                    <th className='min-w-150px'>Time</th>
                  </tr>
                </thead>
                <tbody className='fw-6 fw-semibold text-gray-600'>
                  {filteredSessions?.map((session) => (
                    <tr>
                      <td>{session.location}</td>
                      <td>
                        <Link className='text-dark mb-0 ' to={`/account/${session.user_id}`}>
                          {session.email}
                        </Link>

                        {!!session.is_Admin_login && (
                          <span title='Admin User'>
                            <KTIcon iconName='security-user' className='text-primary ms-1' />
                          </span>
                        )}
                      </td>

                      <td>
                        {session.status === 0 && (
                          <span className='badge badge-light-success fs-7 fw-bold'>OK</span>
                        )}
                        {session.status === -1 && (
                          <span className='badge badge-light-danger fs-7 fw-bold'>Failed</span>
                        )}
                      </td>
                      <td>{session.device}</td>
                      <td>
                        <a
                          href={`https://whatismyipaddress.com/ip/${session.source}`}
                          target='_blank'
                          className='text-hover-primary text-gray-600'
                        >
                          {session.source}
                        </a>
                        <span title='External Link'>
                          <KTIcon iconName='exit-right' className='ms-1' />
                        </span>
                      </td>
                      <td>{dayjs(session.time).fromNow()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </section>
      </div>
    </div>
  );
};

export default LoginSessionTable;
