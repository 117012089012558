import EditUser from '../../../admin/pages/users/components/EditUser';
import CreditWidget from '../../../models-management/pages/overview/components/CreditWidget';
import {AccountBillingBalance} from './components/AccountBillingBalance';
import BillingHistory from './components/BillingHistory';

const AccountBilling = () => {
  return (
    <>
      <AccountBillingBalance />

      <BillingHistory />
    </>
  );
};

export default AccountBilling;
