import clsx from 'clsx';
import {confirmDialog} from 'primereact/confirmdialog';
import {useState} from 'react';
import {toast} from 'react-toastify';
import {warningDialogProps} from '../../../../shared/types';
import HasPermission from '../../../../auth/HasPermission';
import {deleteBulkModels} from '../../../../models-management/api/models-api';
import {useCardsSelection} from '../../../../models-management/pages/models-list/core/CardsSelectionProvider';
import {useQueryResponse} from '../../../../models-management/pages/models-list/core/QueryResponseProvider';
import {useQueryResponseData} from '../MediaLibraryQueryProvider';
import {useNavigate} from 'react-router-dom';
import {MediaStatusEnum} from '../../../media.types';

const DELETE_MESSAGE = `Are you sure you want to delete the selected media items?`;

export const MediaSelectionOptions = () => {
  const {count, selectedCards, selectRange, clearAll} = useCardsSelection();
  const [deleting, setDeleting] = useState(false);
  const {refetch} = useQueryResponse();
  const medias = useQueryResponseData();
  const navigate = useNavigate();

  const deleteMediaItems = async () => {
    confirmDialog({
      ...warningDialogProps,
      message: DELETE_MESSAGE,
      accept: async () => {
        try {
          setDeleting(true);

          await deleteBulkModels(selectedCards);

          clearAll();
          sessionStorage.clear();

          refetch();
          toast.success('Media items deleted successfully');
        } catch (error) {
          toast.error('Failed to delete selected media items, please contact support!');
          console.error(error);
        }
        setDeleting(false);
      },
    });
  };

  const selectAll = () => {
    selectRange(
      medias.filter((d) => d.status === MediaStatusEnum.COMPLETED).map((d) => d.pub_id + '')
    );
  };

  if (count === 0) return <></>;

  return (
    <div className='ms-4 flex-grow-1 fw-bold d-flex justify-content-between align-items-center'>
      <div>
        <span>Selected: {count}</span>

        {/* Delete Button */}
        <HasPermission code='medias.delete'>
          <button
            title='Delete'
            className={clsx(
              'd-none btn btn-sm btn-icon btn-flex bg-body btn-color-gray-700 btn-active-color-danger fw-bold ms-2  position-relative ',
              {'pe-none text-danger border-danger border': deleting}
            )}
            onClick={deleteMediaItems}
          >
            {!deleting && <i className='fas fa-trash  '></i>}

            {deleting && (
              <span className='indicator-progress d-block z-1 text-info'>
                <span className='spinner-border spinner-border-sm align-middle '></span>
              </span>
            )}
          </button>
        </HasPermission>

        {/* Select All Button */}
        <button
          className='btn btn-sm btn-icon btn-flex bg-body btn-color-gray-700 btn-active-color-primary fw-bold ms-2'
          onClick={selectAll}
          title='Select completed media items in this page'
        >
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='16px' height='16px'>
            <path
              fill='currentColor'
              d='M 4 2 C 2.895 2 2 2.895 2 4 L 2 16 C 2 17.105 2.895 18 4 18 L 16 18 C 17.105 18 18 17.105 18 16 L 18 4 C 18 2.895 17.105 2 16 2 L 4 2 z M 4 4 L 16 4 L 16 16 L 4 16 L 4 4 z M 20 6 L 20 20 L 6 20 L 6 22 L 20 22 C 21.105 22 22 21.105 22 20 L 22 6 L 20 6 z M 13.292969 6.2929688 L 9 10.585938 L 6.7070312 8.2929688 L 5.2929688 9.7070312 L 9 13.414062 L 14.707031 7.7070312 L 13.292969 6.2929688 z'
            />
          </svg>

          {/* Select All  */}
        </button>

        {/* Clear Button */}
        <button
          className='btn btn-sm btn-icon btn-flex bg-body btn-color-gray-700 btn-active-color-primary fw-bold ms-2'
          onClick={clearAll}
          title='Clear Selection'
        >
          <i className='fas fa-backspace '></i>

          {/* Clear Selection */}
        </button>
      </div>

      <div>
        {/* <button
          title='Open video editor and import the selected media'
          className={clsx('btn btn-sm  btn-info fw-bold ms-2  position-relative ')}
          onClick={() => navigate(`/rich-media/editor/${selectedCards.join(',')}`)}
        >
          <i className='ki-outline ki-notepad-edit me-2'></i>
          Import to Editor
        </button> */}
      </div>
    </div>
  );
};
