import React, {useEffect, useState} from 'react';
import {fetchPayments} from '../../../../billing/billing-api';
import {Payment} from '../../../../billing/types';
import dayjs from 'dayjs';

const BillingHistory = () => {
  const [payments, setPayments] = useState<Payment[]>([]);

  const getPayments = async () => {
    try {
      const _payments = await fetchPayments();
      setPayments(_payments);
    } catch (error) {}
  };

  useEffect(() => {
    getPayments();
  }, []);

  return (
    <div className='card '>
      <div className='card-header card-header-stretch border-bottom border-gray-200'>
        <div className='card-title'>
          <h3 className='fw-bold m-0'>Billing History</h3>
        </div>
        <div className='card-toolbar m-0'></div>
      </div>
      <div className='tab-content'>
        <div className='card-body p-0'>
          <div className='table-responsive'>
            <table className='table table-row-bordered align-middle gy-4 gs-9'>
              <thead className='border-bottom border-gray-200 fs-6 text-gray-600 fw-bold bg-light bg-opacity-75'>
                <tr>
                  <td className='min-w-150px'>Date</td>
                  <td className='min-w-250px'>Description</td>
                  <td className='min-w-150px'>Amount</td>
                  {/* <td className='min-w-150px'>Invoice</td> */}
                  <td />
                </tr>
              </thead>
              <tbody className='fw-semibold text-gray-600'>
                {payments.map((payment) => (
                  <tr key={payment.id}>
                    <td>{dayjs(payment.date).format('D/M/YYYY - H:mm')}</td>
                    <td className='text-muted'>
                      Invoice for {dayjs(payment.date).format('MMMM, YYYY')}
                    </td>
                    <td>${payment.amount}</td>
                    {/* <td>
                      <a href='#' className='btn btn-sm btn-light btn-active-light-primary'>
                        PDF
                      </a>
                    </td> */}
                    <td className='text-right'>
                      <a
                        href='#'
                        className='btn btn-sm btn-light btn-active-light-primary disabled '
                      >
                        View
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingHistory;
