import {useIntl} from 'react-intl';
import {Navigate, Outlet, Route, Routes} from 'react-router-dom';
import {PageTitle} from '../../../_metronic/layout/core';
import Packages from './Packages/Packages';
import PurchasePackage from './Packages/PurchasePackage';
import Checkout from './Checkout/Checkout';
import Payments from './Payments/Payments';

const Billing = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path='packages'
          element={
            <>
              <PageTitle breadcrumbs={[]}>Packages</PageTitle>
              <Packages />
            </>
          }
        />

        <Route
          path='purchase-package'
          element={
            <>
              <PageTitle breadcrumbs={[]}>Purchase Package</PageTitle>
              <PurchasePackage />
            </>
          }
        />

        <Route
          path='checkout'
          element={
            <>
              <PageTitle breadcrumbs={[]}>Checkout</PageTitle>
              <Checkout />
            </>
          }
        />

        <Route
          path='payments'
          element={
            <>
              <PageTitle breadcrumbs={[]}>Payments History</PageTitle>
              <Payments />
            </>
          }
        />

        <Route index element={<Navigate to='/packages' />} />
      </Route>
    </Routes>
  );
};

export default Billing;
