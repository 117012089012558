import {useState} from 'react';
import clsx from 'clsx';
import {
  Paginator,
  PaginatorNextPageLinkOptions,
  PaginatorPageChangeEvent,
  PaginatorPageLinksOptions,
  PaginatorPrevPageLinkOptions,
} from 'primereact/paginator';
import {classNames} from 'primereact/utils';
import {useMediaLibraryQuery} from '../MediaLibraryQueryProvider';
import useMediaLibraryStore from '../media-library.store';

const DEFAULT_ROWS = 16;
export function MediaPagination() {
  const {isLoading, response} = useMediaLibraryQuery();
  const {updateFilters, filters} = useMediaLibraryStore();

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(DEFAULT_ROWS);

  const template = {
    layout: ' PrevPageLink FirstPageLink PageLinks LastPageLink NextPageLink RowsPerPageDropdown ',
    PrevPageLink: (options: PaginatorPrevPageLinkOptions) => {
      return (
        <button
          type='button'
          className={classNames(
            options.className,
            'btn btn-link btn-color-gray-800 btn-active-color-primary me-5 mb-2'
          )}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className='p-3'>Previous</span>
        </button>
      );
    },
    NextPageLink: (options: PaginatorNextPageLinkOptions) => {
      return (
        <button
          type='button'
          className={classNames(
            options.className,
            'btn btn-link btn-color-gray-800 btn-active-color-primary me-5 mb-2'
          )}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className='p-3'>Next</span>
        </button>
      );
    },
    PageLinks: (options: PaginatorPageLinksOptions) => {
      if (
        (options.view.startPage === options.page && options.view.startPage !== 0) ||
        (options.view.endPage === options.page && options.page + 1 !== options.totalPages)
      ) {
        const className = classNames(options.className, {'p-disabled': true});

        return (
          <span className={className} style={{userSelect: 'none'}}>
            ...
          </span>
        );
      }

      // console.log(options);

      return (
        <button
          type='button'
          className={clsx(
            options.className,
            {'btn-primary': options.currentPage === options.page},
            'btn p-0'
          )}
          onClick={options.onClick}
        >
          {options.page + 1}
        </button>
      );
    },
  };
  const onPageChange = (event: PaginatorPageChangeEvent) => {
    const newPage = event.page + 1;

    setFirst(event.first);
    setRows(event.rows);

    updateFilters({page: newPage, items_per_page: event.rows});
  };

  if (isLoading) return <></>;

  return (
    <Paginator
      className='justify-content-end mt-10'
      template={template}
      first={first}
      rows={rows}
      totalRecords={response?.count}
      rowsPerPageOptions={[8, 16, 32]}
      onPageChange={onPageChange}
    />
  );
}
