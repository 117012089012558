import React, {useState} from 'react';
import {useAuth} from '../../../auth';
import useCheckoutStore from '../checkout.store';
import {Divider} from 'primereact/divider';
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers';
import {createTransaction, submitBSTransaction} from '../../bluesnap-api';
import clsx from 'clsx';

export function CheckoutSummary() {
  const {selectedPackage, newPaymentMethod, pfToken, setStatus} = useCheckoutStore();
  const {currentUser} = useAuth();
  const [submitting, setSubmitting] = useState(false);

  const submitTransaction = async () => {
    if (!newPaymentMethod) return;

    setSubmitting(true);

    try {
      const data = {
        package_id: selectedPackage?.id,
        pf_token: pfToken,
        card_holder: newPaymentMethod?.cardHolder,
      };

      const res = await createTransaction(data);

      if (res?.status === 'success') setStatus('success');
      if (res?.status === 'error') setStatus(res?.error);

      setSubmitting(false);
    } catch (error: any) {
      setSubmitting(false);

      console.error(error);

      const errorFromServer = error?.response?.data;
      if (!errorFromServer)
        return setStatus('Payment Failed, please contact support (PAYMENT_SERVER_ERROR');

      const errorCode = errorFromServer.status;
      const errorDescription = errorFromServer.error;

      if (errorCode === 'failed') return setStatus(errorDescription);

      setStatus('Payment Failed, please contact support (PAYMENT_SERVER_UNKNOWN_ERROR');
    }
  };

  return (
    <article
      className='card card-bordered card-flush pt-3 mb-0'
      style={{
        position: 'sticky',
        top: '100px',
      }}
    >
      <div className='card-header'>
        <div className='card-title'>
          <h2>Summary</h2>
        </div>
      </div>
      <div className='card-body pt-0 fs-6'>
        <div className='mb-7'>
          <h5 className='mb-3'>Customer details</h5>
          <div className='d-flex align-items-center mb-1'>
            <a
              href='/metronic8/demo1/apps/customers/view.html'
              className='fw-bold text-gray-800 text-hover-primary me-2'
            >
              {currentUser?.first_name}
              {currentUser?.last_name}
            </a>
            <span className='badge badge-light-success'>Active</span>
          </div>
          <a href='#' className='fw-semibold text-gray-600 text-hover-primary'>
            {currentUser?.email}
          </a>
        </div>
        <div className='separator separator-dashed mb-7' />
        <div className='mb-7'>
          <h5 className='mb-3'>Product details</h5>
          {selectedPackage && (
            <div className='mb-0'>
              <span className='badge badge-light-info me-2'>{selectedPackage?.name}</span>
              <span className='fw-semibold text-gray-600'>${selectedPackage?.price}</span>
            </div>
          )}
          {!selectedPackage && <div className='text-muted'>-Not Selected-</div>}
        </div>

        <div className='separator separator-dashed mb-7' />

        {newPaymentMethod && (
          <div className='mb-10'>
            <h5 className='mb-3'>Payment Details</h5>
            <div className='mb-0'>
              <div className='fw-semibold text-gray-600 d-flex align-items-center text-capitalize '>
                *** {newPaymentMethod.response?.cardData.last4Digits}
                <img
                  src={toAbsoluteUrl(
                    `/media/svg/card-logos/${newPaymentMethod.response?.cardData.ccType}.svg`
                  )}
                  className='w-35px ms-2'
                  alt=''
                />
              </div>
              <div className='fw-semibold text-gray-600'>
                {' '}
                Expires {newPaymentMethod.response?.cardData.exp}
              </div>
            </div>
          </div>
        )}

        {!newPaymentMethod && (
          <div className='text-muted mb-5'>
            <KTSVG path='/media/icons/duotune/finance/fin002.svg' className='svg-icon-muted me-2' />
            No payment method was added
          </div>
        )}

        <div className='mb-0'>
          <button
            onClick={() => submitTransaction()}
            type='submit'
            className={clsx('btn btn-primary', {disabled: !newPaymentMethod || submitting})}
          >
            {!submitting && <span className='indicator-label'>Pay Now</span>}
            {submitting && (
              <>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2' />
              </>
            )}
          </button>
        </div>
      </div>
    </article>
  );
}
