import {Routes, Route, Outlet, Navigate} from 'react-router-dom';
import {PageTitle} from '../../../_metronic/layout/core';
import AccountPage from './pages/AccountPage';
import AccountOverview from './pages/overview/AccountOverview';
import AccountSettings from './pages/settings/AccountSettings';
import AccountBilling from './pages/billing/AccountBilling';

const Account = () => {
  return (
    <div>
      <AccountPage>
        <Routes>
          <Route
            path=':id'
            element={
              <>
                <PageTitle breadcrumbs={[]}>Account</PageTitle>

                <Outlet />
              </>
            }
          >
            <Route path='overview' element={<AccountOverview />} />
            <Route path='billing' element={<AccountBilling />} />
            <Route path='settings' element={<AccountSettings />} />

            <Route index element={<Navigate to='overview' replace />} />
          </Route>
        </Routes>
      </AccountPage>
    </div>
  );
};

export default Account;
