import clsx from 'clsx';

export const CreditTemplate = (user: any) => {
  const percentage = ((user?.used_credits || 0) / (user?.max_credits || 0)) * 100;
  return (
    <>
      <div className='d-flex justify-content-between w-100 mt-auto '>
        <span className='fw-bold text-muted fs-7'>
          {user?.used_credits}/{user?.max_credits}
        </span>
      </div>
      <div className='h-5px  w-100 bg-secondary '>
        <div
          className={clsx(
            ' rounded h-5px ',
            {'bg-success': percentage < 80},
            {'bg-warning': percentage >= 80 && percentage < 100},
            {'bg-danger': percentage >= 100}
          )}
          role='progressbar'
          style={{
            width: `${percentage}%`,
          }}
        />
      </div>
    </>
  );
};
