/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react';
import {Video, VideoStatusEnum} from '../../../vidoes.types';
import {ModalLayout, ModelHeaderLayout} from '../../../../shared/components/ModalLayout';
import clsx from 'clsx';
import {KTSVG, toAbsoluteUrl} from '../../../../../../_metronic/helpers';
import {convertSecondsToReadableTime} from '../../../../../../shared/date-util';
import {Link} from 'react-router-dom';
import {Dialog} from 'primereact/dialog';
import VideoSharingWindow from './VideoSharingWindow';
import {confirmDialog} from 'primereact/confirmdialog';
import {toast} from 'react-toastify';
import {parseError, sleep} from '../../../../../../shared/utils';
import {rateModel, deleteModel} from '../../../../models-management/api/models-api';
import {deleteDialogProps} from '../../../../shared/types';
import useVideosPageStore from '../videos-page.store';
import {deleteVideo} from '../../../vidoes.api';

interface Props {
  video: Video;
  submitComplete: (ok: boolean) => void;
}

const VideoDialog = ({video, submitComplete}: Props) => {
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showShareWindow, setShowShareWindow] = useState(false);

  const deleteBtnClicked = () => {
    confirmDialog({
      ...deleteDialogProps,
      message: 'Do you want to delete this experience?',
      accept: () => {
        submitDelete();
      },
      reject: () => {},
    });
  };

  const submitDelete = async () => {
    if (!video) return;

    try {
      setDeleting(true);

      await deleteVideo(video.pub_id);

      toast.success('Experience deleted successfully!');

      submitComplete(true);
    } catch (error) {
      setDeleting(false);
      alert(parseError(error));
    }
    setDeleting(false);
  };

  return (
    <div>
      <ModelHeaderLayout
        title={`Video ${video.name}`}
        showCloseButton
        onClose={submitComplete}
      ></ModelHeaderLayout>

      <main
        className={clsx('modal-body p-5 px-6 scroll', {
          'overlay overlay-block': loading,
        })}
      >
        <div className='row'>
          <div className='col-md-8'>
            {video.status === VideoStatusEnum.COMPLETED && (
              <video
                id={video.id + ''}
                src={toAbsoluteUrl(video.video || '')}
                // src={toAbsoluteUrl('/media/videos/placeholder-video.mp4')}
                poster={toAbsoluteUrl(video.thumbnail)}
                className=' min-h-400px mw-300px'
                autoPlay
                controls
                muted
              ></video>
            )}
            {video.status !== VideoStatusEnum.COMPLETED && (
              <div
                className='video-thumbnail-container h-500px w-100 bg-light  rounded-top'
                style={{backgroundImage: `url('${video.thumbnail}')`, backgroundSize: 'cover'}}
              >
                {video.status === VideoStatusEnum.PROCESSING && (
                  <div className='play-icon bg-info'>
                    <i className='fa  fa-spinner fa-spin fs-4 text-white'></i>
                  </div>
                )}

                {video.status === VideoStatusEnum.FAILED && (
                  <div className='play-icon bg-warning'>
                    <i className='fa fa-exclamation-triangle fs-4 text-white'></i>{' '}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className='col-md-4'>
            <fieldset className='row mb-5'>
              <div className='fw-bold'>
                <div className='text-muted'>Video Name</div>
                <div className='fw-bolder capitalized'>{video.name}</div>
              </div>
            </fieldset>

            {/* <fieldset className='row mb-5'>
              <div className='fw-bold'>
                <div className='text-muted'>Length</div>
                <div className='fw-bolder capitalized'>
                  {convertSecondsToReadableTime(video.length)}
                </div>
              </div>
            </fieldset> */}

            {/* <fieldset className='row mb-5'>
              <div className='fw-bold'>
                <div className='text-muted'>Model SKU</div>
                <div className='fw-bolder capitalized'>
                  <Link
                    className='btn btn-link btn-flush btn-active-color-primary'
                    title='Open Model'
                    to={`/dashboard/models?pub_id=${video.modelId}`}
                  >
                    {video.modelName}
                  </Link>
                </div>
              </div>
            </fieldset> */}

            <fieldset className='row mb-5'>
              <div className='fw-bold'>
                <div className='text-muted'>Status</div>
                <div>
                  {video.status === VideoStatusEnum.FAILED && (
                    <span className='badge badge-light-danger'>Failed</span>
                  )}
                  {video.status === VideoStatusEnum.PENDING && (
                    <span className='badge badge-light-warning'>Pending</span>
                  )}
                  {video.status === VideoStatusEnum.PROCESSING && (
                    <span className='badge badge-light-info'>Processing</span>
                  )}
                  {video.status === VideoStatusEnum.COMPLETED && (
                    <span className='badge badge-light-success'>Completed</span>
                  )}
                </div>
              </div>
            </fieldset>

            <fieldset className='row mb-5'>
              <div className='fw-bold'>
                <div className='text-muted mb-1'>Actions</div>

                <div className='d-flex'>
                  <button
                    className='d-none btn btn-sm btn-icon btn-light-success '
                    title='Download video'
                    onClick={() => {}}
                  >
                    <span className='svg-icon svg-icon-1'>
                      <KTSVG
                        path='/media/icons/duotune/files/fil021.svg'
                        className='svg-icon-muted svg-icon-1'
                      />
                    </span>
                  </button>

                  <button
                    className='btn btn-sm btn-icon btn-light-danger '
                    title='Delete'
                    onClick={() => deleteBtnClicked()}
                  >
                    <span className='svg-icon svg-icon-1'>
                      {!deleting && (
                        <KTSVG
                          path='/media/icons/duotune/general/gen027.svg'
                          className='svg-icon-muted svg-icon-1'
                        />
                      )}

                      {deleting && <i className='fa fa-spinner fa-spin' />}
                    </span>
                  </button>

                  {video.status === VideoStatusEnum.COMPLETED && (
                    <a
                      onClick={() => setShowShareWindow(true)}
                      className={clsx('btn btn-sm btn-icon btn-light-primary ms-3 ')}
                    >
                      {/* <i className='fa fa-share-alt fs-3'></i> */}
                      <KTSVG
                        path='/media/icons/duotune/general/gen016.svg'
                        className='svg-icon-muted svg-icon-1'
                      />
                    </a>
                  )}
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </main>

      <footer className='modal-footer p-5 border-top'>
        <button type='button' onClick={() => submitComplete(false)} className='btn btn-light me-2'>
          Close
        </button>
      </footer>

      <Dialog
        modal
        dismissableMask
        closable={false}
        visible={showShareWindow}
        onHide={() => setShowShareWindow(false)}
      >
        <ModalLayout
          onClose={() => setShowShareWindow(false)}
          showHeader
          showFooter
          showCloseButton
          title={`Share: ${video.name}`}
        >
          <VideoSharingWindow video={video} />
        </ModalLayout>
      </Dialog>
    </div>
  );
};

export default VideoDialog;
