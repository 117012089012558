import {Link} from 'react-router-dom';
import {toAbsoluteUrl} from '../../../../_metronic/helpers';

interface Props {
  email: string;
  onTryAgainClick: () => void;
}
export const ConfirmEmail = ({email, onTryAgainClick: tryAgainClicked}: Props) => {
  return (
    <div className='card-body py-15 py-lg-20 text-center'>
      {/* begin::Title */}
      <h1 className='fw-bolder text-gray-900 mb-5'>Verify your email</h1>
      {/* end::Title */}

      <div className='fs-5 mb-4'>
        <span className='fw-semibold text-gray-700'>
          We have sent email to <strong>{email}</strong> to confirm validity of email, once its
          confirmed you will be able to start modeling!
        </span>
      </div>

      {/* begin::Action */}
      <div className='fs-6 mb-8'>
        <span className='fw-semibold text-gray-500'>Did’t receive an email? </span>
        <a onClick={tryAgainClicked} className='link-primary fw-bold'>
          Try Again
        </a>
      </div>
      {/* end::Action */}

      {/* begin::Link */}
      {/* <div className='mb-11'>
        <a href='/metronic8/demo1/../demo1/index.html' className='btn btn-sm btn-primary'>
          Skip for now
        </a>
      </div> */}
      {/* end::Link */}

      {/* begin::Illustration */}
      <div className='mb-0'>
        <img
          src={toAbsoluteUrl('/media/auth/please-verify-your-email.png')}
          className='mw-100 mh-300px theme-light-show'
          alt=''
        />
        <img
          src={toAbsoluteUrl('/media/auth/please-verify-your-email-dark.png')}
          className='mw-100 mh-300px theme-dark-show'
          alt=''
        />
      </div>
      {/* end::Illustration */}
    </div>
  );
};
