import {Dialog} from 'primereact/dialog';
import {useState, useEffect} from 'react';
import {usePermissionChecker} from '../../../../admin/pages/roles/hooks/permission-checker';
import {PermissionLevelEnum} from '../../../../app/types';
import {useAuth} from '../../../../auth';
import useAccountStore from '../../../account.store';
import PurchasePackage from '../../../../billing/Packages/PurchasePackage';
import {ModalLayout} from '../../../../shared/components/ModalLayout';
import {getUserCreditInfo} from '../../../../models-management/api/models-api.fake';

export function AccountBillingBalance() {
  const [showPurchasePackageWindow, setShowPurchasePackageWindow] = useState(false);
  const [creditLimit, setCreditLimit] = useState(0);
  const [currentCredit, setCurrentCredit] = useState(0);
  const percent = () => ((currentCredit - 0) * 100) / (creditLimit - 0);

  useEffect(() => {
    const get = async () => {
      const credits = await getUserCreditInfo();
      setCreditLimit(credits.maxCredits);
      setCurrentCredit(credits.usedCredits);
    };

    get();
  }, []);

  return (
    <div className='card  mb-5 mb-xl-10'>
      <div className='card-body'>
        {/* alert */}
        <div className='d-none notice d-flex bg-light-warning rounded border-warning border border-dashed mb-12 p-6'>
          <div className='d-flex flex-stack flex-grow-1 '>
            <div className=' fw-semibold'>
              <h4 className='text-gray-900 fw-bold'>We need your attention!</h4>
              <div className='fs-6 text-gray-700 '>
                Your payment was declined. To start using tools, please{' '}
                <a
                  href='#'
                  className='fw-bold'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_new_card'
                >
                  Add Payment Method
                </a>
                .
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-7'>
            <h3 className='mb-2'>Subscription Active</h3>
            <p className='fs-6 text-gray-600 fw-semibold mb-6 mb-lg-15'>
              We will send you a notification upon Subscription out of credits
            </p>

            <section className='d-none'>
              <div className='fs-5 mb-2'>
                <span className='text-gray-800 fw-bold me-1'>$24.99</span>
                <span className='text-gray-600 fw-semibold'>Last Package</span>
              </div>
              <div className='fs-6 text-gray-600 fw-semibold'>
                Package with {creditLimit} credits
              </div>
            </section>
          </div>
          <div className='col-lg-5'>
            <div className='d-flex text-muted fw-bold fs-5 mb-3'>
              <span className='flex-grow-1 text-gray-800'>Credits</span>
              <span className='text-gray-800'>
                {currentCredit} of {creditLimit} Used
              </span>
            </div>
            <div className='progress h-8px bg-light-primary mb-2'>
              <div
                className='progress-bar bg-primary'
                role='progressbar'
                style={{
                  width: `${percent()}%`,
                }}
              />
            </div>
            <div className='fs-6 text-gray-600 fw-semibold mb-10'>
              {creditLimit - currentCredit} credits remaining until your out of credits
            </div>
            <div className='d-flex justify-content-end pb-0 px-0'>
              {/* <a
           href='#'
           className='btn btn-light btn-active-light-primary me-2'
           id='kt_account_billing_cancel_subscription_btn'
          >
           Cancel Subscription
          </a> */}
              <button
                className='btn btn-primary'
                onClick={() => setShowPurchasePackageWindow(true)}
              >
                Purchase Package
              </button>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        modal
        dismissableMask
        closable={false}
        visible={showPurchasePackageWindow}
        onHide={() => setShowPurchasePackageWindow(false)}
      >
        <ModalLayout
          title='Purchase Package'
          showCloseButton
          large
          onClose={() => setShowPurchasePackageWindow(false)}
        >
          <PurchasePackage isWindow />
        </ModalLayout>
      </Dialog>
    </div>
  );
}
