import React, {useRef, useState} from 'react';
import {Video} from '../../../vidoes.types';
import {ModelHeaderLayout} from '../../../../shared/components/ModalLayout';
import clsx from 'clsx';
import * as Yup from 'yup';
import {FileUpload} from 'primereact/fileupload';
import {useFormik} from 'formik';
import {toast} from 'react-toastify';
import {FormFieldErrorMessage} from '../../../../../../shared/components/FormFieldErrorMessage';
import {sendIssueReport} from '../../../../app/api/general-api';
import {submitNewTemplate, updateTemplate} from '../../../vidoes.api';
import {SelectButton, SelectButtonChangeEvent} from 'primereact/selectbutton';
import {RadioButton, RadioButtonChangeEvent} from 'primereact/radiobutton';
import HasPermission from '../../../../auth/HasPermission';

interface Props {
  templateId?: number;
  template?: Video;
  submitComplete: (ok?: boolean) => void;
}
const TemplateForm = ({templateId, template, submitComplete}: Props) => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const fileUpload = useRef<FileUpload>(null);

  const initialValues = {
    id: template?.id,
    Name: template?.name,
    NoOfModels: template?.number_of_models || 1,
    published: template?.published || false,
    Files: [],
  };
  const formSchema = Yup.object().shape({
    Name: Yup.string().required('Name is required').min(6, 'Name is too small'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,

    onSubmit: async (values, {setStatus, setSubmitting}) => {
      const files = fileUpload.current?.getFiles();
      console.log(values, files);

      setSubmitting(true);
      setStatus();

      const changed = getChangedValues();

      try {
        template
          ? await updateTemplate(changed, template.id, files || [], setProgress)
          : await submitNewTemplate(values, files || [], setProgress);

        toast.success('Template submitted successfully');
        submitComplete(true);
      } catch (error: Error | any) {
        setStatus(error?.response?.data.msg || error?.message || 'Failed to submit ');
        toast.error('Submit Failed');
      } finally {
        setSubmitting(false);
      }
    },
  });

  const getChangedValues = () => {
    const values = formik.values;
    const initialValues = formik.initialValues as any;

    return Object.entries(values).reduce((acc, [key, value]) => {
      const hasChanged = initialValues[key] !== value;

      if (hasChanged) {
        acc[key] = value;
      }

      return acc;
    }, {} as any);
  };

  return (
    <div>
      <div>
        <ModelHeaderLayout
          title={template ? `Edit Template ${template.name}` : `Add New Template`}
          showCloseButton
          onClose={submitComplete}
        >
          <HasPermission code='menu.all-templates'>
            <>
              {!template?.published && <div className='badge badge-light-primary'>Draft</div>}
              {template?.published && (
                <div className='badge badge-success'>
                  Published
                  <i className='ki-outline ki-verify  text-white ms-1'></i>
                </div>
              )}
            </>
          </HasPermission>
        </ModelHeaderLayout>

        <main
          className={clsx('modal-body p-5 px-6 scroll', {
            'overlay overlay-block': loading,
          })}
        >
          <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_signup_form'
            onSubmit={formik.handleSubmit}
          >
            {template && !formik.status && (
              <div className='mb-5 alert alert-primary'>
                <div className='alert-text font-weight-bold'>
                  Please note that the new files will override the ones you uploaded before
                </div>
              </div>
            )}

            {!template && !formik.status && (
              <div className='mb-5 alert alert-primary'>
                <div className='alert-text font-weight-bold'>Please upload the templates files</div>
              </div>
            )}

            {formik.status && (
              <div className='mb-5 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            )}

            <fieldset className='row fv-row mb-4'>
              <label className='form-label fw-bolder text-dark fs-6'>Name</label>
              <input
                placeholder='Enter template name'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('Name')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.Name && formik.errors.Name,
                  },
                  {
                    'is-valid': formik.touched.Name && !formik.errors.Name,
                  }
                )}
              />
              {formik.touched.Name && formik.errors.Name && (
                <FormFieldErrorMessage>{formik.errors.Name}</FormFieldErrorMessage>
              )}
            </fieldset>

            <fieldset className='row fv-row mb-4'>
              <label className='form-label fw-bolder text-dark fs-6'>No Of Models</label>
              <input
                type='number'
                autoComplete='off'
                {...formik.getFieldProps('NoOfModels')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.NoOfModels && formik.errors.NoOfModels,
                  },
                  {
                    'is-valid': formik.touched.NoOfModels && !formik.errors.NoOfModels,
                  }
                )}
              />
              {formik.touched.NoOfModels && formik.errors.NoOfModels && (
                <FormFieldErrorMessage>{formik.errors.NoOfModels}</FormFieldErrorMessage>
              )}
            </fieldset>

            <fieldset className='row fv-row mb-4'>
              <label className='form-label fw-bolder text-dark fs-6'>Attachments</label>

              <FileUpload
                multiple
                auto
                ref={fileUpload}
                name='files[]'
                headerClassName='py-2 px-4'
                contentClassName='text-muted'
                chooseOptions={{className: 'btn btn-primary btn-sm'}}
                uploadOptions={{className: 'd-none'}}
                cancelOptions={{className: 'd-none'}}
                maxFileSize={1000 * 1024 * 1024}
                emptyTemplate={<p className='m-0'>Drag and drop files here.</p>}
                pt={{badge: {root: {className: 'd-none'}}}} // remove pending badge
                customUpload
              />
            </fieldset>
          </form>
          {formik.isSubmitting && (
            <div className='m-auto mw-75'>
              <div>Uploading files ...</div>
              <div className='progress h-7px bg-primary bg-opacity-50 mt-5'>
                <div
                  className='progress-bar bg-primary'
                  role='progressbar'
                  style={{width: `${progress}%`}}
                ></div>
              </div>
            </div>
          )}
        </main>

        <footer className='modal-footer p-5 border-top d-flex justify-content-between'>
          <div>
            {!template?.published && (
              <HasPermission code='templates.publish'>
                <button
                  type='button'
                  onClick={() => {
                    formik.setFieldValue('published', true);
                    formik.submitForm();
                  }}
                  className='btn btn-light-primary '
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  <span className='indicator-label'>Publish</span>
                </button>
              </HasPermission>
            )}

            {template?.published && (
              <HasPermission code='templates.publish'>
                <button
                  type='button'
                  onClick={() => {
                    formik.setFieldValue('published', false);
                    formik.submitForm();
                  }}
                  className='btn btn-light-primary '
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  <span className='indicator-label'>Unpublish</span>
                </button>
              </HasPermission>
            )}
          </div>

          <div>
            <button type='button' onClick={() => submitComplete()} className='btn btn-light me-2'>
              Close
            </button>

            <button
              type='button'
              onClick={() => formik.submitForm()}
              className='btn btn-primary me-2'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!formik.isSubmitting && <span className='indicator-label'>Save</span>}
              {formik.isSubmitting && (
                <span className='indicator-progress d-block'>
                  Saving ...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default TemplateForm;
