import {PageTitle} from '../../../../../_metronic/layout/core';
import HasPermission from '../../../auth/HasPermission';
import CountersWidget from './components/CountersChartWidget';
import LoginSessionTable from './components/LoginSessionTable';
import LoginSessionsWidget from './components/LoginSessionsWidget';
import ModelsCountWidget from './components/ModelsCountWidget';
import TotalModelsWidget from './components/TotalModelsWidget';
import UsersCountWidget from './components/UsersCountWidget';

export const AdminOverviewPage = () => {
  return (
    <>
      <PageTitle breadcrumbs={[{title: 'User Dashboard', path: '/', isActive: true}]}>
        Overview
      </PageTitle>

      <div className='row container-fluid mx-auto'>
        <HasPermission code='admin-widgets.models-counters-by-status'>
          <div className='col-lg-6 col-xxl-4'>
            <ModelsCountWidget />
          </div>
        </HasPermission>

        <div className='col-lg-8 col-xxl-7'>
          <HasPermission code='admin-widgets.total-models-users-counters'>
            <div className='row'>
              <div className='col-md-6'>
                <UsersCountWidget />
              </div>

              <div className='col-md-6'>
                <TotalModelsWidget />
              </div>
            </div>
          </HasPermission>

          <HasPermission code='admin-widgets.models-statistics-chart'>
            <CountersWidget />
          </HasPermission>
        </div>

        {/* <div className='col-lg-5 col-xl-4'>
          <UsersCountWidget />
        </div> */}
      </div>
    </>
  );
};
