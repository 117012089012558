import clsx from 'clsx';
import {useFormik} from 'formik';
import {useState} from 'react';
import {toast} from 'react-toastify';
import {FormFieldErrorMessage} from '../../../../../../shared/components/FormFieldErrorMessage';
import {ApiError} from '../../../../auth/core/apiModels';
import {ModelHeaderLayout} from '../../../../shared/components/ModalLayout';
import * as Yup from 'yup';
import {sendBulkEmails} from '../../../api/users-api';

import {Editor} from 'primereact/editor';

interface Props {
  submitComplete: (data: 'cancel' | 'ok') => void;
  usersIds: number[];
}

const initialValues = {
  Subject: '',
  EmailBody: '',
};

const validationSchema = Yup.object().shape({
  Subject: Yup.string().required('Subject is required').min(6, 'Subject is too short'),
  EmailBody: Yup.string().required('Email body is required').min(10, 'Email body is too short'),
});

const SendEmail = ({usersIds, submitComplete}: Props) => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true);

      try {
        await sendBulkEmails(usersIds, values.Subject, values.EmailBody);
        toast.success('Emails sent successfully');
        submitComplete('ok');
      } catch (error: any) {
        setSubmitting(false);
        setLoading(false);

        const responseData: ApiError | undefined = error?.response?.data;
        if (responseData) setStatus(responseData);
        else setStatus('Failed!');
      }
    },
  });

  return (
    <div>
      <ModelHeaderLayout
        title={`Send Emails`}
        showCloseButton
        onClose={() => submitComplete('cancel')}
      ></ModelHeaderLayout>

      <main
        className={clsx('modal-body modal-md p-5 px-6 scroll', {
          'overlay overlay-block': formik.isSubmitting,
        })}
      >
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_signup_form'
          onSubmit={formik.handleSubmit}
        >
          {formik.status && (
            <div className='mb-5 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}

          <fieldset className='row fv-row mb-4'>
            <label className='form-label fw-bolder text-dark fs-6'>Subject</label>

            <input
              placeholder='Enter email subject'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('Subject')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.Subject && formik.errors.Subject,
                },
                {
                  'is-valid': formik.touched.Subject && !formik.errors.Subject,
                }
              )}
            />
            {formik.touched.Subject && formik.errors.Subject && (
              <FormFieldErrorMessage>{formik.errors.Subject}</FormFieldErrorMessage>
            )}
          </fieldset>

          <fieldset className='row fv-row mb-4'>
            <label className='form-label fw-bolder text-dark fs-6'>Email Body</label>

            <Editor
              className='h-300px'
              value={formik.values.EmailBody}
              onTextChange={(e) => formik.setFieldValue('EmailBody', e.htmlValue)}
              style={{height: '320px'}}
            />

            {formik.touched.EmailBody && formik.errors.EmailBody && (
              <FormFieldErrorMessage>{formik.errors.EmailBody}</FormFieldErrorMessage>
            )}
          </fieldset>
        </form>
      </main>

      <footer className='modal-footer p-5 border-top'>
        <button
          type='button'
          onClick={() => submitComplete('cancel')}
          className='btn btn-light me-2'
        >
          Close
        </button>
        <button
          type='button'
          onClick={() => formik.submitForm()}
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Send</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Sending emails...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </footer>
    </div>
  );
};

export default SendEmail;
