/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import React from 'react';
import {Link} from 'react-router-dom';
import CountUp from 'react-countup';
import {KTSVG} from '../../../../_metronic/helpers';

interface Props {
  label: string;
  count: number;
  link?: string;
  iconPath?: string;
  iconColor?: 'primary' | 'warning' | 'danger' | 'success' | 'info' | '';
}

const CountWidget = ({label, iconPath: icon_path, iconColor, link, count}: Props) => {
  return (
    <div>
      <Link
        to={link || ''}
        className='card bg-body hoverable card-xl-stretch mb-xl-8 cursor-pointer'
      >
        <div className='card-body'>
          <div className='d-flex justify-content-between'>
            <div>
              <div className='text-gray-900 fw-bold fs-1'>
                <CountUp end={count} duration={1} />
              </div>
              <div className='fw-semibold text-gray-500 fs-5 capitalized'>{label} </div>
            </div>

            <KTSVG
              path={icon_path || '/media/icons/duotune/communication/com005.svg'}
              className={clsx(
                'svg-icon svg-icon-primary svg-icon-5x ms-n1',
                `svg-icon-${iconColor || 'primary'}`
              )}
            />
          </div>
        </div>
      </Link>
    </div>
  );
};

export default CountWidget;
