import {create} from 'zustand';
import {Payment, PaymentsFilter} from '../types';

interface Props {
  payments: Payment[];
  setPayments: (payments: Payment[]) => void;

  filters: Partial<PaymentsFilter>;
  setFilters: (filters: Partial<PaymentsFilter>) => void;
}

const usePaymentsStore = create<Props>((set, get) => ({
  payments: [],
  setPayments: (payments: Payment[]) => set({payments: payments}),

  filters: {},
  setFilters: (filters: Partial<PaymentsFilter>) => set({filters: {...get().filters, ...filters}}),
}));

export default usePaymentsStore;
