/* eslint-disable jsx-a11y/anchor-is-valid */
import {ReactNode, useEffect, useState} from 'react';
import {ErrorPlaceholder} from '../../../../../../shared/components/ErrorPlaceholder';
import {LoadingPlaceholder} from '../../../../../../shared/components/LoadingPlaceholder';
import {useMatch} from 'react-router-dom';
import {useAuth} from '../../../../auth';
import {getBase64codeForImage, parseError} from '../../../../../../shared/utils';
import {getOrganizationById} from '../../../api/organizations-api';
import useOrgStore from '../organization.store';
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers';
import {RolesList} from '../../roles/components/RolesList';
import {BrandingForm} from './BrandingForm';
import {OrganizationForm} from './OrganizationForm';
import {usePermissionSelector} from '../../roles/hooks/permissions-selector';
import {dummyPerms} from '../../roles/dummyPerms';
import {OrgUsersTab} from './OrgUsersTab';
import {OrgPermissionsTab} from './OrgPermissionsTab';

interface Props {
  children: ReactNode;
}

const ViewOrganization = ({children}: Props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const {org, setOrg} = useOrgStore();
  const [id, setId] = useState<string>();
  const match = useMatch('/admin/organization/:id/*');

  useEffect(() => {
    if (match) {
      const {id} = match.params;
      setId(id);
    }
  }, [match]);

  useEffect(() => {
    const get = async () => {
      if (!id) return;
      try {
        setLoading(true);

        const data = await getOrganizationById(+id);
        setOrg({...data});

        setLoading(false);
      } catch (error: any) {
        setError(parseError(error));
        setLoading(false);
      }
    };

    if (id) get();
  }, [id]);

  if (loading) return <LoadingPlaceholder label='Loading...' />;
  if (error) return <ErrorPlaceholder error={error} />;

  return (
    <div>
      <div className='d-flex flex-column flex-column-fluid'>
        {/* Page Title */}
        <section className='app-toolbar  py-3 py-lg-6 '>
          <div className='app-container  container-fluid d-flex flex-stack '>
            <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3 '>
              <h1 className='page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0'>
                Organization Details
              </h1>
              <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
                <li className='breadcrumb-item text-muted'>
                  <a className='text-muted text-hover-primary'>Organizations </a>
                </li>
                <li className='breadcrumb-item'>
                  <span className='bullet bg-gray-500 w-5px h-2px' />
                </li>
                <li className='breadcrumb-item text-muted'>View Organization </li>
              </ul>
            </div>
            <div className='d-flex align-items-center gap-2 gap-lg-3'></div>
          </div>
        </section>

        <section className='app-content  flex-column-fluid '>
          <div className='app-container  container-fluid '>
            <div className='d-flex flex-column flex-xl-row'>
              <aside className='flex-column flex-lg-row-auto w-100 w-xl-350px mb-10'>
                <div className='card mb-5 mb-xl-8'>
                  <div className='card-body pt-15'>
                    <div className='d-flex flex-center flex-column mb-5'>
                      <div className='symbol symbol-100px symbol-circle mb-7'>
                        {org && org.dark_logo && (
                          <a href='#' className='mb-12 theme-light-show'>
                            <img
                              alt='Logo'
                              src={getBase64codeForImage(org.dark_logo)}
                              className='h-50px'
                            />
                          </a>
                        )}
                        {org && org.light_logo && (
                          <a href='#' className='mb-12 theme-dark-show'>
                            <img
                              alt='Logo'
                              src={getBase64codeForImage(org.light_logo)}
                              className='h-50px'
                            />
                          </a>
                        )}
                        {!org && (
                          <a href='#' className='mb-12'>
                            <img
                              alt='Logo'
                              src={toAbsoluteUrl('/media/logos/colorful-lavendar.svg')}
                              className='theme-dark-show h-50px'
                            />
                            <img
                              alt='Logo'
                              src={toAbsoluteUrl('/media/logos/colorful-lavendar.svg')}
                              className='theme-light-show h-50px'
                            ></img>
                          </a>
                        )}

                        {/* <img src='/metronic8/demo1/assets/media/avatars/300-1.jpg' alt='image' /> */}
                      </div>

                      <a className='fs-3 text-gray-800 text-hover-primary fw-bold mb-1'>
                        {org?.name}
                      </a>

                      <div className='fs-5 fw-semibold text-muted mb-6'>{org?.footer_text} </div>
                      <div className='d-flex flex-wrap flex-center'>
                        <article className='border border-gray-300 border-dashed rounded py-3 px-3 mb-3'>
                          <div className='fs-4 fw-bold text-gray-700'>
                            <span className='w-75px'>{org?.users_count}</span>
                            <i className='ki-duotone ki-people fs-3 text-primary'>
                              <span className='path1' />
                              <span className='path2' />
                            </i>{' '}
                          </div>
                          <div className='fw-semibold text-muted'>Users</div>
                        </article>

                        <article className='border border-gray-300 ms-2 border-dashed rounded py-3 px-3 mb-3'>
                          <div className='fs-4 fw-bold text-gray-700 text-center'>
                            <span className='w-75px'>{org?.permissions?.length}</span>
                            <i className='ki-duotone ki-dot fs-3 text-success'>
                              <span className='path1' />
                              <span className='path2' />
                            </i>{' '}
                          </div>
                          <div className='fw-semibold text-muted'>Permissions</div>
                        </article>
                      </div>
                    </div>

                    <div className='d-flex flex-stack fs-4 py-3'>
                      <div
                        className='fw-bold rotate collapsible active'
                        data-bs-toggle='collapse'
                        role='button'
                        aria-expanded='true'
                        aria-controls='kt_customer_view_details'
                      >
                        Details
                      </div>
                      <span
                        data-bs-toggle='tooltip'
                        data-bs-trigger='hover'
                        data-bs-original-title='Edit customer details'
                        data-kt-initialized={1}
                      >
                        <a
                          href='#'
                          className='d-none btn btn-sm btn-light-primary'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_update_customer'
                        >
                          Edit
                        </a>
                      </span>
                    </div>
                    <div className='separator separator-dashed my-3' />
                    <div id='kt_customer_view_details' className='collapse show' style={{}}>
                      <div className='py-5 fs-6'>
                        {/* <div className='badge badge-light-info d-inline'>Premium user</div> */}
                        <div className='fw-bold mt-5'>Account ID</div>
                        <div className='text-gray-600'>{org?.id}</div>
                        <div className='fw-bold mt-5'>Domain</div>
                        <div className='text-gray-600'>
                          <a
                            href={org?.domain}
                            target='_blank'
                            className='text-gray-600 text-hover-primary'
                            rel='noreferrer'
                          >
                            {org?.domain}
                          </a>
                        </div>
                        {/* <div className='fw-bold mt-5'>Date Created</div> */}
                        {/* <div className='text-gray-600'>14 Dec 2020, 8:43 pm (dummy)</div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </aside>

              <div className='flex-lg-row-fluid ms-lg-15'>
                <ul
                  className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-8'
                  role='tablist'
                >
                  <li className='nav-item' role='presentation'>
                    <a
                      className='nav-link text-active-primary pb-4 active'
                      data-bs-toggle='tab'
                      href='#branding_tab'
                      aria-selected='true'
                      role='tab'
                    >
                      Info.
                    </a>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <a
                      className='nav-link text-active-primary pb-4 '
                      data-bs-toggle='tab'
                      href='#permissions_tab'
                      aria-selected='false'
                      role='tab'
                      tabIndex={-1}
                    >
                      Permissions
                    </a>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <a
                      className='nav-link text-active-primary pb-4 '
                      data-bs-toggle='tab'
                      href='#roles_tab'
                      aria-selected='false'
                      role='tab'
                      tabIndex={-1}
                    >
                      Roles
                    </a>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <a
                      className='nav-link text-active-primary pb-4 '
                      data-bs-toggle='tab'
                      href='#users_tab'
                      aria-selected='true'
                      role='tab'
                    >
                      Users
                    </a>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <a
                      className='nav-link text-active-primary pb-4 '
                      data-bs-toggle='tab'
                      href='#stats_tab'
                      aria-selected='true'
                      role='tab'
                    >
                      Statistics
                    </a>
                  </li>

                  <li className='nav-item' role='presentation'></li>
                  <li className='nav-item ms-auto'></li>
                </ul>
                <div className='tab-content' id='myTabContent'>
                  <div className='tab-pane fade active show' id='branding_tab' role='tabpanel'>
                    <OrganizationForm />
                    <BrandingForm />
                  </div>
                  <div className='tab-pane fade ' id='permissions_tab' role='tabpanel'>
                    <OrgPermissionsTab org={org} setOrg={setOrg}></OrgPermissionsTab>
                  </div>
                  <div className='tab-pane fade ' id='roles_tab' role='tabpanel'>
                    {org && <RolesList isOrganizationView org_id={org.id} />}
                  </div>

                  <div className='tab-pane fade' id='users_tab' role='tabpanel'>
                    {org?.id !== undefined && <OrgUsersTab orgId={org.id} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className='app-container container-xxl'>{children}</div>
    </div>
  );
};

export default ViewOrganization;
