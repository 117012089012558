import React, {useEffect, useState} from 'react';
import {usePermissionSelector} from '../hooks/permissions-selector';
import {Organization, Permission} from '../../../../app/types';

import {InputSwitch} from 'primereact/inputswitch';
import {getOrganizationById} from '../../../api/organizations-api';
import {useAuth} from '../../../../auth';
import {getAppPermissions} from '../roles-api';
import {Dropdown} from 'primereact/dropdown';
import {AppLocator} from '../../../../../../app-locator';
import {LoadingPlaceholder} from '../../../../../../shared/components/LoadingPlaceholder';

interface Props {
  type: 'organization' | 'role';
  orgId?: number;
  permissions: Permission[];
  onChange: (permissions: Permission[]) => void;
}

const permissionLevelsOptions = [
  {id: 0, name: 'User'},
  {id: 1, name: 'Organization'},
  {id: 2, name: 'System'},
];
export function PermissionsComponent({type, permissions, orgId, onChange}: Props) {
  const {
    allPermissions,
    groupedPermissions,
    groups,
    selectAll,
    deselectAll,
    setPermissions,
    toggleSelection,
    selectPermissions,
  } = usePermissionSelector();

  const [org, setOrg] = useState<Organization>();
  const {currentUser} = useAuth();

  const fetchAllPerms = async () => {
    const allPerms =
      type === 'organization'
        ? await getAppPermissions()
        : (await getOrganizationById(orgId || 0)).permissions;

    if (!allPerms) return;

    selectPermissions(permissions, allPerms);
  };

  const fetchOrganization = async () => {
    const org = await getOrganizationById(orgId || 0);
    setOrg(org);
  };

  const updatePermissionLevel = (perm: Permission, level?: number) => {
    const permission = allPermissions.find((p) => p.code === perm.code);
    if (!permission) return;

    permission.level = level;

    setPermissions(allPermissions);
  };

  const getFilteredLevels = (perm: Permission) => {
    if (!perm.has_level || !org) return [];

    if (type === 'organization') return permissionLevelsOptions;

    const orgPermissionLevel = org.permissions?.find((x) => x.code === perm.code)?.level;

    if (orgPermissionLevel == undefined) return;

    return permissionLevelsOptions.filter((d) => d.id <= orgPermissionLevel);
  };

  useEffect(() => {
    fetchAllPerms();

    if (orgId === undefined) return;
    fetchOrganization();
  }, [orgId]);

  useEffect(() => {
    onChange(allPermissions.filter((d) => d.selected));
  }, [allPermissions]);

  // if (!org) return <LoadingPlaceholder />;

  return (
    <>
      {/* <button
        className='btn btn-light-primary btn-sm me-2'
        type='button'
        onClick={() => selectPermissions(permissions)}
      >
        Select Org Perms
      </button> */}
      <div className='mb-3'>
        <button
          className='btn btn-light-primary btn-sm me-2'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='.multi-collapse'
          aria-expanded='false'
          aria-controls={groups?.join(' ')}
        >
          De/Collapse All
        </button>
        <button className='btn btn-light-primary btn-sm me-2' type='button' onClick={selectAll}>
          Select All
        </button>
        <button className='btn btn-light-primary btn-sm me-2' type='button' onClick={deselectAll}>
          Deselect All
        </button>
        <span>
          Selected: {allPermissions.filter((d) => d.selected).length} \ {allPermissions.length}
        </span>
      </div>

      {groups?.length === 0 && (
        <div className='text-muted h-100px d-flex flex-center fs-5'>
          No permissions for this organization!
        </div>
      )}
      {groups?.map((group) => (
        <div className='me-n7 pe-7' key={group}>
          <a
            className='fw-bold fs-4 rotate collapsible mt-4 collapsed text-dark capitalized'
            data-bs-toggle='collapse'
            href={`#${group}`}
            role='button'
          >
            {group}
            <span className='ms-2 rotate-180'>
              <i className='ki-duotone ki-down fs-3' />{' '}
            </span>
          </a>

          <div id={group} className='collapse multi-collapse show'>
            <div className='table-responsive ml-3'>
              <table className='table align-middle table-row-dashed table-hover fs-6 gy-5'>
                <tbody className='text-gray-600 fw-semibold'>
                  {groupedPermissions.get(group)?.map((perm) => (
                    <tr key={perm.code}>
                      <td className='text-gray-800 w-50 py-2' title={perm.code}>
                        <label htmlFor={perm.code}>{perm.name}</label>
                      </td>
                      <td className='py-2'>
                        <div className='d-flex align-items-center'>
                          <InputSwitch
                            className='me-4'
                            inputId={perm.code}
                            name={perm.code}
                            checked={perm.selected}
                            onChange={(e: any) => toggleSelection(perm)}
                          />

                          {!!perm.has_level && !perm.selected && (
                            <i
                              className='fa fa-shield'
                              title='This permission has multiple levels'
                            />
                          )}

                          {!!perm.has_level && perm.selected && (
                            <div className='d-flex align-items-center'>
                              <div className='small'>Level:</div>

                              <Dropdown
                                className='ms-1 lh-1'
                                name={perm.code}
                                optionLabel='name'
                                optionValue='id'
                                value={Number(perm.level)}
                                options={getFilteredLevels(perm)}
                                onChange={(e) => updatePermissionLevel(perm, e.target.value)}
                              />
                            </div>
                          )}
                        </div>
                      </td>
                      <td className='py-2'>
                        <div className='text-muted'>{perm.description}</div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
