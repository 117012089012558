import HasPermission from '../../../auth/HasPermission';
import AccountCredits from './components/AccountCredits';
import Info from './components/Info';

const AccountOverview = () => {
  return (
    <div>
      <Info />
    </div>
  );
};

export default AccountOverview;
