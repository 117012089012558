import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import CreativeEditorSDK, {
  AssetResult,
  Configuration,
  CreativeEngine,
  UserInterfaceElements,
} from '@cesdk/cesdk-js';
import VIDEO_SCENES_ASSETS from './data/StaticVideoScenesAssets.json';
import loadAssetSourceFromContentJSON from './components/loadAssetSourceFromContentJSON';
import {formatAssetsToPresets} from './components/PageFormatAssetLibrary';
import PAGE_FORMAT_ASSETS from './data/PageFormatAssets.json';
import {createApplyFormatAsset} from './components/createApplyFormatAsset';
import {getMediaItemsByIds, getSingleMedia} from '../../media.api';
import {fetchImageAsFile, imageFileToDataURL, sleep} from '../../../../../shared/utils';
import {Media} from '../../media.types';
import {useSidebarStatus} from '../../../../../shared/hooks/useSidebarStatus';

const config: Configuration = {
  license: 'gcfOwoDKORGrJuFPRmOIacYqYrnyiIq__l41xiF-qwtNUGx2XOreVOKzFAaadyMq', // vree
  // license: 'mtLT-_GJwMhE7LDnO8KKEma7qSuzWuDxiKuQcxHKmz3fjaXWY2lT3o3Z2VdL5twm', // test
  userId: 'guides-user',
  // Enable local uploads in Asset Library
  role: 'Creator', // 'Adopter' 'Viewer'
  theme: 'light',
  //   license: process.env.NEXT_PUBLIC_LICENSE,
  i18n: {
    en: {
      'libraries.ly.img.audio.ly.img.audio.label': 'Soundstripe',
      //   ...pageFormatI18n(PAGE_FORMAT_ASSETS.assets),
      'libraries.ly.img.video.scene.label': 'Example Templates',
    },
  },
  // Specify baseURL for all relative URLs.
  //   baseURL: 'https://img.ly/showcases/cesdk/cases/video-ui/page-formats/square.svg', // or 'https://cdn.mydomain.com/assets'
  //   core: {
  //     // Specify location of core assets, required by the engine.
  //     baseURL: 'imgly-assets/core/',
  //   },
  ui: {
    pageFormats: formatAssetsToPresets(PAGE_FORMAT_ASSETS),
    elements: {
      view: 'default',
      panels: {
        settings: false, // customize editor button
      },
      dock: {
        show: true,
      },
      navigation: {
        position: UserInterfaceElements.NavigationPosition.Top,
        action: {
          export: true,
          // back: true, // true or false
          load: true, // true or false
          // close: true, // true or false
          // save: true, // true or false
          // custom: [
          //   {
          //     label: 'common.custom', // string or i18n key
          //     iconName: 'default', // one of 'default', 'download', 'upload', or 'save'
          //     style: {
          //       backgroundColor: '#000000', // Custom background color
          //       color: 'black',             // Custom text color
          //       borderRadius: '20px',        // Rounded corners
          //     },
          //     callback: () => {
          //       // callback signature is `() => void | Promise<void>`
          //       // place custom functionality here
          //     }
          //   }
          // ]
          download: true, // save design file
        },
      },
    },
  },
  callbacks: {
    onUpload: 'local',
    onDownload: 'download',
    onExport: 'download',
    onLoad: 'upload',
    onSave: (scene) => {
      window.alert('Save callback!');
      console.info(scene);
    },
    // onExport: (blobs, options) => {
    //   window.alert('Export callback!');
    //   console.info(options.mimeType);
    //   console.info(options.jpegQuality);
    //   console.info(options.pages);
    //   return Promise.resolve();
    // },
    // onUpload: (file, onProgress) => {
    //   window.alert('Upload callback!');
    //   const newImage = {
    //     id: 'exampleImageIdentifier',
    //     meta: {
    //       uri: 'https://YOURSERVER/images/file.jpg',
    //       thumbUri: 'https://YOURSERVER/images/thumb.jpg'
    //     }
    //   };
    //   return Promise.resolve(newImage);
    // }
    // onBack: () => {
    //   window.alert('Back callback!');
    // },
    // onClose: () => {
    //   window.alert('Close callback!');
    // },
  },
};

const VideoEditor = () => {
  const {ids: routeIds} = useParams<{ids: string}>();
  const ids = routeIds?.split(',') || [];
  const [medias, setMedias] = useState<Media[]>([]);

  const cesdkContainer = useRef<HTMLDivElement | null>(null); // Use ref for the container
  const [cesdk, setCesdk] = useState<any | null>(null); // Use state with proper type
  // const isSidebarOpen = useSidebarStatus();

  const [isSidebarMinimized, setIsSidebarMinimized] = useState(
    document.body.hasAttribute('data-kt-aside-minimize')
  );

  useEffect(() => {
    // Callback function to execute when mutations are observed
    const observerCallback = (mutationsList: any) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'data-kt-aside-minimize') {
          setIsSidebarMinimized(document.body.hasAttribute('data-kt-aside-minimize'));
        }
      }
    };

    // Create a MutationObserver instance linked to the callback function
    const observer = new MutationObserver(observerCallback);

    // Start observing the target node for configured mutations (attributes only)
    observer.observe(document.body, {
      attributes: true, // Observe attribute changes
      attributeFilter: ['data-kt-aside-minimize'], // Only watch for this specific attribute
    });

    // Clean up the observer on component unmount
    return () => observer.disconnect();
  }, []); // Empty dependency array since MutationObserver will watch for changes

  useEffect(() => {
    if (!cesdkContainer.current || medias.length === 0) return;

    let cleanedUp = false;
    let instance: any;
    // Initialize the CreativeEditorSDK instance
    CreativeEditorSDK.create(cesdkContainer.current, config).then(async (_instance) => {
      instance = _instance;
      const engine = instance.engine;

      if (cleanedUp) {
        instance.dispose();
        return;
      }

      // Import default assets from img.ly CDN
      await instance.addDefaultAssetSources();
      await instance.addDemoAssetSources({
        sceneMode: 'Video',
        excludeAssetSourceIds: ['ly.img.video', 'ly.img.image'],
      });

      // instance.ui.setNavigationBarOrder([
      //   'ly.img.back.navigationBar',
      //   'ly.img.undoRedo.navigationBar',

      //   'ly.img.spacer',

      //   'ly.img.title.navigationBar',

      //   'ly.img.spacer',

      //   'ly.img.zoom.navigationBar',
      //   'ly.img.preview.navigationBar',
      //   'ly.img.actions.navigationBar',
      //   'ly.img.close.navigationBar'
      // ]);

      buildDockMenu();

      // buildInspectorMenu(instance);

      instance.ui.addAssetLibraryEntry({
        id: 'ly.img.video.scene',
        sourceIds: ['ly.img.video.scene'],
      });

      instance.ui.addAssetLibraryEntry(PAGE_FORMATS_INSERT_ENTRY_ASSET);

      // Disable placeholder and preview features
      instance.feature.enable('ly.img.placeholder', false);
      instance.feature.enable('ly.img.preview', false);

      engine.editor.setSettingBool('page/title/show', false);

      instance.ui.addAssetLibraryEntry({
        id: 'background-videos-asset-source',
        sourceIds: ['background-videos'],
        previewLength: 3,
        gridColumns: 3,
        gridItemHeight: 'square',
        previewBackgroundType: 'contain',
        gridBackgroundType: 'contain',
        icon: ({theme, iconSize}: any) => {
          if (theme === 'dark') {
            if (iconSize === 'normal') {
              return 'https://img.ly/static/cesdk/guides/icon-normal-dark.svg';
            } else {
              return 'https://img.ly/static/cesdk/guides/icon-large-dark.svg';
            }
          }

          if (iconSize === 'normal') {
            return 'https://img.ly/static/cesdk/guides/icon-normal-light.svg';
          } else {
            return 'https://img.ly/static/cesdk/guides/icon-large-light.svg';
          }
        },
      });

      addCustomAssetsSource();

      await editVideosAssets();

      //   loadAssetSourceFromContentJSON(
      //     engine,
      //     VIDEO_SCENES_ASSETS,
      //     caseAssetPath('/templates'),
      //     async (asset: any) => {
      //       console.log('Load assets:', asset);
      //       if (!asset.meta || !asset.meta.uri) throw new Error('Asset does not have a uri');
      //       await engine.scene.loadFromURL(asset.meta.uri);
      //       persistSelectedTemplateToURL(asset.id);
      //       return undefined;
      //     }
      //   );

      //   loadAssetSourceFromContentJSON(
      //     engine,
      //     AUDIO_ASSETS,
      //     caseAssetPath('/audio')
      //   );

      loadAssetSourceFromContentJSON(
        engine,
        PAGE_FORMAT_ASSETS,
        caseAssetPath('/page-formats'),
        createApplyFormatAsset(engine)
      );

      // instance
      //   .loadFromURL(
      //     caseAssetPath(`/templates/${loadSelectedTemplateFromURL()}.scene`)
      //   )
      //   .catch(() => {
      //     // Fallback to motion template if the selected template fails to load, e.g due to 404
      //     instance.loadFromURL(caseAssetPath(`/templates/motion.scene`));
      //   });

      setCesdk(instance);

      // const videoClip = await engine.block.create({
      //   type: 'video',
      //   src: 'https://file-examples.com/storage/feb05093336710053a32bc1/2017/04/file_example_MP4_640_3MG.mp4', // Replace with your video URL
      // });

      // Add the video clip to the scene
      // await engine.scene.addBlock(videoClip);

      await instance.createVideoScene();

      // await engine.scene.createFromVideo('https://img.ly/static/ubq_video_samples/bbb.mp4');

      engine.scene.createFromVideo(medias[0].video_url);

      setTimeout(() => {
        const maxExportSizeInPixels = engine.editor?.getMaxExportSize();

        const availableMemoryInBytes = Number(engine.editor?.getAvailableMemory());

        console.log(
          `max export: ${maxExportSizeInPixels} pixles, \navailable memory: ${Math.round(
            availableMemoryInBytes / (1024 * 1024)
          )} MB`
        );
      }, 1000);

      // const imageElement = engine.block.findAll()[0];
      // console.log('imageElement', imageElement);
      // if (imageElement) {
      //   // set image element to be selected
      //   engine.block.select(imageElement);
      // }
      // const videoClip = await engine.block.create('graphic');
      // const videoFill = engine.block.createFill('//ly.img.ubq/fill/video');

      // await engine.block.setString(videoFill, 'fill/video/fileURI', medias[0].video_url);
      // engine.block.appendChild(videoClip, videoFill);

      // const block = engine.block.create('graphic');

      // const videoFill = engine.block.createFill('//ly.img.ubq/fill/video');

      // engine.block.setString(videoFill, 'fill/video/fileURI', medias[0].video_url);
      // engine.block.appendChild(scene, videoFill);

      //
      function buildDockMenu() {
        instance.ui.setDockOrder([
          {
            id: 'ly.img.assetLibrary.dock',
            key: 'pageFormats',
            label: 'Formats',
            icon: () => caseAssetPath('/page-sizes-large-icon.svg'),
            entries: ['pageFormats'],
          },
          // 'ly.img.separator',
          // {
          //   id: 'ly.img.assetLibrary.dock',
          //   key: 'examples',
          //   label: 'libraries.ly.img.video.scene.label',
          //   icon: () => caseAssetPath('/static-video-scenes-icon.svg'),
          //   entries: ['ly.img.video.scene']
          // },
          // 'ly.img.separator',
          {
            id: 'ly.img.assetLibrary.dock',
            key: 'empty-custom-asset-source2',
            icon: '@imgly/Video',
            label: 'Selected Media ',
            entries: ['ly.img.video'],
          },

          ...instance.ui
            .getDockOrder()
            .filter(({key}: any) =>
              [
                'ly.img.image',
                'ly.img.audio',
                'ly.img.text',
                'ly.img.upload',
                'ly.img.vectorpath',
                'ly.img.sticker',
              ].includes(key)
            ),
        ]);
      }

      async function editVideosAssets() {
        engine.asset.addLocalSource('ly.img.video');
        engine.asset.addLocalSource('ly.img.image');

        medias.map(async (media) => {
          //   const mediasRes = getSingleMedia(ids[i]);
          //   const media = (await mediasRes).video[0];
          //   console.log('media fetched', media);

          engine.asset.addAssetToSource('ly.img.video', {
            id: media.pub_id,
            label: {
              en: media.name || media.chosen_scene_image?.description || 'media',
            },
            tags: {
              // en: ['ocean', 'waves', 'soothing', 'slow'],
            },
            meta: {
              uri: media.video_url,
              thumbUri: media.thumbnail_url,

              mimeType: 'video/mp4',
              width: media.resolution.split('x')[0],
              height: media.resolution.split('x')[1],
            },
            credits: {
              name: media.created_by.name,
              // url: 'https://example.com/johndoe',
            },
          });

          await addImage(media, media.product_in_frame_image_url);
          await addImage(media, media.thumbnail_url);

          async function addImage(media: Media, url?: string) {
            if (!url) return;

            const image = await fetchImageAsFile(url, 'a.png');
            const dataUrl = await imageFileToDataURL(image);

            engine.asset.addAssetToSource('ly.img.image', {
              id: 'img-' + media.pub_id,
              label: {
                en: media.name || media.chosen_scene_image?.description || 'img',
              },
              tags: {
                // en: ['ocean', 'waves', 'soothing', 'slow'],
              },
              meta: {
                uri: dataUrl,
                thumbUri: url,

                mimeType: 'image/png',
                width: media.resolution.split('x')[0],
                height: media.resolution.split('x')[1],
              },
              credits: {
                name: media.created_by.name,
              },
            });
          }
        });
      }

      function addCustomAssetsSource() {
        const localSourceId = 'background-videos';
        engine.asset.addLocalSource(localSourceId);
        engine.asset.addAssetToSource(localSourceId, {
          id: 'ocean-waves-1',
          label: {
            en: 'relaxing ocean waves',
            es: 'olas del mar relajantes',
          },
          tags: {
            en: ['ocean', 'waves', 'soothing', 'slow'],
            es: ['mar', 'olas', 'calmante', 'lento'],
          },
          meta: {
            uri: 'https://cdn.img.ly/assets/demo/v1/ly.img.video/videos/pexels-drone-footage-of-a-surfer-barrelling-a-wave-12715991.mp4',
            thumbUri:
              'https://cdn.img.ly/assets/demo/v1/ly.img.video/thumbnails/pexels-drone-footage-of-a-surfer-barrelling-a-wave-12715991.jpg',

            mimeType: 'video/mp4',
            width: 1920,
            height: 1080,
          },
          credits: {
            name: 'John Doe',
            url: 'https://example.com/johndoe',
          },
        });
      }
    });

    const cleanup = () => {
      cleanedUp = true;
      instance?.dispose();
      setCesdk(null);
    };

    return cleanup;
  }, [cesdkContainer, medias]);

  useEffect(() => {
    const get = async () => {
      const res = await getMediaItemsByIds(ids);
      console.log('getMediaItemsByIds', res);

      const mediaRecords = res.video;

      setMedias(mediaRecords);
    };
    get();
  }, []);

  useLayoutEffect(() => {
    const updateStyle = () => {
      const button: any = document
        .getElementById('root-shadow')
        ?.shadowRoot?.querySelector('button[data-cy="timelinePlayButton"]');

      if (!button) return;

      button.style = `display: flex;
                    align-items: center;
                    justify-content: center;`;
    };

    setTimeout(() => updateStyle(), 2000);
    setTimeout(() => updateStyle(), 4000);
    setTimeout(() => updateStyle(), 8000);
  }, [cesdkContainer]);

  return (
    <div
      className='app-container '
      style={{
        position: 'absolute',
        top: '75px',
        right: '1px',
        left: isSidebarMinimized ? '0' : '265px',
        bottom: '0px',
      }}
    >
      {/* <header className='d-flex justify-content-between align-items-center mb-3'>
        <div className='d-flex align-items-center flex-grow-1'>
          <h2>Video Editor</h2>
        </div>
      </header> */}

      <div
        ref={cesdkContainer}
        className='w-100 '
        style={{
          position: 'absolute',
          top: '0',
          right: '0',
          left: '0',
          bottom: '0',
        }}
      ></div>
    </div>
  );
};

export default VideoEditor;

export const caseAssetPath = (path: any, caseId = 'video-ui') =>
  `https://img.ly/showcases/cesdk/cases/video-ui/${path}`;

function buildInspectorMenu(instance: any) {
  instance.ui.setInspectorBarOrder([
    'ly.img.text.typeFace.inspectorBar',
    'ly.img.text.fontSize.inspectorBar',
    'ly.img.text.bold.inspectorBar',
    'ly.img.text.italic.inspectorBar',
    'ly.img.text.alignHorizontal.inspectorBar',

    'ly.img.shape.options.inspectorBar', // rounded corners for images

    'ly.img.cutout.type.inspectorBar',
    'ly.img.cutout.offset.inspectorBar',
    'ly.img.cutout.smoothing.inspectorBar',
    'ly.img.group.create.inspectorBar',
    'ly.img.group.ungroup.inspectorBar',
    'ly.img.audio.replace.inspectorBar',
    'ly.img.separator',
    'ly.img.combine.inspectorBar',
    'ly.img.separator',
    'ly.img.fill.inspectorBar',
    'ly.img.trim.inspectorBar',
    'ly.img.volume.inspectorBar',
    'ly.img.crop.inspectorBar',
    'ly.img.separator',
    'ly.img.stroke.inspectorBar',
    'ly.img.separator',
    'ly.img.adjustment.inspectorBar',
    'ly.img.filter.inspectorBar',
  ]);
}

function persistSelectedTemplateToURL(templateName: any) {
  const url = new URL(window.location.href);
  url.searchParams.set('template', templateName);
  window.history.pushState({}, '', url);
}
function loadSelectedTemplateFromURL() {
  const url = new URL(window.location.href);
  return url.searchParams.get('template') || 'motion';
}

export const PAGE_FORMATS_INSERT_ENTRY_ASSET = {
  id: 'pageFormats',
  sourceIds: ['pageFormats'],
  previewLength: 3,
  previewBackgroundType: 'contain',
  gridBackgroundType: 'cover',
  gridColumns: 3,
  gridItemHeight: 'square',

  cardLabel: (assetResult: AssetResult) => assetResult.label,
  cardLabelPosition: () => 'below',
};
