/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react';
import {useBranding} from '../../../app/modules/app/core/BrandingProvider';
import {useLayout} from '../core';
import HasPermission from '../../../app/modules/auth/HasPermission';

const Footer: FC = () => {
  const {classes} = useLayout();
  const {organization} = useBranding();

  return (
    <div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        {/* begin::Copyright */}
        <div className='text-dark order-2 order-md-1'>
          <span className='text-muted fw-bold me-2'>&copy;</span>
          <a className='text-gray-800 text-hover-primary'>
            {organization?.footer_text || 'VRee AI'}
          </a>
          <span className='text-muted fw-bold me-2 ms-1'>{new Date().getFullYear()}</span>
        </div>
        {/* end::Copyright */}

        {/* begin::Nav */}
        <ul className=' menu menu-gray-600 menu-hover-primary fw-bold order-1'>
          <HasPermission code='footer.about-link'>
            <li className='menu-item'>
              <a
                className='menu-link ps-0 pe-2'
                href={organization?.about_link || 'https://vree.ai/#section_clients'}
                target='_blank'
                rel='noopener noreferrer'
                hidden={organization && !organization?.about_link}
              >
                About
              </a>
            </li>
          </HasPermission>
          <HasPermission code='footer.privacy-link'>
            <li className='menu-item'>
              <a
                className='menu-link ps-0 pe-2'
                href={organization?.privacy_link || 'https://vree.ai/privacy.html'}
                target='_blank'
                rel='noopener noreferrer'
                hidden={organization && !organization?.privacy_link}
              >
                Privacy Policy
              </a>
            </li>
          </HasPermission>
          <HasPermission code='footer.terms-link'>
            <li className='menu-item'>
              <a
                className='menu-link ps-0 pe-2'
                href={organization?.terms_link || 'https://vree.ai/terms.html'}
                target='_blank'
                rel='noopener noreferrer'
                hidden={organization && !organization?.terms_link}
              >
                T&C
              </a>
            </li>
          </HasPermission>
          <HasPermission code='footer.contact-link'>
            <li className='menu-item'>
              <a
                className='menu-link ps-0 pe-2'
                href={organization?.contact_link || 'https://vree.ai/#section_contact'}
                target='_blank'
                rel='noopener noreferrer'
                hidden={organization && !organization?.contact_link}
              >
                Contact Us
              </a>
            </li>
          </HasPermission>
        </ul>
        {/* end::Nav */}
      </div>
      {/* end::Container */}
    </div>
  );
};

export {Footer};
