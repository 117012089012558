import {useBranding} from '../../../../app/modules/app/core/BrandingProvider';
import {useAuth} from '../../../../app/modules/auth';
import HasPermission from '../../../../app/modules/auth/HasPermission';
import TopBarCredit from '../../../../app/modules/models-management/components/TopBarCredit';
import {generateUserAvatarImage} from '../../../assets/ts/_utils/UserAvatarGenerator';
import {KTSVG} from '../../../helpers';
import {HeaderUserMenu} from '../../../partials';

/* eslint-disable jsx-a11y/anchor-is-valid */
const AsideToolbar = () => {
  const {currentUser} = useAuth();
  const {organization} = useBranding();

  return (
    <>
      {/*begin::User*/}
      <div className='aside-user d-flex align-items-sm-center justify-content-center py-5'>
        {/*begin::Symbol*/}
        <div className='symbol symbol-50px'>
          <img src={generateUserAvatarImage(currentUser)} alt='' />
        </div>
        {/*end::Symbol*/}

        {/*begin::Wrapper*/}
        <div className='aside-user-info flex-row-fluid flex-wrap ms-5'>
          {/*begin::Section*/}
          <div className='d-flex'>
            {/*begin::Info*/}
            <div className='flex-grow-1 me-2'>
              {/*begin::Username*/}
              <a
                href={`/account/${currentUser?.id}`}
                className='text-white text-hover-primary fs-6 fw-bold capitalized truncate'
              >
                {currentUser?.first_name} {currentUser?.last_name}
              </a>
              {/*end::Username*/}

              {/*begin::Description*/}
              {currentUser?.is_super_admin && (
                <span className='text-gray-600 fw-bold d-block fs-8 mb-1'>Admin</span>
              )}

              {/*end::Description*/}

              {/*begin::Label*/}
              <div className='d-flex align-items-center text-success fs-9'>
                <span className='bullet bullet-dot bg-success me-1'></span>online
              </div>
              {/*end::Label*/}
            </div>
            {/*end::Info*/}

            {/*begin::User menu*/}
            <div className='me-n2'>
              {/*begin::Action*/}
              <a
                href='#'
                className='btn btn-icon btn-sm btn-active-color-primary mt-n2'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-start'
                data-kt-menu-overflow='false'
              >
                <KTSVG
                  path='/media/icons/duotune/coding/cod001.svg'
                  className='svg-icon-muted svg-icon-12'
                />
              </a>

              <HeaderUserMenu />
              {/*end::Action*/}
            </div>
            {/*end::User menu*/}
          </div>
          {/*end::Section*/}
        </div>
        {/*end::Wrapper*/}
      </div>
      {/*end::User*/}

      {/*begin::Aside search*/}
      {/*end::Aside search*/}

      <div className='d-md-none flex-center d-flex '>
        <span className='me-3 text-white align-items-center'>Credit:</span>
        <HasPermission code='misc.credits'>
          <TopBarCredit />
        </HasPermission>
      </div>
    </>
  );
};

export {AsideToolbar};
