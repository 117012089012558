/* eslint-disable jsx-a11y/alt-text */
import React, {useEffect, useState} from 'react';
import {toAbsoluteUrl} from '../../../../../_metronic/helpers';
import useCheckoutStore from '../checkout.store';
import AddPaymentMethod from './AddPaymentMethod';
import {Dialog} from 'primereact/dialog';
import clsx from 'clsx';
import {BluesnapHostedFields} from './BluesnapHostedFields';
import {CountryCode} from '../../../../countries';
declare var bluesnap: any;

export function CheckoutPaymentMethod() {
  const {
    newPaymentMethod,
    setNewPaymentMethod,
    pfToken,
    setCardError,
    setCardType,
    pfErrors,
    setPFErrors,
  } = useCheckoutStore();
  const [showWindow, setShowWindow] = useState(false);

  const setupBluesnap = () => {
    const bsObj = {
      token: pfToken,
      onFieldEventHandler: {
        setupComplete: () => {
          console.log('setupComplete');
        },
        threeDsChallengeExecuted: () => {
          console.log('threeDsChallengeExecuted');
        },
        onFocus: (tagId: string) => {
          console.log('onFocus');
        }, // Handle focus
        onBlur: (tagId: string) => {
          console.log('onBlur');
        }, // Handle blur
        onError: (tagId: string, errorCode: string, errorDescription: string) => {
          console.log('errorCode', errorCode, errorDescription);

          if (!errorCode) return setCardError(undefined);

          if (errorCode === '10') {
            if (tagId === 'ccn') setPFErrors({ccn: 'Invalid Card Number '});
            if (tagId === 'cvv') setPFErrors({cvv: 'Invalid CVV '});
            if (tagId === 'exp') setPFErrors({exp: 'Invalid Expiration Date '});
          } else if (errorCode === '22013') setCardError('Not supported credit card');
          else setCardError(errorDescription);
        }, // Handle a change in validation
        onType: (tagId: string, cardType: string, cardData?: any) => {
          setCardType(cardType);
          if (cardData != null) {
            console.log(cardData);
          }
          console.log('onType', cardData);
        },
        onEnter: (tagId: string) => {}, // Will trigger when shopper presses enter while inside one of the inputs
        onValid: (tagId: string) => {
          if (tagId === 'ccn') setPFErrors({ccn: ''});
          if (tagId === 'cvv') setPFErrors({cvv: ''});
          if (tagId === 'exp') setPFErrors({exp: ''});
        }, // Handle a change in validation
      },
      style: {
        ':focus': {
          //style for all input elements on focus event
          color: 'orange',
        },
        input: {
          //style for all input elements
          color: 'blue',
        },
        '.invalid': {
          //style for all input elements when invalid
          color: 'red',
        },
        '#ccn': {
          //style only ccn element
          'background-color': '#F3F4F6',
          color: '#4b5675',
          'border-radius': '0.475rem',
          padding: '0.775rem 1rem',
          'font-weight': '500',
          'line-height': '1.5',
          height: '48px',
        },

        '#cvv': {
          //style only ccn element
          'background-color': '#F3F4F6',
          color: '#4b5675',
          'border-radius': '0.475rem',
          padding: '0.775rem 1rem',
          'font-weight': '500',
          'line-height': '1.5',
          height: '48px',
        },

        '#exp': {
          //style only ccn element
          'background-color': '#F3F4F6',
          color: '#4b5675',
          'border-radius': '0.475rem',
          padding: '0.775rem 1rem',
          'font-weight': '500',
          'line-height': '1.5',
          height: '48px',
        },
      },
      ccnPlaceHolder: '1234 5678 9012 3456', //for example
      cvvPlaceHolder: 'XXX', //for example
      expPlaceHolder: 'MM/YY', //for example
    };

    console.log('Play snap setting up...', bluesnap);
    // Run the following command after Document Object Model (DOM) is fully loaded
    bluesnap.hostedPaymentFieldsCreate(bsObj);
  };

  useEffect(() => {
    if (!pfToken) return;

    setupBluesnap();
  }, [pfToken]);

  return (
    <article
      className='card card-bordered card-flush pt-3 mb-5 mb-lg-10'
      data-kt-subscriptions-form='pricing'
    >
      <div className='card-header'>
        <div className='card-title'>
          <h2 className='fw-bold'>Payment Method</h2>
        </div>
        <div className='card-toolbar'>
          {/* <a className='btn btn-light-primary' onClick={() => setShowWindow(true)}>
            New Method
          </a> */}
        </div>
      </div>
      <div className='card-body pt-0'>
        <div id='kt_create_new_payment_method'>
          {/* {!newPaymentMethod && (
            <div className='w-100 d-flex flex-center py-10 text-muted fs-5'>
              No payment methods added, please add a new one
            </div>
          )} */}

          {newPaymentMethod && (
            <article className='py-1'>
              <div className='py-3 d-flex flex-stack flex-wrap'>
                <div
                  className='d-flex align-items-center collapsible toggle '
                  data-bs-toggle='collapse'
                  data-bs-target='#kt_create_new_payment_method_2'
                >
                  <div className='btn btn-sm btn-icon btn-active-color-primary ms-n3 me-2'>
                    <i className='ki-duotone ki-minus-square toggle-on text-primary fs-2'>
                      <span className='path1' />
                      <span className='path2' />
                    </i>
                    <i className='ki-duotone ki-plus-square toggle-off fs-2'>
                      <span className='path1' />
                      <span className='path2' />
                      <span className='path3' />
                    </i>{' '}
                  </div>

                  {newPaymentMethod.cardType === 'visa' && (
                    <img
                      src={toAbsoluteUrl('/media/svg/card-logos/visa.svg')}
                      className='w-40px me-3'
                      alt=''
                    />
                  )}

                  {newPaymentMethod.cardType === 'mastercard' && (
                    <img
                      src={toAbsoluteUrl('/media/svg/card-logos/mastercard.svg')}
                      className='w-40px me-3'
                      alt=''
                    />
                  )}

                  <div className='me-3'>
                    <div className='d-flex align-items-center fw-bold'>
                      <span className='text-capitalize me-2'>{newPaymentMethod.cardType}</span> ***
                      {newPaymentMethod.last4numbers}
                    </div>
                    <div className='text-muted'>
                      Expires {newPaymentMethod.response?.cardData.exp}
                    </div>
                  </div>
                </div>
                <div className='d-flex my-3 ms-9'>
                  <button
                    title='Remove'
                    className='btn'
                    onClick={() => {
                      setNewPaymentMethod(undefined);
                      setTimeout(() => {
                        setupBluesnap();
                      }, 500);
                    }}
                  >
                    <i className='ki-duotone ki-trash fs-3'>
                      <span className='path1' />
                      <span className='path2' />
                      <span className='path3' />
                      <span className='path4' />
                      <span className='path5' />
                    </i>
                  </button>
                  {/* <label className='form-check form-check-custom form-check-solid me-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='payment_method'
                      checked
                    />
                  </label> */}
                </div>
              </div>
              <div
                id='kt_create_new_payment_method_2'
                className={clsx('  fs-6 ps-10', {collapse: false})}
              >
                <div className='d-flex flex-wrap py-5'>
                  <div className='flex-equal me-5'>
                    <table className='table table-flush fw-semibold gy-1'>
                      <tbody>
                        <tr>
                          <td className='text-muted min-w-125px w-125px'>Name</td>
                          <td className='text-gray-800'>{newPaymentMethod.cardHolder}</td>
                        </tr>
                        <tr>
                          <td className='text-muted min-w-125px w-125px'>Number</td>
                          <td className='text-gray-800'>
                            **** {newPaymentMethod.response?.cardData.last4Digits}
                          </td>
                        </tr>
                        <tr>
                          <td className='text-muted min-w-125px w-125px'>Expires</td>
                          <td className='text-gray-800'>
                            {newPaymentMethod.response?.cardData.exp}
                          </td>
                        </tr>
                        <tr>
                          <td className='text-muted min-w-125px w-125px'>Type</td>
                          <td className='text-gray-800 text-capitalize '>
                            {newPaymentMethod.response?.cardData.ccType},{' '}
                            {newPaymentMethod.response?.cardData.cardSubType}
                          </td>
                        </tr>
                        <tr>
                          <td className='text-muted min-w-125px w-125px'>Issuer</td>
                          <td className='text-gray-800'>
                            {newPaymentMethod.response?.cardData.ccIssuingBank}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className='flex-equal '>
                    <table className='table table-flush fw-semibold gy-1'>
                      <tbody>
                        {/* <tr>
                          <td className='text-muted min-w-125px w-125px'>Billing address</td>
                          <td className='text-gray-800'>-</td>
                        </tr> */}
                        <tr>
                          <td className='text-muted min-w-125px w-125px'>Card Category </td>
                          <td className='text-gray-800'>
                            {newPaymentMethod.response?.cardData.cardCategory}
                          </td>
                        </tr>
                        <tr>
                          <td className='text-muted min-w-125px w-125px'>BIN Category</td>
                          <td className='text-gray-800'>
                            {newPaymentMethod.response?.cardData.binCategory}
                          </td>
                        </tr>
                        <tr>
                          <td className='text-muted min-w-125px w-125px'>Origin</td>
                          <td className='text-gray-800'>
                            {newPaymentMethod.response?.cardData.issuingCountry}

                            <div className='symbol symbol-20px symbol-circle ms-2'>
                              <img
                                src={toAbsoluteUrl(
                                  `/media/flags/${
                                    CountryCode[
                                      newPaymentMethod.response?.cardData.issuingCountry.toLocaleUpperCase() ||
                                        ''
                                    ]
                                  }.svg`
                                )}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className='text-muted min-w-125px w-125px'>CVC check</td>
                          <td className='text-gray-800'>-</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </article>
          )}

          {!newPaymentMethod && pfToken && <BluesnapHostedFields />}
        </div>
      </div>

      <Dialog
        modal
        dismissableMask
        closable={false}
        visible={showWindow}
        onHide={() => setShowWindow(false)}
      >
        <AddPaymentMethod
          submitComplete={(method) => {
            setShowWindow(false);
          }}
        />
      </Dialog>
    </article>
  );
}
