import {toAbsoluteUrl} from '../../_metronic/helpers';

interface Props {
  error?: string | object | any;
}
export function ErrorPlaceholder({error}: Props) {
  return (
    <div className='text-center p-10 pt-10'>
      <div className='fw-semibold py-10'>
        <div className='text-danger fs-3 mb-2 fw-semibold'>An error happened!</div>
        <div className='fs-6 mh-100px text-muted text-truncate text-wrap ' title={error}>
          <div>Error details:</div>
          {error}
        </div>
      </div>
      <div className='text-center px-5'>
        <img
          src={toAbsoluteUrl('/media/illustrations/sketchy-1/17.png')}
          alt=''
          className=' h-200px h-sm-325px'
        />
      </div>
    </div>
  );
}
