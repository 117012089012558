import {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import {LoadingPlaceholder} from '../../../../../shared/components/LoadingPlaceholder';
import {ErrorPlaceholder} from '../../../../../shared/components/ErrorPlaceholder';
import {PageTitle} from '../../../../../_metronic/layout/core';
import {getAllSettings, updateSettings} from '../../api/users-api';
import * as Yup from 'yup';
import {Settings} from '../../types';
import clsx from 'clsx';
import {FormFieldErrorMessage} from '../../../../../shared/components/FormFieldErrorMessage';
import {parseError, round} from '../../../../../shared/utils';
import {toast} from 'react-toastify';

const initialValues: Settings = {
  credits_initial: 0,
  ai_version: 1.0,
};

const formSchema = Yup.object().shape({
  credits_initial: Yup.number().min(0, 'Minimum 0 credits'),
  ai_version: Yup.number().min(0, 'Minimum 0 '),
});

const SettingsPage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [settings, setSettings] = useState<Settings>();

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        await updateSettings(values);
        toast.success('Settings updated successfully');
      } catch (err: any) {
        toast.error('Settings failed to update! ');
        console.error(err);

        setSubmitting(false);
        setStatus(parseError(err));
      }
    },
  });

  const reset = () => {
    settings && formik.setValues(settings, false);
  };

  const incrementVersion = () => {
    const value = (Number(formik.values.ai_version) || 0) + 0.1;
    formik.setFieldValue('ai_version', round(value, 1));
  };

  useEffect(() => {
    const getSettings = async () => {
      try {
        setLoading(true);
        const _settings = await getAllSettings();
        setSettings(_settings);
        formik.setValues(_settings);
      } catch (err) {
        setError(parseError(err));
      } finally {
        setLoading(false);
      }
    };

    getSettings();
  }, []);

  if (loading) return <LoadingPlaceholder label='Load settings ...' />;
  if (error) return <ErrorPlaceholder error={error} />;

  return (
    <div>
      <PageTitle>System Settings</PageTitle>
      <div>
        <div className=''>
          <article className='card'>
            <header className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold text-dark fs-2'>Settings</span>
                <span className='text-muted mt-1 fw-semibold fs-5'>
                  Please fill in the data below
                </span>
              </h3>
            </header>

            <div className='card-body p-9 '>
              {/* Form Status */}
              {formik.status && (
                <div className='mb-5 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
              )}

              <form noValidate onSubmit={formik.handleSubmit}>
                <fieldset className='row mb-8'>
                  <div className='col-xl-3 col-lg-4 col-sm-4 fs-5 fw-bold mt-2 mb-3'>
                    Initial Free Credits
                  </div>
                  <div className='col-xl-3 col-md-4 '>
                    <input
                      type='text'
                      className={clsx('form-control form-control-lg form-control-solid')}
                      {...formik.getFieldProps('credits_initial')}
                    />

                    {formik.touched.credits_initial && formik.errors.credits_initial && (
                      <FormFieldErrorMessage>{formik.errors.credits_initial}</FormFieldErrorMessage>
                    )}
                  </div>
                </fieldset>

                <fieldset className='row mb-8'>
                  <div className='col-xl-3 col-lg-4 col-sm-4 fs-5 fw-bold mt-2 mb-3'>
                    AI Version Number
                  </div>
                  <div className='col-xl-3 col-md-4 '>
                    <div className='input-group mb-5'>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='AI Version '
                        {...formik.getFieldProps('ai_version')}
                        disabled
                      />

                      <span className='input-group-text p-0' id='aiversion'>
                        <button
                          className='btn btn-icon ripple active:bg-black-alpha-10 rounded-0 rounded-end'
                          type='reset'
                          onClick={incrementVersion}
                        >
                          <i className='fas fa-plus fs-4'></i>
                          {/* Increase */}
                        </button>
                      </span>
                    </div>
                  </div>
                </fieldset>

                <div className='h-150px'></div>

                <footer className='row'>
                  <div className='col-xl-3 col-lg-4 col-sm-4'></div>
                  <div className='col-md-4'>
                    <button type='submit' className='btn btn-primary w-fit '>
                      {!formik.isSubmitting && <span>Save Settings</span>}
                      {formik.isSubmitting && (
                        <span className='indicator-progress d-block'>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                    <button
                      className='btn btn-light-primary ms-3'
                      type='reset'
                      onClick={() => reset()}
                    >
                      Reset Changes
                    </button>
                  </div>
                </footer>
              </form>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
