/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import {PageTitle} from '../../../../_metronic/layout/core';
import clsx from 'clsx';
import {mode} from 'crypto-js';
import dayjs from 'dayjs';
import {Column} from 'primereact/column';
import {confirmDialog} from 'primereact/confirmdialog';
import {DataTable, DataTableFilterMeta} from 'primereact/datatable';
import {deleteDialogProps} from '../../shared/types';
import {
  deactivatePackage,
  fetchFilteredPayments,
  fetchPayments,
  reactivatePackage,
} from '../billing-api';
import {Payment, PaymentStatusEnum} from '../types';
import {useThemeMode} from '../../../../_metronic/partials';
import {FilterMatchMode} from 'primereact/api';
import usePaymentsStore from './payments.store';
import PaymentsFilter from './components/PaymentsFilter';

const Payments = () => {
  const {mode} = useThemeMode();
  const {payments, setPayments, filters} = usePaymentsStore();

  const getPayments = async () => {
    try {
      const _payments = await fetchFilteredPayments(filters);
      setPayments(_payments);
    } catch (error) {}
  };

  useEffect(() => {
    getPayments();
  }, [filters]);

  return (
    <div>
      <div>
        <PageTitle breadcrumbs={[{title: 'Billing', path: 'billing', isActive: false}]}>
          Payments History
        </PageTitle>

        <div>
          <div className='container-fluid'>
            <header className='d-flex flex-wrap flex-stack pb-7'>
              <div className='d-flex flex-wrap   align-items-center my-1'>
                <h3 className='fw-bold me-5 my-1'>Payments ({payments.length})</h3>
              </div>

              <div className='d-flex flex-wrap my-1 '>
                <PaymentsFilter />
              </div>
            </header>

            <section className='card card-flush '>
              <div className='card-body pt-0'>
                <section className='table-responsive'>
                  <div className='dataTables_wrapper dt-bootstrap4 no-footer pt-10  min-h-400px'>
                    <DataTable
                      showGridlines
                      tableClassName='table align-middle table-row-dashed gy-5  table-hover table-rounded'
                      value={payments}
                      rowsPerPageOptions={[10, 25, 50, 100]}
                      //   sortField='created_date'
                      //   sortOrder={-1}
                      removableSort
                      paginator
                      rows={10}
                      tableStyle={{minWidth: '50rem'}}
                      cellClassName={() => 'fs-6 fw-semibold'}
                      rowClassName={() => `lh-xl ${mode === 'dark' && 'bg-light text-gray-700'}`}
                      paginatorClassName={clsx({'bg-light text-gray-700': mode === 'dark'})}
                      dataKey='id'
                      filterDisplay='menu'
                      globalFilterFields={['role_name']}
                    >
                      <Column
                        field='id'
                        header='ID '
                        filterField='id'
                        bodyClassName='text-muted'
                      ></Column>

                      <Column
                        className='w-200px'
                        field='date'
                        header='Date '
                        filterField='date'
                        dataType='date'
                        sortable
                        body={(payment) => (
                          <div className='lh-base'>
                            <div>{dayjs(payment.date).format('D/M/YYYY - H:mm')}</div>
                          </div>
                        )}
                        bodyClassName={() => ''}
                      ></Column>

                      {/* <Column
                        field='date'
                        header='Description '
                        filterField='date'
                        dataType='date'
                        sortable
                        body={(payment) => (
                          <div className='lh-base text-muted'>
                            Invoice for {dayjs(payment.date).format('MMMM, YYYY')}
                          </div>
                        )}
                        bodyClassName={() => ''}
                      ></Column> */}

                      <Column
                        header='Purchased Package '
                        body={(payment: Payment) => (
                          <div
                            className='lh-base text-gray-700 '
                            title={'Package ID: ' + payment.package?.id + ''}
                          >
                            {payment.package?.name}
                          </div>
                        )}
                      ></Column>

                      <Column
                        className='w-100px'
                        field='amount'
                        header='Amount'
                        body={(py) => <div className='text-primary'>{py.amount}$</div>}
                        sortable
                        bodyClassName={() => 'fw-semibold text-end pe-10'}
                      ></Column>

                      <Column
                        header='Status '
                        field='date'
                        filterField='date'
                        dataType='date'
                        sortable
                        body={(payment: Payment) => (
                          <div className='lh-base'>
                            {payment.status === PaymentStatusEnum.SUCCESS && (
                              <div className='badge badge-light-success'>Success</div>
                            )}
                            {payment.status === PaymentStatusEnum.FAILED && (
                              <div className='badge badge-light-danger' title={payment.reason}>
                                Failed
                              </div>
                            )}
                            {payment.status === PaymentStatusEnum.NOT_COMPLETED && (
                              <div className='badge badge-light-primary'>Incomplete</div>
                            )}
                            {payment.status === PaymentStatusEnum.CANCELED && (
                              <div className='badge badge-light-warning'>Cancelled</div>
                            )}
                          </div>
                        )}
                        bodyClassName={() => ''}
                      ></Column>

                      <Column
                        header='Actions'
                        className='w-auto '
                        body={(pckg) => (
                          <div className='text-start'>
                            {/* {!!!pckg.inactive && (
                              <a
                                title='Edit'
                                onClick={() => {
                                //   setSelectedPackage(pckg);
                                //   setShowPackageWindow(true);
                                }}
                                className='btn btn-icon btn-bg-light btn-active-color-warning btn-sm mx-1'
                              >
                                <i className='ki-duotone ki-pencil fs-2'>
                                  <span className='path1'></span>
                                  <span className='path2'></span>
                                </i>
                              </a>
                            )} */}

                            {!!!pckg.inactive && (
                              <a
                                title='Cancel'
                                onClick={() => {
                                  //   confirmDialog({
                                  //     ...deleteDialogProps,
                                  //     header: 'Confirm',
                                  //     acceptLabel: 'Deactivate',
                                  //     message: `Do you want to deactivate the package (${pckg.name})?`,
                                  //     accept: async () => {
                                  //       await deactivatePackage(pckg);
                                  //       getPackages();
                                  //     },
                                  //     reject: () => {},
                                  //   });
                                }}
                                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                              >
                                <i className='ki-duotone ki-cross-circle fs-2'>
                                  <span className='path1' />
                                  <span className='path2' />
                                </i>
                              </a>
                            )}
                          </div>
                        )}
                      ></Column>
                    </DataTable>
                  </div>
                </section>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payments;
