import React from 'react';
import './SpinkitLoader.scss';
const SpinkitLoader = () => {
  return (
    <div className='sk-chase'>
      <div className='sk-chase-dot'></div>
      <div className='sk-chase-dot'></div>
      <div className='sk-chase-dot'></div>
      <div className='sk-chase-dot'></div>
      <div className='sk-chase-dot'></div>
      <div className='sk-chase-dot'></div>
    </div>
  );
};

export default SpinkitLoader;
