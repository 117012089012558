import React, {ReactNode} from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import {useAuth} from './core/Auth';

interface Props {
  children: ReactNode;
  allowedRoles?: number[];
  redirect?: boolean;
}
const Authorize = ({children, allowedRoles, redirect}: Props) => {
  const {currentUser} = useAuth();
  const location = useLocation();
  // const userRole = useRealUserRole();

  if (!currentUser) {
    console.log(`Authorize failed!`);
    return <Navigate to={`/auth/registration?from=${location?.pathname}`} replace />;
  }

  // let userRole = currentUser.role_id;

  // let allowed = !!allowedRoles.find((allowedRole) => userRole === allowedRole);

  // if (!allowed && redirect)
  //   return <Navigate to={`/unauthorized?from=${location?.pathname}`} replace />;
  // if (!allowed && !redirect) return <></>;
  return <>{children}</>;
};

export default Authorize;
