import React from 'react';
import {Routes, Route, Outlet, Navigate} from 'react-router-dom';
import {PageTitle} from '../../../../../_metronic/layout/core';
import AccountOverview from '../../../account/pages/overview/AccountOverview';
import AccountSettings from '../../../account/pages/settings/AccountSettings';
import ViewOrganization from './components/ViewOrganization';

const Organization = () => {
  return (
    <ViewOrganization>
      <Routes>
        <Route
          path=':id'
          element={
            <>
              <PageTitle breadcrumbs={[]}>View Organization</PageTitle>

              <Outlet />
            </>
          }
        >
          {/* <Route path='overview' element={<AccountOverview />} /> */}
          {/* <Route path='settings' element={<AccountSettings />} /> */}

          {/* <Route index element={<Navigate to='overview' replace />} /> */}
        </Route>
      </Routes>
    </ViewOrganization>
  );
};

export default Organization;
