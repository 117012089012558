import React, {useEffect, useState} from 'react';
import useMediaWizardStore from '../../media-wizard.store';
import {InputText} from 'primereact/inputtext';
import {RadioButton} from 'primereact/radiobutton';
import {CanvasEditor} from '../../../../../canvas-editor/CanvasEditor';
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers';
import {Checkbox} from 'primereact/checkbox';
import {Next} from 'react-bootstrap/esm/PageItem';
import clsx from 'clsx';
import {dataURLtoFile, parseError, scrollToTop, sleep} from '../../../../../../../shared/utils';
import {generateMedia} from '../../../../media.api';

const SCALE_RATE = 0.5;
const mobileScale = 0.8;

function Pinch() {
  return (
    <div
      className='position-absolute  z-far '
      style={{
        bottom: `5px`,
        right: '20px',
        userSelect: 'none',
      }}
    >
      <img className=' w-75px ' src={toAbsoluteUrl(`/media/icons/pinch.gif`)} alt='pinch' />
      <div className='text-primary fw-semibold'>Tap to scale, move or rotate</div>
    </div>
  );
}

const PROMPT_VALIDATION = 'Please enter scene description';
const MediaDesignStep = () => {
  const {prev, next, media: video, updateMedia: updateVideo} = useMediaWizardStore();
  const [submitting, setSubmitting] = useState(false);
  const [type, setType] = useState<'prompt' | 'inspire'>(video.prompt ? 'prompt' : 'inspire');
  const [showPinch, setShowPinch] = useState(true);
  const [error, setError] = useState('');
  const [progress, setProgress] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const width = +video.resolution?.split('x')[0] || 0;
  const height = +video.resolution?.split('x')[1] || 0;
  const ratio = height / width;

  const submit = async () => {
    setError('');
    if (type === 'prompt' && !video.prompt) {
      scrollToTop();
      return setError(PROMPT_VALIDATION);
    }

    try {
      setSubmitting(true);
      const res = await generateMedia(video, setProgress);
      console.log(res.data);
      const pub_id = res.data.pub_id;

      updateVideo({pub_id: pub_id});
      // await sleep(600);

      next();
    } catch (error) {
      setError(`Error while generating scenes (Details:${parseError(error)})`);
      scrollToTop();
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setShowPinch(false);
    }, 7000);
  }, []);

  useEffect(() => {
    const checkWidth = () => setIsMobile(window.innerWidth <= 768);
    checkWidth(); // Initial check
    window.addEventListener('resize', checkWidth);

    return () => window.removeEventListener('resize', checkWidth);
  }, []);

  return (
    <div className='container mw-800px'>
      {error && <div className='alert alert-danger mb-5'>{error}</div>}

      <div className='d-flex flex-center'>
        <section
          className='position-relative '
          onClick={() => setShowPinch(false)}
          style={{
            width: `${width * SCALE_RATE * (isMobile ? mobileScale : 1)}px`,
            height: `${height * SCALE_RATE * (isMobile ? mobileScale : 1)}px`,
          }}
        >
          {showPinch && <Pinch />}

          {video.transparentProductImage && (
            <CanvasEditor
              hideTextAdding
              hideLogoAdding
              addedLogo={video.transparentProductImage}
              width={width}
              height={height}
              onChanged={(dataURL) => {
                const file = dataURLtoFile(dataURL, 'product_in_frame_image.png');
                updateVideo({productWithFrameImage: file});
              }}
            />
          )}

          <div
            className='position-absolute bottom-0 left-0 text-muted fw-semibold px-2'
            title='Selected video resolution'
          >
            {video.resolution}
          </div>
        </section>
      </div>

      <section className='my-15'>
        <div className='fw-semibold mb-5 fs-5 '>
          Place your product in right position and size and give our Co-Pilot to do the work.
        </div>

        <fieldset className='mb-2'>
          <div className='d-flex align-items-center'>
            <RadioButton
              inputId='inspire'
              name='type'
              value='inspire'
              onChange={(e) => {
                updateVideo({prompt: '', is_auto_descriped: 1});

                setType('inspire');
              }}
              checked={type === 'inspire'}
            />
            <label className='ms-3  py-3' htmlFor='inspire'>
              <div className='fs-5 fw-bold'>Automatic</div>
              <div className='fs-6 text-muted'>Our Co-Pilot ready to work.</div>
            </label>
          </div>
        </fieldset>

        <fieldset className='mb-2'>
          <div className='d-flex align-items-center'>
            <RadioButton
              inputId='prompt'
              name='type'
              value='prompt'
              onChange={(e) => {
                setType('prompt');
                updateVideo({is_auto_descriped: 0});
              }}
              checked={type === 'prompt'}
            />
            <label
              className={clsx('ms-3  py-3', {
                required: error && type === 'prompt',
              })}
              htmlFor='prompt'
            >
              <div className='fs-5 fw-bold'>Manual</div>
              <div className='fs-6 text-muted'>Describe your scene...</div>
            </label>
          </div>

          {type === 'prompt' && (
            <div className='ps-10'>
              <div className='p-inputgroup flex-1'>
                <InputText
                  placeholder='Type your prompt here...'
                  name='desc'
                  id='desc'
                  autoFocus
                  autoComplete='off'
                  className={clsx(
                    'bg-light mb-3 mb-lg-0 border-0 w-100 fw-bold text-gray-700 fs-6'
                  )}
                  value={video.prompt}
                  onChange={(e) => updateVideo({prompt: e.target.value})}
                />
                <span className='p-inputgroup-addon bg-light border-0 fw-bold text-gray-700 '>
                  <div className='mx-10 d-flex'>
                    <Checkbox
                      inputId='enhance'
                      className=''
                      checked={video.enhancePrompt || false}
                      onChange={(e) => updateVideo({enhancePrompt: e.checked})}
                    ></Checkbox>
                    <label className='text-nowrap ms-2' htmlFor='enhance'>
                      Auto Enhance
                    </label>
                  </div>
                </span>
              </div>
            </div>
          )}
        </fieldset>
      </section>
      <footer className='d-flex justify-content-between'>
        <button className='btn btn-light ' onClick={prev}>
          <span className='d-flex align-items-center'>
            <i className='me-2 ki-duotone ki-arrow-left fs-3'>
              <span className='path1' />
              <span className='path2' />
            </i>
            Back
          </span>
        </button>

        <button className='btn btn-primary' onClick={submit}>
          {!submitting && (
            <span className='d-flex align-items-center'>
              Continue
              <i className='ms-2 ki-duotone ki-arrow-right fs-3'>
                <span className='path1' />
                <span className='path2' />
              </i>
            </span>
          )}
          {submitting && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {progress === 100 && <span>Generating scenes ...</span>}
              {progress < 100 && <span> Uploading image ... ({progress})% </span>}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </footer>
    </div>
  );
};

export default MediaDesignStep;
