import {useEffect} from 'react';
import {ModelValidate} from '../../models-management/pages/single-model/components/ModelValidate';
import {Model} from '../../models-management/types';
import {useAuth} from '../../auth';
import {ErrorPlaceholder} from '../../../../shared/components/ErrorPlaceholder';
declare var FitMix: any;
interface Props {
  frame: string;
  review?: boolean;
  model?: Model;
  onComplete?: (isDeleted: boolean) => void;
}

const FBXARViewer = ({frame, review, model, onComplete}: Props) => {
  const {currentUser} = useAuth();

  useEffect(() => {
    if (!model) return;
    if (!model.api_key) return;
    const container = document.getElementById('fitmix-container');
    if (!container) return;
    (window as any).fitmixInstance = FitMix.createWidget(
      'fitmix-container',
      {
        apiKey: model?.api_key,
        frame: model?.model_name,
        onStopVto: () => {
          container.style.display = 'none'; // Hide the container when VTO stops
        },
      },

      () => {
        (window as any).fitmixInstance.startVto('live'); // Start VTO automatically
      }
    );

    (window as any).fitmixInstance.setFrame(model?.model_name);
  }, [model]);
  // function hideVto() {
  //   (window as any).fitmixInstance.stopVto();
  // }

  // function showVto() {
  //   const container = document.getElementById('fitmix-container');
  //   if (container) {
  //     container.style.display = 'block';
  //     (window as any).fitmixInstance.startVto('live');
  //   }
  // }
  if (model && !model.api_key) return <ErrorPlaceholder error='api key is not loaded' />;

  return (
    <>
      {' '}
      {model && onComplete && review && (
        <div className='position-absolute right-0 bottom-0 me-8  mb-5 flex flex-row'>
          <ModelValidate
            model={model}
            complete={(rate: number) => {
              if (rate !== model.rating) {
                onComplete(true);
              }
            }}
            rate={0}
          />
          <div className='me-2'></div>
          <ModelValidate
            model={model}
            complete={(rate: number) => {
              if (rate !== model.rating) {
                model.rating = rate;
              }
            }}
            rate={5}
          />
        </div>
      )}
      <div id='fitmix-container' className='w-100 h-100'></div>
    </>
  );
};

export default FBXARViewer;

const params = {
  apiKey: 'TBVAcXitApiZPVH791yxdHbAc8AKzBwtCnjtv6Xn',
  // popupIntegration: {
  //   centeredHorizontal: true,
  //   centeredVertical: true,
  //   width: '600px',
  //   height: '500px',
  // },
};
