import React, {useState} from 'react';
import {toast} from 'react-toastify';
import {ApiError} from '../../../../auth/core/apiModels';
import clsx from 'clsx';
import {ModalLayout} from '../../../../shared/components/ModalLayout';
import {sleep} from '../../../../../../shared/utils';
import {Checkbox} from 'primereact/checkbox';
import {insertOrg} from '../../../api/organizations-api';
import {useNavigate} from 'react-router-dom';

export interface NewOrgProps {
  name?: string;
  vree_api_key?: string;
  reply_email_ar?: string;
  noreply_email_arn?: string;
  ses_region?: string;
  admin_email?: string;
  format_name?: string;
}

export interface Props {
  close: () => void;
}

export function NewOrg({close}: Props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [newOrg, setNewOrg] = useState<NewOrgProps>({});

  const navigate = useNavigate();

  const submitNewOrg = async () => {
    setLoading(true);
    setError('');

    try {
      if (!newOrg.name) return setError('Please fill organization name');

      const addedOrgId = await insertOrg(newOrg);

      toast.success('Organization successfully created');

      setLoading(false);
      close();

      navigate(`/admin/organization/${addedOrgId}`);
    } catch (error: any) {
      console.error(error);

      setLoading(false);

      const responseData: ApiError | undefined = error?.response?.data;
      if (responseData?.msg) setError(responseData?.msg);
      else setError('Failed to submit ');
    }
  };

  return (
    <ModalLayout
      onClose={() => close()}
      showHeader
      showFooter
      showCloseButton
      showOkButton
      okButtonText='Create'
      onOk={() => submitNewOrg()}
      loading={loading}
      title={`Create New Organization `}
    >
      {error && <div className='text-danger'>{error}</div>}

      <fieldset className='fv-row mb-4'>
        <label className='form-label w-150px fw-bolder text-dark fs-6 required'>Name</label>
        <input
          placeholder='Type name...'
          type='text'
          autoComplete='off'
          onChange={(e) => setNewOrg({...newOrg, name: e.target.value || ''})}
          className={clsx(
            'form-control form-control-lg form-control-solid d-inline-block w-300px',
            {
              'is-invalid': error,
            }
          )}
        />
      </fieldset>

      {/* vree_api_key */}
      <fieldset className='fv-row mb-4'>
        <label className='form-label w-150px fw-bolder text-dark fs-6 '>vree api key</label>
        <input
          placeholder='Type here...'
          type='text'
          autoComplete='off'
          onChange={(e) => setNewOrg({...newOrg, vree_api_key: e.target.value || ''})}
          className={clsx(
            'form-control form-control-lg form-control-solid d-inline-block w-300px',
            {}
          )}
        />
      </fieldset>

      {/* reply_email_ar */}
      <fieldset className='fv-row mb-4'>
        <label className='form-label w-150px fw-bolder text-dark fs-6 '>reply email arn</label>
        <input
          placeholder='Type here...'
          type='text'
          autoComplete='off'
          onChange={(e) => setNewOrg({...newOrg, reply_email_ar: e.target.value || ''})}
          className={clsx(
            'form-control form-control-lg form-control-solid d-inline-block w-300px',
            {}
          )}
        />
      </fieldset>

      {/* noreply_email_arn */}
      <fieldset className='fv-row mb-4'>
        <label className='form-label w-150px fw-bolder text-dark fs-6 '>noreply email arn</label>
        <input
          placeholder='Type here...'
          type='text'
          autoComplete='off'
          onChange={(e) => setNewOrg({...newOrg, noreply_email_arn: e.target.value || ''})}
          className={clsx(
            'form-control form-control-lg form-control-solid d-inline-block w-300px',
            {}
          )}
        />
      </fieldset>

      {/* ses_region */}
      <fieldset className='fv-row mb-4'>
        <label className='form-label w-150px fw-bolder text-dark fs-6 '>ses region</label>
        <input
          placeholder='Type here...'
          type='text'
          autoComplete='off'
          onChange={(e) => setNewOrg({...newOrg, ses_region: e.target.value || ''})}
          className={clsx(
            'form-control form-control-lg form-control-solid d-inline-block w-300px',
            {}
          )}
        />
      </fieldset>

      {/* admin_email */}
      <fieldset className='fv-row mb-4'>
        <label className='form-label w-150px fw-bolder text-dark fs-6 '>admin email</label>
        <input
          placeholder='Type here...'
          type='text'
          autoComplete='off'
          onChange={(e) => setNewOrg({...newOrg, admin_email: e.target.value || ''})}
          className={clsx(
            'form-control form-control-lg form-control-solid d-inline-block w-300px',
            {}
          )}
        />
      </fieldset>

      {/* custom format */}
      <fieldset className='fv-row mb-4'>
        <label className='form-label w-150px fw-bolder text-dark fs-6 '>Custom format name</label>
        {/*  */}
        <input
          placeholder='Type here...'
          type='text'
          autoComplete='off'
          onChange={(e) => setNewOrg({...newOrg, format_name: e.target.value || ''})}
          className={clsx(
            'form-control form-control-lg form-control-solid d-inline-block w-300px',
            {}
          )}
        />
      </fieldset>
    </ModalLayout>
  );
}
