/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import React, {useEffect, useState} from 'react';
import {PageTitle} from '../../../../../../_metronic/layout/core';
import {Link, useNavigate} from 'react-router-dom';
import {Dialog} from 'primereact/dialog';
import {toast} from 'react-toastify';
import {ApiError} from '../../../../auth/core/apiModels';
import {UserRolesEnum} from '../../../../models-management/types';
import {createResellerCustomer, createUserByAdmin} from '../../../api/users-api';
import {parseError, randomIntFromInterval, sleep} from '../../../../../../shared/utils';
import {Role} from '../../../../app/types';
import dayjs from 'dayjs';
import {confirmDialog} from 'primereact/confirmdialog';
import {deleteDialogProps} from '../../../../shared/types';
import BlockUi from 'react-block-ui';
import {GetRolesByOrg, deleteRole, getRoles, insertRole} from '../roles-api';
import {useAuth} from '../../../../auth';
import {SplitButton} from 'primereact/splitbutton';
import {NewRole} from './NewRole';

interface Props {
  isOrganizationView?: boolean;
  org_id?: number;
}

export function RolesList({isOrganizationView, org_id}: Props) {
  const [showNewWindow, setShowNewWindow] = useState(false);
  const [roles, setRoles] = useState<Role[]>([]);
  const [blockMessage, setBlockMessage] = useState('');
  const {currentUser} = useAuth();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);

  const submitDelete = async (roleId: number) => {
    if (!roleId) return;

    try {
      setBlockMessage('Deleting role...');

      await deleteRole(roleId);

      toast.success('Role deleted successfully!');

      fetchRoles();
      setBlockMessage('');
    } catch (error) {
      setBlockMessage('');
      alert(parseError(error));
    }
  };

  const fetchRoles = async () => {
    try {
      const roles = currentUser?.is_super_admin
        ? await GetRolesByOrg(org_id || 0)
        : await getRoles();

      setRoles(roles);
    } catch (error: any) {
      toast.error('Failed to get roles, check browser console');
      console.error(error);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  return (
    <div className='d-flex flex-column flex-column-fluid'>
      {!isOrganizationView && <PageTitle breadcrumbs={[]}>Organization Roles</PageTitle>}

      {!isOrganizationView && (
        <div className='app-toolbar  py-3 py-lg-6 '>
          <div className='app-container  container-fluid d-flex flex-stack '>
            <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3 '>
              <h1 className='page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0'>
                Organization Roles
              </h1>
            </div>
          </div>
        </div>
      )}

      <BlockUi tag='div' blocking={!!blockMessage} message={blockMessage} keepInView>
        <div className={clsx({'app-container container-fluid': !isOrganizationView})}>
          <div className='card pt-4 mb-6 mb-xl-9'>
            <div className='card-header border-0'>
              <div className='card-title'>
                <h2>Roles</h2>
              </div>
              <div className='card-toolbar'>
                <button
                  type='button'
                  className='btn btn-sm btn-flex btn-light-primary'
                  onClick={() => setShowNewWindow(true)}
                >
                  {!submitting && (
                    <>
                      <i className='ki-duotone ki-plus-square fs-3'>
                        <span className='path1' />
                        <span className='path2' />
                        <span className='path3' />
                        <span className='path4' />
                      </i>
                      Add Role
                    </>
                  )}
                  {submitting && (
                    <span>
                      Adding role...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
            <div className='card-body pt-0 pb-5'>
              <div
                id='kt_table_customers_payment_wrapper'
                className='dataTables_wrapper dt-bootstrap4 no-footer'
              >
                <div className='table-responsive'>
                  <table
                    className='table align-middle table-row-dashed gy-5 dataTable no-footer'
                    id='kt_table_customers_payment'
                  >
                    <thead className='border-bottom border-gray-200 fs-7 fw-bold'>
                      <tr className='text-start text-muted text-uppercase gs-0'>
                        <th
                          className='min-w-300px sorting'
                          tabIndex={0}
                          rowSpan={1}
                          colSpan={1}
                          style={{
                            width: '138.875px',
                          }}
                        >
                          Role Name
                        </th>

                        <th
                          className='min-w-100px sorting'
                          tabIndex={0}
                          rowSpan={1}
                          colSpan={1}
                          style={{
                            width: '231.328px',
                          }}
                        >
                          Date Created
                        </th>
                        <th
                          className='min-w-100px sorting'
                          tabIndex={0}
                          rowSpan={1}
                          colSpan={1}
                          style={{
                            width: '231.328px',
                          }}
                        >
                          Created By
                        </th>
                        <th
                          className='text-end min-w-100px pe-4 sorting_disabled'
                          rowSpan={1}
                          colSpan={1}
                          style={{
                            width: '147.75px',
                          }}
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className='fs-6 fw-semibold text-gray-600'>
                      {roles?.map((role) => (
                        <tr className='odd' key={role.id}>
                          <td>
                            <div>
                              <Link
                                to={{
                                  pathname: `/roles/${role.id}`,
                                  search: isOrganizationView ? '?fromOrg=true' : '',
                                }}
                                className='text-dark  text-hover-primary mb-1'
                              >
                                {role.name}
                              </Link>
                            </div>

                            <div className='text-muted '>{role.desc}</div>
                          </td>

                          <td data-order='2020-12-14T20:43:00+02:00'>
                            {dayjs(role.time_created).format('D MMM YYYY, h:mm a')}
                          </td>

                          <td>
                            {/* <Link
                              to={`/account/${role.createdby}/overview`}
                              className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                            >
                              {role.user_name}
                            </Link> */}

                            {role.user_name}
                          </td>

                          <td className='pe-0 text-end'>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              <Link
                                to={`/roles/${role.id}`}
                                title='Edit Role Permissions'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              >
                                <i className='ki-duotone ki-setting-3 fs-3'>
                                  <span className='path1' />
                                  <span className='path2' />
                                  <span className='path3' />
                                  <span className='path4' />
                                  <span className='path5' />
                                </i>
                              </Link>

                              <a
                                title='Delete Role'
                                onClick={() => {
                                  confirmDialog({
                                    ...deleteDialogProps,
                                    message: `Do you want to delete this role (${role.name})?`,
                                    accept: () => {
                                      submitDelete(role.id);
                                    },
                                    reject: () => {},
                                  });
                                }}
                                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                              >
                                <i className='ki-duotone ki-trash fs-2'>
                                  <span className='path1' />
                                  <span className='path2' />
                                  <span className='path3' />
                                  <span className='path4' />
                                  <span className='path5' />
                                </i>
                              </a>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {roles.length === 0 && (
                    <div className='w-100 d-flex flex-center h-200px fs-5 text-gray-600'>
                      No roles found
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </BlockUi>

      <Dialog
        modal
        dismissableMask
        closable={false}
        visible={showNewWindow}
        onHide={() => setShowNewWindow(false)}
      >
        <NewRole onClose={() => setShowNewWindow(false)} org_id={org_id} />
      </Dialog>
    </div>
  );
}
