import axios, {AxiosResponse} from 'axios';
import {Permission, Role} from '../../../app/types';

const API_URL = process.env.REACT_APP_API_URL;

export const getRoles = (): Promise<Role[]> => {
  return axios.get(`${API_URL}/get_roles`).then((d: AxiosResponse) => d.data?.roles);
};

export const GetRolesByOrg = (orgId: number): Promise<Role[]> => {
  return axios
    .get(`${API_URL}/get_roles_by_org?org_id=${orgId}`)
    .then((d: AxiosResponse<any>) => d.data?.roles);
};

export const getRole = (id: number): Promise<Role> => {
  return axios
    .get(`${API_URL}/get_role_by_id?role_id=${id}`)
    .then((d: AxiosResponse<any>) => d.data?.roles);
};

export const getAppPermissions = (): Promise<Permission[]> => {
  return axios
    .get(`${API_URL}/get_permissions`)
    .then((d: AxiosResponse<any>) => d.data?.permissions);
};

export const updateRole = async (role: Role) => {
  const json = JSON.stringify({
    role_desc: role.desc,
    role_name: role.name,
    permissions: role.permissions.map((d) => {
      return {code: d.code, level: d.level || 0};
    }),
  });
  const res = await axios.put(`${API_URL}/update_role/${role.id}`, json, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return res;
};

export const insertRole = async (
  role_name: string,
  org_id: number,
  copy_from?: number
): Promise<Role> => {
  const json = JSON.stringify({
    role_name,
    org_id,
    copy_from,
  });
  const res = await axios.post<any>(`${API_URL}/create_role`, json, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return res.data?.role;
};

export const deleteRole = async (role_id: number): Promise<Role> => {
  const res = await axios.delete<any>(`${API_URL}/delete_role/${role_id}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return res.data;
};
