import React, {useState} from 'react';
import {useAuth} from '../../../../auth';
import clsx from 'clsx';
import {changeAccountPassword} from '../../../../admin/api/users-api';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import useAccountStore from '../../../account.store';
import {parseError} from '../../../../../../shared/utils';
import {Toast} from 'react-toastify/dist/components';
import {toast} from 'react-toastify';
import {PasswordRegEx} from '../../../../app/types';

const initialValues = {
  current_password: '',
  new_password: '',
  confirmPassword: '',
};

const resetPasswordSchema = Yup.object().shape({
  current_password: Yup.string().max(50, 'Maximum 50 symbols').required('Password is required'),
  new_password: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .matches(PasswordRegEx, 'Password must include a mix of letters & numbers')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .required('Password confirmation is required')
    .when('new_password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('new_password')],
        "Password and Confirm Password didn't match"
      ),
    }),
});

const ChangePassword = () => {
  const {currentUser, auth} = useAuth();
  const {user} = useAccountStore();
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      if (!auth?.api_token) return;

      setLoading(true);
      setSubmitting(true);
      changeAccountPassword(auth.api_token, values.current_password, values.new_password)
        .then(({data: {result}}) => {
          setLoading(false);
          setSubmitting(false);
          toast.success('Password successfully changed');
          setStatus('');
          setShowPasswordForm(false);
        })
        .catch((error) => {
          setLoading(false);
          setSubmitting(false);
          setStatus(parseError(error) || 'Failed to reset password, please try again');
        });
    },
  });

  return (
    <div className='card  mb-5 mb-xl-10'>
      <header
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_signin_method'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bold m-0'>Sign-in Method</h3>
        </div>
      </header>

      <div className='collapse show'>
        <div className='card-body border-top p-9'>
          <section className='d-flex flex-wrap align-items-center'>
            <div id='kt_signin_email' className=''>
              <div className='fs-6 fw-bold mb-1'>Email Address</div>
              <div className='fw-semibold text-gray-600'>{currentUser?.email}</div>
            </div>
            <div id='kt_signin_email_edit' className='flex-row-fluid d-none'>
              <form
                id='kt_signin_change_email'
                className='form fv-plugins-bootstrap5 fv-plugins-framework'
              >
                <div className='row mb-6'>
                  <div className='col-lg-6 mb-4 mb-lg-0'>
                    <div className='fv-row mb-0 fv-plugins-icon-container'>
                      <label htmlFor='emailaddress' className='form-label fs-6 fw-bold mb-3'>
                        Enter New Email Address
                      </label>
                      <input
                        type='email'
                        className='form-control form-control-lg form-control-solid'
                        id='emailaddress'
                        placeholder='Email Address'
                        name='emailaddress'
                        defaultValue='support@keenthemes.com'
                      />
                      <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback' />
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className='fv-row mb-0 fv-plugins-icon-container'>
                      <label
                        htmlFor='confirmemailpassword'
                        className='form-label fs-6 fw-bold mb-3'
                      >
                        Confirm Password
                      </label>
                      <input
                        type='password'
                        className='form-control form-control-lg form-control-solid'
                        name='confirmemailpassword'
                        id='confirmemailpassword'
                      />
                      <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback' />
                    </div>
                  </div>
                </div>
                <div className='d-flex'>
                  <button
                    id='kt_signin_submit'
                    type='button'
                    className='btn btn-primary  me-2 px-6'
                  >
                    Update Email
                  </button>
                  <button
                    id='kt_signin_cancel'
                    type='button'
                    className='btn btn-color-gray-400 btn-active-light-primary px-6'
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
            <div id='kt_signin_email_button' className='ms-auto  d-none'>
              <button className='btn btn-light btn-active-light-primary'>Change Email</button>
            </div>
          </section>

          <div className='separator separator-dashed my-6' />

          {/* Password */}
          <section className='d-flex flex-wrap align-items-center mb-10'>
            <div id='kt_signin_password' className={clsx({'d-none': showPasswordForm})}>
              <div className='fs-6 fw-bold mb-1'>Password</div>
              <div className='fw-semibold text-gray-600'>************</div>
            </div>

            <div
              id='kt_signin_password_edit'
              className={clsx('flex-row-fluid', {'d-none': !showPasswordForm})}
            >
              <form
                id='kt_signin_change_password'
                noValidate
                onSubmit={formik.handleSubmit}
                className='form fv-plugins-bootstrap5 fv-plugins-framework'
              >
                {/* Form Status */}
                {formik.status && (
                  <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                  </div>
                )}

                <div className='row mb-1'>
                  <div className='col-lg-4'>
                    <div className='fv-row mb-0 fv-plugins-icon-container'>
                      <label htmlFor='currentpassword' className='form-label fs-6 fw-bold mb-3'>
                        Current Password
                      </label>
                      <div className='position-relative'>
                        <input
                          type={showPassword ? 'text  ' : 'password'}
                          {...formik.getFieldProps('current_password')}
                          className={clsx('form-control form-control-lg form-control-solid')}
                        />
                        <div
                          className='btn btn-icon position-absolute top-0 right-0 pt-1'
                          onClick={() => setShowPassword((v) => !v)}
                        >
                          <i
                            className={clsx(
                              'fa  fs-4',
                              {'fa-eye': showPassword},
                              {'fa-eye-slash': !showPassword}
                            )}
                          ></i>
                        </div>
                      </div>
                      {formik.touched.current_password && formik.errors.current_password && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.current_password}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-lg-4'>
                    <div className='fv-row mb-0 fv-plugins-icon-container'>
                      <label htmlFor='newpassword' className='form-label fs-6 fw-bold mb-3'>
                        New Password
                      </label>
                      <input
                        type='password'
                        {...formik.getFieldProps('new_password')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {'is-invalid': formik.touched.new_password && formik.errors.new_password},
                          {
                            'is-valid': formik.touched.new_password && !formik.errors.new_password,
                          }
                        )}
                      />
                      {formik.touched.new_password && formik.errors.new_password && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.new_password}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-lg-4'>
                    <div className='fv-row mb-0 fv-plugins-icon-container'>
                      <label htmlFor='confirmpassword' className='form-label fs-6 fw-bold mb-3'>
                        Confirm New Password
                      </label>
                      <input
                        type='password'
                        autoComplete='off'
                        {...formik.getFieldProps('confirmPassword')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid':
                              formik.touched.confirmPassword && formik.errors.confirmPassword,
                          },
                          {
                            'is-valid':
                              formik.touched.confirmPassword && !formik.errors.confirmPassword,
                          }
                        )}
                      />
                      {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.confirmPassword}</span>
                          </div>
                        </div>
                      )}{' '}
                    </div>
                  </div>
                </div>
                <div className='d-flex mt-5'>
                  <button
                    id='kt_password_submit'
                    type='submit'
                    className='btn btn-primary me-2 px-6'
                    disabled={!formik.isValid || formik.isSubmitting}
                  >
                    {!loading && <span>Update Password</span>}
                    {loading && (
                      <span>
                        Saving password...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                  <button
                    id='kt_password_cancel'
                    type='button'
                    onClick={() => setShowPasswordForm(false)}
                    className='btn btn-color-gray-400 btn-active-light-primary px-6'
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>

            <div
              id='kt_signin_password_button'
              className={clsx('ms-auto', {'d-none': showPasswordForm})}
            >
              <button
                className='btn btn-light btn-active-light-primary'
                onClick={() => setShowPasswordForm(true)}
              >
                Reset Password
              </button>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
