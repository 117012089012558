import {useIntl} from 'react-intl';
import {Navigate, Outlet, Route, Routes} from 'react-router-dom';
import {PageTitle} from '../../../_metronic/layout/core';
import {AdminOverviewPage} from './pages/overview/AdminOverviewPage';
import SettingsPage from './pages/settings/SettingsPage';
import UsersPage from './pages/users/UsersPage';
import LoginSessionsPage from './pages/login-sessions/LoginSessionsPage';
import OrganizationsList from './pages/organization/components/OrganizationsList';
import Organization from './pages/organization/Organization';

const Admin = () => {
  const intl = useIntl();

  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path='overview'
          element={
            <>
              <PageTitle breadcrumbs={[]}>Overview</PageTitle>
              <AdminOverviewPage />
            </>
          }
        />

        <Route
          path='users'
          element={
            <>
              <PageTitle breadcrumbs={[]}>Users</PageTitle>
              <UsersPage />
            </>
          }
        />

        <Route
          path='settings'
          element={
            <>
              <PageTitle breadcrumbs={[]}>Settings</PageTitle>
              <SettingsPage />
            </>
          }
        />

        <Route
          path='login-sessions'
          element={
            <>
              <PageTitle breadcrumbs={[]}>Login Activity</PageTitle>
              <LoginSessionsPage />
            </>
          }
        />

        <Route
          path='organizations'
          element={
            <>
              <PageTitle breadcrumbs={[]}>Organizations Managements</PageTitle>
              <OrganizationsList />
            </>
          }
        />

        <Route
          path='organization/*'
          element={
            <>
              <PageTitle breadcrumbs={[]}>View Organization </PageTitle>
              <Organization />
            </>
          }
        />

        <Route index element={<Navigate to='/admin/overview' />} />
      </Route>
    </Routes>
  );
};

export default Admin;
