import clsx from 'clsx';
import {useFormik} from 'formik';
import {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import {Dropdown} from 'react-bootstrap';
import {FormFieldErrorMessage} from '../../../../shared/components/FormFieldErrorMessage';
import {editUser} from '../../admin/api/users-api';
import {GetRolesByOrg, getRoles} from '../../admin/pages/roles/roles-api';
import {Role} from '../../app/types';
import {UserModel, useAuth} from '../../auth';
import {ApiError} from '../../auth/core/apiModels';
import {ModelHeaderLayout} from '../../shared/components/ModalLayout';
import {Package} from '../types';
import {Editor} from 'primereact/editor';
import {InputNumber} from 'primereact/inputnumber';
import {createPackage, editPackage} from '../billing-api';

interface Props {
  submitComplete: (packages?: Package) => void;
  package?: Package;
}

const EditPackage = ({submitComplete, package: existingPackage}: Props) => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [orgRoles, setOrgRoles] = useState<Role[]>([]);

  const {currentUser} = useAuth();

  const validationSchema = Yup.object().shape({
    credits: Yup.number().min(1, 'Minimum 1 credits'),
    name: Yup.string().min(3),
    price: Yup.number().min(1, 'Minimum 1 USD'),
  });

  const initialValues: Package = {
    id: 0,
    name: '',
    description: '',
    credits: 0,
    price: 0,
    inactive: false,
    time_created: new Date(),
    time_updated: new Date(),
  };

  const renderHeader = () => {
    return (
      <>
        <span className='ql-formats'>
          <button className='ql-bold' aria-label='Bold'></button>
          <button className='ql-italic' aria-label='Italic'></button>
          <button className='ql-underline' aria-label='Underline'></button>
          <button className='ql-underline' aria-label='Underline'></button>
        </span>
        <span className='ql-formats'>
          <select className='ql-header'></select>
        </span>
        <span className='ql-formats'>
          <select className='ql-color'></select>
          <select className='ql-background'></select>
        </span>
      </>
    );
  };

  const formik = useFormik({
    initialValues: existingPackage || initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true);

      try {
        if (formik.initialValues === values) return;

        const pckg: Package = {...values};
        existingPackage ? await editPackage(pckg) : await createPackage(pckg);

        // await editUser(plan.id);

        toast.success(`${pckg.name} package submitted successfully`);

        setSubmitted(true);
        submitComplete(pckg);
      } catch (error: any) {
        setSubmitting(false);
        setLoading(false);

        const responseData: ApiError | undefined = error?.response?.data;
        if (responseData) setStatus(responseData.msg);
        else setStatus('Failed to update User Info');
      }
    },
  });

  // RENDERING
  return (
    <>
      <ModelHeaderLayout
        title={existingPackage ? `Edit Package: ${existingPackage.name}` : `New package`}
        showCloseButton
        onClose={() => submitComplete()}
      ></ModelHeaderLayout>

      <main
        className={clsx('modal-body p-5 px-6 scroll', {
          'overlay overlay-block': loading,
        })}
      >
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          onSubmit={formik.handleSubmit}
        >
          {formik.status && (
            <div className='mb-5 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}

          <fieldset className='row fv-row mb-4'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Name</label>
            <input
              placeholder='Package Name'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('name')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.name && formik.errors.name,
                },
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                }
              )}
            />
            {formik.touched.name && formik.errors.name && (
              <FormFieldErrorMessage>{formik.errors.name}</FormFieldErrorMessage>
            )}
          </fieldset>

          <fieldset className='row fv-row mb-4'>
            <label className='form-label fw-bolder text-dark fs-6'>Description</label>

            <Editor
              value={formik.values.description}
              headerTemplate={renderHeader()}
              onTextChange={(e) => formik.setFieldValue('description', e.htmlValue)}
              style={{height: '120px'}}
            />

            {formik.touched.description && formik.errors.description && (
              <FormFieldErrorMessage>{formik.errors.description}</FormFieldErrorMessage>
            )}
          </fieldset>

          <fieldset className='row fv-row mb-4'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Credits</label>

            <InputNumber
              inputClassName='bg-light'
              className='mw-200px'
              value={formik.values.credits}
              onValueChange={(e) => formik.setFieldValue('credits', e.value)}
              suffix=' credits'
              showButtons
              min={1}
              max={999999}
              disabled={!!existingPackage}
            />

            {formik.touched.credits && formik.errors.credits && (
              <FormFieldErrorMessage>{formik.errors.credits}</FormFieldErrorMessage>
            )}
          </fieldset>

          <fieldset className='row fv-row mb-4'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Price</label>

            <InputNumber
              className='mw-200px'
              inputClassName='bg-light'
              value={formik.values.price}
              onValueChange={(e) => formik.setFieldValue('price', e.value)}
              mode='currency'
              currency='USD'
              showButtons
              min={1}
              disabled={!!existingPackage}
            />

            {formik.touched.price && formik.errors.price && (
              <FormFieldErrorMessage>{formik.errors.price}</FormFieldErrorMessage>
            )}
          </fieldset>
        </form>
      </main>

      <footer className='modal-footer p-5 border-top'>
        <button type='button' onClick={() => submitComplete()} className='btn btn-light me-2'>
          Close
        </button>
        <button
          type='button'
          onClick={() => formik.submitForm()}
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </footer>
    </>
  );
};

export default EditPackage;
