/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react';
import {Link, useSearchParams} from 'react-router-dom';
import {toAbsoluteUrl} from '../../../../_metronic/helpers';
import {useAuth} from '..';
import {useBranding} from '../../app/core/BrandingProvider';
const SUPPORT_EMAIL = 'admin@vree.ai';

export const FailedPage = () => {
  const [search] = useSearchParams();
  const {organization} = useBranding();

  const msg = search.get('m');
  const header = search.get('h');

  return (
    <div className='card-body py-15 py-lg-20 text-center'>
      {/* begin::Title */}
      <h1 className='fw-bolder text-gray-900 mb-5'>
        {header ? atob(header) : `Something wrong happened!`}
      </h1>
      {/* end::Title */}

      <div className='fs-5 mb-4'>
        <span className='fw-semibold text-gray-700'>
          {msg ? atob(msg) : `Something wrong happened!!`}, please contact support at
          <a
            className='text-primary ms-2'
            href={`mailto:${(organization as any)?.support_email || SUPPORT_EMAIL}`}
          >
            {(organization as any)?.support_email || SUPPORT_EMAIL}
          </a>
        </span>
      </div>

      {/* begin::Action */}
      {/* <div className='fs-6 mb-8  '>
        <Link to={`/auth/login`}>
          <a className='btn  btn-link btn-color-info btn-active-color-primary fw-bold'>Login</a>
        </Link>
      </div> */}
      {/* end::Action */}

      {/* begin::Link */}
      {/* <div className='mb-11'>
        <a href='/metronic8/demo1/../demo1/index.html' className='btn btn-sm btn-primary'>
          Skip for now
        </a>
      </div> */}
      {/* end::Link */}

      {/* begin::Illustration */}
      <div className='mb-0'>
        <img
          src={toAbsoluteUrl('/media/illustrations/unitedpalms-1/16.png')}
          className='mw-100 mh-300px theme-light-show'
          alt=''
        />
        <img
          src={toAbsoluteUrl('/media/illustrations/unitedpalms-1/16-dark.png')}
          className='mw-100 mh-300px theme-dark-show'
          alt=''
        />
      </div>
      {/* end::Illustration */}
    </div>
  );
};
