import {Link} from 'react-router-dom';
import {toAbsoluteUrl} from '../../../../_metronic/helpers';

export const EmailAlreadyConfirmed = () => {
  return (
    <div className='card-body py-15 py-lg-20 text-center'>
      {/* begin::Title */}
      <h1 className='fw-bolder text-gray-900 mb-5'>Email Already Confirmed!</h1>
      {/* end::Title */}

      <div className='fs-5 mb-4'>
        <span className='fw-semibold text-gray-700'>
          Your email is already confirmed, You can login to the application and start modeling!
        </span>
      </div>

      {/* begin::Link */}
      <div className='mb-11'>
        <Link to='/auth/login' className='btn btn-sm btn-primary'>
          Login to App
        </Link>
      </div>
      {/* end::Link */}

      {/* begin::Illustration */}
      <div className='mb-0'>
        <img
          src={toAbsoluteUrl('/media/illustrations/unitedpalms-1/2.png')}
          className='mw-100 mh-300px theme-light-show'
          alt=''
        />
      </div>
      {/* end::Illustration */}
    </div>
  );
};
