/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import ImageUploading, {ImageListType, ImageType} from 'react-images-uploading';
import clsx from 'clsx';
import heic2any from 'heic2any';
import {
  convertImageFileToBase64,
  convertHEICtoPNG,
  convertHeicFilesIfExist,
} from '../../../../../../shared/images-util';
import {imageFileToDataURL} from '../../../../../../shared/utils';

export const allowedFileType = ['png', 'jpg', 'jpeg', 'webp', 'heic'];

const maxFileSize = 4096000;

interface Props {
  onImagesChanged: (imageList: File[] | undefined) => void;
  defaultImages: any[] | undefined;
  maxNumber?: number;
  customDesign?: boolean;
}

const ImageUpload = ({onImagesChanged, defaultImages, maxNumber = 5, customDesign}: Props) => {
  const [images, setImages] = useState<ImageType[]>([]);
  const [loading, setLoading] = useState(false);

  const onChange = async (imageList: ImageListType, addUpdateIndex: number[] | undefined) => {
    setLoading(true);

    const originalFiles = imageList.map((item) => item.file) as File[];
    const files = await convertHeicFilesIfExist(originalFiles);

    const updatedImages = await updateImagesBase64Field(files);
    setImages(updatedImages as never[]);

    setLoading(false);

    if (!files || files.length === 0) {
      console.log('image changes undefined', files);

      return onImagesChanged(undefined);
    }

    onImagesChanged(files);
    console.log('image changes', files);
  };

  const updateImagesBase64Field = async (files: File[]) => {
    const base64Files = await Promise.all(files.map((file) => convertImageFileToBase64(file)));
    let i = 0;
    const updatedImages = files.map((file) => {
      const imgType: ImageType = {
        dataURL: base64Files[i++] || '',
        file: file,
      };
      return imgType;
    });
    return updatedImages;
  };

  const mapImages = async () => {
    if (!defaultImages || defaultImages?.length === 0) return setImages([]);

    const updatedImages = await updateImagesBase64Field(defaultImages);
    setImages(updatedImages);
  };

  useEffect(() => {
    mapImages();
  }, [defaultImages]);

  return (
    <div>
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        maxFileSize={maxFileSize}
        acceptType={allowedFileType}
        allowNonImageType={true}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          errors,
          isDragging,
          dragProps,
        }) => (
          <div>
            <header className={clsx('mb-7')}>
              <div className={clsx({'d-none': customDesign})}>
                <a className='btn btn-sm btn-primary' onClick={onImageUpload}>
                  Upload
                  {loading && (
                    <span title='Convert heic images may take some times'>
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </a>
                {maxNumber && maxNumber > 1 && (
                  <a className='btn btn-sm btn-light-primary mx-2' onClick={onImageRemoveAll}>
                    Remove all
                  </a>
                )}
              </div>

              <div className='form-text'>
                Allowed file types: {allowedFileType.join(', ')}. Max file size is{' '}
                {maxFileSize / 1000} KB.
              </div>

              <div className='errors text-danger'>
                {errors?.maxNumber && <div>Upload up to {maxNumber} images only</div>}
                {errors?.acceptType && <div> File type is not allowed</div>}
                {errors?.maxFileSize && <div> File exceeded maximum size </div>}
              </div>
            </header>

            {/* Images list */}
            <section className='d-flex flex-row flex-wrap'>
              {/* Drop Zone */}
              {imageList.length < maxNumber && (
                <div
                  className={clsx(
                    'd-flex flex-center w-full h-100px mb-8 me-8 p-3 border-box border-dashed text-center text-gray-600',
                    {'border-primary ': isDragging},
                    {'border-gray-300': !isDragging}
                    // {'absolute w-300px h-200px bg-white z-1 ': isMouseDragging}
                  )}
                  {...dragProps}
                >
                  Drop image{maxNumber !== 1 ? 's' : ''} here
                  {customDesign && (
                    <div className='ms-3'>
                      or
                      <a className='ms-3 btn btn-sm btn-primary' onClick={onImageUpload}>
                        Upload
                        {loading && (
                          <span title='Convert heic images may take some times'>
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </a>
                    </div>
                  )}
                </div>
              )}
              {/* Images */}
              {imageList.map((image, index) => (
                <div key={index} className='mb-8 me-8'>
                  <div className='image-input image-input-outline' data-kt-image-input='true'>
                    <div
                      className='image-input-wrapper w-100px h-100px'
                      style={{backgroundImage: `url('${image.dataURL}')`}}
                    ></div>

                    {/* begin::Label */}
                    <label
                      className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                      onClick={() => onImageUpdate(index)}
                      data-bs-toggle='tooltip'
                      data-kt-image-input-action='change'
                      title='Change avatar'
                    >
                      <i className='bi bi-pencil-fill fs-7'></i>
                    </label>
                    {/* end::Label */}

                    {/* begin::Cancel */}
                    <span
                      className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                      data-kt-image-input-action='cancel'
                      data-bs-toggle='tooltip'
                      title='Cancel image'
                    >
                      <i className='bi bi-x fs-2'></i>
                    </span>
                    {/* end::Cancel */}

                    {/* begin::Remove */}
                    <span
                      className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                      onClick={() => onImageRemove(index)}
                      data-kt-image-input-action='remove'
                      data-bs-toggle='tooltip'
                      title='Remove image'
                    >
                      <i className='bi bi-x fs-2'></i>
                    </span>
                    {/* end::Remove */}
                  </div>
                </div>
              ))}
            </section>
          </div>
        )}
      </ImageUploading>
    </div>
  );
};

export default ImageUpload;
