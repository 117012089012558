/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react';

import {
  createResponseContext,
  initialQueryResponse,
  WithChildren,
  stringifyRequestQuery,
} from '../../../../../_metronic/helpers';
import {parseError} from '../../../../../shared/utils';
import {useAuth} from '../../../auth';
import {Model} from '../../../models-management/types';
import useVideosPageStore from './videos-page.store';
import {getAllVideos, getVideos} from '../../vidoes.api';
import {Video} from '../../vidoes.types';
import {useQuery} from 'react-query';

const VideosQueryContext = createResponseContext<Video>(initialQueryResponse);

const VideosQueryProvider: FC<WithChildren> = ({children}) => {
  const {filters: requestState} = useVideosPageStore();

  const [query, setQuery] = useState<string>(stringifyRequestQuery(requestState));
  const updatedQuery = useMemo(() => stringifyRequestQuery(requestState), [requestState]);
  const [error, setError] = useState('');

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery]);

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery<any, string>(
    `VideosPage-${query}`,
    async () => {
      try {
        const res = await getVideos(query);
        const response = res.data;
        console.log('videos fetched', query, requestState, response);

        setError('');

        // client filtering
        const filteredVideos = response.experiences?.filter((v: any) =>
          v.name
            .toLocaleLowerCase()
            .includes((requestState.search || '').trim().toLocaleLowerCase())
        );

        const result = {
          data: filteredVideos || [],
          count: response.count,
          payload: {page: 1},
          error: 'yyyy',
        };
        return result;
      } catch (error: any) {
        console.error(error);
        setError(parseError(error));
        return {
          data: [],
          count: 0,
          payload: {},
        };
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <VideosQueryContext.Provider
      value={{isLoading: isFetching, refetch, response, query, error: error}}
    >
      {children}
    </VideosQueryContext.Provider>
  );
};

const useVideosQuery = () => useContext(VideosQueryContext);

const useQueryResponseData = () => {
  const {response} = useVideosQuery();
  if (!response) {
    return [];
  }

  return response?.data || [];
};

export {VideosQueryProvider, useVideosQuery, useQueryResponseData};
