import clsx from 'clsx';
import React, {useState} from 'react';
import useEditorStore from '../../model-editor.store';
import {GLTFExporter} from 'three/examples/jsm/exporters/GLTFExporter';
import {toast} from 'react-toastify';
import {uploadEditedModel} from '../../model-editor.api';
import {parseError, sleep} from '../../../../../shared/utils';

export function ModelEditorSubmitForm({onBack, onSaved}: any) {
  const {model, groupRef} = useEditorStore();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [error, setError] = useState('');

  const exportGLB = () => {
    if (!groupRef) return; // Check if groupRef is available
    setLoading(true);
    const exporter = new GLTFExporter();
    exporter.parse(
      groupRef,
      async (result) => {
        if (result instanceof ArrayBuffer) {
          //   download(result, 'scene.glb');

          await SubmitModel(result);
        }
      },
      (error) => {
        setLoading(false);
        // toast.error(`Can't export glb file`);
        console.error(error);
        setError(parseError(error));
      },
      {binary: true, onlyVisible: false} // Export as binary GLB
    );
  };

  const download = (buffer: ArrayBuffer, filename: string) => {
    const blob = new Blob([buffer], {type: 'application/octet-stream'});
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  };

  const SubmitModel = async (result: ArrayBuffer) => {
    if (!model?.pub_id) return;

    try {
      const blob = new Blob([result], {type: 'application/octet-stream'});
      const file = new File([blob], 'test.glb');

      await uploadEditedModel(model.pub_id, file, name, (progress) => {});

      // await sleep(1500);

      sessionStorage.clear();

      toast.success('Model saved successfully');
      setLoading(false);

      onSaved();
    } catch (error) {
      setError(parseError(error));
      setLoading(false);
    }
  };

  return (
    <div className='editor-fields d-flex flex-column h-100 card shadow-sm py-2'>
      <>
        <header>
          <div className='d-flex w-100 align-items-center justify-content-start border-bottom-2 border-secondary '>
            <button className='btn btn-icon' title='Back to editor' onClick={() => onBack()}>
              <i className='fas fa-arrow-left fs-3'></i>
            </button>

            <div className='p-2 text-dark'>Edited model information:</div>
          </div>
        </header>

        {error && <div className='alert alert-danger m-5'>{error}</div>}

        <div
          className='fields flex-grow-1 p-6 scroll'
          style={{
            maxHeight: '58vh',
          }}
        >
          <div className='tab-content'>
            <div className='mb-10 text-muted'>
              The new result will be saved as a brand new model , please provide a name for it:
            </div>
            <fieldset>
              <label className='form-label fw-bolder text-dark fs-6'>Name</label>
              <input
                placeholder='Enter new name'
                type='text'
                value={name}
                onChange={(e) => setName(e.target.value)}
                autoComplete='off'
                className={clsx('form-control form-control-lg form-control-solid')}
              />
            </fieldset>
          </div>
        </div>
      </>

      <footer className='text-end'>
        <div className='btn btn-light-primary mx-2 px-10' onClick={() => onBack(false)}>
          <i className='bi bi-arrow-left fs-4 me-2'></i>
          Back
        </div>
        <div
          className='btn btn-primary mx-2 px-10'
          onClick={() => {
            exportGLB();
          }}
        >
          {!loading && (
            <>
              <i className='bi bi-save fs-4 me-2'></i>
              Submit
            </>
          )}
          {loading && (
            <span>
              Submitting...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </div>
      </footer>
    </div>
  );
}
