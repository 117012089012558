import {ColumnFilterElementTemplateOptions} from 'primereact/column';
import {Dropdown} from 'primereact/dropdown';

export const RateFilterTemplate = (users: any[], options: ColumnFilterElementTemplateOptions) => {
  const list = [...new Set(users.map((d) => d.role_name))];
  return (
    <Dropdown
      value={options.value}
      options={list}
      onChange={(e: any) => options.filterApplyCallback(e.value)}
      placeholder='Select One'
      className='p-column-filter'
      showClear
      style={{minWidth: '12rem'}}
    />
  );
};
