/* eslint-disable no-fallthrough */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import {useSearchParams} from 'react-router-dom';

import {useEffect, useState} from 'react';
import {
  ARModelPageParameters,
  ArAppModelType,
  Model,
  ModelCategoryEnum,
  categories,
} from '../../types';
import {
  getSharedModel,
  getSingleModel as getModel,
  getAllUserModelsIds,
  getSingleModel,
} from '../../api/models-api';
import {parseError} from '../../../../../shared/utils';
import {LoadingPlaceholder} from '../../../../../shared/components/LoadingPlaceholder';
import {useBranding} from '../../../app/core/BrandingProvider';
import useModelStore from '../single-model/model.store';
import {FBXARApp} from './components/FBXARApp';
import {VREEARApp} from './components/VREEARApp';
import {WatchesApp} from './components/WatchesApp';
import {FootARApp, FootARAppWrapper} from '../../../model-viewer/components/FootARApp';

const FBX_ORG_ID = 1;

export const ARModelPage = () => {
  const {organization} = useBranding();
  const [model, setModel] = useState<Model>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [appType, setAppType] = useState<ArAppModelType>('vree');
  const [search] = useSearchParams();
  const [parameters, setParameters] = useState<ARModelPageParameters>({});
  const {id, is_external, is_sample, location, query} = parameters;
  const {modelsIds, setModelsIds, setCurrentModelId} = useModelStore();

  const fetchModel = async () => {
    if (!id) return;

    setLoading(true);

    try {
      const _model = await (is_external ? getSharedModel(id) : getModel(id));
      if (is_external) _model.location = `${_model.location}?type=AR`;

      setModel(_model);
    } catch (error) {
      setError(parseError(error));
    } finally {
      setLoading(false);
    }
  };

  const getNavigationIds = async () => {
    const ids = await getAllUserModelsIds(query || '', 'AR');
    setModelsIds(ids);
  };

  // USAGE: get page parameters
  useEffect(() => {
    setParameters({
      id: search.get('id'),
      is_external: search.get('is_external') === '1',
      is_sample: search.get('is_sample') === '1',
      location: search.get('location'),
      name: search.get('name'),
      query: search.toString().split('query=')[1],
    });
  }, [search, modelsIds]);

  useEffect(() => {
    if (
      search.get('is_external') === '1' ||
      modelsIds.length > 0 ||
      Object.keys(parameters).length === 0
    )
      return;

    getNavigationIds();
  }, [parameters]);

  //USAGE: app type
  useEffect(() => {
    const isFBXDomain = organization?.domain === '3d-from-photo.fittingbox.com';
    if (isFBXDomain) return setAppType('fbx');

    if (!model) return;

    setAppType([1, 2].includes(model.org_id || 0) ? 'fbx' : 'vree');
  }, [organization, model]);

  // USAGE: get model from DB
  useEffect(() => {
    if (!id || is_sample) return;

    setCurrentModelId(id);

    if (!location) fetchModel();
  }, [id]);

  // fitmix script
  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      organization && organization.id === 2
        ? 'https://static-test.fittingbox.com/api/v1/fitmix.js'
        : 'https://static.fittingbox.com/api/v1/fitmix.js';
    script.id = 'fitmix-js';
    script.async = true;
    document.head.appendChild(script);
  }, [organization]);

  // RENDERING
  if (loading) return <LoadingPlaceholder label='loading model' />;

  switch (model?.category_id) {
    case ModelCategoryEnum.Footwear:
      return <FootARAppWrapper model={model} parameters={parameters} />;
    case ModelCategoryEnum.Watches:
      return <WatchesApp model={model} parameters={parameters} />;
    default:
    case ModelCategoryEnum.Eyewear:
      return appType === 'fbx' ? (
        <FBXARApp model={model} parameters={parameters} />
      ) : (
        <VREEARApp model={model} parameters={parameters} />
      );
  }
};
