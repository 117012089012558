import {useEffect} from 'react';
import {Navigate, Routes, useNavigate} from 'react-router-dom';
import {useAuth} from './core/Auth';

export function Logout() {
  const {logout} = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    logout();
    navigate('/auth/login');
  }, [logout]);

  return (
    <Routes>
      <Navigate to='/auth/login' />
    </Routes>
  );
}
