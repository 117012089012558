import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {parseError} from '../../../../../../shared/utils';
import CountWidget from '../../../../shared/components/CountWidget';
import {getUsersCount} from '../../../api/users-api';

const UsersCountWidget = () => {
  const [count, setCount] = useState(0);
  const [error, setError] = useState('');

  useEffect(() => {
    const get = async () => {
      try {
        const _count = await getUsersCount();
        setCount(_count || 0);
      } catch (err) {
        setError(parseError(err));
        toast.error(error || 'Can not load users count');
      }
    };

    get();
  }, []);

  return (
    <div>
      <CountWidget count={count} label='users' link='/admin/users' />
    </div>
  );
};

export default UsersCountWidget;
