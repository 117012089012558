/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react';
import {PageTitle} from '../../../../_metronic/layout/core';
import clsx from 'clsx';
import {Organization} from '../../app/types';
import {useAuth} from '../../auth';
import {usePermissionChecker} from '../../admin/pages/roles/hooks/permission-checker';
import {ErrorPlaceholder} from '../../../../shared/components/ErrorPlaceholder';
import {LoadingPlaceholder} from '../../../../shared/components/LoadingPlaceholder';
import usePackagesStore from './packages.store';
import {DataTable, DataTableFilterMeta} from 'primereact/datatable';
import {useThemeMode} from '../../../../_metronic/partials';
import {FilterMatchMode} from 'primereact/api';
import {Column} from 'primereact/column';
import dayjs from 'dayjs';
import {Dialog} from 'primereact/dialog';
import EditPackage from './EditPackage';
import {parseError} from '../../../../shared/utils';
import {Package} from '../types';
import {confirmDialog} from 'primereact/confirmdialog';
import {deleteDialogProps} from '../../shared/types';
import {deactivatePackage, fetchPackages, reactivatePackage} from '../billing-api';
import {Link} from 'react-router-dom';
import PurchasePackage from './PurchasePackage';
import {ModalLayout} from '../../shared/components/ModalLayout';

interface Props {}

const Packages = ({}: Props) => {
  const {currentUser} = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const {packages, setPackages} = usePackagesStore();
  const [orgs, setOrgs] = useState<Organization[]>([]);
  const {mode} = useThemeMode();
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    name: {value: null, matchMode: FilterMatchMode.EQUALS},
  });
  const [showPackageWindow, setShowPackageWindow] = useState(false);
  const [showPurchasePackageWindow, setShowPurchasePackageWindow] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState();
  const [gridView, setGridView] = useState(false);

  const getPackages = async () => {
    try {
      setLoading(true);

      const res = await fetchPackages();

      const _packages = res.data.packages || [];
      setPackages(_packages);
    } catch (error) {
      setError(parseError(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPackages();
  }, []);

  if (loading) return <LoadingPlaceholder label='getting packages..' />;
  if (error) return <ErrorPlaceholder error={error} />;

  return (
    <div>
      <PageTitle breadcrumbs={[{title: 'Billing', path: 'billing', isActive: false}]}>
        Packages
      </PageTitle>

      <div>
        <div className='container-fluid'>
          <header className='d-flex flex-wrap flex-stack pb-7'>
            <div className='d-flex flex-wrap align-items-center my-1'>
              <h3 className='fw-bold me-5 my-1'>Packages ({packages.length})</h3>
              {/* <UsersFilters /> */}
            </div>

            {/* <Link to={'/billing/purchase-package'}>
              <button className='btn btn-light'>Purchase</button>
            </Link> */}

            {/* <button
              className='btn btn-primary btn-sm ms-1'
              onClick={() => {
                setShowPurchasePackageWindow(true);
              }}
            >
              Purchase Window
            </button> */}

            <div className='d-flex flex-wrap my-1 '>
              <button className='btn btn-light  mx-3' onClick={() => setGridView((d) => !d)}>
                {gridView ? 'Manage' : 'Preview'}
              </button>

              <button
                className='btn btn-primary btn-sm ms-1'
                onClick={() => {
                  setSelectedPackage(undefined);
                  setShowPackageWindow(true);
                }}
              >
                New Package
              </button>
            </div>
          </header>

          {gridView && (
            <div className='container row g-10'>
              {packages
                .filter((d) => !d.inactive)
                .map((pckg) => (
                  <div className='col-md-3'>
                    <div className='card shadow-sm hover-elevate-up '>
                      <div className='card-header'>
                        <h3 className='card-title d-flex flex-column'>
                          <div>{pckg.name}</div>
                          <div className='text-primary fs-7 fw-bold'>{pckg.price} $</div>
                        </h3>
                        <div className='card-toolbar'></div>
                      </div>
                      <div className='card-body card-scroll h-150px'>
                        <div dangerouslySetInnerHTML={{__html: pckg.description}}></div>
                      </div>
                      <div className='card-footer text-end d-flex justify-content-between align-items-center h-70px '>
                        <div className='text-start'>+{pckg.credits.toLocaleString()} Credits</div>
                        <button type='button' className='btn btn-sm btn-primary '>
                          Purchase
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}

          {!gridView && (
            <section className='card card-flush '>
              <div className='card-body pt-0'>
                <section className='table-responsive'>
                  <div className='dataTables_wrapper dt-bootstrap4 no-footer pt-10  min-h-400px'>
                    <DataTable
                      showGridlines
                      tableClassName='table align-middle table-row-dashed gy-5  table-hover table-rounded'
                      value={packages}
                      rowsPerPageOptions={[10, 25, 50, 100]}
                      sortField='created_date'
                      sortOrder={-1}
                      removableSort
                      paginator
                      rows={10}
                      tableStyle={{minWidth: '50rem'}}
                      cellClassName={() => 'fs-6 fw-semibold'}
                      rowClassName={() => `lh-xl ${mode === 'dark' && 'bg-light text-gray-700'}`}
                      paginatorClassName={clsx({'bg-light text-gray-700': mode === 'dark'})}
                      // selectionMode={'checkbox'}
                      // selection={selectedUsers}
                      // onSelectionChange={(e) => setSelectedUsers(e.value || [])}
                      dataKey='id'
                      filters={filters}
                      filterDisplay='menu'
                      globalFilterFields={['role_name']}
                    >
                      <Column
                        className='w-250px'
                        field='name'
                        header='Package Name'
                        sortable
                        body={(pckg) => (
                          <>
                            <div>{pckg.name}</div>
                            {/* <div className='text-muted '>{pckg.description}</div> */}
                          </>
                        )}
                        bodyClassName={() => 'fw-semibold'}
                      ></Column>

                      <Column
                        className='w-200px'
                        field='credits'
                        header='Credits'
                        body={(pckg) => <div className='text-gray-700'>{pckg.credits} credits</div>}
                        sortable
                        bodyClassName={() => 'fw-semibold'}
                      ></Column>

                      <Column
                        className='w-100px'
                        field='price'
                        header='Price'
                        body={(pckg) => <div className='text-primary'>{pckg.price}$</div>}
                        sortable
                        bodyClassName={() => 'fw-semibold text-end pe-10'}
                      ></Column>

                      <Column
                        field='time_created'
                        header='Created '
                        filterField='time_created'
                        dataType='date'
                        sortable
                        body={(pckg) => (
                          <div className='lh-base'>
                            <div>{dayjs(pckg.created_date).format('YYYY/MM/DD')}</div>
                            <div className='text-muted fs-7 '>
                              By {pckg.created_by?.name || '-Not Specified-'}
                            </div>
                          </div>
                        )}
                        bodyClassName={() => 'text-gray-700'}
                      ></Column>

                      <Column
                        field='inactive'
                        header='State'
                        sortable
                        body={(pckg) => (
                          <>
                            {!!!pckg.inactive && (
                              <div className='badge badge-sm badge-light-success'>Active</div>
                            )}
                            {!!pckg.inactive && (
                              <div className='badge badge-sm badge-light-danger'>Inactive</div>
                            )}
                          </>
                        )}
                        bodyClassName={() => 'text-gray-700'}
                      ></Column>

                      <Column
                        header='Actions'
                        className='w-auto '
                        body={(pckg) => (
                          <div className='text-start'>
                            {!!!pckg.inactive && (
                              <a
                                title='Edit'
                                onClick={() => {
                                  setSelectedPackage(pckg);
                                  setShowPackageWindow(true);
                                }}
                                className='btn btn-icon btn-bg-light btn-active-color-warning btn-sm mx-1'
                              >
                                <i className='ki-duotone ki-pencil fs-2'>
                                  <span className='path1'></span>
                                  <span className='path2'></span>
                                </i>
                              </a>
                            )}

                            {!!!pckg.inactive && (
                              <a
                                title='Delete'
                                onClick={() => {
                                  confirmDialog({
                                    ...deleteDialogProps,
                                    header: 'Confirm',
                                    acceptLabel: 'Deactivate',
                                    message: `Do you want to deactivate the package (${pckg.name})?`,
                                    accept: async () => {
                                      await deactivatePackage(pckg);
                                      getPackages();
                                    },
                                    reject: () => {},
                                  });
                                }}
                                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                              >
                                <i className='ki-duotone ki-trash fs-2'>
                                  <span className='path1' />
                                  <span className='path2' />
                                  <span className='path3' />
                                  <span className='path4' />
                                  <span className='path5' />
                                </i>
                              </a>
                            )}

                            {!!pckg.inactive && (
                              <a
                                title='Activate'
                                onClick={() => {
                                  confirmDialog({
                                    ...deleteDialogProps,
                                    acceptClassName: 'btn btn-sm btn-success',
                                    header: 'Confirm',
                                    acceptLabel: 'Reactivate',
                                    message: `Do you want to reactivate the package (${pckg.name})?`,
                                    accept: async () => {
                                      await reactivatePackage(pckg);
                                      getPackages();
                                    },
                                    reject: () => {},
                                  });
                                }}
                                className='btn btn-icon btn-bg-light btn-active-color-success btn-sm'
                              >
                                <i className='ki-duotone ki-arrow-circle-left fs-2'>
                                  <span className='path1' />
                                  <span className='path2' />
                                </i>
                              </a>
                            )}
                          </div>
                        )}
                      ></Column>
                    </DataTable>
                  </div>
                </section>
              </div>
            </section>
          )}
        </div>
      </div>

      <Dialog
        modal
        dismissableMask
        closable={false}
        visible={showPackageWindow}
        onHide={() => setShowPackageWindow(false)}
      >
        <EditPackage
          package={selectedPackage}
          submitComplete={(pckg) => {
            setShowPackageWindow(false);
            pckg && getPackages();
          }}
        />
      </Dialog>

      <Dialog
        modal
        dismissableMask
        closable={false}
        visible={showPurchasePackageWindow}
        onHide={() => setShowPurchasePackageWindow(false)}
      >
        <ModalLayout
          title='Purchase Package'
          showCloseButton
          large
          onClose={() => setShowPurchasePackageWindow(false)}
        >
          <PurchasePackage isWindow />
        </ModalLayout>
      </Dialog>
    </div>
  );
};

export default Packages;
