import {create} from 'zustand';

interface IModelStore {
  currentModelId: string;
  modelsIds: string[];
  modelIdsQuery: string;
  showViewer: boolean;

  init: () => void;
  setCurrentModelId: (pubId: string) => void;
  setModelsIds: (user: string[]) => void;
  setNextId: () => void;
  setPreviousId: () => void;
  getNext: () => string | undefined;
  getPrevious: () => string | undefined;
  setModelsIdsQuery: (queryVar: string) => void;
  getModelsIdsQuery: () => string;
  setShowViewer: (value: boolean) => void;
}

const useModelStore = create<IModelStore>((set, get) => ({
  currentModelId: '',
  modelsIds: [],
  modelIdsQuery: '',
  showViewer: true,

  setCurrentModelId: (pubId: string) => set({currentModelId: pubId}),
  setModelsIdsQuery: (queryVar: string) => {
    set({modelIdsQuery: queryVar});
  },
  setModelsIds: (modelsIds: string[] | undefined) => set({modelsIds: modelsIds || []}),

  setPreviousId: () => {
    const {modelsIds, currentModelId} = get();

    const currentIndex = modelsIds.findIndex((d) => d === currentModelId);
    const id = currentIndex > 0 ? modelsIds[currentIndex - 1] : undefined;

    if (id === undefined) return;

    set({currentModelId: id});
  },
  setNextId: () => {
    const {modelsIds, currentModelId} = get();

    const currentIndex = modelsIds.findIndex((d) => d === currentModelId);
    const id = currentIndex < modelsIds.length - 1 ? modelsIds[currentIndex + 1] : undefined;

    if (id === undefined) return;

    set({currentModelId: id});
  },
  getPrevious: () => {
    const {modelsIds, currentModelId} = get();

    const currentIndex = modelsIds.findIndex((d) => d === currentModelId);
    const id = currentIndex > 0 ? modelsIds[currentIndex - 1] : undefined;

    if (id === undefined) return;

    return id;
  },
  getNext: () => {
    const {modelsIds, currentModelId} = get();

    const currentIndex = modelsIds.findIndex((d) => d === currentModelId);
    const id = currentIndex < modelsIds.length - 1 ? modelsIds[currentIndex + 1] : undefined;

    if (id === undefined) return;

    return id;
  },
  getModelsIdsQuery: () => {
    const {modelIdsQuery} = get();
    return modelIdsQuery;
  },
  init: () => {
    set({
      currentModelId: '',
      modelsIds: [],
      modelIdsQuery: '',
      showViewer: true,
    });
  },
  setShowViewer: (value) => set({showViewer: value}),
}));

export default useModelStore;
