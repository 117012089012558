import React, {useEffect, useState} from 'react';
import {PageTitle} from '../../../_metronic/layout/core';
import clsx from 'clsx';
import {getAllOrganizations} from '../admin/api/organizations-api';
import {Organization, PermissionLevelEnum} from '../app/types';
import Authorize from '../auth/Authorize';
import {UserRolesEnum} from '../models-management/types';
import {Dropdown} from 'primereact/dropdown';
import {Calendar} from 'primereact/calendar';
import {toAbsoluteUrl} from '../../../_metronic/helpers';
import {downloadModelsReport} from '../admin/api/users-api';
import {parseError} from '../../../shared/utils';
import {toast} from 'react-toastify';
import {useBranding} from '../app/core/BrandingProvider';
import {useAuth} from '../auth';
import {usePermissionChecker} from '../admin/pages/roles/hooks/permission-checker';
import {Nullable} from 'primereact/ts-helpers';

interface Props {
  reportName: string;
}
const VREE_ORG_ID = 9999;
const Report = ({reportName}: Props) => {
  const {currentUser} = useAuth();
  const [orgs, setOrgs] = useState<Organization[]>([]);
  const [orgFilter, setOrgFilter] = useState<number>();
  const [dates, setDates] = useState<Nullable<(Date | null)[]>>(null);
  const [dateType, setDateType] = useState<'day' | 'month'>('day');
  const [loading, setLoading] = useState(false);
  const {hasPermission} = usePermissionChecker();

  const download = async () => {
    if (!dates) return;
    const _dates = dates as Date[];
    try {
      await downloadModelsReport(_dates[0], _dates[1], orgFilter);
    } catch (error: any) {
      const json = await error.response.data.text();
      var msg = JSON.parse(json);
      toast.error(msg.msg);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    try {
      const getOrgs = async () => {
        const orgs = await getAllOrganizations();
        setOrgs([{name: 'All Organizations', id: undefined}, ...orgs]);

        if (!orgs) return;

        setOrgFilter(orgs[0].id);
      };

      getOrgs();
    } catch (error: any) {
      console.error(error);
    }
  }, []);

  return (
    <div>
      <PageTitle>Reports</PageTitle>
      <div>
        <div className='container-fluid'>
          <article className='card'>
            <header className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold text-dark fs-2'>Models Report</span>
                <span className='text-muted mt-1 fw-semibold fs-5'>Models data CSV Report</span>
              </h3>
            </header>

            <div className='card-body p-9 '>
              <div className='row'>
                <div className='col-md-6'>
                  {' '}
                  <div className='h-150px'>
                    {(currentUser?.is_super_admin ||
                      hasPermission('menu.models', PermissionLevelEnum.SYSTEM)) && (
                      <fieldset className='row mb-7'>
                        <label className='py-2 col-lg-3  fw-bold fs-6 mb-2'>Organization:</label>

                        <div className='col-lg-7 col-xl-5'>
                          <Dropdown
                            className='w-200px '
                            value={orgFilter}
                            onChange={(e: any) => setOrgFilter(e.value)}
                            options={orgs}
                            optionLabel='name'
                            optionValue='id'
                            placeholder='All Organizations'
                            showClear
                          />
                        </div>
                      </fieldset>
                    )}

                    <fieldset className='row mb-7'>
                      <label className='py-2 col-lg-3  fw-bold fs-6 mb-2'>Period:</label>

                      <div className='col-lg-9 '>
                        <Calendar
                          className='w-200px '
                          value={dates}
                          onChange={(e: any) => setDates(e.value)}
                          selectionMode='range'
                          numberOfMonths={2}
                          placeholder='Select range'
                          readOnlyInput
                          // view={dateType === 'month' ? 'month' : undefined}
                          dateFormat={dateType === 'month' ? 'mm/yy' : undefined}
                        />

                        <button
                          className='d-none btn btn-light  btn-sm btn-icon ms-2'
                          title={
                            dateType === 'month'
                              ? 'Enable days selection'
                              : 'Disable days selection'
                          }
                          onClick={() => {
                            setDateType(dateType === 'day' ? 'month' : 'day');
                            setDates(null);
                          }}
                        >
                          <i
                            className={clsx(
                              'fa  color-primary fs-4 ',
                              {'fa-calendar-alt': dateType === 'month'},
                              {'fa-calendar-day': dateType === 'day'}
                            )}
                          ></i>
                        </button>

                        <div className='text-muted text-sm'>*Please choose two dates</div>
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div className='col-md-6'>
                  <img
                    src={toAbsoluteUrl('/media/illustrations/unitedpalms-1/19.png')}
                    alt=''
                    className=' h-200px   h-sm-325px '
                  />
                </div>
              </div>

              <footer className='row'>
                <div className='col-lg-2 '></div>
                <div className='col-md-4'>
                  <button onClick={() => download()} className='btn btn-primary w-fit '>
                    {!loading && <span>Download CSV</span>}
                    {loading && (
                      <span className='indicator-progress d-block'>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </footer>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
};

export default Report;
