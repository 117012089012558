import clsx from 'clsx';
import {useFormik} from 'formik';
import React, {useEffect, useRef, useState} from 'react';
import {toast} from 'react-toastify';
import {FormFieldErrorMessage} from '../../../shared/components/FormFieldErrorMessage';
import {ApiError} from '../auth/core/apiModels';
import {ModelHeaderLayout} from '../shared/components/ModalLayout';
import * as Yup from 'yup';
import {FileUpload} from 'primereact/fileupload';
import {sendIssueReport} from './api/general-api';
import {Model} from '../models-management/types';

interface Props {
  submitComplete: () => void;
  model?: Model;
}

const initialValues = {
  Title: '',
  Description: '',
  Files: [],
};
const formSchema = Yup.object().shape({
  Title: Yup.string().required('Title is required').min(6, 'Title is too small'),
  Description: Yup.string().required('Description is required').min(10, 'Description is too small'),
});

const ReportIssue = ({submitComplete, model}: Props) => {
  const [progress, setProgress] = useState(0);

  const fileUpload = useRef<FileUpload>(null);

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,

    onSubmit: async (values, {setStatus, setSubmitting}) => {
      const files = fileUpload.current?.getFiles();
      console.log(values, files);

      setSubmitting(true);
      setStatus();
      try {
        await sendIssueReport(
          {
            title: values.Title,
            description: values.Description,
            attachments: files || [],
            model_pub_id: model?.pub_id,
          },
          setProgress
        );

        toast.success('Issue reported successfully');
        submitComplete();
      } catch (error: Error | any) {
        setStatus(error?.response?.data.msg || error?.message || 'Failed to submit this model');
        toast.error('Submit Failed');
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (!model) return;

    formik.setValues({
      Title: `Report model #${model?.model_name}`,
      Description: 'this model has an issue',
      Files: [],
    });
  }, [model]);

  return (
    <div>
      <ModelHeaderLayout
        title={`Contact Support`}
        showCloseButton
        onClose={() => submitComplete()}
      ></ModelHeaderLayout>

      <main
        className={clsx('modal-body p-5 px-6 scroll', {
          'overlay overlay-block': formik.isSubmitting,
        })}
      >
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_signup_form'
          onSubmit={formik.handleSubmit}
        >
          {formik.status && (
            <div className='mb-5 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}

          <fieldset className='row fv-row mb-4'>
            <label className='form-label fw-bolder text-dark fs-6'>Title</label>
            <input
              placeholder='Enter issue title'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('Title')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.Title && formik.errors.Title,
                },
                {
                  'is-valid': formik.touched.Title && !formik.errors.Title,
                }
              )}
            />
            {formik.touched.Title && formik.errors.Title && (
              <FormFieldErrorMessage>{formik.errors.Title}</FormFieldErrorMessage>
            )}
          </fieldset>

          <fieldset className='row fv-row mb-4'>
            <label className='form-label fw-bolder text-dark fs-6'>Description</label>
            <textarea
              rows={5}
              placeholder='Describe your issue briefly '
              {...formik.getFieldProps('Description')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.Description && formik.errors.Description,
                },
                {
                  'is-valid': formik.touched.Description && !formik.errors.Description,
                }
              )}
            />
            {formik.touched.Description && formik.errors.Description && (
              <FormFieldErrorMessage>{formik.errors.Description}</FormFieldErrorMessage>
            )}
          </fieldset>

          <fieldset className='row fv-row mb-4'>
            <label className='form-label fw-bolder text-dark fs-6'>Attachments</label>

            {model && <div className='fw-bold mb-3'>- Model ({model?.model_name}) attached</div>}

            <FileUpload
              multiple
              auto
              ref={fileUpload}
              name='files[]'
              headerClassName='py-2 px-4'
              contentClassName='text-muted'
              chooseOptions={{className: 'btn btn-primary btn-sm'}}
              uploadOptions={{className: 'd-none'}}
              cancelOptions={{className: 'd-none'}}
              maxFileSize={3000000}
              emptyTemplate={<p className='m-0'>Drag and drop files here.</p>}
              customUpload
              pt={{badge: {root: {className: 'd-none'}}}} // remove pending badge
            />
          </fieldset>
        </form>
        {formik.isSubmitting && (
          <div className='m-auto mw-75'>
            <div>Uploading files ...</div>
            <div className='progress h-7px bg-primary bg-opacity-50 mt-5'>
              <div
                className='progress-bar bg-primary'
                role='progressbar'
                style={{width: `${progress}%`}}
              ></div>
            </div>
          </div>
        )}
      </main>

      <footer className='modal-footer p-5 border-top'>
        <button type='button' onClick={() => submitComplete()} className='btn btn-light me-2'>
          Close
        </button>

        <button
          type='button'
          onClick={() => formik.submitForm()}
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!formik.isSubmitting && <span className='indicator-label'>Submit</span>}
          {formik.isSubmitting && (
            <span className='indicator-progress d-block'>
              Sending report ...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </footer>
    </div>
  );
};

export default ReportIssue;
