import {create} from 'zustand';
import {Organization} from '../../../app/types';

interface StoreProps {
  users: any[];
  originalUsers: any[];
  searchText: string;
  orgFilter?: number;
  orgs: Organization[];
  setUsers: (value: any[]) => void;
  setOriginalUsers: (value: any[]) => void;
  setSearch: (text: string) => void;
  setOrgFilter: (orgId?: number) => void;
  setOrgs: (value: any[]) => void;
}

const useUsersStore = create<StoreProps>((set, get) => ({
  users: [],
  originalUsers: [],
  searchText: '',
  orgFilter: undefined,
  orgs: [],

  setUsers: (users: any[]) => set({users: users}),
  setOriginalUsers: (users: any[]) => set({originalUsers: users, users: users}),
  setSearch: (text: string) => set({searchText: text}),
  setOrgFilter: (orgId?: number) => set({orgFilter: orgId}),
  setOrgs: (orgs: any[]) => set({orgs: orgs}),
}));

export default useUsersStore;
