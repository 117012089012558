import React, {useState} from 'react';
import dayjs from 'dayjs';
import {Image} from 'primereact/image';
import {Media} from '../../../media.types';
import {useAuth} from '../../../../auth';
import {InputText} from 'primereact/inputtext';
import {toast} from 'react-toastify';
import {parseError} from '../../../../../../shared/utils';
import {ModelHeaderLayout} from '../../../../shared/components/ModalLayout';
import clsx from 'clsx';
import {editMedia} from '../../../media.api';

interface Props {
  video: Media;
  submitComplete: (updatedMedia?: Media) => void;
}
export function MediaEditWindow({video, submitComplete}: Props) {
  const {currentUser} = useAuth();
  const [name, setName] = useState(video.name || '');
  const [loading, setLoading] = useState(false);

  const submit = async () => {
    try {
      setLoading(true);

      const updatedVideo = {...video, name};
      const res = await editMedia(updatedVideo);

      submitComplete(updatedVideo);
    } catch (error) {
      toast.error(`Naming failed, ${parseError(error)}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className=''>
      <ModelHeaderLayout
        title={`Edit name`}
        showCloseButton
        onClose={() => submitComplete()}
      ></ModelHeaderLayout>

      <main className={clsx('modal-body p-5 px-6 scroll', {})}>
        <div className='text-muted mb-5'>
          Feel free to enter a custom name for your rich media .
        </div>
        <fieldset className='mb-2 d-flex align-items-center'>
          <label className='fw-bold w-100px text-dark'>Name: </label>
          <div>
            <InputText
              placeholder='Type the new name...'
              name='name'
              id='name'
              autoFocus
              autoComplete='false'
              className='bg-light mb-3 mb-lg-0 border-0 w-100 fw-bold text-gray-700'
              value={name}
              onChange={(e) => setName(e.target.value || '')}
            />
          </div>
        </fieldset>
      </main>

      <footer className='modal-footer p-5 border-top'>
        <button type='button' onClick={() => submitComplete()} className='btn btn-light me-2'>
          Close
        </button>
        <button
          type='button'
          onClick={() => submit()}
          className='btn btn-primary'
          disabled={loading}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </footer>
    </div>
  );
}
