import {confirmDialog} from 'primereact/confirmdialog';
import {useState, useEffect} from 'react';
import {toast} from 'react-toastify';
import {parseError} from '../../../../../../shared/utils';
import {deleteDialogProps} from '../../../../shared/types';
import {rateModel} from '../../../api/models-api';
import {Model, ModelStatusesNamesEnum} from '../../../types';
import clsx from 'clsx';
const RERUN_WARNING_MESSAGE = `Please consider that Rerun will override the old result, continue?`;

interface Props {
  model: Model;
  complete?: (rate: number) => void;
  rate: number;
}
export function ModelValidate({model, complete, rate}: Props) {
  const [showValidateButton, setShowValidateButton] = useState(true);
  const [validating, setValidating] = useState(false);
  const [hoverText, setHoverText] = useState('');
  const [isRejectButton, setIsRejectButton] = useState(false);

  const reject = () => {
    confirmDialog({
      ...deleteDialogProps,
      message: 'The model will be deleted, confirm?',
      accept: () => {
        validate();
      },
      reject: () => {},
    });
  };

  const validate = async () => {
    try {
      if (validating) return;
      setValidating(true);
      await rateModel(model?.pub_id, rate);

      sessionStorage.clear();

      toast.success('Validate request sent successfully, will notify your organization admin');
      complete && complete(rate);
    } catch (err: any) {
      toast.error(parseError(err));
    } finally {
      setValidating(false);
    }
  };

  useEffect(() => {
    setIsRejectButton(rate === 0);
    if (!model || !model.rating) return;
    if (model.status_name !== ModelStatusesNamesEnum.COMPLETED) {
      setHoverText('You can only validate Done Models');
      setShowValidateButton(false);
    }
  }, []);
  return (
    <>
      <div title={hoverText}>
        <button
          disabled={!showValidateButton}
          onClick={(e) => (isRejectButton ? reject() : validate())}
          className={clsx(
            'btn ',
            {
              'btn-success ': !isRejectButton,
            },
            {
              'btn-danger me': isRejectButton,
            },
            {
              disabled: !isRejectButton && model.rating === 5,
            }
          )}
        >
          {!isRejectButton && <span>Validate</span>}
          {isRejectButton && <span>Reject</span>}
        </button>
      </div>
    </>
  );
}
