import {OrbitControls} from '@react-three/drei';
import React from 'react';
import useViewerStore from '../viewer-store';

export const Controls = ({}: any) => {
  const {config} = useViewerStore();

  return (
    <OrbitControls
      target={config.controls?.position || [0, 0, 0]}
      minDistance={config.controls?.minDistance || 3}
      maxDistance={config.controls?.maxDistance || 10}
      minPolarAngle={config.controls?.minPolarAngle || 0}
      maxPolarAngle={config.controls?.maxPolarAngle || Math.PI}
      enableRotate={config.controls?.enableRotate || true}
      enableZoom={config.controls?.enableZoom || true}
      enablePan={config.controls?.enablePan || false}
    />
  );
};
