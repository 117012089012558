/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import React, {useEffect, useState} from 'react';
import {AnyObject, ModelEditorProps, modelEditTabs} from '../../model-editor.types';
import useEditorStore from '../../model-editor.store';
import './EditorFields.scss';
import {FieldSlider} from './FieldSlider';
import {toAbsoluteUrl} from '../../../../../_metronic/helpers';
import {FieldColor} from './FieldColor';
import {FieldTexture} from './FieldTexture';
import {ModelEditorSubmitForm} from './ModelEditorSubmitForm';
import {getModelFile, parseError} from '../../../../../shared/utils';
import {useAuth} from '../../../auth';
import {getSingleModel} from '../../../models-management/api/models-api';
import {toast} from 'react-toastify';

export const frameColors = ['#FFFFFF', '#D5BC93', '#AC252B', '#185848', '#025D98', '#D2A693'];

const EditorFields = ({onSave, onReset}: any) => {
  const {reset, fields, selectedTab, setSelectedTab, model, setModel, updateFields} =
    useEditorStore();
  const [loading, setLoading] = useState(false);
  const [showFinalForm, setShowFinalForm] = useState(false);
  const auth = useAuth();
  const next = () => setSelectedTab(selectedTab === modelEditTabs.length - 1 ? 0 : selectedTab + 1);
  const prev = () => setSelectedTab(selectedTab === 0 ? modelEditTabs.length - 1 : selectedTab - 1);

  const fieldsX: AnyObject = fields;

  if (showFinalForm)
    return <ModelEditorSubmitForm onBack={() => setShowFinalForm(false)} onSaved={onSave} />;
  return (
    <div className='editor-fields d-flex flex-column h-100 card shadow-sm py-2'>
      <>
        <header>
          <div className='d-flex w-100 align-items-center justify-content-between border-bottom-2 border-secondary '>
            <button className='btn btn-icon' onClick={prev}>
              <i className='fas fa-arrow-left fs-3  '></i>
            </button>
            <div className='flex-grow-1'>
              <ul
                className='nav nav-tabs nav-line-tabs nav-line-tabs-2x position-relative border-0 fs-6 align-items-center justify-content-evenly '
                style={{bottom: '-4px'}}
              >
                {modelEditTabs.map((tab) => (
                  <li className='nav-item cursor-pointer  my-0'>
                    <a
                      className={clsx('nav-link', {
                        'active text-primary fw-bold py-2': selectedTab === tab.index,
                      })}
                      onClick={() => setSelectedTab(tab.index)}
                    >
                      {tab.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <button className='btn btn-icon' onClick={next}>
              <i className='fas fa-arrow-right fs-3 '></i>
            </button>
          </div>
        </header>

        <div className='fields flex-grow-1 p-6 scroll' style={{maxHeight: '58vh'}}>
          <div className='tab-content'>
            {modelEditTabs.map((tab) => (
              <div
                id='frame-tab'
                className={clsx('tab-pane fade', {'show active': selectedTab === tab.index})}
              >
                {tab.components.map((component) => (
                  <fieldset className={clsx('mb-5')}>
                    {component.type !== 'range' && (
                      <div className='fs-3 mb-2 fw-bold text-capitalize w-200px'>
                        {component.title}
                      </div>
                    )}

                    {component.type === 'texture' && (
                      <FieldTexture
                        field={fieldsX[tab.tabName][component.fieldName]}
                        onUpdate={(value) =>
                          updateFields({[tab.tabName]: {[component.fieldName]: value}})
                        }
                      />
                    )}

                    {component.type === 'color' && (
                      <FieldColor
                        field={fieldsX[tab.tabName][component.fieldName]}
                        onUpdate={(value) =>
                          updateFields({[tab.tabName]: {[component.fieldName]: value}})
                        }
                      />
                    )}

                    {component.type === 'range' && (
                      <div className='d-flex  align-items-center'>
                        <div className='fs-4 mb-2 fw-semibold text-capitalize w-150px'>
                          {component.title}
                        </div>

                        <div className='flex-grow-1 '>
                          <FieldSlider
                            field={fieldsX[tab.tabName][component.fieldName]}
                            onUpdate={(value) =>
                              updateFields({[tab.tabName]: {[component.fieldName]: value}})
                            }
                          />
                        </div>
                      </div>
                    )}
                  </fieldset>
                ))}
              </div>
            ))}
          </div>
        </div>
      </>

      <footer className='text-end'>
        <div className='btn btn-light-primary mx-2 px-10' onClick={() => onReset && onReset()}>
          <i className='bi bi-arrow-clockwise fs-4 me-2'></i>
          Reset
        </div>
        <div
          className='btn btn-primary mx-2 px-10'
          onClick={() => {
            setShowFinalForm(true);
          }}
        >
          <i className='bi bi-save fs-4 me-2'></i>
          Save
        </div>
      </footer>
    </div>
  );
};

export default EditorFields;
