import clsx from 'clsx';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../../../../auth';

export const RoleTemplate = (user: any) => {
  const navigate = useNavigate();
  const {currentUser} = useAuth();

  const clickableRole =
    currentUser?.is_super_admin ||
    (currentUser?.is_org_admin && user?.org_id === currentUser.org_id);

  const colorMapping: {[key: string]: string} = {
    '': 'badge-warning',
    admin: 'badge-light-success',
    'FBX admin reseller': 'badge-light-primary',
    'FBX_TEST admin reseller': 'badge-light-primary',
    user: 'badge-light',
    'FBX_TEST user': 'badge-light',
    'FBX user': 'badge-light',
  };

  return (
    <div
      className={clsx('badge ', colorMapping[user.role_name || ''], {
        'cursor-pointer': clickableRole,
      })}
      onClick={() => (clickableRole ? navigate(`/roles/${user.role_id}`) : {})}
    >
      {user.role_name}
    </div>
  );
};
