/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react';
import noUiSlider from 'nouislider';
import {useLayout} from '../../core';
import {KTSVG, toAbsoluteUrl} from '../../../helpers';
import {DefaultTitle} from './page-title/DefaultTitle';
import {HeaderNotificationsMenu, HeaderUserMenu, ThemeModeSwitcher} from '../../../partials';
import clsx from 'clsx';
import {useAuth} from '../../../../app/modules/auth';
import {generateUserAvatarImage} from '../../../assets/ts/_utils/UserAvatarGenerator';
import {Toolbar2} from '../toolbar/Toolbar2';
import TopBarCredit from '../../../../app/modules/models-management/components/TopBarCredit';
import {isResellerAccount} from '../../../../shared/utils';
import HasPermission from '../../../../app/modules/auth/HasPermission';

const itemClass = 'ms-1 ms-lg-3';
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px';
const userAvatarClass = 'symbol-35px symbol-md-40px';
const btnIconClass = 'svg-icon-1';

const HeaderToolbar = () => {
  const {classes, config} = useLayout();
  const {currentUser} = useAuth();

  useEffect(() => {
    const rangeSlider = document.querySelector('#kt_toolbar_slider');
    const rangeSliderValueElement = document.querySelector('#kt_toolbar_slider_value');

    if (!rangeSlider || !rangeSliderValueElement) {
      return;
    }

    // @ts-ignore
    noUiSlider.create(rangeSlider, {
      start: [5],
      connect: [true, false],
      step: 1,
      format: {
        to: function (value) {
          const val = +value;
          return Math.round(val).toString();
        },
        from: function (value) {
          return value;
        },
      },
      range: {
        min: [1],
        max: [10],
      },
    });

    // @ts-ignore
    rangeSlider.noUiSlider.on('update', function (values, handle) {
      rangeSliderValueElement.innerHTML = values[handle];
    });

    const handle = rangeSlider.querySelector('.noUi-handle');
    if (handle) {
      handle.setAttribute('tabindex', '0');
    }

    // @ts-ignore
    handle.addEventListener('click', function () {
      // @ts-ignore
      this.focus();
    });

    // @ts-ignore
    handle.addEventListener('keydown', function (event) {
      // @ts-ignore
      const value = Number(rangeSlider.noUiSlider.get());
      // @ts-ignore
      switch (event.which) {
        case 37:
          // @ts-ignore
          rangeSlider.noUiSlider.set(value - 1);
          break;
        case 39:
          // @ts-ignore
          rangeSlider.noUiSlider.set(value + 1);
          break;
      }
    });
    return () => {
      // @ts-ignore
      rangeSlider.noUiSlider.destroy();
    };
  }, []);

  return (
    <div className='toolbar d-flex align-items-stretch'>
      {/* begin::Toolbar container */}
      <div
        className={`${classes.headerContainer.join(
          ' '
        )} py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between`}
      >
        <DefaultTitle />

        <div className='d-none d-md-flex flex-center'>
          <HasPermission code='misc.credits'>
            <TopBarCredit />
          </HasPermission>
        </div>

        <div className='app-navbar flex-shrink-0 d-none d-md-flex flex-row align-items-center'>
          {/* notifications */}
          {/* <div className={clsx('app-navbar-item', itemClass)}>
            <div
              data-kt-menu-trigger="{default: 'click'}"
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
              className={btnClass}
            >
              <KTSVG path='/media/icons/duotune/general/gen022.svg' className={btnIconClass} />
            </div>
            <HeaderNotificationsMenu />
          </div> */}

          <div className={clsx('app-navbar-item', itemClass)}>
            <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
          </div>

          <div className={clsx('app-navbar-item', itemClass)}>
            <div
              className={clsx('cursor-pointer symbol', userAvatarClass)}
              data-kt-menu-trigger="{default: 'click'}"
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
            >
              <img src={generateUserAvatarImage(currentUser)} alt='' />
            </div>
            <HeaderUserMenu />
          </div>
        </div>

        {/* end::Toolbar container */}
      </div>
    </div>
  );
};

export {HeaderToolbar};
