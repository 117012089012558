import {useGLTF} from '@react-three/drei';
import {PrimitiveProps, useFrame} from '@react-three/fiber';
import React, {useEffect, useRef} from 'react';
import * as THREE from 'three';
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers';
import useViewerStore from '../viewer-store';
import {usePoint3D} from '../../../../shared/hooks/usePoint3D';
import {ViewerPortal} from './ViewerPortal';
import {XYZEditor} from './XYZEditor';

const enableAnimation = false;
const rotationSpeed = 0.05;
const targetRotation = Math.PI * 2;
const SHOW_CONTROLS = false;

export const Model = ({path}: any) => {
  const gltf = useGLTF(toAbsoluteUrl(path));
  const {config, model, showOccluder} = useViewerStore();
  const group = useRef<any>();

  const [rotation, updateRotation] = usePoint3D(config.rotation);
  const [position, updatePosition] = usePoint3D(config.obj_position);
  const [scale, updateScale] = usePoint3D(config.scale);

  const animate = () => {
    const rotationAxis = model?.category_name === 'Footwear' ? 'z' : 'y';

    if (enableAnimation && !showOccluder) limitRotate();

    function limitRotate() {
      if (group.current && group.current.rotation[rotationAxis] < targetRotation) {
        group.current.rotation[rotationAxis] += rotationSpeed;
        if (group.current.rotation[rotationAxis] >= targetRotation) {
          group.current.rotation[rotationAxis] = targetRotation;
        }
      }
    }
    function alwaysRotate() {
      if (group.current) {
        group.current.rotation[rotationAxis] += 0.005;
      }
    }
  };
  useFrame(() => {
    animate();
  });

  useEffect(() => {
    if (config.centerObject && group.current) {
      const box = new THREE.Box3().setFromObject(group.current);
      const center = new THREE.Vector3();
      box.getCenter(center);

      group.current.position.y -= center.y;
      group.current.position.y += config.obj_position.y;
    }
  }, []);

  useEffect(() => {
    console.log('gltf', gltf);
  }, [gltf]);

  return (
    <>
      {SHOW_CONTROLS && (
        <ViewerPortal>
          <div className='left-0 right-0 w-100 bg-black bg-opacity-25 p-3 rounded text-black'>
            <XYZEditor name='Position' objRotation={position} setObjRotation={updatePosition} />
            <XYZEditor name='Rotation' objRotation={rotation} setObjRotation={updateRotation} />
            <XYZEditor name='Scale' objRotation={scale} setObjRotation={updateScale} />
          </div>
        </ViewerPortal>
      )}
      <primitive
        ref={group}
        object={gltf.scene}
        position={[position?.x || 0, position?.y || 0, position?.z || 0]}
        rotation={[rotation?.x || 0, rotation?.y || 0, rotation?.z || 0]}
        scale={[scale?.x || 1, scale?.y || 1, scale?.z || 1]}
      />
    </>
  );
};
