import React from 'react';
import {PageTitle} from '../../../../../_metronic/layout/core';
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers';
import useCheckoutStore from '../checkout.store';
import {Link} from 'react-router-dom';

const CheckoutResponse = () => {
  const {status} = useCheckoutStore();
  //   const status = 'success';
  return (
    <div className='container'>
      <PageTitle
        breadcrumbs={[
          {title: 'Billing', path: 'billing', isActive: false},
          {title: 'Package Purchase', path: 'billing/purchase-package', isActive: true},
        ]}
      >
        Checkout
      </PageTitle>

      <h1 className='page-heading py-7 d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0'>
        Checkout Completed
      </h1>

      <div className='card p-10'>
        <div className='row align-items-center'>
          <div className='col-md-6'>
            <div className='mb-7'>
              {status === 'success' && (
                <KTSVG
                  path='/media/icons/duotune/arrows/arr016.svg'
                  className='svg-icon-success svg-icon-3qx me-5'
                />
              )}
              {status !== 'success' && (
                <KTSVG
                  path='/media/icons/duotune/general/gen044.svg'
                  className='svg-icon-danger svg-icon-3qx me-5'
                />
              )}
            </div>
            <div className='fs-1 d-flex align-items-center fw-semibold'>
              {status === 'success' && <div>Payment successful! Thank you for your purchase</div>}
              {status !== 'success' && <div className='text-danger'>Payment failed!</div>}
            </div>

            {status === 'success' && (
              <div className='fs-5 mt-3 mb-15'>
                Your order is now being processed and you will receive a confirmation email with the
                details shortly.
              </div>
            )}

            {status !== 'success' && (
              <div className='fs-5 mt-3 mb-15'>
                {status || 'Somthing wrong happened! please try to checkout the product again.'}
              </div>
            )}

            <div>
              {status === 'success' && (
                <Link to='/dashboard' className='btn btn-primary '>
                  Return to app{' '}
                </Link>
              )}
              {status !== 'success' && (
                <Link to='/billing/purchase-package' className='btn btn-primary '>
                  Return to Packages{' '}
                </Link>
              )}
            </div>
          </div>

          <div className='col-md-6'>
            {status === 'success' && (
              <img
                src={toAbsoluteUrl('/media/illustrations/sketchy-1/6.png')}
                alt=''
                className=' h-400px mb-15'
              />
            )}
            {status !== 'success' && (
              <img
                src={toAbsoluteUrl('/media/illustrations/sketchy-1/19.png')}
                alt=''
                className=' h-400px mb-15'
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutResponse;
