import axios from 'axios';
import {AppLocator} from '../../../../app-locator';
import {UserModel} from '../../auth';

const API_URL =
  AppLocator.forceUsingRealApi || process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_DEV_API_URL;

const DELETE_ACCOUNT = `${API_URL}/delete_account`;
const GET_USER = `${API_URL}/user_profile`;

export const deleteUser = async (userId: number | undefined = undefined) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  if (userId) {
    const data = {
      user_id: userId,
    };
    return axios
      .delete(`${DELETE_ACCOUNT}`, {
        data: data,
        headers: headers,
      })
      .then((d: any) => d.data);
  }
  return axios.delete(`${DELETE_ACCOUNT}`, {data: {}, headers: headers}).then((d: any) => d.data);
};

export const getUser = (id: number) => {
  return axios.get<any>(`${GET_USER}?id=${id}`).then((d) => d.data?.user);
};
