/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react';

import {
  createResponseContext,
  initialQueryResponse,
  WithChildren,
  stringifyRequestQuery,
} from '../../../../../_metronic/helpers';
import {parseError, randomIntFromInterval, sleep} from '../../../../../shared/utils';
import {useAuth} from '../../../auth';
import {Model} from '../../../models-management/types';
import {useQuery} from 'react-query';
import useMediaLibraryStore from './media-library.store';
import {Media} from '../../media.types';
import {getMedias} from '../../media.api';

const MediaQueryContext = createResponseContext<Media>(initialQueryResponse);

const MediaLibraryQueryProvider: FC<WithChildren> = ({children}) => {
  const {filters: requestState} = useMediaLibraryStore();

  const [query, setQuery] = useState<string>(stringifyRequestQuery(requestState));
  const updatedQuery = useMemo(() => stringifyRequestQuery(requestState), [requestState]);
  const [error, setError] = useState('');

  useEffect(() => {
    if (query !== updatedQuery) {
      // setQuery(updatedQuery + `-${randomIntFromInterval(999, 9999)}`);
      setQuery(updatedQuery);
      console.log(`>> Update Query`);
    }
  }, [updatedQuery]);

  const {
    isFetching,
    refetch,
    data: response,
    isLoading,
  } = useQuery<any, string>(
    `GenerativeVideosPage-${query}`,
    async () => {
      try {
        const res = await getMedias(query);
        const response = res.data;
        console.log('videos fetched', query, requestState, response);

        await sleep(2000);
        setError('');

        // client filtering
        const filteredVideos = response.videos;

        const result = {
          data: filteredVideos || [],
          count: response.count,
          payload: {page: 1},
          error: 'yyyy',
        };

        return result;
      } catch (error: any) {
        console.error(error);
        setError(parseError(error));
        return {
          data: [],
          count: 0,
          payload: {},
        };
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <MediaQueryContext.Provider
      value={{isLoading: isFetching, refetch, response, query, error: error}}
    >
      {children}
    </MediaQueryContext.Provider>
  );
};

const useMediaLibraryQuery = () => useContext(MediaQueryContext);

const useQueryResponseData = () => {
  const {response} = useMediaLibraryQuery();
  if (!response) {
    return [];
  }

  return response?.data || [];
};

export {MediaLibraryQueryProvider, useMediaLibraryQuery, useQueryResponseData};
