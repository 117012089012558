import {Link} from 'react-router-dom';

export const NameTemplate = (user: any) => {
  return (
    <>
      <Link className='text-dark mb-0 ' to={`/account/${user.id}`}>
        {user.first_name} {user.last_name}
      </Link>

      <div className='text-muted lh-1'>{user.email}</div>
    </>
  );
};
