/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx';
import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useBranding} from '../../../../app/modules/app/core/BrandingProvider';
import {getBase64codeForImage} from '../../../../shared/utils';
import {KTSVG, toAbsoluteUrl} from '../../../helpers';
import {useLayout} from '../../core';
import {Toolbar2} from '../toolbar/Toolbar2';
import {HeaderToolbar} from './HeaderToolbar';
import {useThemeMode} from '../../../partials';
// import 'src/_metronic/assets/images/logo/colorful-lavendar.svg';

export function HeaderWrapper() {
  const {config, classes, attributes} = useLayout();
  const [logo, setLogo] = useState('/media/logos/colorful-lavendar.svg');
  const {mode} = useThemeMode();

  const {organization} = useBranding();
  const {aside} = config;

  useEffect(() => {
    if (organization?.light_logo) setLogo(getBase64codeForImage(organization?.light_logo));
  }, [organization]);

  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'd-flex align-items-stretch')}
      {...attributes.headerMenu}
    >
      <div className='d-flex justify-content-between w-100'>
        {/* begin::Brand */}
        <div className='header-brand'>
          {/* begin::Logo */}
          <Link to='/'>
            <img
              alt='Logo'
              src={logo}
              // src={toAbsoluteUrl('/media/logos/colorfull-with-word.svg')}
              className='h-30px h-lg-30px'
            />
          </Link>
          {/* end::Logo */}

          {aside.minimize && (
            <div
              id='kt_aside_toggle'
              className='btn btn-icon w-auto px-0 btn-active-color-primary aside-minimize'
              data-kt-toggle='true'
              data-kt-toggle-state='active'
              data-kt-toggle-target='body'
              data-kt-toggle-name='aside-minimize'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr092.svg'
                className='svg-icon-1 me-n1 minimize-default'
              />
              <KTSVG
                path='/media/icons/duotune/arrows/arr076.svg'
                className='svg-icon-1 minimize-active'
              />
            </div>
          )}

          {/* begin::Aside toggle */}
          <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
            <div
              className='btn btn-icon btn-active-color-primary w-30px h-30px'
              id='kt_aside_mobile_toggle'
            >
              <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-1' />
            </div>
          </div>
          {/* end::Aside toggle */}
        </div>
        {/* end::Brand */}

        <HeaderToolbar />
      </div>
    </div>
  );
}
