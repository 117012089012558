/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import {getAllOrganizations} from '../../../api/organizations-api';
import {Organization} from '../../../../app/types';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Dialog} from 'primereact/dialog';
import {NewOrg} from './NewOrg';

export interface Props {
  close: () => void;
}

const OrganizationsList = () => {
  const [orgs, setOrgs] = useState<Organization[]>([]);
  const navigate = useNavigate();
  const [showNewWindow, setShowNewWindow] = useState(false);

  const getOrgs = async () => {
    try {
      const orgs = await getAllOrganizations();
      setOrgs(orgs);
    } catch (error: any) {
      toast.error('Failed to get orgs, check browser console');
      console.error(error);
    }
  };

  useEffect(() => {
    getOrgs();
  }, []);

  return (
    <div>
      <div className='d-flex flex-column flex-column-fluid'>
        <div className='app-toolbar  py-3 py-lg-6 '>
          <div className='app-container   d-flex flex-stack '>
            <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3 '>
              <h1 className='page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0'>
                Organizations List
              </h1>
            </div>

            <div className='d-flex align-items-center gap-2 gap-lg-3'>
              <a onClick={() => setShowNewWindow(true)} className='btn btn-sm fw-bold btn-primary'>
                Create{' '}
              </a>
            </div>
          </div>
        </div>

        <div className='app-content  flex-column-fluid '>
          <div className='app-container   '>
            <div className='card card-flush '>
              <header className='card-header mt-6'>
                <div className='card-title'>
                  <div className='d-none align-items-center position-relative my-1 me-5'>
                    <i className='ki-duotone ki-magnifier fs-3 position-absolute ms-5'>
                      <span className='path1' />
                      <span className='path2' />
                    </i>{' '}
                    <input
                      type='text'
                      className='form-control form-control-solid w-250px ps-13'
                      placeholder='Search by name'
                    />
                  </div>
                </div>

                <div className='card-toolbar'>
                  {/* <button
                    type='button'
                    className='btn btn-light-primary'
                  >
                    <i className='ki-duotone ki-plus-square fs-3'>
                      <span className='path1' />
                      <span className='path2' />
                      <span className='path3' />
                    </i>{' '}
                    Add Permission
                  </button> */}
                </div>
              </header>

              <section className='card-body pt-0'>
                <div className='dataTables_wrapper dt-bootstrap4 no-footer'>
                  <div className='table-responsive'>
                    <table className='table align-middle table-row-dashed table-hover fs-6 gy-5 mb-0 dataTable no-footer'>
                      <thead>
                        <tr className='text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0'>
                          <th
                            className='min-w-125px sorting'
                            tabIndex={0}
                            rowSpan={1}
                            colSpan={1}
                            style={{width: '244.516px'}}
                          >
                            Name
                          </th>
                          {/* <th
                            className='min-w-125px sorting'
                            tabIndex={0}
                            rowSpan={1}
                            colSpan={1}
                            style={{width: '233.969px'}}
                          >
                            Created Date
                          </th> */}
                          <th
                            className='min-w-125px sorting'
                            tabIndex={0}
                            rowSpan={1}
                            colSpan={1}
                            style={{width: '233.969px'}}
                          >
                            Users
                          </th>
                          <th
                            className='text-end min-w-100px sorting_disabled'
                            rowSpan={1}
                            colSpan={1}
                            style={{width: '145.109px'}}
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {orgs.map((org) => (
                          <tr
                            className='cursor-pointer px-2'
                            onClick={() => navigate(`/admin/organization/${org.id}`)}
                          >
                            <td className='ps-2 fw-semibold text-gray-800'>{org.name}</td>

                            {/* <td>- </td> */}
                            <td className='text-gray-700'>{org.users_count} user </td>
                            <td className='text-gray-800 text-end'>
                              {/* <button className='btn btn-icon btn-active-light-primary w-30px h-30px me-3'>
                                <i className='ki-duotone ki-setting-3 fs-3'>
                                  <span className='path1' />
                                  <span className='path2' />
                                  <span className='path3' />
                                  <span className='path4' />
                                  <span className='path5' />
                                </i>{' '}
                              </button> */}
                              <button className='btn btn-light-primary btn-sm'>Details</button>
                              {/* <button
                                className='btn btn-icon btn-active-light-primary w-30px h-30px'
                              >
                                <i className='ki-duotone ki-trash fs-3'>
                                  <span className='path1' />
                                  <span className='path2' />
                                  <span className='path3' />
                                  <span className='path4' />
                                  <span className='path5' />
                                </i>{' '}
                              </button> */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        modal
        dismissableMask
        closable={false}
        visible={showNewWindow}
        onHide={() => setShowNewWindow(false)}
      >
        <NewOrg close={() => setShowNewWindow(false)}></NewOrg>
      </Dialog>
    </div>
  );
};

export default OrganizationsList;
