import {createContext, FC, useContext, useState} from 'react';
import {WithChildren} from '../../../../_metronic/helpers';
import {Organization} from '../types';

interface BrandingContextProps {
  organization: Organization | undefined;
  setOrganization: (org: Organization | undefined) => void;
  outOfCredits?: boolean;
  setOutOfCredits: (v: boolean) => void;
}

const initAuthContextPropsState: BrandingContextProps = {
  organization: undefined,
  setOrganization: () => {},
  outOfCredits: false,
  setOutOfCredits: () => {},
};

const BrandingContext = createContext<BrandingContextProps>(initAuthContextPropsState);

const useBranding = () => {
  return useContext(BrandingContext);
};

const BrandingProvider: FC<WithChildren> = ({children}) => {
  const [organization, setOrganization] = useState<Organization>();
  const [outOfCredits, setOutOfCredits] = useState(false);
  return (
    <BrandingContext.Provider
      value={{
        organization,
        setOrganization,
        outOfCredits,
        setOutOfCredits,
      }}
    >
      {children}
    </BrandingContext.Provider>
  );
};

export {BrandingProvider, useBranding};
