import {create} from 'zustand';
import {getMaxId} from '../../../shared/utils';

export type CanvasText = {
  body: string;
  image?: CanvasImageSource;
  id?: number;
  x?: number;
  y?: number;
  scaleX?: number;
  scaleY?: number;
  rotation?: number;
};

interface Props {
  reset: () => void;

  texts: CanvasText[];
  setTexts: (texts: CanvasText[]) => void;
  addText: (text: CanvasText) => void;
  updateText: (text: CanvasText) => void;
  deleteText: (id?: number) => void;
}

const useCanvasEditorStore = create<Props>((set, get) => ({
  texts: [],

  setTexts: (texts) => set({texts: texts}),

  addText: (text) => {
    const data: CanvasText[] = get().texts;
    const maxId = getMaxId(data);
    set({texts: [...data, {...text, id: maxId + 1}]});
  },

  updateText: (text) => {
    const data: CanvasText[] = get().texts;

    const updated = data.map((item) => (item.id === text.id ? {...text} : item));

    set({texts: updated});
  },

  deleteText: (id) => {
    const data: CanvasText[] = get().texts;
    set({texts: [...data.filter((d) => d.id !== id)]});
  },

  reset: () => {
    set({
      texts: [],
    });
  },
}));

export default useCanvasEditorStore;
