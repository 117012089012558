import React, {useEffect, useState} from 'react';
import {useMatch, useNavigate, useSearchParams} from 'react-router-dom';
import {PageTitle} from '../../../../../../_metronic/layout/core';
import clsx from 'clsx';
import {Role} from '../../../../app/types';
import {usePermissionSelector} from '../hooks/permissions-selector';
import {toast} from 'react-toastify';
import {PermissionsComponent} from './PermissionsComponent';
import {dummyPerms} from '../dummyPerms';
import {GetRolesByOrg, getRole, getRoles, updateRole} from '../roles-api';
import {LoadingPlaceholder} from '../../../../../../shared/components/LoadingPlaceholder';
import {useAuth} from '../../../../auth';

const ViewRole = () => {
  const [id, setId] = useState<string>();
  const match = useMatch('/roles/:id/*');
  const [role, setRole] = useState<Role>();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState<Role[]>([]);
  const {currentUser} = useAuth();

  const [search] = useSearchParams();
  const fromOrg = search.get('fromOrg') || false;

  const save = async () => {
    try {
      if (!role) return;

      setSubmitting(true);
      await updateRole(role);
      setSubmitting(false);

      toast.success('Role successfully updated');

      if (fromOrg) navigate(-1);
      else navigate('/roles');
    } catch (error: any) {
      setSubmitting(false);

      toast.error('Failed to save role, check browser console');
      console.error(error);
    }
  };

  useEffect(() => {
    if (match) {
      const {id} = match.params;
      setId(id);
    }
  }, [match]);

  useEffect(() => {
    const get = async () => {
      try {
        if (!id) return;

        setLoading(true);

        const _role = await getRole(+id);
        setRole(_role);

        setLoading(false);
      } catch (error: any) {
        toast.error('Failed to get orgs, check browser console');
        console.error(error);
      }
    };

    get();
  }, [id]);

  if (loading) return <LoadingPlaceholder />;

  return (
    <div>
      <PageTitle breadcrumbs={[{title: 'Roles', path: '/Roles', isActive: true}]}>
        View Role
      </PageTitle>

      <div className='d-flex flex-column flex-column-fluid'>
        <div className='app-toolbar  py-3 py-lg-6 '>
          <div className='app-container  container-fluid d-flex flex-stack '>
            <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3 '>
              <h1 className='page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0'>
                Permissions
              </h1>
            </div>

            <div className='d-flex align-items-center gap-2 gap-lg-3'></div>
          </div>
        </div>

        <div className='app-container  container-fluid '>
          <div className='card pt-4 mb-6 mb-xl-9'>
            <div className='card-header border-0'>
              <div className='card-title'>
                <h2>Details</h2>
              </div>
              <div className='card-toolbar'>
                <button
                  onClick={() => save()}
                  type='button'
                  className='btn btn-sm btn-light-primary'
                >
                  {!submitting && (
                    <>
                      {' '}
                      <i className='ki-duotone ki-check-circle fs-3'>
                        <span className='path1' />
                        <span className='path2' />
                      </i>
                      Save Changes
                    </>
                  )}
                  {submitting && (
                    <span>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
            <div className='card-body py-0'>
              <form className='form' noValidate>
                <fieldset className='row mb-7'>
                  <label className='py-2 col-lg-3 required fw-bold fs-6 mb-2'>Role Name</label>

                  <div className='col-lg-7 col-xl-5'>
                    <input
                      placeholder='Type name'
                      type='text'
                      name='name'
                      value={role?.name}
                      onChange={(e) => {
                        const updated = {...role, name: e.target.value} as Role;
                        setRole(updated);
                      }}
                      className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                      autoComplete='off'
                    />
                  </div>
                </fieldset>

                <fieldset className='row mb-7'>
                  <label className='py-2 col-lg-3 required fw-bold fs-6 mb-2'>
                    Role Description
                  </label>

                  <div className='col-lg-7 col-xl-5'>
                    <input
                      placeholder='Type description details'
                      type='text'
                      value={role?.desc}
                      onChange={(e) => {
                        const updated = {...role, desc: e.target.value} as Role;
                        setRole(updated);
                      }}
                      name='description'
                      className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                      autoComplete='off'
                    />
                  </div>
                </fieldset>

                <fieldset className='row mb-7'>
                  <label className='py-2 col-lg-3 required fw-bold fs-6 mb-2'>Created By</label>

                  <div className='col-lg-7 col-xl-5'>{role?.user_name}</div>
                </fieldset>
              </form>

              {role?.permissions !== undefined && (
                <PermissionsComponent
                  type='role'
                  orgId={role.org_id}
                  permissions={role.permissions}
                  onChange={(permissions) => {
                    setRole({...role, permissions});
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewRole;
