import React, {useEffect, useRef, useState} from 'react';
import {Rect, Circle, Image} from 'react-konva';
import {Editor, EditorTextChangeEvent} from 'primereact/editor';
import html2canvas from 'html2canvas';

import {CanvasEditor} from '../CanvasEditor';
import Quill from 'quill';
import {useDebounce} from 'primereact/hooks';
import {CanvasText} from '../CanvasEditor.store';

interface Props {
  value: CanvasText;
  onChange?: (text: CanvasText) => void;
  onSetImage?: (img: CanvasImageSource) => void;
}

const fontSizes = ['18px', '24px', '48px', '72px'];
export function CanvasTextEditor({value, onChange, onSetImage}: Props) {
  const [canvasText, setCanvasText] = useState<CanvasText | undefined>(value);
  const [text, debouncedValue, setText] = useDebounce(value?.body || '', 1);

  useEffect(() => {
    // init quill
    const Font = Quill.import('formats/font');
    Font.whitelist = ['arial', 'Roboto', 'Inter', 'verdana']; // Add your custom fonts here
    Quill.register(Font, true);

    const FontSizeStyle = Quill.import('attributors/style/size');
    FontSizeStyle.whitelist = fontSizes;
    Quill.register(FontSizeStyle, true);
  }, []);

  useEffect(() => {
    const elem = document.querySelector('.ql-editor') as HTMLElement;

    if (!elem) return;

    // html2canvas(elem, {
    //   backgroundColor: 'transparent',
    // }).then((canvas) => {
    //   // onSetImage(canvas);
    //   setCanvasText((v) => {
    //     return {...v, body: debouncedValue, image: canvas};
    //   });
    // });
    setCanvasText((v) => {
      return {...v, body: debouncedValue};
    });
  }, [debouncedValue]);

  useEffect(() => {
    onChange && canvasText && onChange(canvasText);
  }, [canvasText]);

  useEffect(() => {
    return () => {
      setCanvasText(undefined);
    };
  }, []);

  const renderHeader = () => {
    return (
      <>
        <span className='ql-formats' title='Font type'>
          <select className='ql-font'>
            {/* <option >Sans Serif</option> */}
            <option value='roboto' selected>
              Roboto
            </option>
            <option value='arial'>Arial</option>
            <option value='verdana'>Verdana</option>
            <option value='inter'>Inter</option>
          </select>
          {/* <select className='ql-size'></select> */}

          <select className='ql-size' title='Font size'>
            <option value='18px'>Small</option>
            <option value='24px'>Normal</option>
            <option value='48px'>Large</option>
            <option value='72px'>Huge</option>

            {/* {fontSizes.map((f) => (
              <option value={f}>{f}</option>
            ))} */}
          </select>
        </span>

        <span className='ql-formats'>
          <button className='ql-bold' aria-label='Bold' title='Bold'></button>
          <button className='ql-italic' aria-label='Italic' title='Italic'></button>
          <button className='ql-underline' aria-label='Underline' title='Underline'></button>
          <button className='ql-strike' aria-label='Strikethrough' title='Strikethrough'></button>
        </span>

        <span className='ql-formats'>
          <select className='ql-color' title='Color'></select>
          {/* <select className='ql-background' title='Background'></select> */}
          <select className='ql-background' title='Background'>
            <option selected></option>
            <option value='white'></option>
            <option value='red'></option>
            <option value='yellow'></option>
            <option value='green'></option>
            <option value='blue'></option>
            <option value='black'></option>
            <option value='orange'></option>
            <option value='purple'></option>
            <option value='pink'></option>
            <option value='brown'></option>
            <option value='gray'></option>
            <option value='#f0f0f0'></option>
            <option value='#00ff00'></option>
          </select>
        </span>
        <span className='ql-formats'>
          <button className='ql-image' title='Add Photo'></button>
        </span>

        <span className='ql-formats'>
          <button className='ql-clean' title='Remove styles'></button>
        </span>
      </>
    );
  };

  return (
    <Editor
      id='editor'
      value={text}
      onTextChange={(e: EditorTextChangeEvent) => setText(e.htmlValue || '')}
      style={{
        height: '150px',
      }}
      headerTemplate={renderHeader()}
      modules={
        {
          // toolbar: customToolbarOptions,
        }
      }
      // formats={[
      //   'font',
      //   'header',
      //   'bold',
      //   'italic',
      //   'underline',
      //   'blockquote',
      //   'code-block',
      //   'list',
      //   'bullet',
      //   'script',
      //   'indent',
      //   'direction',
      //   'size',
      //   'color',
      //   'background',
      //   'align',
      //   'clean',
      // ]}
    />
  );
}

export default CanvasTextEditor;
