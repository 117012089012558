import clsx from 'clsx';
import {Link} from 'react-router-dom';
import VTOGlasses from '../../../../model-viewer/components/VTOGlasses';
import {ARModelPageParameters, Model} from '../../../types';
import useModelStore from '../../single-model/model.store';
import VTO from '../../../../model-viewer/components/hand-vto/WatchVTO';

interface Props {
  model?: Model;
  parameters: ARModelPageParameters;
}

export const WatchesApp: React.FC<Props> = ({model, parameters}) => {
  const {getNext, getPrevious} = useModelStore();

  const {is_external, is_sample, location, name, query} = parameters;

  return (
    <div className='w-100 h-100'>
      <div className=' position-absolute text-center w-100 pt-1'>
        <div
          className='mx-auto w-fit  text-white  text-center p-1 fs-4 rounded '
          style={{
            position: 'relative',
            zIndex: '9999',
            textShadow: '1px 1px #000',
            background: '#00000010',
          }}
        >
          Watch Model: {model?.model_name || name}
        </div>
      </div>

      {/* <HandVTO model_path={location || `${model?.location}`} /> */}
      {(location || model?.location) && <VTO model_path={location || `${model?.location}`} />}

      {!is_external && !is_sample && (
        <>
          <Link
            className={clsx(
              'position-absolute top-50 left-0 ms-5 cursor-pointer px-3 py-4 my-n10 bg-hover-light-dark rounded bg-dark bg-opacity-25',
              {
                'd-none': !getPrevious(),
              }
            )}
            style={{
              zIndex: 999,
            }}
            title='Previous Model'
            to={`?id=${getPrevious()}&is_external=0${query ? '&query=' + query : ''}`}
          >
            <i className='fa fa-chevron-left fs-1 text-primary'></i>
          </Link>

          <Link
            className={clsx(
              'position-absolute top-50 right-0 me-10 cursor-pointer px-3 py-4 my-n10 bg-hover-light-dark rounded bg-dark bg-opacity-25',
              {
                'd-none': !getNext(),
              }
            )}
            style={{
              zIndex: 999,
            }}
            title='Next Model'
            to={`?id=${getNext()}&is_external=0${query ? '&query=' + query : ''}`}
          >
            <i className='fa fa-chevron-right fs-1 text-primary'></i>
          </Link>
        </>
      )}
    </div>
  );
};
