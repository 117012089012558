import {useEffect} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import {AsideDefault} from './components/aside/AsideDefault';
import {Footer} from './components/Footer';
import {HeaderWrapper} from './components/header/HeaderWrapper';
import {RightToolbar} from '../partials/layout/RightToolbar';
import {ScrollTop} from './components/ScrollTop';
import {Content} from './components/Content';
import {PageDataProvider} from './core';
import {ActivityDrawer, DrawerMessenger, InviteUsers, UpgradePlan, useThemeMode} from '../partials';
import {MenuComponent} from '../assets/ts/components';

import {useBranding} from '../../app/modules/app/core/BrandingProvider';
import {getBase64codeForImage, setFavIcon} from '../../shared/utils';
import {Organization} from '../../app/modules/app/types';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MasterLayout = () => {
  const location = useLocation();
  const {mode} = useThemeMode();
  const {organization} = useBranding();

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, [location.key]);

  useEffect(() => {
    if (!organization) {
      document.title = 'VRee AI';
      return;
    }
    document.title = organization.window_title || 'VRee AI';

    if (!organization.favicon) return;
    setFavIcon(organization.favicon);
  }, [organization]);

  useEffect(() => {
    // fitmix script
    const script = document.createElement('script');
    script.src =
      organization && organization.id === 2
        ? 'https://static-test.fittingbox.com/api/v1/fitmix.js'
        : 'https://vto-advanced-integration-api.fittingbox.com/index.js';
    script.id = 'fitmix-js';
    script.async = true;
    document.head.appendChild(script);
  }, [organization]);

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <AsideDefault />
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper />

          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <Content>
                <Outlet />
              </Content>
            </div>
          </div>
          <Footer />
          <ToastContainer theme={mode === 'dark' ? 'dark' : 'light'} />
        </div>
      </div>

      {/* begin:: Drawers */}

      {/* end:: Drawers */}

      {/* begin:: Modals */}

      {/* end:: Modals */}

      <ScrollTop />
    </PageDataProvider>
  );
};

export {MasterLayout};
