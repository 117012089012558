import {Canvas} from '@react-three/fiber';
import React, {ReactNode} from 'react';
import useViewerStore from '../viewer-store';
interface Props {
  children: ReactNode;
}
const Camera = ({children}: Props) => {
  const {config} = useViewerStore();

  return (
    <Canvas
      gl={{antialias: true, toneMappingExposure: 0.55}}
      shadows
      camera={{
        position: [config.camera.position.x, config.camera.position.y, config.camera.position.z],
        fov: config.camera.fov,
      }}
    >
      {children}
    </Canvas>
  );
};

export default Camera;
