import {UserModel} from '../auth';
import {CardSubmitResponse} from './bluesnap-api';

export interface Package {
  id: number;
  name: string;
  description: string;
  credits: number;
  price: number;

  time_created: Date;
  time_updated: Date;

  updated_by?: any;
  created_by?: any;

  inactive: boolean;
}

export interface Payment {
  id: number;
  user_id: number;
  package_id?: number;
  date: Date;
  amount: number;
  external_transaction_id?: string;
  status: number;
  failed_reason?: string;
  initial_max_credits?: number;
  reason?: string;

  package?: Package;
  user?: UserModel;
}

export interface PaymentsFilter {
  package_id: number;
  to_date: Date;
  from_date: Date;

  transaction_id: string;
  status: number;
  user_id: number;
}

export enum PaymentStatusEnum {
  FAILED = -1,
  NOT_COMPLETED = 0,
  SUCCESS = 1,
  CANCELED = 2,
}

export const paymentStatuses = [
  {name: 'Failed', id: '-1', color: 'danger'},
  {name: 'Incomplete', id: '0', color: 'primary'},
  {name: 'Succeed', id: '1', color: 'success'},
  {name: 'Cancelled', id: '2', color: 'warning'},
];

export interface CreatePaymentResponse {
  external_token: string;
  payment_id: number;
}

// export interface UserPlan {
//   planId: string;
//   plan: Package;
//   userId: string;
//   user: UserModel;
//   StartDate: Date;
//   CloseDate?: Date;
//   BillingDay: number;
// }

export interface PaymentMethod {
  cardHolder?: string;
  cardNumber?: number;
  last4numbers?: string;
  cardType?: 'visa' | 'mastercard' | string;
  cvv?: number;
  expireMonth?: number;
  expireYear?: number;
  saveCard: boolean;
  response?: CardSubmitResponse;
}

export interface CheckoutProps {
  package?: Package;
  paymentMethod?: PaymentMethod;
}
