/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react';
import {PageTitle} from '../../../../_metronic/layout/core';
import clsx from 'clsx';
import {Organization} from '../../app/types';
import {useAuth} from '../../auth';
import {usePermissionChecker} from '../../admin/pages/roles/hooks/permission-checker';
import {ErrorPlaceholder} from '../../../../shared/components/ErrorPlaceholder';
import {LoadingPlaceholder} from '../../../../shared/components/LoadingPlaceholder';
import usePackagesStore from './packages.store';
import {DataTable, DataTableFilterMeta} from 'primereact/datatable';
import {useThemeMode} from '../../../../_metronic/partials';
import {FilterMatchMode} from 'primereact/api';
import {Column} from 'primereact/column';
import dayjs from 'dayjs';
import {Dialog} from 'primereact/dialog';
import EditPackage from './EditPackage';
import {parseError} from '../../../../shared/utils';
import {Package, PaymentStatusEnum} from '../types';
import {confirmDialog} from 'primereact/confirmdialog';
import {deleteDialogProps} from '../../shared/types';
import {
  createPayment,
  deactivatePackage,
  fetchPackages,
  reactivatePackage,
  updatePaymentStatus,
} from '../billing-api';
import axios from 'axios';
import {useNavigate, useSearchParams} from 'react-router-dom';
declare var bluesnap: any;

interface Props {
  isWindow?: boolean;
  enableSelection?: boolean;
  showOutCreditMessage?: boolean;
  onSubmit?: (pckg: Package | undefined) => void;
}

const PurchasePackage = ({isWindow, enableSelection, showOutCreditMessage, onSubmit}: Props) => {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const {packages, setPackages} = usePackagesStore();
  const [isBluesnapLoaded, setIsBluesnapLoaded] = useState(false);
  const navigate = useNavigate();

  const [search] = useSearchParams();
  const state = search.get('state');

  const getPackages = async () => {
    try {
      setLoading(true);

      const res = await fetchPackages();

      const _packages: Package[] = res.data.packages || [];
      setPackages(_packages.filter((p) => !p.inactive));
    } catch (error) {
      setError(parseError(error));
    } finally {
      setLoading(false);
    }
  };

  const purchase = async (pckg: Package) => {
    try {
      // redirection to checkout page
      navigate(`/billing/checkout?product=${pckg.id}`);
      return;
      // old stuff

      if (!isBluesnapLoaded) return;

      setSubmitting(true);

      const res = await createPayment(pckg.id);

      const {payment_id: paymentId, external_token: jwt} = res;

      if (!paymentId) return setError('Initializing payment failed, please contact support!');

      // const jwt =
      // 'eyJhbGciOiJIUzI1NiJ9.eyJwYXlsb2FkIjp7ImNvbW1vbkp3dFBheWxvYWQiOnsiaWQiOiI0OTI2NjA4NDg3NjM3MjEzNTg5NjIzMiIsIm1lcmNoYW50SWQiOjEyMDM0ODksImRhdGVDcmVhdGVkIjoxNzE0NDgxNzYxOTQxLCJlbnYiOiJTQU5EQk9YIn0sIm1vZGUiOiJvbmVfdGltZSIsImN1cnJlbmN5IjoiVVNEIiwic2VudHJ5Ijp0cnVlLCJzdWNjZXNzVXJsIjoiaHR0cHMlM0ElMkYlMkZ0ZXN0LnZyZWUuYWklMkZiaWxsaW5nJTJGcHVyY2hhc2UtcGFja2FnZSUzRnN0YXRlJTNEc3VjY2VzcyIsImNhbmNlbFVybCI6Imh0dHBzJTNBJTJGJTJGdGVzdC52cmVlLmFpJTJGYmlsbGluZyUyRnB1cmNoYXNlLXBhY2thZ2UlM0ZzdGF0ZSUzRGNhbmNlbCIsIm1lcmNoYW50VHJhbnNhY3Rpb25JZCI6InBheW1lbnRJZDEyMzQ1NiIsImNvbmZpZ3VyYXRpb24iOnsidGhlbWUiOnsiY29sb3IiOnsicHJpbWFyeSI6IiNmZmZmZmYiLCJzZWNvbmRhcnkiOiIjMzMzODNiIn19LCJpZCI6NzU2MX0sImxpbmVJdGVtcyI6W3siaWQiOiIwMDEiLCJsYWJlbCI6IkNyZWRpdHMgVG9wdXAgMjAiLCJkZXNjcmlwdGlvbiI6IkFkZCBtb3JlIGNyZWFkaXRzIHRvIHlvdXIgYWNjb3VudCIsInF1YW50aXR5IjoxLCJhbW91bnQiOjIwfV0sIm9uQmVoYWxmIjpmYWxzZX19.SMbk2SrmLJxwsu9GaIET_dPydWt3BckEhWwl79JuvH4._';
      // const jwt = await getBluesnapToken(paymentId, pckg);

      if (!jwt) return setError('Failed to get provider token, please contact support!');

      // redirect user to payment page
      bluesnap.redirectToPaymentPage({
        jwt,
      });
    } catch (error) {
      setError(parseError(error));
    } finally {
      setSubmitting(false);
    }
  };

  const getBluesnapToken = async (paymentId: number, pckg: Package): Promise<any> => {
    const postData = {
      mode: 'one_time',
      onBehalf: false,
      currency: 'USD',
      successUrl: 'http://vree.ai/auth/payment-success',
      cancelUrl: 'http://vree.ai/auth/payment-canceled',
      merchantTransactionId: paymentId,
      lineItems: [
        {
          id: pckg.id,
          quantity: pckg.credits,
          label: pckg.name,
          description: pckg.description,
          amount: pckg.price,
        },
      ],
    };

    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Basic QVBJXzE3MTQ0NjY1ODc3MzUxNTk5MzI1MTE0OnI5V0kzRTQjZDZDMA==',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'X-Requested-With',
    };

    await fetch('https://sandbox.bluesnap.com/services/2/bn3-services/jwt', {
      method: 'POST',
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        console.log('Bluesnap response:', data);
        return data?.jwt;
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const handleResponse = async () => {
    const merchantTransactionId = search.get('merchantTransactionId');
    const jwtId = search.get('jwtId');
    const altTransaction = search.get('altTransaction') || '';
    const subscription = search.get('subscription');

    if (!merchantTransactionId)
      return alert('merchantTransactionId is not given in params, contact support');

    if (state === 'success') {
      try {
        await updatePaymentStatus(
          +merchantTransactionId,
          PaymentStatusEnum.SUCCESS,
          altTransaction
        );
      } catch (error) {
        alert('Error when updating payment status, please contact support');
      }
    } else if (state === 'cancel') {
      try {
        await updatePaymentStatus(
          +merchantTransactionId,
          PaymentStatusEnum.CANCELED,
          altTransaction
        );
      } catch (error) {
        alert('Error when updating payment status, please contact support');
      }
    }
  };

  useEffect(() => {
    getPackages();
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://sandpay.bluesnap.com/web-sdk/5/bluesnap.js';
    // script.async = true;
    document.body.appendChild(script);

    setIsBluesnapLoaded(true);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (!state) return;

    handleResponse();
  }, [state]);

  if (submitting) return <LoadingPlaceholder label='Preparing for payment...' />;
  if (loading) return <LoadingPlaceholder label='getting packages..' />;
  if (error) return <ErrorPlaceholder error={error} />;

  return (
    <div>
      {!isWindow && (
        <PageTitle breadcrumbs={[{title: 'Billing', path: 'billing', isActive: false}]}>
          Package Purchase
        </PageTitle>
      )}

      <div>
        <div className='container-fluid'>
          {!isWindow && (
            <header className='d-flex flex-wrap flex-stack pb-7'>
              <div className='d-flex flex-wrap align-items-center my-1'>
                <h3 className='fw-bold me-5 my-1'>Package Purchase </h3>
                {/* <UsersFilters /> */}
              </div>

              <div className='d-flex flex-wrap my-1'></div>
            </header>
          )}

          {state === 'success' && (
            <div className='alert alert-success d-flex align-items-center p-5 mx-10 mb-10'>
              <i className='ki-duotone ki-shield-tick fs-2hx text-success me-4'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>

              <div className='d-flex flex-column'>
                <h4 className='mb-1 text-green-600'>Payment Succeeded</h4>

                <span>
                  Congratulations! Your payment has been successfully processed. Thank you for your
                  purchase.
                </span>
              </div>
            </div>
          )}

          {state === 'cancel' && (
            <div className='alert alert-warning d-flex align-items-center p-5 mx-10 mb-10'>
              <i className='ki-duotone ki-shield-tick fs-2hx text-yellow-500 me-4'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>

              <div className='d-flex flex-column'>
                <h4 className='mb-1 text-yellow-600'>Payment Cancelled</h4>

                <span>Your payment is cancelled, you can always purchase any new package</span>
              </div>
            </div>
          )}

          {!state && !showOutCreditMessage && !enableSelection && (
            <div className='alert alert-primary d-flex align-items-center p-5 mx-10 mb-10'>
              <i className='ki-duotone ki-shield-tick fs-2hx text-primary me-4'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>

              <div className='d-flex flex-column'>
                <h4 className='mb-1 text-primary'>Info</h4>

                <span>
                  This page displays the available packages for purchase. Upon clicking the
                  'Purchase' button, you will be redirected to the payment page. Upon successful
                  completion of the payment process, the purchased credits will be added to your
                  account.
                </span>
              </div>
            </div>
          )}

          {showOutCreditMessage && (
            <div className='alert alert-danger d-flex align-items-center p-5 mx-10 mb-10'>
              <i className='ki-duotone ki-information-4 fs-2hx text-danger me-4'>
                <span className='path1'></span>
                <span className='path2'></span>
                <span className='path3'></span>
              </i>

              <div className='d-flex flex-column'>
                <h4 className='mb-1 text-danger'>Out of Credits!</h4>

                <span>
                  Sorry, but looks like you ran out of credits. You can still create 3D models but
                  you cannot download it. Please buy more credits or contact us on admin@Vree.ai{' '}
                </span>
              </div>
            </div>
          )}

          <div className='container row g-10'>
            {packages.map((pckg) => (
              <div className={clsx({'col-md-3': !isWindow}, {'col-md-4': isWindow})}>
                <div className='card shadow-sm hover-elevate-up '>
                  <div className='card-header'>
                    <h3 className='card-title d-flex flex-column'>
                      <div>{pckg.name}</div>
                      <div className='text-primary fs-7 fw-bold'>{pckg.price} $</div>
                    </h3>
                    <div className='card-toolbar'></div>
                  </div>
                  <div className='card-body card-scroll h-150px'>
                    <div dangerouslySetInnerHTML={{__html: pckg.description}}></div>
                  </div>
                  <div className='card-footer text-end d-flex justify-content-between align-items-center h-70px '>
                    <div className='text-start'>+{pckg.credits?.toLocaleString()} Credits</div>
                    {!enableSelection && (
                      <button
                        type='button'
                        className='btn btn-sm btn-primary '
                        onClick={() => purchase(pckg)}
                      >
                        Purchase
                      </button>
                    )}

                    {enableSelection && (
                      <button
                        type='button'
                        className='btn btn-sm btn-primary '
                        onClick={() => onSubmit && onSubmit(pckg)}
                      >
                        Select
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchasePackage;
