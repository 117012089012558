import {create} from 'zustand';
import {ViewerConfig} from '../../../shared/types';
import {viewerConfig} from '../../viewer-config';
import {Model} from '../../../models-management/types';

interface IStore {
  reset: () => void;

  config: ViewerConfig;
  setConfig: (config: ViewerConfig) => void;

  showOccluder: boolean;
  setShowOccluder: (show: boolean) => void;

  model?: Model;
  setModel: (model: Model) => void;
}

const useViewerStore = create<IStore>((set, get) => ({
  config: viewerConfig.eyewear,
  setConfig: (config) => set({config: config}),

  showOccluder: false,
  setShowOccluder: (show) => set({showOccluder: show}),

  model: undefined,
  setModel: (model) => set({model: model}),

  reset: () => {
    set({
      config: viewerConfig.eyewear,
      showOccluder: false,
      model: undefined,
    });
  },
}));

export default useViewerStore;
