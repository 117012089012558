/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */

import dayjs from 'dayjs';
import {useState, useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';
import {toAbsoluteUrl} from '../../../../_metronic/helpers';
import {useThemeMode} from '../../../../_metronic/partials';
import {AppLocator} from '../../../../app-locator';
import {ErrorPlaceholder} from '../../../../shared/components/ErrorPlaceholder';
import {LoadingPlaceholder} from '../../../../shared/components/LoadingPlaceholder';
import {isObjectEmpty, setFavIcon, parseError} from '../../../../shared/utils';
import {getOrganizationByDomain} from '../../admin/api/organizations-api';
import {useBranding} from '../../app/core/BrandingProvider';
import VTOGlasses from '../../model-viewer/components/VTOGlasses';
import Viewer3D from '../../model-viewer/components/Viewer3D';
import {WatchesApp} from '../../models-management/pages/ar-model/components/WatchesApp';
import {ModelSharingType, Model} from '../../models-management/types';
import {Video} from '../vidoes.types';
import {getSharedVideo} from '../vidoes.api';

const productionMode = process.env.NODE_ENV === 'production';
const APP_URL = productionMode
  ? `https://${window.location.hostname}`
  : `http://${window.location.hostname}:3011`;
interface Props {}

export const VideoSharedPage = ({}: Props) => {
  const [search] = useSearchParams();
  const {mode} = useThemeMode();

  const [video, setVideo] = useState<Video>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const {organization, setOrganization} = useBranding();

  const id = search.get('id');

  useEffect(() => {
    const getBranding = async () => {
      const org = await getOrganizationByDomain(window.location.hostname);
      setLoading(false);

      if (!org || isObjectEmpty(org)) return;

      setOrganization(org);
    };

    getBranding();
  }, []);

  useEffect(() => {
    if (!organization) {
      document.title = 'VRee AI';
      return;
    }
    document.title = organization.window_title || 'VRee AI';

    if (!organization.favicon) return;
    setFavIcon(organization.favicon);
  }, [organization]);

  useEffect(() => {
    if (!id) return;

    setLoading(true);

    const get = async () => {
      try {
        const res = await getSharedVideo(id);
        setVideo(res.data);
      } catch (error) {
        setError(parseError(error));
      } finally {
        setLoading(false);
      }
    };

    get();
  }, [id]);

  if (loading) return <LoadingPlaceholder label='loading' />;
  if (error) return <ErrorPlaceholder error={error} />;

  return (
    <>
      <div
        className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        style={{
          backgroundImage: `url(${toAbsoluteUrl(
            `/media/illustrations/sketchy-1/14${mode === 'dark' ? '-dark' : ''}.png`
          )})`,
        }}
      >
        {/* begin::Content */}
        <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20 text-center'>
          {/* begin::Logo */}
          <a href='#' className='mb-12'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/colorful-lavendar.svg')}
              className='theme-dark-show h-75px'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/colorful-lavendar.svg')}
              className='theme-light-show h-75px'
            ></img>
          </a>
          {/* end::Logo */}

          {/* begin::Wrapper */}
          <section className='w-lg-1000px bg-body rounded shadow-sm p-10 p-lg-15 pt-4 mx-auto'>
            <div className='row'>
              <div className='col-md-6 order-md-0 order-1'>
                {video && (
                  <video
                    id={video.id + ''}
                    src={toAbsoluteUrl(video.video || '')}
                    poster={toAbsoluteUrl(video.thumbnail)}
                    className='mh-600px '
                    autoPlay
                    controls
                    controlsList='nodownload'
                    muted
                  ></video>
                )}
              </div>
              <div className='col-md-6 d-flex flex-center flex-column order-md-1 order-0'>
                <h3 className='fw-bolder mb-3 capitalized'>
                  View Shared 3D Experience: {video?.name}
                </h3>

                <div className='mw-980px mx-auto mb-7 position-relative'></div>

                <div className='mt-8 text-muted mx-lg-15 mx-5'>
                  This 3D experience is created by VRee.ai, you can use VRee services to generate
                  more 3D experiences
                </div>

                <div className='mt-8 mb-10'>
                  <fieldset className='d-flex flex-center text-start fw-bold'>
                    <div className='text-gray-400  me-3 w-100px '>Name:</div>
                    <div className=' fs-6 w-200px'>{video?.name}</div>
                  </fieldset>
                  <fieldset className='d-flex flex-center text-start fw-bold'>
                    <div className='text-gray-400  me-3 w-100px '>Creation Date:</div>
                    <div className=' fs-6 w-200px'>
                      {dayjs(video?.create_date).format('DD MMM, YYYY - HH:mm ')}
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          </section>
          {/* end::Wrapper */}
        </div>
        {/* end::Content */}

        {/* begin::Footer */}
        <footer className='d-none flex-center flex-column-auto p-10'>
          <div className='d-flex align-items-center fw-semibold fs-6'>
            <a
              href={organization?.about_link || 'https://vree.ai/#section_clients'}
              className='text-muted text-hover-primary px-2'
            >
              About
            </a>

            <a
              href={organization?.contact_link || 'https://vree.ai/#section_contact'}
              className='text-muted text-hover-primary px-2'
            >
              Contact Us
            </a>
          </div>
        </footer>
        {/* end::Footer */}
      </div>
    </>
  );
};

export default VideoSharedPage;
