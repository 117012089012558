import {create} from 'zustand';
import {Package} from '../types';

interface StoreProps {
  packages: Package[];
  originalPackages: Package[];
  searchText: string;
  // orgFilter?: number;
  // orgs: Organization[];
  setPackages: (value: Package[]) => void;
  setOriginalPackages: (value: Package[]) => void;
  setSearch: (text: string) => void;
  // setOrgFilter: (orgId?: number) => void;
  // setOrgs: (value: any[]) => void;
}

const usePackagesStore = create<StoreProps>((set, get) => ({
  packages: [],
  originalPackages: [],
  searchText: '',
  // orgFilter: undefined,
  // orgs: [],

  setPackages: (users: any[]) => set({packages: users}),
  setOriginalPackages: (users: any[]) => set({originalPackages: users, packages: users}),
  setSearch: (text: string) => set({searchText: text}),
  // setOrgFilter: (orgId?: number) => set({orgFilter: orgId}),
  // setOrgs: (orgs: any[]) => set({orgs: orgs}),
}));

export default usePackagesStore;
