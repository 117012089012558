import {create} from 'zustand';
import {Package, PaymentMethod} from '../types';

export interface PFErrors {
  name?: string;
  ccn?: string;
  exp?: string;
  cvv?: string;
}

interface Props {
  selectedPackage?: Package;
  setSelectedPackage: (pckg: Package | undefined) => void;

  newPaymentMethod?: PaymentMethod;
  setNewPaymentMethod: (method: PaymentMethod | undefined) => void;

  pfToken?: string;
  setPFToken: (token: string | undefined) => void;

  cardError?: string;
  setCardError: (error: string | undefined) => void;

  cardType?: any;
  setCardType: (data: any | undefined) => void;

  status?: 'success' | 'failed' | string;
  setStatus: (status: 'success' | 'failed' | string | undefined) => void;

  pfErrors: PFErrors;
  setPFErrors: (errors: Partial<PFErrors>) => void;

  reset: () => void;
}

const initialState = {
  selectedPackage: undefined,
  newPaymentMethod: undefined,
  pfToken: undefined,
  cardError: undefined,
  cardType: undefined,
  status: undefined,
  pfErrors: {ccn: '', cvv: '', exp: '', name: ''},
};

const useCheckoutStore = create<Props>((set, get) => ({
  ...initialState,

  setSelectedPackage: (pckg) => set({selectedPackage: pckg}),
  setNewPaymentMethod: (method) => set({newPaymentMethod: method}),
  setPFToken: (token) => set({pfToken: token}),
  setCardError: (error) => set({cardError: error}),
  setCardType: (data: any | undefined) => set({cardType: data}),
  setStatus: (status) => set({status: status}),
  setPFErrors: (errors) => {
    const prev = get().pfErrors;
    console.log('setPFErrors', errors, prev);

    set({pfErrors: {...prev, ...errors}});
  },

  reset: () => set({...initialState}),
}));

export default useCheckoutStore;
