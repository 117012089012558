import React, {useState} from 'react';
import useCheckoutStore from '../checkout.store';
import {Dialog} from 'primereact/dialog';
import PurchasePackage from '../../Packages/PurchasePackage';
import {ModalLayout} from '../../../shared/components/ModalLayout';

export function CheckoutProducts() {
  const {selectedPackage, setSelectedPackage} = useCheckoutStore();
  const [showPurchasePackageWindow, setShowPurchasePackageWindow] = useState(false);

  return (
    <article className='card card-bordered card-flush pt-3 mb-5 mb-lg-10'>
      <div className='card-header'>
        <div className='card-title'>
          <h2 className='fw-bold'>Product</h2>
        </div>
        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-light-primary'
            onClick={() => setShowPurchasePackageWindow(true)}
          >
            Change Product
          </button>
        </div>
      </div>
      <div className='card-body pt-0'>
        {!selectedPackage && (
          <div className='w-100 d-flex flex-center py-10 text-muted fs-5'>
            No packages selected, please select the package
          </div>
        )}

        {selectedPackage && (
          <div className='table-responsive'>
            <div className='dt-container dt-bootstrap5 dt-empty-footer'>
              <div className='table-responsive'>
                <table className='table align-middle table-row-dashed fs-6 fw-semibold gy-4 dataTable'>
                  <thead>
                    <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                      <th className='min-w-300px '>
                        <span className=''>Product</span>
                      </th>
                      <th className='min-w-100px  '>
                        <span className=''>Qty</span>
                      </th>
                      <th className='min-w-150px '>
                        <span className=''>Total</span>
                      </th>
                      <th className='min-w-70px text-end '>
                        <span className=''></span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className='text-gray-600'>
                    <tr>
                      <td className='text-dark' title={selectedPackage.id + ''}>
                        {selectedPackage?.name}
                      </td>
                      <td className=''>{selectedPackage?.credits} credits</td>
                      <td>
                        <div className='text-primary'>{selectedPackage.price}$</div>
                      </td>
                      <td className='text-end'>
                        <button
                          className='btn'
                          onClick={() => {
                            setSelectedPackage(undefined);
                          }}
                        >
                          <i className='ki-duotone ki-trash fs-3'>
                            <span className='path1' />
                            <span className='path2' />
                            <span className='path3' />
                            <span className='path4' />
                            <span className='path5' />
                          </i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot />
                </table>
              </div>
              <div id='' className='row'>
                <div
                  id=''
                  className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start dt-toolbar'
                />
                <div
                  id=''
                  className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'
                />
              </div>
            </div>
          </div>
        )}
      </div>

      <Dialog
        modal
        dismissableMask
        closable={false}
        visible={showPurchasePackageWindow}
        onHide={() => setShowPurchasePackageWindow(false)}
      >
        <ModalLayout
          title='Purchase Package'
          showCloseButton
          large
          onClose={() => setShowPurchasePackageWindow(false)}
        >
          <PurchasePackage
            isWindow
            enableSelection
            onSubmit={(pckg) => {
              pckg && setSelectedPackage(pckg);
              setShowPurchasePackageWindow(false);
            }}
          />
        </ModalLayout>
      </Dialog>
    </article>
  );
}
