/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import {useState, useEffect} from 'react';
import {Dropdown} from 'primereact/dropdown';

import {useSearchParams} from 'react-router-dom';
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components';
import {initialQueryState, KTSVG} from '../../../../../../_metronic/helpers';

import {Calendar} from 'primereact/calendar';
import {Tooltip} from 'primereact/tooltip';
import {videosStatuses} from '../../../vidoes.types';
import useVideosPageStore from '../videos-page.store';
import {useVideosQuery} from '../VideosQueryProvider';
import {useAuth} from '../../../../auth';
import {getUsers} from '../../../../admin/api/users-api';
import {sortByProp} from '../../../../../../shared/utils';

export function VideosFilter() {
  const [isLoading] = useState(false);
  const {currentUser} = useAuth();
  const {updateFilters, filters} = useVideosPageStore();

  const [status, setStatus] = useState<string | undefined>(filters?.filter?.status);
  const [isShared, setIsShared] = useState<boolean | undefined>();
  const [dateFrom, setDateFrom] = useState<Date>();
  const [dateTo, setDateTo] = useState<Date>();
  const [users, setUsers] = useState<any[]>([]);
  const [selectedUser, setSelectedUser] = useState('');

  const [search] = useSearchParams();
  const statusFilter = search.get('status');

  const resetData = () => {
    setStatus(undefined);
    setIsShared(false);
    setDateFrom(undefined);
    setDateTo(undefined);
    setSelectedUser('');
    updateFilters({filter: undefined, ...initialQueryState});
  };

  const filterData = () => {
    if (!status && !isShared && !dateFrom && !dateTo && !selectedUser) return;

    const filters: any = {
      status,
      is_shared: isShared ? '1' : '',
      from_date: dateFrom?.toISOString(),
      to_date: dateTo?.toISOString(),
      user_id: selectedUser,
    };
    updateFilters({
      ...initialQueryState,
      filter: {...filters},
    });
  };

  const loadUsers = async () => {
    const res = await getUsers();
    const users: any[] = res.data.users || [];
    const sortedUsers = sortByProp(users, 'first_name');
    setUsers(sortedUsers);
  };

  useEffect(() => {
    if (!statusFilter) return;

    setStatus(statusFilter);
  }, [statusFilter]);

  useEffect(() => {
    MenuComponent.reinitialization();

    if (currentUser?.is_super_admin) loadUsers();
  }, []);

  return (
    <>
      {/* Toggle */}
      <button
        type='button'
        className='btn btn-sm btn-icon btn-flex bg-body btn-color-gray-700 btn-active-color-primary fw-bold'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-static='true'
      >
        <KTSVG
          path='/media/icons/duotune/general/gen031.svg'
          className={clsx('svg-icon-2', {'text-primary': filters.filter})}
        />
        {/* Filter */}
      </button>

      {/* Menu */}
      <section className='menu menu-sub menu-sub-dropdown w-400px w-md-425px' data-kt-menu='true'>
        <header className='flex align-items-center justify-content-between px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
          <div
            title='Close filters'
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            data-kt-menu-dismiss='true'
          >
            <i className='la la-close fs-3'></i>
          </div>
        </header>

        <section className='separator border-gray-200'></section>

        <form className='px-7 py-5' data-kt-user-table-filter='form'>
          {!!currentUser?.is_super_admin && (
            <fieldset className='flex flex align-items-center mb-2'>
              <label className='w-200px form-label fs-6 fw-bold'>User:</label>

              <Dropdown
                // placeholder='Choose...'
                optionLabel='first_name'
                optionValue='id'
                name='users'
                id='users'
                className='bg-light mb-3 mb-lg-0 border-0 w-100 fw-bold text-gray-700'
                value={selectedUser}
                onChange={(e) => setSelectedUser(e.target.value)}
                options={users}
                itemTemplate={(item) => (
                  <div className='capitalized' title={item.email}>
                    {item?.first_name} {item?.last_name} {item.company && <>({item.company})</>}
                  </div>
                )}
                valueTemplate={(item) => (
                  <div className='capitalized'>
                    {item?.first_name} {item?.last_name} &nbsp;
                  </div>
                )}
                showClear
                filter
                filterBy='first_name,last_name,company,email'
                filterPlaceholder='Search by first Name or last name or company'
              />
            </fieldset>
          )}

          <fieldset className='flex flex align-items-center mb-2'>
            <label className='w-200px form-label fs-6 fw-bold'>Status:</label>

            <Dropdown
              // placeholder='Choose...'
              optionLabel='label'
              optionValue='id'
              name='status'
              id='status'
              className='bg-light mb-3 mb-lg-0 border-0 w-100 fw-bold text-gray-700'
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              options={videosStatuses}
              itemTemplate={(item) => <div className='capitalized'>{item?.label}</div>}
              valueTemplate={(item) => <div className='capitalized'>{item?.label}&nbsp;</div>}
              showClear
            />
          </fieldset>

          <div className='separator my-4' />

          <fieldset className='flex flex align-items-center mb-2'>
            <label className='w-200px form-label fs-6 fw-bold'>
              Starting From:
              <i className='fa fa-info-circle mx-2 text-blue-400 tooltip-from-date'></i>
            </label>
            <Calendar
              className='w-100 bg-light'
              value={dateFrom}
              onChange={(e: any) => setDateFrom(e.value)}
              placeholder='Select date'
              showTime
              inputClassName='form-select form-select-solid'
              readOnlyInput
              showButtonBar
              stepMinute={5}
            />
            <Tooltip target='.tooltip-from-date' className='text-center'>
              <div>Show you the models created after chosen date</div>
            </Tooltip>
          </fieldset>

          <fieldset className='flex flex align-items-center mb-2'>
            <label className='w-200px form-label fs-6 fw-bold'>
              Ending At:
              <i className='fa fa-info-circle mx-2 text-blue-400 tooltip-to-date'></i>
            </label>
            <Calendar
              className='w-100 bg-light'
              value={dateTo}
              onChange={(e: any) => setDateTo(e.value)}
              placeholder='Select date'
              showTime
              inputClassName='form-select form-select-solid'
              readOnlyInput
              showButtonBar
              stepMinute={5}
            />
            <Tooltip target='.tooltip-to-date' className='text-center'>
              <div>Show you the models created before chosen date</div>
            </Tooltip>
          </fieldset>

          <fieldset className={clsx('flex align-items-center mb-2')}>
            <div className='form-check form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                type='checkbox'
                onChange={() => setIsShared(!isShared)}
                id='isSharedCheckBox'
              />
              <label className='w-200px form-check-label' htmlFor='isSharedCheckBox'>
                {' '}
                Show shared experiences only
              </label>
            </div>
          </fieldset>

          <footer className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </footer>
        </form>
      </section>
    </>
  );
}
