import {toAbsoluteUrl} from '../../_metronic/helpers';

interface Props {
  label?: string;
  description?: string;
}
export const EmptyPlaceholder = ({label, description}: Props) => {
  return (
    <>
      <div className='text-center pt-10'>
        <div className='fw-semibold py-10'>
          <div className='text-gray-600 fs-3 mb-2'>{label || 'No records found'}</div>
          <div className='text-muted fs-6'>{description}</div>
        </div>
        <div className='text-center px-5'>
          <img
            src={toAbsoluteUrl('/media/illustrations/sketchy-1/5.png')}
            alt=''
            className=' h-200px h-sm-325px'
          />
        </div>
      </div>
    </>
  );
};
