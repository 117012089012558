import React from 'react';
import useMediaWizardStore from '../media-wizard.store';
import clsx from 'clsx';

export function MediaWizardHeader() {
  const {currentStep} = useMediaWizardStore();

  return (
    <div className='stepper-nav mb-10 pb-5 w-100 bg-body '>
      <div
        className={clsx('stepper-item mx-10 ', {
          current: currentStep === 1,
        })}
      >
        <h3 className='stepper-title'>Let's start </h3>
      </div>
      <div
        className={clsx('stepper-item mx-10', {
          current: currentStep === 2,
        })}
      >
        <h3 className='stepper-title'>Creative design</h3>
      </div>
      <div
        className={clsx('stepper-item mx-10', {
          current: currentStep === 3,
        })}
      >
        <h3 className='stepper-title'>Style selection</h3>
      </div>
      <div
        className={clsx('stepper-item mx-10', {
          current: currentStep === 4,
        })}
      >
        <h3 className='stepper-title'>Ad dynamics</h3>
      </div>
      <div
        className={clsx('stepper-item mx-10', {
          current: currentStep === 5,
        })}
      >
        <h3 className='stepper-title z-2'>Final Step</h3>
      </div>
    </div>
  );
}
