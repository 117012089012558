import clsx from 'clsx';
import React, {useEffect, useRef} from 'react';
import {frameColors} from './EditorFields';
import {useDebounce} from 'primereact/hooks';

interface Props {
  field: any;
  onUpdate: (value: any) => void;
}

export function FieldColor({field, onUpdate}: Props) {
  const colorPickerRef = useRef<HTMLInputElement>(null);

  const [value, debouncedValue, setValue] = useDebounce('', 400);

  useEffect(() => {
    onUpdate(debouncedValue);
  }, [debouncedValue]);

  const handleButtonClick = () => {
    if (colorPickerRef.current) {
      colorPickerRef.current.click();
    }
  };

  const handleColorChange = (event: any) => {
    const newValue = event.target.value;
    if (newValue) setValue(newValue);
  };

  return (
    <div className='d-flex flex-wrap ms-2'>
      {frameColors.map((color) => (
        <div
          className={clsx(
            'border-2  shadow-sm border-circle hover-elevate-up w-50px h-50px me-5 mb-5 shadow cursor-pointer',
            {
              'border-primary border-2 shadow-primary': field === color,
            },
            {
              'border-secondary  ': field !== color,
            }
          )}
          style={{backgroundColor: color}}
          onClick={() => {
            onUpdate(color);
            setValue('');
          }}
        ></div>
      ))}
      <div
        onClick={handleButtonClick}
        className={clsx(
          ' border-4 border-light border-circle w-50px h-50px hover-elevate-up me-2 shadow cursor-pointer d-flex flex-center',
          {
            'border-primary border-2 shadow-primary': !!value,
          }
        )}
        style={{backgroundColor: value}}
      >
        <i className='fas fa-edit text-primary fs-2'></i>
      </div>

      <input className='opacity-0' type='color' ref={colorPickerRef} onChange={handleColorChange} />
    </div>
  );
}
