import {useIntl} from 'react-intl';
import {Navigate, Outlet, Route, Routes} from 'react-router-dom';
import {PageTitle} from '../../../_metronic/layout/core';
import Report from './Report';

const ReportsPage = () => {
  const intl = useIntl();

  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path='models'
          element={
            <>
              <PageTitle breadcrumbs={[]}>Overview</PageTitle>
              <Report reportName='models' />
            </>
          }
        />

        <Route index element={<Navigate to='/reports/models' />} />
      </Route>
    </Routes>
  );
};

export default ReportsPage;
