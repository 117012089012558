import clsx from 'clsx';
import {toAbsoluteUrl} from '../../../../../_metronic/helpers';

interface Props {
  field: any;
  onUpdate: (value: any) => void;
}

const textures = [
  {id: 0, name: 'original', path: '/media/texture/original.jpg'},
  {id: 1, name: 'texture1', path: '/media/texture/texture1.png'},
  {id: 2, name: 'texture2', path: '/media/texture/texture2.jpg'},
  {id: 3, name: 'texture3', path: '/media/texture/texture3.jpg'},
];

export function FieldTexture({field, onUpdate}: Props) {
  return (
    <div className='d-flex flex-wrap ms-2'>
      <div
        className={clsx(
          'border-2 border-light border-circle w-50px h-50px hover-elevate-up me-5 mb-5 shadow cursor-pointer d-flex flex-center',
          {
            'border-primary border-2 shadow-primary': !field,
          },
          {'border-secondary ': !!field}
        )}
        onClick={() => onUpdate('')}
      >
        <i className='fas fa-ban text-gray-600 fs-2'></i>
      </div>
      {textures.map((tex) => (
        <div
          key={tex.id}
          title={tex.name}
          className={clsx(
            'border-2 border-circle w-50px h-50px hover-elevate-up shadow me-5 cursor-pointer',
            {
              'border-primary border-2 shadow-primary': field === tex.name,
            },
            {
              'border-secondary ': field !== tex.name,
            }
          )}
          onClick={() => onUpdate(tex.name)}
          style={{backgroundImage: `url('${toAbsoluteUrl(tex.path)}')`}}
        ></div>
      ))}
    </div>
  );
}
