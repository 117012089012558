/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useReducer} from 'react';
import {Link} from 'react-router-dom';
import {useAuth} from '../../../../app/modules/auth';
import {generateUserAvatarImage} from '../../../assets/ts/_utils/UserAvatarGenerator';
import {MenuComponent} from '../../../assets/ts/components';
import {AppLocator} from '../../../../app-locator';

const HeaderUserMenu: FC = () => {
  const {currentUser, logout} = useAuth();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-300px'
      data-kt-menu='true'
      data-popper-placement='bottom-start'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={generateUserAvatarImage(currentUser)} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5 capitalized'>
              {currentUser?.first_name} {currentUser?.last_name}
              {currentUser?.is_super_admin && (
                <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>
                  Admin
                </span>
              )}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      {/* <Languages /> */}

      <div className='menu-item px-5 my-1'>
        <Link to={`/account/${currentUser?.id}`} className='menu-link px-5 '>
          Account Settings
        </Link>
      </div>

      {currentUser?.is_super_admin && (
        <div className='menu-item px-5 my-1'>
          <div
            onClick={() => {
              AppLocator.showPermissionCodes = !AppLocator.showPermissionCodes;
            }}
            className='menu-link px-5 '
          >
            Toggle permission codes {AppLocator.showPermissionCodes ? 'show' : 'hide'}
          </div>
        </div>
      )}

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  );
};

export {HeaderUserMenu};
