import axios, {AxiosResponse} from 'axios';
import {AppLocator} from '../../../../app-locator';
import {getAuth} from '../../auth/core/AuthHelpers';
import {ModelsCounter, UserRolesEnum} from '../../models-management/types';
import {NewUserType, Settings} from '../types';
import {downloadFile} from '../../../../shared/utils';
import dayjs from 'dayjs';

const API_URL =
  AppLocator.forceUsingRealApi || process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_DEV_API_URL;

const CREATE_USER_URL = `${API_URL}/admin/create_user`;
const RESELLER_CREATE_USER_URL = `${API_URL}/reseller/create_user`;
const GET_USERS_URL = `${API_URL}/admin/get_users`;
const GET_USERS_COUNT_URL = `${API_URL}/admin/get_users_count`;
const UPDATE_MAX_CREDITS_FOR_USER_URL = `${API_URL}/models/update_max_credits_for_user`;
const UPDATE_USER_URL = `${API_URL}/user/edit`;
const GET_ALL_MODELS = `${API_URL}/models/count_all_models`;
const CREATE_MODEL_URL = `${API_URL}/models/admin/create`;

const ADD_EXTRA_CREDITS_URL = `${API_URL}/models/admin/increase_max_credits`;
const SEND_BULK_EMAIL_URL = `${API_URL}/admin/send_bulk_emails`;

const GET_SETTINGS_URL = `${API_URL}/settings/`;
const GET_AI_VERSION_URL = `${API_URL}/settings/ai_version`;
const UPDATE_SETTINGS_URL = `${API_URL}/settings/update`;
const CHANGE_PASSWORD_URL = `${API_URL}/user/change_password`;

// export const createUserAdmin = (
//   email: string,
//   firstname: string,
//   lastname: string,
//   company: string,
//   api_key: string | undefined = undefined,
//   reseller: boolean = false
// ) => {
//   return axios.post(reseller ? RESELLER_CREATE_USER_URL : REGISTER_URL, {
//     email,
//     first_name: firstname,
//     last_name: lastname,
//     company,
//     api_key,
//   });
// };

export const createUserByAdmin = (user: NewUserType) => {
  return axios.post(CREATE_USER_URL, user);
};

export const createResellerCustomer = (user: NewUserType) => {
  return axios.post(RESELLER_CREATE_USER_URL, user);
};

export const createReseller = (
  email: string,
  firstname: string,
  lastname: string,
  company: string,
  api_key: string | undefined = undefined,
  reseller: boolean = false
) => {
  return axios.post(reseller ? RESELLER_CREATE_USER_URL : CREATE_USER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    company,
    api_key,
  });
};

export const createModelAdmin = async (
  name?: string,
  category?: number,
  files?: File[],
  userId?: string,

  frame_width?: number,
  frame_height?: number,
  lens_width?: number,

  // frame_thickness?: number,
  // temples_thickness?: number,
  // frame_transparency?: string,
  // lens_transparency?: string,
  // mirror_lenses?: boolean,
  setProgress?: (progress: number) => void
) => {
  const formData = new FormData();
  formData.append('name', name || '');
  formData.append('category', category + '' || '');
  formData.append('user_id', userId || '');

  formData.append('frame_width', frame_width ? frame_width + '' : '');
  formData.append('frame_height', frame_height ? frame_height + '' : '');
  formData.append('lens_width', lens_width ? lens_width + '' : '');
  // formData.append('frame_thickness', frame_thickness ? frame_thickness + '' : '');
  // formData.append('temples_thickness', temples_thickness ? temples_thickness + '' : '');
  // formData.append('frame_transparency', frame_transparency || '');
  // formData.append('lens_transparency', lens_transparency || '');
  // formData.append('mirror_lenses', mirror_lenses ? mirror_lenses + '' : '');

  if (files)
    files.forEach((file) => {
      formData.append('images', file);
    });
  await axios({
    method: 'POST',
    url: CREATE_MODEL_URL,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getAuth()?.api_token}`,
    },
    onUploadProgress: (progressEvent) => {
      const progress = (progressEvent.loaded / (progressEvent.total || 1)) * 50;

      if (setProgress) setProgress(progress);
    },
  });
};

export const getUsers = () => {
  return axios.get(GET_USERS_URL);
};

export const getUsersByOrg = (orgId: number) => {
  return axios.get(`${GET_USERS_URL}?org_id=${orgId}`);
};

export const updateMaxCreditsForUser = async (userId?: number, maxCredits?: number) => {
  const json = JSON.stringify({
    user_id: userId,
    max_credits: maxCredits,
  });
  const res = await axios.put(UPDATE_MAX_CREDITS_FOR_USER_URL, json, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return res;
};

export const editUser = async (user_id: number, values: any) => {
  const json = JSON.stringify({
    user_id,
    ...values,
  });
  const res = await axios.put(UPDATE_USER_URL, json, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return res;
};

export const getUsersCount = (): Promise<number> => {
  return axios.get(GET_USERS_COUNT_URL).then((d: AxiosResponse<any>) => d.data?.users_count || 0);
};

export const getAllModelsCount = async (): Promise<ModelsCounter> => {
  return axios.get(GET_ALL_MODELS).then((d: AxiosResponse<any>) => {
    const data = d.data;

    const counter = new ModelsCounter();

    if (!data) return counter;

    counter.done = data.done;
    counter.pendingOrNotStarted = data.pending_or_not_started;
    counter.processing = data.processing;
    counter.error = data.error;
    counter.rejected = data.rejected;
    counter.approved = data.approved;
    return counter;
  });
};

export const addExtraCreditForUser = async (usersIds?: number[], extra_credits?: number) => {
  const json = JSON.stringify({
    user_ids: usersIds,
    extra_credits: extra_credits,
  });
  const res = await axios.put(ADD_EXTRA_CREDITS_URL, json, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return res;
};

export const sendBulkEmails = async (usersIds: number[], subject: string, emailBody: string) => {
  const json = JSON.stringify({
    user_ids: usersIds,
    subject: subject,
    emailBody: emailBody,
  });
  const res = await axios.post(SEND_BULK_EMAIL_URL, json, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return res;
};

export const getCurrentAIVersion = async () => {
  const AI_VERSION_KEY = 'ai_version';
  const cached = sessionStorage.getItem(AI_VERSION_KEY);
  if (cached) {
    const cachedData = JSON.parse(cached) as string;
    return new Promise((resolve) => {
      resolve(cachedData);
    });
  }

  return axios.get(GET_AI_VERSION_URL).then((d: AxiosResponse<any>) => {
    const data = d.data;

    sessionStorage.setItem(AI_VERSION_KEY, JSON.stringify(data));

    return data;
  });
};

export const getAllSettings = async () => {
  return axios.get(GET_SETTINGS_URL).then((d: AxiosResponse<Settings>) => d.data);
};

export const updateSettings = async (settings: Settings) => {
  const res = await axios.post(UPDATE_SETTINGS_URL, JSON.stringify(settings), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return res;
};

export const deleteBulkUsers = (ids: number[]) => {
  const json = JSON.stringify({
    ids: ids,
  });
  return axios.delete(`${API_URL}/delete_multiple_accounts`, {
    headers: {
      'Content-Type': 'application/json',
    },
    data: json,
  });
};

export const downloadBulkUsersData = async (ids: number[]) => {
  const json = JSON.stringify({
    users_ids: ids,
  });
  const res = await axios.post(`${API_URL}/models/csv`, json, {
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
  });
  if (res?.data) downloadFile(res.data, 'users_data');

  return res;
};

export const downloadModelsReport = async (from_date: Date, to_date: Date, orgId?: number) => {
  const json = JSON.stringify({
    org_id: orgId,
    from_date: dayjs(from_date).format('YYYY-MM-DD 00:00:00'),
    to_date: dayjs(to_date).format('YYYY-MM-DD 23:59:59'),
  });
  const res = await axios.post(`${API_URL}/models/csv`, json, {
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
  });
  if (res && res?.data) downloadFile(res.data, 'users_data');

  return res;
};

export function changeAccountPassword(
  token: string,
  current_password: string,
  new_password: string
) {
  return axios.post<{result: boolean}>(CHANGE_PASSWORD_URL, {
    current_password,
    new_password,
  });
}
