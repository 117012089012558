import {create} from 'zustand';
import {Organization} from '../../../app/types';

interface IOrgStore {
  org: Organization | undefined;
  setOrg: (user: Organization | undefined) => void;
}

const useOrgStore = create<IOrgStore>((set) => ({
  org: undefined,
  setOrg: (user: Organization | undefined) => set({org: user}),
}));

export default useOrgStore;
