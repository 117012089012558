import React, {ReactNode, useEffect, useState} from 'react';
import {Link, useMatch, useParams} from 'react-router-dom';
import {ErrorPlaceholder} from '../../../../shared/components/ErrorPlaceholder';
import {LoadingPlaceholder} from '../../../../shared/components/LoadingPlaceholder';
import {parseError} from '../../../../shared/utils';
import {useBranding} from '../../app/core/BrandingProvider';
import {useAuth, UserModel} from '../../auth';
import useAccountStore from '../account.store';
import {getUser} from '../api/account-api';
import {KTSVG} from '../../../../_metronic/helpers';
import {AccountHeader} from './AccountHeader';
import {PermissionLevelEnum} from '../../app/types';

interface Props {
  children: ReactNode;
}

const AccountPage = ({children}: Props) => {
  const {currentUser} = useAuth();
  const [id, setId] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const {setUser, user, userLevel, setUserLevel} = useAccountStore();
  const match = useMatch('/account/:id/*');

  useEffect(() => {
    if (match) {
      const {id} = match.params;
      setId(id);
    }
  }, [match]);

  useEffect(() => {
    const get = async () => {
      if (!id) return;
      try {
        setLoading(true);

        const user = await getUser(+id);
        setUser(user);

        setLoading(false);
      } catch (error: any) {
        setError(parseError(error));
        setLoading(false);
      }
    };

    if (id && +id !== currentUser?.id) get();
    else setUser(currentUser);
  }, [id]);

  useEffect(() => {
    const forDiffOrg = user?.org_id !== currentUser?.org_id;
    const forSameOrgDiffUser = !forDiffOrg && user?.id !== currentUser?.id;

    if (forDiffOrg) setUserLevel(PermissionLevelEnum.SYSTEM);
    else if (forSameOrgDiffUser) setUserLevel(PermissionLevelEnum.ORG);
    else setUserLevel(PermissionLevelEnum.USER);
  }, [user]);

  if (loading) return <LoadingPlaceholder label='Loading user...' />;
  if (error) return <ErrorPlaceholder error={error} />;

  return (
    <div>
      <div className='app-container '>
        <AccountHeader />

        {children}
      </div>
    </div>
  );
};

export default AccountPage;
