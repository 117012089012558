import axios from 'axios';
import {AppLocator} from '../../../../app-locator';
import {getAuth} from '../../auth/core/AuthHelpers';
import {ReportingIssue} from '../types';

const API_URL =
  AppLocator.forceUsingRealApi || process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_DEV_API_URL;

const REPORT_ISSUE_URL = `${API_URL}/user/report`;

export const sendIssueReport = async (
  issue: ReportingIssue,
  setProgress?: (progress: number) => void
) => {
  const formData = new FormData();
  formData.append('title', issue.title);
  formData.append('description', issue.description);
  issue.model_pub_id && formData.append('model_pub_id', issue.model_pub_id);

  if (issue.attachments.length > 0)
    issue.attachments.forEach((file) => {
      formData.append('attachments', file);
    });

  await axios({
    method: 'POST',
    url: REPORT_ISSUE_URL,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getAuth()?.api_token}`,
    },
    onUploadProgress: (progressEvent) => {
      const progress = (progressEvent.loaded / (progressEvent.total || 1)) * 50;

      if (setProgress) setProgress(progress);
    },
  });
};

export const sendNotifyMe = (code: string) => {
  return axios.post(`${API_URL}/user/notify-me`, {
    code,
  });
};
