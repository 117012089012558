import React, {ReactNode} from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import {UserRolesEnum} from '../models-management/types';
import {useAuth} from './core/Auth';
import useRealUserRole from '../app/core/useCheckRole';
import {useBranding} from '../app/core/BrandingProvider';
import {usePermissionChecker} from '../admin/pages/roles/hooks/permission-checker';
import {AppLocator} from '../../../app-locator';

interface Props {
  children: ReactNode;
  code: string;
  redirect?: boolean;
  hideForAdmin?: boolean;
  extraCondition?: boolean;
  // checkLevel?: boolean;
  entityLevel?: number;
}
const HasPermission = ({
  children,
  code: permissionCode,
  redirect,
  hideForAdmin,
  extraCondition,
  // checkLevel,
  entityLevel,
}: Props) => {
  const {currentUser} = useAuth();
  const {organization} = useBranding();
  const location = useLocation();
  const {hasPermission, passPermissionLevel} = usePermissionChecker();

  if (currentUser?.is_super_admin && hideForAdmin) return <></>;

  const allowed = hasPermission(permissionCode);
  if (!allowed) return <></>;

  if (extraCondition !== undefined && extraCondition === false) return <></>;

  if (AppLocator.showPermissionCodes)
    return (
      <div className='relative'>
        <div className='absolute opacity-75 top-0 left-0' title={permissionCode}>
          <i className='fa fa-shield text-warning fs-7'></i>
        </div>
        {children}
      </div>
    );

  if (currentUser?.is_super_admin) return <>{children}</>;

  if (entityLevel !== undefined) {
    const levelPassed = passPermissionLevel(permissionCode, entityLevel);
    if (!levelPassed) return <></>;
  }

  return <>{children}</>;
};

export default HasPermission;
