import {PageTitle} from '../../../../../_metronic/layout/core';
import LoginSessionTable from '../overview/components/LoginSessionTable';
import LoginSessionsWidget from '../overview/components/LoginSessionsWidget';

const LoginSessionsPage = () => {
  return (
    <div>
      <PageTitle>Login Sessions</PageTitle>
      <div>
        <div className='container-fluid'>
          <LoginSessionsWidget />

          <LoginSessionTable />
        </div>
      </div>
    </div>
  );
};

export default LoginSessionsPage;
