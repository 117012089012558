/* eslint-disable @typescript-eslint/no-unused-vars */
import {UserModel} from '../auth';

export const PasswordRegEx = /^(?=.*[a-z])(?=.*\d).{6,50}$/;
export interface ReportingIssue {
  title: string;
  description: string;
  attachments: File[];
  model_pub_id?: string;
}

export interface Organization {
  id?: number;
  name?: string;
  domain?: string;
  users_count?: number;
  // companyId?: number;
  logo?: string;
  dark_logo?: string;
  favicon?: string;
  light_logo?: string;

  window_title?: string;
  footer_text?: string;
  about_link?: string;
  terms_link?: string;
  contact_link?: string;
  privacy_link?: string;
  welcome_text?: string;
  done_button_color?: string;
  permissions?: Permission[];
  format_name?: string;
  has_custom_format?: boolean;
}

export interface Permission {
  code: string;
  name: string;
  group: string;
  description?: string;
  changeset?: 'add' | 'delete';
  selected: boolean;
  has_level?: boolean;
  level?: number;
}

export interface Role {
  id: number;
  name: string;
  desc?: string;
  org_id: number;
  time_created: Date;
  createdByUserId?: number; // optional
  createdByUser?: UserModel; // optional
  user_name: string;
  permissions: Permission[];
}

export enum PermissionLevelEnum {
  USER = 0,
  ORG,
  SYSTEM,
}
