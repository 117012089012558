import {ConfirmDialog} from 'primereact/confirmdialog';
import {Suspense} from 'react';
import {ToastContainer} from 'react-bootstrap';
import {Outlet} from 'react-router-dom';
import {I18nProvider} from '../_metronic/i18n/i18nProvider';
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core';
import {MasterInit} from '../_metronic/layout/MasterInit';
import {BrandingProvider} from './modules/app/core/BrandingProvider';
import {AuthInit} from './modules/auth';
import 'primereact/resources/themes/lara-light-blue/theme.css';

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <BrandingProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
              <ConfirmDialog />
            </AuthInit>
          </BrandingProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  );
};

export {App};
