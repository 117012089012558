import clsx from 'clsx';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {sleep} from '../../../../../../shared/utils';
import {useAuth} from '../../../../auth';
import useAccountStore from '../../../account.store';
import {deleteUser} from '../../../api/account-api';

const Deactivate = () => {
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const {currentUser, logout} = useAuth();
  const {user} = useAccountStore();
  const navigate = useNavigate();

  const submit = async () => {
    if (!user?.id) return;

    setLoading(true);

    try {
      if (user.id === currentUser?.id) {
        await deleteUser();
      } else {
        await deleteUser(user?.id);
      }

      toast.success('Account deleted successfully');
      setLoading(false);

      await sleep(1500); // do not delete, its delay to let user read the message before redirect ;)

      if (user.id === currentUser?.id) logout();
      else navigate('/admin/users');
    } catch (error: any) {
      console.error(error);

      setLoading(false);

      toast.error('Unable to delete, please contact support');
    }
  };

  return (
    <div className='card'>
      <header className='card-header border-0 cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bold m-0'>Deactivate Account</h3>
        </div>
      </header>

      <section>
        <form className='form fv-plugins-bootstrap5 fv-plugins-framework'>
          <div className='card-body border-top p-9'>
            <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6'>
              <i className='fa fa-exclamation-triangle fs-2tx text-warning me-4'></i>

              <div className='d-flex flex-stack flex-grow-1 '>
                <div className=' fw-semibold'>
                  <h4 className='text-gray-900 fw-bold'>You Are Deactivating Your Account</h4>
                  <div className='fs-6 text-gray-700 '>
                    By clicking the 'Deactivate Account' button, you will be initiating the process
                    of permanently deactivating your account on this platform. Please be aware that
                    this action is irreversible. It will result in the complete erasure of all your
                    account data, including uploaded images, generated 3D models, and any other
                    associated information from our system. Once this action has been completed, it
                    will not be possible to recover or restore your data.
                    <br />
                    {/* <a className='fw-bold'>Learn more</a> */}
                  </div>
                </div>
              </div>
            </div>
            <div className='form-check form-check-solid fv-row fv-plugins-icon-container'>
              <input
                id='deactivate'
                name='deactivate'
                className='form-check-input'
                type='checkbox'
                onChange={(e) => setConfirm(!confirm)}
              />
              <label className='form-check-label fw-semibold ps-2 fs-6' htmlFor='deactivate'>
                I confirm my account deactivation
              </label>
              <div className='fv-plugins-message-container invalid-feedback' />
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button
              type='reset'
              className={clsx('btn btn-danger fw-semibold ', {
                disabled: !confirm,
              })}
              onClick={submit}
            >
              {!loading && <span>Deactivate Account</span>}
              {loading && (
                <span>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default Deactivate;
