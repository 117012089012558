import Deactivate from './components/Deactivate';
import ChangePassword from './components/ChangePassword';
import {useAuth} from '../../../auth';
import useAccountStore from '../../account.store';
import Authorize from '../../../auth/Authorize';
import useRealUserRole from '../../../app/core/useCheckRole';
import HasPermission from '../../../auth/HasPermission';
import {useEffect, useState} from 'react';
import {PermissionLevelEnum} from '../../../app/types';
import {usePermissionChecker} from '../../../admin/pages/roles/hooks/permission-checker';

const AccountSettings = () => {
  const {currentUser} = useAuth();
  const {user, userLevel} = useAccountStore();
  const isLoggedUser = currentUser?.id === user?.id;

  return (
    <div>
      {isLoggedUser && <ChangePassword />}

      <HasPermission code='users.delete-user-account' entityLevel={userLevel}>
        <Deactivate />
      </HasPermission>
    </div>
  );
};

export default AccountSettings;
