import React, {useEffect, useState} from 'react';
import useMediaWizardStore from './media-wizard.store';
import {MediaWizardHeader} from './components/MediaWizardHeader';
import MediaStartStep from './components/steps/MediaStartStep';
import MediaDesignStep from './components/steps/MediaDesignStep';
import useCanvasEditorStore from '../../../canvas-editor/CanvasEditor.store';
import SceneSelectionStep from './components/steps/SceneSelectionStep';
import {useParams, useSearchParams} from 'react-router-dom';
import {getSingleMedia} from '../../media.api';
import {fetchImageAsFile, parseError} from '../../../../../shared/utils';
import {LoadingPlaceholder} from '../../../../../shared/components/LoadingPlaceholder';
import MediaFinalStep from './components/steps/MediaFinalStep';
import {Media, MediaStatusEnum} from '../../media.types';
import DynamicsStep from './components/steps/DynamicsStep';

const NewMediaWizard = () => {
  const {currentStep, setCurrentStep, reset, updateMedia, media, setMedia, setSelectedScene} =
    useMediaWizardStore();
  const {reset: resetCanvas} = useCanvasEditorStore();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const {id: pubId} = useParams<{id: string}>();

  const get = async () => {
    if (!pubId) return;

    try {
      setLoading(true);

      const res = await getSingleMedia(pubId);
      const video = res.video[0];
      setMedia(video);

      fetchExistingMediaImages(video);

      if (video.status === MediaStatusEnum.PROCESSING || video.status === MediaStatusEnum.COMPLETED)
        setCurrentStep(5);
      else setCurrentStep(3);

      setLoading(false);
    } catch (error) {
      setError(`Error while loading, (details: ${parseError(error)}`);
    }
  };

  async function fetchExistingMediaImages(media: Media) {
    if (!media.cleared_product_image_url || !media.original_product_image_url) return;

    const clearedImage = await fetchImageAsFile(media.cleared_product_image_url, 'image');
    const originalImage = await fetchImageAsFile(media.original_product_image_url, 'image');

    updateMedia({
      transparentProductImage: clearedImage,
      productImage: originalImage,
    });
  }

  useEffect(() => {
    get();
  }, [pubId]);

  useEffect(() => {
    return () => {
      reset();
      resetCanvas();
      setSelectedScene(undefined);
    };
  }, []);

  if (loading) return <LoadingPlaceholder />;

  return (
    <div>
      <div className='card'>
        <div className='card-body'>
          <div className='stepper stepper-links bg-body d-flex flex-column pt-15'>
            <MediaWizardHeader />

            {currentStep === 1 && <MediaStartStep />}
            {currentStep === 2 && <MediaDesignStep />}
            {currentStep === 3 && <SceneSelectionStep />}
            {currentStep === 4 && <DynamicsStep />}
            {currentStep === 5 && <MediaFinalStep />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewMediaWizard;
