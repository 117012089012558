import {Html, useGLTF} from '@react-three/drei';
import {useFrame} from '@react-three/fiber';
import React, {useRef, useState} from 'react';
import * as THREE from 'three';
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers';
import useViewerStore from '../viewer-store';
import {ViewerPortal} from './ViewerPortal';
import {XYZEditor} from './XYZEditor';
import {usePoint3D} from '../../../../shared/hooks/usePoint3D';

const SHOW_CONTROLS = false;

export const Occluder = ({path}: any) => {
  const gltf = useGLTF(toAbsoluteUrl(path));
  const {config, showOccluder} = useViewerStore();
  const group = useRef<any>();

  const [rotation, updateRotation] = usePoint3D(config.occluderRotation);
  const [position, updatePosition] = usePoint3D(config.occluderPosition);
  const [scale, updateScale] = usePoint3D(config.occluderScale);

  return (
    <>
      {SHOW_CONTROLS && (
        <ViewerPortal>
          <div className='left-0 right-0 w-100 bg-black bg-opacity-25 p-3 rounded text-black'>
            state: {showOccluder ? 'visible' : 'hidden'}
            <XYZEditor name='Position' objRotation={position} setObjRotation={updatePosition} />
            <XYZEditor name='Rotation' objRotation={rotation} setObjRotation={updateRotation} />
            <XYZEditor name='Scale' objRotation={scale} setObjRotation={updateScale} />
          </div>
        </ViewerPortal>
      )}

      <primitive
        name='occluder-obj'
        visible={showOccluder}
        ref={group}
        object={gltf.scene}
        position={[position?.x || 0, position?.y || 0, position?.z || 0]}
        rotation={[rotation.x || 0, rotation?.y || 0, rotation?.z || 0]}
        scale={[scale.x || 0, scale?.y || 0, scale?.z || 0]}
      />
    </>
  );
};
