import axios, {AxiosResponse} from 'axios';
import {AppLocator} from '../../../../app-locator';
import {Organization} from '../../app/types';
import {NewOrgProps} from '../pages/organization/components/NewOrg';

const API_URL = process.env.REACT_APP_API_URL;

export const getAllOrganizations = () => {
  return axios.get(`${API_URL}/orgs`).then((d: AxiosResponse<Organization[]>) => d.data);
};

export const getOrganizationByDomain = (domain: string): Promise<Organization> => {
  return axios
    .get(`${API_URL}/pub_orgs?org_domain=${domain}`)
    .then((d: AxiosResponse<Organization>) => d.data);
};

export const getOrganizationById = (id: number): Promise<Organization> => {
  return axios
    .get(`${API_URL}/orgs?org_id=${id}`)
    .then((d: AxiosResponse<Organization[]>) => d.data[0]);
};

export const updateOrganization = async (org: Organization) => {
  const json = JSON.stringify({
    dark_logo: org.dark_logo,
    about_link: org.about_link,
    terms_link: org.terms_link,
    contact_link: org.contact_link,
    privacy_link: org.privacy_link,
    welcome_text: org.welcome_text,
    done_button_color: org.done_button_color,
    footer_text: org.footer_text,
    light_logo: org.light_logo,
    favicon: org.favicon,
    window_title: org.window_title,
    users_count: org.users_count,
    name: org.name,
    domain: org.domain,
    format_name: org.format_name,
    permissions: org.permissions?.map((d) => {
      return {code: d.code, level: d.level || ''};
    }),
  });
  const res = await axios.put(`${API_URL}/admin/update_org/${org.id}`, json, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return res;
};

export const insertOrg = async (newOrg: NewOrgProps): Promise<number | undefined> => {
  const json = JSON.stringify(newOrg);
  const res = await axios.post<any>(`${API_URL}/admin/create_org`, json, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return res.data?.organization.id;
};
