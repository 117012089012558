export interface ModelEditorProps {
  lenses: {
    color: string;
    transparency: number;
  };
  frame: {
    tex: string;
    color: string;
    metallic: number;
    roughness: number;
    transparency: number;
  };
  temple: {
    tex: string;
    color: string;
    metallic: number;
    roughness: number;
    transparency: number;
  };
}

export type AnyObject = {[key: string]: any};

export interface ModelEditorTabs {
  index: number;
  tabName: string;
  title: string;
  meshNode: string[];
  components: ModelEditorComponents[];
}
export interface ModelEditorComponents {
  type: 'color' | 'texture' | 'range' | 'header';
  title: string;
  fieldName: string;
  options?: any[];
}

export const modelEditTabs: ModelEditorTabs[] = [
  {
    index: 0,
    title: 'Temple',
    tabName: 'temple',
    meshNode: ['left_temple', 'right_temple'],
    components: [
      {
        title: 'Texture',
        type: 'texture',
        fieldName: 'tex',
        options: [],
      },
      {
        title: 'Color',
        type: 'color',
        fieldName: 'color',
        options: ['#ffffff', '#D5BC93', '#AC252B', '#185848', '#025D98'],
      },
      {
        type: 'header',
        title: 'Material Properties',
        fieldName: '',
      },
      {fieldName: 'metallic', title: 'metallic', type: 'range'},
      {fieldName: 'roughness', title: 'roughness', type: 'range'},
      {fieldName: 'transparency', title: 'transparency', type: 'range'},
    ],
  },
  {
    index: 1,
    title: 'Frame',
    tabName: 'frame',
    meshNode: ['frame'],
    components: [
      {
        title: 'Texture',
        type: 'texture',
        fieldName: 'tex',
        options: [],
      },
      {
        title: 'Color',
        type: 'color',
        fieldName: 'color',
        options: ['#ffffff', '#D5BC93', '#AC252B', '#185848', '#025D98'],
      },
      {
        type: 'header',
        title: 'Material Properties',
        fieldName: '',
      },
      {fieldName: 'metallic', title: 'metallic', type: 'range'},
      {fieldName: 'roughness', title: 'roughness', type: 'range'},
      {fieldName: 'transparency', title: 'transparency', type: 'range'},
    ],
  },
  {
    index: 2,
    title: 'Lenses',
    tabName: 'lenses',
    meshNode: ['left_lens', 'right_lens'],
    components: [
      {
        title: 'Color',
        type: 'color',
        fieldName: 'color',
        options: ['#ffffff', '#D5BC93', '#AC252B', '#185848', '#025D98'],
      },
      {
        type: 'header',
        title: 'Material Properties',
        fieldName: '',
      },
      {fieldName: 'transparency', title: 'transparency', type: 'range'},
    ],
  },
];
