import {Dropdown} from 'primereact/dropdown';
import useUsersStore from '../users.store';
import {useState, useEffect} from 'react';
import {getAllOrganizations} from '../../../api/organizations-api';
import {Organization, PermissionLevelEnum} from '../../../../app/types';
import Authorize from '../../../../auth/Authorize';
import {UserRolesEnum} from '../../../../models-management/types';
import {useAuth} from '../../../../auth';
import {usePermissionChecker} from '../../roles/hooks/permission-checker';

export const UsersFilters = () => {
  const {setUsers, originalUsers, setSearch, searchText, orgFilter, setOrgFilter, orgs, setOrgs} =
    useUsersStore();
  const {currentUser} = useAuth();
  const {hasPermission} = usePermissionChecker();

  useEffect(() => {
    try {
      const getOrgs = async () => {
        const orgs = await getAllOrganizations();
        setOrgs([
          {
            name: 'All Organizations',
            id: undefined,
          },
          ...orgs,
        ]);
      };

      getOrgs();
    } catch (error: any) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    let filteredUsers = [...originalUsers];

    if (orgFilter !== undefined)
      filteredUsers = filteredUsers.filter((d) => d.org_id === orgFilter);

    if (searchText)
      filteredUsers = filteredUsers.filter((d) =>
        (d.first_name + d.last_name + d.email + d.api_key)
          .toLocaleLowerCase()
          .includes(searchText.toLocaleLowerCase())
      );

    setUsers(filteredUsers);
  }, [orgFilter, searchText, originalUsers]);

  return (
    <>
      <div className='d-flex align-items-center position-relative my-1'>
        <span className='svg-icon svg-icon-3 position-absolute ms-3'>
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <rect
              opacity='0.5'
              x='17.0365'
              y='15.1223'
              width='8.15546'
              height='2'
              rx='1'
              transform='rotate(45 17.0365 15.1223)'
              fill='currentColor'
            ></rect>
            <path
              d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
              fill='currentColor'
            ></path>
          </svg>
        </span>
        <input
          type='text'
          value={searchText}
          onChange={(e) => setSearch(e.target.value)}
          className='form-control form-control-sm border-body bg-body w-150px ps-10'
          placeholder='Search'
        />
      </div>
      <div>
        {(currentUser?.is_super_admin ||
          hasPermission('menu.users', PermissionLevelEnum.SYSTEM)) && (
          <Dropdown
            className='w-250px ms-5 border-0'
            value={orgFilter}
            onChange={(e: any) => setOrgFilter(e.value)}
            options={orgs}
            optionLabel='name'
            optionValue='id'
            placeholder={orgFilter === null ? 'No Organization' : 'Filter by organization'}
            showClear
          />
        )}
      </div>
    </>
  );
};
