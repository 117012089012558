import axios from 'axios';
import {AppLocator} from '../../../app-locator';

const API_URL =
  AppLocator.forceUsingRealApi || process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_DEV_API_URL;

const paymentFieldTokenURL = `https://sandbox.bluesnap.com/services/2/payment-fields-tokens`;
const bluesnapApiToken = 'Basic QVBJXzE3MTQ0NjY1ODc3MzUxNTk5MzI1MTE0OnI5V0kzRTQjZDZDMA==';

export const getPFToken = () => {
  return axios.get<any>(`${API_URL}/get_payment_token`).then((d) => d.data?.token);
};
export const createTransaction = (data: any) => {
  return axios.post(`${API_URL}/process_transaction`, data).then((d) => d.data);
};

// bluesnap server api - invalid in FE
export const submitBSTransaction = (data: any) => {
  const options: any = {
    method: 'POST',
    url: 'https://sandbox.bluesnap.com/services/2/transactions',
    headers: {
      'content-type': 'application/json',
      accept: 'application/json',
      'bluesnap-version': '3.0',
      Authorization: bluesnapApiToken,
    },
    data,
  };

  return axios
    .create()
    .request(options)
    .then(function (response) {
      console.log(response.data);
    })
    .catch(function (error) {
      console.error(error);
    });
};

// bluesnap server api - invalid in FE
export const createHostedFieldsToken = async () => {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: bluesnapApiToken,
    'Access-Control-Expose-Headers': 'Location',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Authorization, Location',
  };

  try {
    const response = await fetch(paymentFieldTokenURL, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({}), // or null if you want to send an empty body
    });

    if (response.status !== 201) {
      throw new Error('Network response was not created');
    }

    const location = response.headers.get('Location');
    console.log('pf token', location);

    return location;
  } catch (error) {
    console.error('Error:', error);
  }
};

export interface CardSubmitResponse {
  cardData: {
    binCategory: string;
    ccBin: string;
    cardSubType: string;
    ccType: string;
    isRegulatedCard: string;
    issuingCountry: string;
    last4Digits: string;
    cardCategory: string;
    ccIssuingBank: string;
    exp: string;
  };
  statusCode: string;
  transactionFraudInfo: {
    fraudSessionId: string;
  };
  error?: any;
}
