/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import React, {useEffect, useState} from 'react';

import BlockUi from 'react-block-ui';
import {UserModel} from '../../../../auth';
import dayjs from 'dayjs';
import {confirmDialog} from 'primereact/confirmdialog';
import {Dialog} from 'primereact/dialog';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import {deleteDialogProps} from '../../../../shared/types';
import {getUsers, getUsersByOrg} from '../../../api/users-api';
import useOrgStore from '../organization.store';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import NewUser from '../../users/components/NewUser';
import {deleteUser} from '../../../../account/api/account-api';
import HasPermission from '../../../../auth/HasPermission';
dayjs.extend(relativeTime as any);

interface Props {
  orgId: number;
}

export function OrgUsersTab({orgId}: Props) {
  const [showNewWindow, setShowNewWindow] = useState(false);
  const [newRoleText, setNewRoleText] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [users, setUsers] = useState<UserModel[]>([]);
  const [blockMessage, setBlockMessage] = useState('');
  const {org, setOrg} = useOrgStore();
  const [newAdmin, setNewAdmin] = useState(false);

  const get = async () => {
    try {
      const _users = await getUsersByOrg(orgId);
      setUsers(_users.data.users || []);
    } catch (error: any) {
      toast.error('Failed to get org users, check browser console');
      console.error(error);
    }
  };

  useEffect(() => {
    if (orgId === undefined) return;
    get();
  }, [orgId]);

  return (
    <div className='d-flex flex-column flex-column-fluid'>
      <BlockUi tag='div' blocking={!!blockMessage} message={blockMessage} keepInView>
        <div className={clsx()}>
          <div className='card pt-4 mb-6 mb-xl-9'>
            <div className='card-header border-0'>
              <div className='card-title'>
                <h2>Users</h2>
              </div>
              <div className='card-toolbar'>
                <button
                  type='button'
                  className='btn btn-sm btn-flex btn-light-primary'
                  onClick={() => {
                    setNewAdmin(false);
                    setShowNewWindow(true);
                  }}
                >
                  <i className='ki-duotone ki-plus-square fs-3'>
                    <span className='path1' />
                    <span className='path2' />
                    <span className='path3' />
                  </i>
                  Add User
                </button>

                <button
                  type='button'
                  className='btn btn-sm btn-flex btn-light-primary ms-2'
                  onClick={() => {
                    setNewAdmin(true);
                    setShowNewWindow(true);
                  }}
                >
                  <i className='ki-duotone ki-plus-square fs-3'>
                    <span className='path1' />
                    <span className='path2' />
                    <span className='path3' />
                  </i>
                  Add Admin
                </button>
              </div>
            </div>
            <div className='card-body pt-0 pb-5'>
              <div
                id='kt_table_customers_payment_wrapper'
                className='dataTables_wrapper dt-bootstrap4 no-footer'
              >
                <div className='table-responsive'>
                  <table
                    className='table align-middle table-row-dashed gy-5 dataTable no-footer'
                    id='kt_table_customers_payment'
                  >
                    <thead className='border-bottom border-gray-200 fs-7 fw-bold'>
                      <tr className='text-start text-muted text-uppercase gs-0'>
                        <th
                          className='min-w-100px sorting'
                          tabIndex={0}
                          rowSpan={1}
                          colSpan={1}
                          style={{
                            width: '138.875px',
                          }}
                        >
                          User Name
                        </th>

                        <th
                          className='min-w-100px sorting'
                          tabIndex={0}
                          rowSpan={1}
                          colSpan={1}
                          style={{
                            width: '231.328px',
                          }}
                        >
                          Date Created
                        </th>
                        <th
                          className='min-w-100px sorting'
                          tabIndex={0}
                          rowSpan={1}
                          colSpan={1}
                          style={{
                            width: '231.328px',
                          }}
                        >
                          Role
                        </th>
                        <th
                          className='text-end min-w-100px pe-4 sorting_disabled'
                          rowSpan={1}
                          colSpan={1}
                          style={{
                            width: '147.75px',
                          }}
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className='fs-6 fw-semibold text-gray-600'>
                      {users.map((user) => (
                        <tr className='odd' key={user.id}>
                          <td>
                            <Link
                              title={user.email}
                              to={`/account/${user.id}`}
                              className='text-dark text-hover-primary mb-1'
                            >
                              {user.first_name + ' ' + user.last_name}
                            </Link>
                          </td>

                          <td>{dayjs(user.created_date).fromNow()}</td>

                          <td>
                            {!!user.is_org_admin && (
                              <div className='badge badge-success'>Admin</div>
                            )}
                            {!!!user.is_org_admin && (
                              <div className=''>{user.role_name || '-no role-'}</div>
                            )}
                          </td>

                          <td className='pe-0 text-end'>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              <Link
                                to={`/account/${user.id}`}
                                title='Edit Account'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              >
                                <i className='ki-duotone ki-pencil fs-3'>
                                  <span className='path1' />
                                  <span className='path2' />
                                  <span className='path3' />
                                  <span className='path4' />
                                  <span className='path5' />
                                </i>
                              </Link>

                              <HasPermission code='account.deactivate-account'>
                                <a
                                  title='Delete user'
                                  onClick={() => {
                                    confirmDialog({
                                      ...deleteDialogProps,
                                      message: `Do you want to delete this user (${user.first_name})?`,
                                      accept: () => {
                                        try {
                                          deleteUser(user?.id);
                                          toast.success('Account deleted successfully');
                                        } catch (error) {
                                          toast.success(
                                            'Failed to delete, please contact app support'
                                          );
                                        }
                                      },
                                      reject: () => {},
                                    });
                                  }}
                                  className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                                >
                                  <i className='ki-duotone ki-trash fs-2'>
                                    <span className='path1' />
                                    <span className='path2' />
                                    <span className='path3' />
                                    <span className='path4' />
                                    <span className='path5' />
                                  </i>
                                </a>
                              </HasPermission>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BlockUi>

      <Dialog
        modal
        dismissableMask
        closable={false}
        visible={showNewWindow}
        onHide={() => setShowNewWindow(false)}
      >
        <NewUser
          org_id={org?.id}
          isAdmin={newAdmin}
          submitComplete={() => {
            setShowNewWindow(false);
            setNewAdmin(false);
            get();
          }}
        />
      </Dialog>
    </div>
  );
}
