/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react';
import useAccountStore from '../../../account.store';
import {Dialog} from 'primereact/dialog';
import EditUser from '../../../../admin/pages/users/components/EditUser';
import HasPermission from '../../../../auth/HasPermission';
import {usePermissionChecker} from '../../../../admin/pages/roles/hooks/permission-checker';
import {useAuth} from '../../../../auth';
import {getOrganizationById} from '../../../../admin/api/organizations-api';
import {PermissionLevelEnum} from '../../../../app/types';
import dayjs from 'dayjs';

const Info = () => {
  const {user, setUser} = useAccountStore();
  const [showEditUserWindow, setShowEditUserWindow] = useState(false);
  const {currentUser} = useAuth();
  const {hasPermission} = usePermissionChecker();
  const [userLevel, setUserLevel] = useState(PermissionLevelEnum.USER);

  useEffect(() => {
    const forDiffOrg = user?.org_id !== currentUser?.org_id;
    const forSameOrgDiffUser = !forDiffOrg && user?.id !== currentUser?.id;

    if (forDiffOrg) setUserLevel(PermissionLevelEnum.SYSTEM);
    else if (forSameOrgDiffUser) setUserLevel(PermissionLevelEnum.ORG);
    else setUserLevel(PermissionLevelEnum.USER);
  }, [user]);

  return (
    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
      <header className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bold m-0'>Profile Details</h3>
        </div>

        {(hasPermission('users.edit-user', userLevel) || user?.id === currentUser?.id) && (
          <a
            className='btn btn-sm btn-primary align-self-center'
            onClick={() => setShowEditUserWindow(true)}
          >
            Edit Profile
          </a>
        )}
      </header>

      <section className='card-body p-9'>
        <div className='row mb-7'>
          <label className='col-lg-4 fw-semibold text-muted'>Full Name</label>

          <div className='col-lg-8'>
            <span className='fw-bold fs-6 text-gray-800 capitalized'>
              {user?.first_name} {user?.last_name}
            </span>
          </div>
        </div>

        {(!!currentUser?.is_super_admin || currentUser?.org_id === 0) && (
          <div className='row mb-7'>
            <label className='col-lg-4 fw-semibold text-muted'>Organization</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-semibold text-gray-800 fs-6'>
                {user?.org?.name || '-Not Provided-'}
              </span>
            </div>
          </div>
        )}

        <div className='row mb-7'>
          <label className='col-lg-4 fw-semibold text-muted'>Company</label>

          <div className='col-lg-8 fv-row'>
            <span className='fw-semibold text-gray-800 fs-6'>
              {user?.company || '-Not Provided-'}
            </span>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-semibold text-muted'>Email</label>

          <div className='col-lg-8 d-flex align-items-center'>
            <span className='fw-bold fs-6 text-gray-800 me-2'>{user?.email}</span>
            {!!user?.confirmed && <span className='badge badge-success'>Verified</span>}
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-semibold text-muted'>Created Date</label>

          <div className='col-lg-8 d-flex align-items-center'>
            <span className='fw-semibold fs-6 text-gray-800 me-2'>
              {dayjs(user?.created_date).format('DD MMMM, YYYY - H:mm')}
            </span>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-semibold text-muted'>Job Title</label>

          <div className='col-lg-8 d-flex align-items-center'>
            <span className='fw-semibold fs-6 text-gray-800 me-2'>
              {user?.job_title || '-Not Provided-'}
            </span>
          </div>
        </div>
      </section>

      <Dialog
        modal
        dismissableMask
        closable={false}
        visible={showEditUserWindow}
        onHide={() => setShowEditUserWindow(false)}
      >
        <EditUser
          openedFromAccountPage
          submitComplete={(user) => {
            setShowEditUserWindow(false);

            if (!user) return;

            setUser(user);
          }}
          user={user}
        />
      </Dialog>
    </div>
  );
};

export default Info;
