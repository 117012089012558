import {useThree, useFrame, useLoader} from '@react-three/fiber';
import React, {useEffect, useState} from 'react';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';
import {Environment} from '@react-three/drei';
import * as THREE from 'three';

import {toAbsoluteUrl} from '../../../../_metronic/helpers';
import {
  ACESFilmicToneMapping,
  BoxGeometry,
  CylinderGeometry,
  Mesh,
  MeshNormalMaterial,
  Object3D,
  Vector3,
} from 'three';

import {Model, ARModelPageParameters} from '../../models-management/types.js';
import WebARRocksFeetThreeHelper from '../../../../WebRocksFeet/WebARRocksFeetThreeHelper.js';

import NN from '../../../../WebRocksFeet/neuralNets/NN_FEET_5.json';

import './foot-ar.css';
import {getModelFile} from '../../../../shared/utils';
import {useAuth} from '../../auth';
import {LoadingPlaceholder} from '../../../../shared/components/LoadingPlaceholder';

const _settings = {
  threshold: 0.3, // detection sensitivity, between 0 and 1

  // neural network version:
  //NN: 'NN_BAREFEET_2.json',
  NN: 'media/NN_FEET_5.json', // best: 5

  // CONVERSES SHOES:
  // 3D models:
  shoeRightPath: 'media/shoes.glb',
  isModelLightMapped: false,
  occluderPath: '/media/webrocks-feet/occluder.glb',

  // pose settings:
  scale: 1.1,
  translation: [0, -0.02, 0], // Z -> verical, Y+ -> front way

  /*
    // BALLERINA SHOES:  
    // 3D models:
    shoeRightPath: 'assets/ballerinaShoe.glb',
    isModelLightMapped: false,
    occluderPath: 'assets/occluder.glb',
  
    // pose settings:
    scale: 1.2,
    translation: [0, 0.01, -0.02], // Z -> verical
    //*/

  // debug flags:
  debugCube: false, // Add a cube
  debugDisplayLandmarks: false,
};

const _three = {
  loadingManager: null,
};

const _states = {
  notLoaded: -1,
  loading: 0,
  running: 1,
  busy: 2,
};
let _state = _states.notLoaded;

function setup_fullScreen(cv: any) {
  cv.width = window.innerWidth;
  cv.height = window.innerHeight;
}

interface Props {
  model?: any;
  parameters: ARModelPageParameters;
}

export const FootARApp: React.FC<Props> = ({model, parameters}) => {
  let GLTFModel: any;
  const [xyz, setXyz] = useState({x: 0, y: 0, z: 0});

  const gltf: any = useLoader(GLTFLoader, model);

  // const gltf: any = useLoader(GLTFLoader, `../../media/webrocks-feet/converseShoe2.glb`);
  //   const gltf: any = useLoader(GLTFLoader, `../../media/webrocks-feet/shoes-s.glb`);
  // const gltf: any = useLoader(GLTFLoader, `../../media/webrocks-feet/occluder.glb`);
  const occluderGltf: any = useLoader(GLTFLoader, `../../media/webrocks-feet/occluder.glb`);

  function start(three: any) {
    three.loadingManager.onLoad = function () {
      console.log('INFO in main.js: Everything is loaded');
      _state = _states.running;
    };

    // set tonemapping:
    three.renderer.toneMapping = THREE.ACESFilmicToneMapping;
    three.renderer.outputEncoding = THREE.sRGBEncoding;

    // set lighting:
    if (!_settings.isModelLightMapped) {
      const pointLight = new THREE.PointLight(0xffffff, 2);
      const ambientLight = new THREE.AmbientLight(0xffffff, 0.8);
      three.scene.add(pointLight, ambientLight);
    }

    // add a debug cube:
    if (_settings.debugCube) {
      const s = 1;
      const cubeGeom = new BoxGeometry(s, s, s);
      const cubeMesh = new Mesh(cubeGeom, new THREE.MeshNormalMaterial());
      WebARRocksFeetThreeHelper.add_threeObject(cubeMesh);
    }

    function transform(object: any) {
      // Rotate 90 degrees around the Y-axis
      object.rotation.y += Math.PI; // 180 degrees in radians

      // Rotate 180 degrees around the Z-axis
      object.rotation.z += Math.PI / 2; // 90 degrees in radians

      // Scale by 1.5
      object.scale.multiplyScalar(1.5);

      // Translate position
      object.position.set(
        object.position.x + -0.0,
        object.position.y + 0.074,
        object.position.z + 0.1
      );
    }

    // load the shoe 3D model:
    const shoe = gltf.scene;
    transform(shoe);

    // shoe.scale.multiplyScalar(_settings.scale);
    // shoe.position.add(new THREE.Vector3().fromArray(_settings.translation));

    WebARRocksFeetThreeHelper.add_threeObject(shoe);

    // load occluder
    const occluder = occluderGltf.scene.children[0];
    occluder.scale.multiplyScalar(_settings.scale);
    occluder.position.add(new THREE.Vector3().fromArray(_settings.translation));
    WebARRocksFeetThreeHelper.add_threeOccluder(occluder);

    // fix a weird bug on IOS
    if (check_isAppleCrap())
      WebARRocksFeetThreeHelper.resize(window.innerWidth, window.innerHeight - 0.001);
  } //end start()

  // return true if IOS:
  function check_isAppleCrap() {
    return (
      /iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
    );
  }

  const init = () => {
    _state = _states.loading;

    const feetTrackerCanvas = document.getElementById('feetTrackerCanvas');
    const VTOCanvas = document.getElementById('ARCanvas');

    setup_fullScreen(feetTrackerCanvas);
    setup_fullScreen(VTOCanvas);

    WebARRocksFeetThreeHelper.init({
      cameraZoom: 1,
      threshold: _settings.threshold,
      VTOCanvas: VTOCanvas,
      feetTrackerCanvas: feetTrackerCanvas,
      NNPath: '../../' + _settings.NN,
      debugDisplayLandmarks: _settings.debugDisplayLandmarks,
    })
      .then(start)
      .catch(function (err) {
        console.log('INFO in main.js: an error happens ', err);
      });
  };

  useEffect(() => {
    if (!gltf || !occluderGltf) return;

    init();
  }, [gltf, occluderGltf]);

  return (
    <div>
      <div id='canvases'>
        <canvas id='feetTrackerCanvas'></canvas>

        <canvas id='ARCanvas'></canvas>
      </div>
    </div>
  );
};

export interface WrapperProps {
  model_path: string;
  parameters: ARModelPageParameters;
}
export const FootARAppWrapper = ({model, parameters}: Props) => {
  const [loading, setLoading] = useState(false);
  const auth = useAuth();
  const [glbFile, setGlbFile] = useState<any>();
  const model_path = model?.location;

  useEffect(() => {
    if (!model_path) return;

    setLoading(true);
    getModelFile(model_path, auth.auth?.api_token).then((updatedGlpFile) => {
      setGlbFile(updatedGlpFile);
      setLoading(false);
    });
  }, [model_path]);

  if (loading || !glbFile) return <LoadingPlaceholder />;
  return <FootARApp model={glbFile} parameters={parameters} />;
};
