/* eslint-disable jsx-a11y/anchor-is-valid */
import dayjs from 'dayjs';
import {Dialog} from 'primereact/dialog';
import {FC, useEffect, useState} from 'react';
import {PageTitle} from '../../../../../_metronic/layout/core';
import {LoadingPlaceholder} from '../../../../../shared/components/LoadingPlaceholder';
import {parseError} from '../../../../../shared/utils';
import {ErrorPlaceholder} from '../../../../../shared/components/ErrorPlaceholder';
import {useAuth, UserModel} from '../../../auth';
import {DataTable, DataTableFilterMeta} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {useThemeMode} from '../../../../../_metronic/partials';
import clsx from 'clsx';

import {ActionsTemplate} from './templates/ActionsTemplate';
import {StatusTemplate} from './templates/StatusTemplate';
import SendEmail from './components/SendEmail';
import {
  deleteBulkUsers,
  downloadBulkUsersData,
  getUsers as getUsersFromDB,
} from '../../api/users-api';
import NewUser from './components/NewUser';
import IncreaseCredits from './components/IncreaseCredits';
import {UserRolesEnum} from '../../../models-management/types';
import {confirmDialog} from 'primereact/confirmdialog';
import {deleteDialogProps} from '../../../shared/types';
import useRealUserRole from '../../../app/core/useCheckRole';
import {getAllOrganizations} from '../../api/organizations-api';
import {Organization} from '../../../app/types';
import {UsersFilters} from './components/UsersFilters';
import useUsersStore from './users.store';
import {FilterMatchMode} from 'primereact/api';
import {CreditTemplate} from './templates/CreditTemplate';
import {NameTemplate} from './templates/NameTemplate';
import {RateFilterTemplate} from './templates/RateFilterTemplate';
import {RoleTemplate} from './templates/RoleTemplate';
import HasPermission from '../../../auth/HasPermission';
import {usePermissionChecker} from '../roles/hooks/permission-checker';

const actionsPermissions = [
  'users.increase-credits',
  'users.delete-user-account',
  'users.send-email',
];

const UsersPage = () => {
  const [showNewUserWindow, setShowNewUserWindow] = useState(false);
  const [showIncreaseCreditsWindow, setShowIncreaseCreditsWindow] = useState(false);
  const [showEmailWindow, setShowEmailWindow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [filteredUsers, setfilteredUsers] = useState<UserModel[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<UserModel[]>([]);
  const [downloading, setDownloading] = useState(false);
  const [newAdmin, setNewAdmin] = useState(false);

  const {users, setOriginalUsers, orgFilter, setOrgFilter, orgs} = useUsersStore();
  const {mode} = useThemeMode();
  const {currentUser} = useAuth();
  const role = useRealUserRole();
  const {hasNoPermission, hasAnyPermission} = usePermissionChecker();

  const [filters, setFilters] = useState<DataTableFilterMeta>({
    role_name: {value: null, matchMode: FilterMatchMode.EQUALS},
  });

  const deleteAccounts = async () => {
    confirmDialog({
      ...deleteDialogProps,
      message: `Are you sure you want to delete these ${selectedUsers.length} accounts?`,
      accept: async () => {
        try {
          setLoading(true);
          await deleteBulkUsers(selectedUsers.map((d) => d.id));

          await getUsers();
        } catch (error) {
          setError(parseError(error));
        } finally {
          setLoading(false);
        }
      },
      reject: () => {},
    });
  };

  const getUsers = async () => {
    try {
      setLoading(true);
      const res = await getUsersFromDB();

      let _users: any[] = res.data.users || [];
      _users = _users.map((user) => {
        return {...user, created_date: new Date(user.created_date)};
      });

      setOriginalUsers(_users);
    } catch (error) {
      setError(parseError(error));
    } finally {
      setLoading(false);
    }
  };

  const downloadData = async () => {
    try {
      setDownloading(true);

      await downloadBulkUsersData(selectedUsers.map((d) => d.id));
    } catch (error) {
      setError(parseError(error));
    } finally {
      setDownloading(false);
    }
  };

  useEffect(() => {
    getUsers();

    // if (currentUser?.is_org_admin) setOrgFilter(currentUser.org_id); its filtered in BE
  }, []);

  // useEffect(() => {
  //   if (searchText)
  //     setfilteredUsers(
  //       users.filter((d: UserModel) =>
  //         (d.first_name + d.last_name + d.email + d.api_key)
  //           .toLocaleLowerCase()
  //           .includes(searchText.toLocaleLowerCase())
  //       )
  //     );
  //   else setfilteredUsers(users);
  // }, [searchText, users]);

  // if (loading) return <LoadingPlaceholder label='getting users..' />;
  if (error) return <ErrorPlaceholder error={error} />;

  return (
    <div>
      <PageTitle breadcrumbs={[{title: 'Admin Dashboard', path: 'admin', isActive: true}]}>
        Users
      </PageTitle>

      <div>
        <div className=''>
          <header className='d-flex flex-wrap flex-stack pb-7'>
            <div className='d-flex flex-wrap align-items-center my-1'>
              <h3 className='fw-bold me-5 my-1'>Users ({users.length})</h3>
              <UsersFilters />
            </div>

            <div className='d-flex flex-wrap my-1'>
              {/* <button
                className='btn btn-light-primary btn-sm'
                onClick={() => {
                  setNewAdmin(true);
                  setShowNewUserWindow(true);
                }}
              >
                Add Admin
              </button> */}

              <HasPermission code='users.create-user'>
                <button
                  className='btn btn-primary btn-sm ms-1'
                  onClick={() => {
                    setNewAdmin(false);
                    setShowNewUserWindow(true);
                  }}
                >
                  Create User
                </button>
              </HasPermission>
            </div>
          </header>

          <section className='card card-flush '>
            <div className='card-body pt-0'>
              {selectedUsers.length > 0 && (
                <header className='mt-8 d-flex align-items-center'>
                  <div className='fw-bold'>Selected: {selectedUsers.length} user</div>

                  {hasAnyPermission(actionsPermissions) && (
                    <div className='ms-8 text-muted '>Actions:</div>
                  )}
                  <div>
                    <HasPermission code='users.increase-credits'>
                      <div
                        className='btn btn-bg-light btn-color-info btn-sm fw-bold ms-2'
                        onClick={() => {
                          setShowIncreaseCreditsWindow(true);
                        }}
                      >
                        <i className='fas fa-edit me-1'></i>
                        Increase credits
                      </div>
                    </HasPermission>

                    <HasPermission code='users.delete-user-account'>
                      <div
                        className='btn btn-bg-light btn-color-danger btn-sm fw-bold ms-2'
                        onClick={deleteAccounts}
                      >
                        <i className='fas fa-trash me-1'></i>
                        Delete
                      </div>
                    </HasPermission>

                    <HasPermission code='users.send-email'>
                      <div
                        className='btn btn-bg-light btn-color-info btn-sm fw-bold ms-2'
                        onClick={() => setShowEmailWindow(true)}
                      >
                        <i className='fas fa-envelope me-1'></i>
                        Send Email
                      </div>
                    </HasPermission>

                    <div
                      className={clsx(
                        'd-none btn btn-bg-light btn-color-info btn-sm fw-bold ms-2',
                        {
                          disabled: downloading,
                        }
                      )}
                      onClick={() => downloadData()}
                    >
                      {downloading && <span>Downloading...</span>}
                      {!downloading && <span>Download Data</span>}
                    </div>
                  </div>
                </header>
              )}

              <section className='table-responsive'>
                <div className='dataTables_wrapper dt-bootstrap4 no-footer pt-10'>
                  <DataTable
                    tableClassName='table table-hover min-h-400px'
                    value={users}
                    // sortMode='multiple'
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    sortField='created_date'
                    sortOrder={-1}
                    removableSort
                    paginator
                    responsiveLayout='scroll'
                    rows={10}
                    tableStyle={{minWidth: '50rem'}}
                    cellClassName={() => 'fs-6 fw-semibold'}
                    rowClassName={() => `lh-xl ${mode === 'dark' && 'bg-light text-gray-700'}`}
                    paginatorClassName={clsx({'bg-light text-gray-700': mode === 'dark'})}
                    selectionMode={'checkbox'}
                    selection={selectedUsers}
                    onSelectionChange={(e) => setSelectedUsers(e.value || [])}
                    dataKey='id'
                    filters={filters}
                    filterDisplay='menu'
                    globalFilterFields={['role_name']}
                  >
                    <Column selectionMode='multiple' headerStyle={{width: '3rem'}}></Column>

                    <Column
                      field='first_name'
                      body={NameTemplate}
                      header='Name'
                      sortable
                      bodyClassName={() => 'fw-semibold'}
                    ></Column>
                    <Column
                      body={(user) => orgs.find((d) => d.id === user.org_id)?.name}
                      header='Organization'
                      sortable
                      bodyClassName={() => 'text-muted'}
                    ></Column>
                    {/* <Column
                      field='email'
                      header='Email'
                      sortable
                      bodyClassName={() => 'text-muted'}
                    ></Column> */}
                    <Column
                      field='created_date'
                      header='Joined '
                      filterField='date'
                      dataType='date'
                      sortable
                      body={(user) => dayjs(user.created_date).format('YYYY/MM/DD')}
                      bodyClassName={() => 'text-dark'}
                    ></Column>

                    <Column
                      field='max_credits'
                      header='Credits'
                      sortable
                      bodyClassName={() => 'text-dark'}
                      body={CreditTemplate}
                      hidden={hasNoPermission('misc.credits')}
                    ></Column>

                    <Column
                      field='models_count'
                      header='Models'
                      sortable
                      bodyClassName={() => 'text-dark'}
                      className='min-w-100px'
                    ></Column>

                    <Column
                      field='role_name'
                      header='Role'
                      sortable
                      filter
                      filterElement={(options) => RateFilterTemplate(users, options)}
                      showFilterMenuOptions={false}
                      showApplyButton={false}
                      showClearButton={false}
                      bodyClassName={() => 'text-dark'}
                      body={RoleTemplate}
                      className='min-w-100px'
                    ></Column>

                    <Column
                      field='api_key'
                      header='Api Key'
                      sortable
                      bodyClassName={() => 'text-dark'}
                      className='min-w-100px mw-150px text-truncate'
                      hidden={!currentUser?.is_org_admin}
                    ></Column>

                    <Column
                      header=''
                      className='min-w-150px'
                      body={(user) => <ActionsTemplate user={user} onChange={() => getUsers()} />}
                    ></Column>
                  </DataTable>
                </div>
              </section>
            </div>
          </section>
        </div>
      </div>

      {/* DIALOGS */}
      <Dialog
        modal
        dismissableMask
        closable={false}
        visible={showNewUserWindow}
        onHide={() => setShowNewUserWindow(false)}
      >
        <NewUser
          org_id={orgFilter}
          submitComplete={() => {
            setShowNewUserWindow(false);
            getUsers();
          }}
        />
      </Dialog>

      <Dialog
        modal
        dismissableMask
        closable={false}
        visible={showIncreaseCreditsWindow}
        onHide={() => setShowIncreaseCreditsWindow(false)}
      >
        <IncreaseCredits
          usersIds={selectedUsers.map((a) => a.id)}
          submitComplete={(data) => {
            setShowIncreaseCreditsWindow(false);
            if (data === 'cancel') return;
            getUsers();
          }}
        />
      </Dialog>

      <Dialog
        modal
        dismissableMask
        closable={false}
        visible={showEmailWindow}
        onHide={() => setShowEmailWindow(false)}
      >
        <SendEmail
          usersIds={selectedUsers.map((a) => a.id)}
          submitComplete={(data) => {
            setShowEmailWindow(false);
            if (data === 'cancel') return;
            getUsers();
          }}
        />
      </Dialog>
    </div>
  );
};

export default UsersPage;
