import {toast} from 'react-toastify';
import {PermissionsComponent} from '../../roles/components/PermissionsComponent';
import {updateRole} from '../../roles/roles-api';
import {useState} from 'react';
import {LoadingPlaceholder} from '../../../../../../shared/components/LoadingPlaceholder';
import {updateOrganization} from '../../../api/organizations-api';

export function OrgPermissionsTab({org, setOrg}: any) {
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);

  const save = async () => {
    try {
      if (!org) return;

      setSubmitting(true);
      await updateOrganization(org);
      setSubmitting(false);

      toast.success('Permissions successfully updated');
    } catch (error: any) {
      setSubmitting(false);

      toast.error('Failed to save org, check browser console');
      console.error(error);
    }
  };
  if (loading) return <LoadingPlaceholder />;

  return (
    <div className='card pt-4 mb-6 mb-xl-9'>
      <div className='card-header border-0'>
        <div className='card-title'>
          <h2>Org Permissions</h2>
        </div>
        <div className='card-toolbar'>
          <button onClick={() => save()} type='button' className='btn btn-sm btn-light-primary'>
            {!submitting && (
              <>
                {' '}
                <i className='ki-duotone ki-check-circle fs-3'>
                  <span className='path1' />
                  <span className='path2' />
                </i>
                Save Changes
              </>
            )}
            {submitting && (
              <span>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
      <div className='card-body py-0'>
        {org?.permissions && (
          <PermissionsComponent
            type='organization'
            orgId={org.id}
            permissions={org.permissions}
            onChange={(permissions) => {
              setOrg({...org, permissions});
            }}
          />
        )}
      </div>
    </div>
  );
}
