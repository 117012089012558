import {create} from 'zustand';
import {UserModel} from '../auth';
import {PermissionLevelEnum} from '../app/types';

interface IAccountStore {
  user: UserModel | undefined;
  setUser: (user: UserModel | undefined) => void;

  userLevel: number;
  setUserLevel: (level: number) => void;
}

const useAccountStore = create<IAccountStore>((set) => ({
  user: undefined,
  setUser: (user: UserModel | undefined) => set({user: user}),

  userLevel: PermissionLevelEnum.USER,
  setUserLevel: (level: number) => set({userLevel: level}),
}));

export default useAccountStore;
