/* eslint-disable jsx-a11y/anchor-is-valid */
import {Dialog} from 'primereact/dialog';
import React, {useEffect, useState} from 'react';
import {UserModel, useAuth} from '../../../../auth';
import {Link} from 'react-router-dom';
import EditUser from '../components/EditUser';
import {downloadBulkUsersData, getUsers} from '../../../api/users-api';
import SendEmail from '../components/SendEmail';
import Authorize from '../../../../auth/Authorize';
import {UserRolesEnum} from '../../../../models-management/types';
import HasPermission from '../../../../auth/HasPermission';
import {usePermissionChecker} from '../../roles/hooks/permission-checker';
import {PermissionLevelEnum} from '../../../../app/types';

const componentPermissions = [
  'menu.models',
  'models.admin-create-model',
  'users.edit-user',
  'users.send-email',
];

interface Props {
  user: UserModel;
  onChange?: () => void;
  accountView?: boolean;
}

export const ActionsTemplate = ({user, onChange, accountView}: Props) => {
  const [showEditUserWindow, setshowEditUserWindow] = useState(false);
  const [showEmailWindow, setShowEmailWindow] = useState(false);
  const {currentUser} = useAuth();
  const {hasAnyPermission} = usePermissionChecker();

  const [userLevel, setUserLevel] = useState(PermissionLevelEnum.USER);

  const downloadData = async () => {
    if (!user) return;

    await downloadBulkUsersData([user.id]);
  };

  useEffect(() => {
    const forDiffOrg = user?.org_id !== currentUser?.org_id;
    const forSameOrgDiffUser = !forDiffOrg && user?.id !== currentUser?.id;

    if (forDiffOrg) setUserLevel(PermissionLevelEnum.SYSTEM);
    else if (forSameOrgDiffUser) setUserLevel(PermissionLevelEnum.ORG);
    else setUserLevel(PermissionLevelEnum.USER);
  }, [user]);

  if (!hasAnyPermission(componentPermissions)) return <></>;

  return (
    <div>
      <div className='dropdown'>
        <button
          className='btn btn-light btn-active-light-primary btn-sm'
          data-bs-toggle='dropdown'
          aria-expanded='false'
        >
          Actions
          <span className='svg-icon svg-icon-5 m-0'>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z'
                fill='currentColor'
              ></path>
            </svg>
          </span>
        </button>

        <ul className='dropdown-menu pb-4 w-125px'>
          <HasPermission code='menu.models'>
            <li className='m-0'>
              <Link className='dropdown-item py-2' to={`/dashboard/models?email=${user.email}`}>
                View User Models
              </Link>
            </li>
          </HasPermission>
          <HasPermission code='models.create' entityLevel={userLevel}>
            <li className='m-0'>
              <Link className='dropdown-item py-2' to={`/dashboard/create-model?userId=${user.id}`}>
                Create Model
              </Link>
            </li>
          </HasPermission>
          {!accountView && (
            <li className='m-0'>
              <Link className='dropdown-item py-2' to={`/account/${user.id}`}>
                Account Settings
              </Link>
            </li>
          )}

          <HasPermission code='users.edit-user' entityLevel={userLevel}>
            <li className='m-0'>
              <a
                className='dropdown-item py-2 cursor-pointer'
                onClick={() => {
                  setshowEditUserWindow(true);
                }}
              >
                Edit User
              </a>
            </li>
          </HasPermission>
          {accountView && (
            <HasPermission code='users.send-email' entityLevel={userLevel}>
              <li className='m-0'>
                <a
                  className='dropdown-item cursor-pointer'
                  onClick={() => setShowEmailWindow(true)}
                >
                  Send Email
                </a>
              </li>
            </HasPermission>
          )}
        </ul>
      </div>

      <Dialog
        modal
        dismissableMask
        closable={false}
        visible={showEditUserWindow}
        onHide={() => setshowEditUserWindow(false)}
      >
        <EditUser
          submitComplete={(user) => {
            setshowEditUserWindow(false);
            onChange && onChange();
          }}
          user={user}
        />
      </Dialog>

      <Dialog
        modal
        dismissableMask
        closable={false}
        visible={showEmailWindow}
        onHide={() => setShowEmailWindow(false)}
      >
        <SendEmail
          usersIds={[user.id]}
          submitComplete={(data) => {
            setShowEmailWindow(false);
            if (data === 'cancel') return;
          }}
        />
      </Dialog>
    </div>
  );
};
