import axios from 'axios';
import {AppLocator} from '../../../../app-locator';
import {encryptPassword} from '../../../../shared/utils';
import {LoginResponse} from './apiModels';
import {AuthModel, UserModel, SettingsModel} from './_models';

const API_URL =
  AppLocator.forceUsingRealApi || process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_DEV_API_URL;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/profile`;
export const LOGIN_URL = `${API_URL}/login`;
export const LOGOUT_URL = `${API_URL}/logout`;
export const REGISTER_URL = `${API_URL}/create_user`;
export const REQUEST_PASSWORD_URL = `${API_URL}/forget_password`;
export const RESET_PASSWORD_URL = `${API_URL}/reset_password`;

export function login(email: string, password: string) {
  return axios.post<LoginResponse>(LOGIN_URL, {email, password});
}

export function logout(token: string) {
  return axios.delete<string | undefined>(LOGOUT_URL, {
    headers: {Authorization: `Bearer ${token}`},
  });
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string,
  company: string
) {
  // const encryptedPassword = encryptPassword(password);

  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password: password,
    company,
  });
}

export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  });
}
export function resetPassword(key: string, password: string) {
  // const encryptedPassword = encryptPassword(password);

  return axios.post<{result: boolean}>(RESET_PASSWORD_URL, {
    key,
    password: password,
  });
}

export function getUserByToken(token: string) {
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
