import React from 'react';
import * as THREE from 'three';
import useViewerStore from '../viewer-store';

export const Lights = ({}: any) => {
  const {config} = useViewerStore();

  return (
    <>
      {config.dirLights.map((light: any) => (
        <directionalLight
          position={new THREE.Vector3(light.position.x, light.position.y, light.position.z)}
        />
      ))}

      {config.ambientLightIntensity && <ambientLight intensity={config.ambientLightIntensity} />}

      {config.hemiLight && (
        <hemisphereLight
          intensity={config.hemiLight.intensity}
          position={[
            config.hemiLight.position.x,
            config.hemiLight.position.y,
            config.hemiLight.position.z,
          ]}
        />
      )}
    </>
  );
};
