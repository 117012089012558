import axios, {AxiosError, AxiosResponse} from 'axios';
import {AppLocator} from '../../../../app-locator';
import {downloadFile} from '../../../../shared/utils';
import {getAuth} from '../../auth/core/AuthHelpers';
import {
  Category,
  Model,
  ModelsApiResponse,
  ModelSourceImage,
  ModelsCounter,
  CreditsInfo,
  ViewsInfo,
} from '../types';

// const API_URL = process.env.REACT_APP_API_URL;

const API_URL =
  AppLocator.forceUsingRealApi || process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_DEV_API_URL;

const GET_MODELS_URL = `${API_URL}/models/view`;
const CREATE_MODEL_URL = `${API_URL}/models/create`;
const UPLOAD_MODEL_FILE_URL = `${API_URL}/models/admin/upload`;
const SHARE_MODEL_URL = `${API_URL}/models/share`;
const DELETE_MODEL_URL = `${API_URL}/models/delete`;
const GET_CATEGORIES_URL = `${API_URL}/models/categories`;
const DOWNLOAD_MODELS_URL = `${API_URL}/models/view/content?type=Download`;
const RERUN_MODELS_URL = `${API_URL}/models/rerun`;
const DELETE_MODELS_URL = `${API_URL}/models/delete_bulk`;
const VALIDATE_MODEL_URL = `${API_URL}/models/rate`;
const AllUserModelsIds = `${API_URL}/models/view_pub_id`;

export const createModel = async (
  name?: string,
  category?: number,
  files?: File[],
  frame_width?: number,
  frame_height?: number,
  lens_width?: number,

  // frame_thickness?: number,
  // temples_thickness?: number,
  // frame_transparency?: string,
  // lens_transparency?: string,
  // mirror_lenses?: boolean,
  setProgress?: (progress: number) => void
) => {
  const formData = new FormData();
  formData.append('name', name || '');
  formData.append('category', category + '' || '');

  formData.append('frame_width', frame_width ? frame_width + '' : '');
  formData.append('frame_height', frame_height ? frame_height + '' : '');
  formData.append('lens_width', lens_width ? lens_width + '' : '');
  // formData.append('frame_thickness', frame_thickness ? frame_thickness + '' : '');
  // formData.append('temples_thickness', temples_thickness ? temples_thickness + '' : '');
  // formData.append('frame_transparency', frame_transparency || '');
  // formData.append('lens_transparency', lens_transparency || '');
  // formData.append('mirror_lenses', mirror_lenses ? mirror_lenses + '' : '');

  if (files)
    files.forEach((file) => {
      formData.append('images', file);
    });

  await axios({
    method: 'POST',
    url: CREATE_MODEL_URL,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getAuth()?.api_token}`,
    },
    onUploadProgress: (progressEvent) => {
      const progress = (progressEvent.loaded / (progressEvent.total || 1)) * 50;

      if (setProgress) setProgress(progress);
    },
  });
};

export const getModels = (query: string, page: number): Promise<ModelsApiResponse> => {
  return axios
    .get(`${GET_MODELS_URL}?${query}`)
    .then((d: AxiosResponse<ModelsApiResponse>) => d?.data);
};

export const getSingleModel = (pub_id: string): Promise<Model> => {
  return axios.get(`${API_URL}/models/view/${pub_id}`).then((d: AxiosResponse<Model>) => d.data);
};
export const getSharedModel = (pub_id: string): Promise<Model> => {
  return axios.get(`${API_URL}/models/share/view/${pub_id}`).then((d: AxiosResponse<Model>) => {
    return {...d.data, location: `${d.data.location}`};
  });
};

export const shareModel = async (pub_id: string) => {
  return axios.get(`${SHARE_MODEL_URL}/${pub_id}`).then((d: AxiosResponse<Model>) => d.data);
};

export const getModelsCategories = (): Promise<Category[]> => {
  const cached = sessionStorage.getItem('data_models_categories');
  if (cached) {
    const cachedData = JSON.parse(cached) as Category[];
    return new Promise((resolve) => {
      resolve(cachedData);
    });
  }

  return axios.get(`${GET_CATEGORIES_URL}`).then((d: AxiosResponse<Category[]>) => {
    const data = d?.data;

    if (data) sessionStorage.setItem('data_models_categories', JSON.stringify(data));

    return data;
  });
};

export const getModelSourceImages = async (pub_id: string): Promise<ModelSourceImage[]> => {
  return axios
    .get(`${API_URL}/models/view/${pub_id}/images`)
    .then((d: AxiosResponse<ModelSourceImage[]>) => d.data);
};

export const getUserCreditInfo = async (): Promise<CreditsInfo> => {
  return axios.get(`${API_URL}/models/credits_info`).then((d: AxiosResponse<any>) => {
    const data = d.data;
    const info: CreditsInfo = {
      maxCredits: data.max_credits,
      usedCredits: data.used_credits,
    };
    return info;
  });
};

export const getViewsStats = async (): Promise<ViewsInfo> => {
  return axios.get(`${API_URL}/models/views_stats`).then((d: AxiosResponse<any>) => {
    const data = d.data;
    const info: ViewsInfo = {
      viewersStats: data.viewer_views,
      arStats: data.AR_views,
    };
    return info;
  });
};
export const deleteModel = async (pub_id: string | undefined) => {
  return axios.delete(`${DELETE_MODEL_URL}/${pub_id}`).then((d: AxiosResponse<Model>) => d.data);
};

export const deleteBulkModels = async (modelsIds: string[]) => {
  const json = JSON.stringify({
    model_ids: modelsIds,
  });
  const res = await axios.delete(DELETE_MODELS_URL, {
    data: json,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return res?.data;
};

export const uploadModelFile = async (
  pub_id: string,
  files: File[],
  setProgress: (progress: number) => void
) => {
  const formData = new FormData();
  files.map((file) => formData.append('model', file));

  await axios({
    method: 'POST',
    url: `${UPLOAD_MODEL_FILE_URL}/${pub_id}`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getAuth()?.api_token}`,
    },
    onUploadProgress: (progressEvent) => {
      const progress = (progressEvent.loaded / (progressEvent.total || 1)) * 50;

      if (setProgress) setProgress(progress);
    },
  });
};

export const downloadSelectedModels = async (
  modelsIds: string[],
  setProgress: (progress: number) => void,
  type: 'default' | 'custom' | 'source-files' | 'inter-files' = 'default'
) => {
  const json = JSON.stringify({
    model_ids: modelsIds,
    type,
  });

  let url = DOWNLOAD_MODELS_URL;

  if (type === 'inter-files') url = `${API_URL}/models/admin/multiple_model_files`;
  if (type === 'source-files') url = `${API_URL}/models/view/multiple_images`;

  const res = await axios
    .post(url, json, {
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
      onDownloadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / (progressEvent.total || 1)) * 100;

        if (setProgress) setProgress(Math.round(progress));
      },
    })
    .catch(async (err: AxiosError) => {
      const errorObject: Blob = err.response?.data;

      const jsonTxt = await errorObject.text();
      const errorOfError = JSON.parse(jsonTxt);

      throw errorOfError;
    });

  if (res?.data) downloadFile(res.data, 'Models');
  return res;
};

export const rerunSelectedModels = async (modelsIds: string[]) => {
  const json = JSON.stringify({
    model_ids: modelsIds,
  });
  const res = await axios.post(RERUN_MODELS_URL, json, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return res?.data;
};

export const rateModel = async (pub_id: string | undefined, value: number) => {
  const res = await axios.put(`${VALIDATE_MODEL_URL}/${pub_id}?value=${value}`);
  return res;
};

export const getAllUserModelsIds = (query: string, view_type: string = '3d'): Promise<string[]> => {
  return axios
    .get(`${AllUserModelsIds}?view_type=${view_type}${query == '' ? '' : '&' + query}`)
    .then((d: AxiosResponse<any>) => d?.data?.pub_id_list || []);
};

export const getViewsCounters = (days_backwards: number): Promise<any> => {
  return axios
    .get(`${API_URL}/models/view_count?days_backwards=${days_backwards}`)
    .then((d: AxiosResponse<any>) => d?.data);
};

export const getModelsByPubIds = (ids: string[]): Promise<any> => {
  return axios
    .post(`${API_URL}/models/view_multiple_pub_id`, {model_ids: ids})
    .then((d: AxiosResponse<any>) => {
      const data = d?.data;

      return data;
    });
};
