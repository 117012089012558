import {getViewsCounters} from '../../../../models-management/api/models-api';
import {useState, useEffect} from 'react';
import {ApexOptions} from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import dayjs from 'dayjs';
import {getAllOrganizations} from '../../../api/organizations-api';
import {Dropdown} from 'primereact/dropdown';
import {sumProperty} from '../../../../../../shared/utils';
import useRealUserRole from '../../../../app/core/useCheckRole';
import {useAuth} from '../../../../auth';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import {PermissionLevelEnum} from '../../../../app/types';
import {usePermissionChecker} from '../../roles/hooks/permission-checker';

const GREEN = '#50CD89';
const RED = '#F1416C';
const BLUE = '#009EF7';
const GRAY = '#A1A5B7';

const periodFilterOptions = [
  {label: 'Today', value: 1},
  {label: 'Last 7 Days', value: 7},
  {label: 'Last 30 Days', value: 30},
  {label: 'Last 6 months', value: 180},
  {label: 'Last Year', value: 360},
];
const CountersWidget = () => {
  const [chartOptions, setChartOptions] = useState<Partial<ApexOptions>>();
  const [data, setData] = useState<Counters>();
  const [orgs, setOrgs] = useState<any[]>([]);
  const [daysBackwards, setDaysBackwards] = useState(7);
  const [orgFilter, setOrgFilter] = useState();
  const role = useRealUserRole();
  const {currentUser} = useAuth();
  const {hasPermission} = usePermissionChecker();

  const buildDataByStatus = () => {
    if (!data) return [];

    const filteredData: Counters =
      orgFilter !== undefined
        ? {
            approved: data.approved.filter((d) => d.org_id === orgFilter),
            rejected: data.rejected.filter((d) => d.org_id === orgFilter),
            not_rated: data.not_rated.filter((d) => d.org_id === orgFilter),
          }
        : data;

    // dayjs.extend(timezone as any);
    // dayjs.extend(utc as any);

    // const gmt = 'Wed, 10 Jan 2024 07:51:14 GMT';
    // const x = dayjs(gmt).utc();
    // const localDate = x.tz(dayjs.tz.guess());

    // console.log(x);

    // alert(dayjs.tz.guess() + '' + localDate.toString());

    // const filteredData: Counters = {
    //   approved: filteredDatax.approved.map((d) => {
    //     return {
    //       ...d,
    //       creation_date: dayjs(d.creation_date).utc().toDate().toString(),
    //     };
    //   }),
    //   rejected: filteredDatax.rejected.map((d) => {
    //     return {
    //       ...d,
    //       creation_date: dayjs(d.creation_date).utc().toDate().toString(),
    //     };
    //   }),
    //   not_rated: filteredDatax.not_rated.map((d) => {
    //     return {
    //       ...d,
    //       creation_date: dayjs(d.creation_date).utc().toDate().toString(),
    //     };
    //   }),
    // };

    const mergedData = sortByProperty([
      ...filteredData.not_rated,
      ...filteredData.rejected,
      ...filteredData.approved,
    ]);
    const dates = [...new Set(mergedData?.map((d) => d.creation_date))];

    const notRated = dates?.map((d) => [
      dayjs(d).valueOf(),
      sumProperty(
        filteredData?.not_rated.filter((x) => x.creation_date === d),
        'models_count'
      ) || 0,
    ]) as any[];

    const approved = dates?.map((d) => [
      dayjs(d).valueOf(),
      sumProperty(
        filteredData?.approved.filter((x) => x.creation_date === d),
        'models_count'
      ) || 0,
    ]) as any[];

    const rejected = dates?.map((d) => [
      dayjs(d).valueOf(),
      sumProperty(
        filteredData?.rejected.filter((x) => x.creation_date === d),
        'models_count'
      ) || 0,
    ]) as any[];

    const all = dates?.map((d) => [
      dayjs(d).valueOf(),
      sumProperty(
        filteredData?.rejected.filter((x) => x.creation_date === d),
        'models_count'
      ) +
        sumProperty(
          filteredData?.approved.filter((x) => x.creation_date === d),
          'models_count'
        ) +
        sumProperty(
          filteredData?.not_rated.filter((x) => x.creation_date === d),
          'models_count'
        ),
    ]) as any[];

    const series: ApexAxisChartSeries = [
      {
        name: 'Not Rated',
        data: notRated,
        color: GRAY,
        type: 'line',
      },
      {
        name: 'Approved',
        data: approved,
        color: GREEN,
        type: 'line',
      },
      {
        name: 'Rejected',
        data: rejected,
        color: RED,
        type: 'line',
      },
      {
        name: 'All',
        data: all,
        color: BLUE,
        type: 'line',
      },
    ];

    return series;
  };

  useEffect(() => {
    if (!data) return;

    // var max = dayjs().valueOf(); // Current timestamp
    // var min = dayjs()
    //   .add(-1 * 15, 'days')
    //   .valueOf(); // timestamp 90 days before

    // var range = max - min;

    const options: Partial<ApexOptions> = {
      chart: {
        type: 'line',
        stacked: false,
        height: 200,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true,
        },
        toolbar: {
          autoSelected: 'zoom',
        },
        // events: {
        //   beforeZoom: function (ctx) {
        //     // we need to clear the range as we only need it on the iniital load.
        //     ctx.w.config.xaxis.range = undefined;
        //   },
        // },
      },
      series: buildDataByStatus() as any[],
      dataLabels: {
        enabled: false,
      },
      markers: {
        // size: 3,
        colors: [GRAY, GREEN, RED, BLUE],
        shape: 'circle',
      },
      title: {},
      // fill: {
      // type: 'gradient',
      // gradient: {
      //   shadeIntensity: 1,
      //   inverseColors: false,
      //   opacityFrom: 0.5,
      //   opacityTo: 0,
      //   stops: [0, 90, 100],
      // },
      // },
      yaxis: {
        // labels: {
        //   show: true,
        // },

        title: {
          text: 'Count',
        },
        labels: {
          style: {
            colors: '#99A1B7',
          },
        },
      },
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeUTC: false,
          style: {
            colors: '#99A1B7',
          },
        },
        // range: range,

        // categories: data.not_rated.map((d) => dayjs(d.creation_date).format('DD/MMM')),
      },
      stroke: {
        width: 3,
        curve: 'smooth',
        // colors: ['#ff0000', '#00ff00', '#0000ff'],
      },
      grid: {
        strokeDashArray: 3,
        borderColor: '#F1F1F2',
      },
      legend: {
        // offsetY: 10,
      },
      tooltip: {
        x: {
          format: daysBackwards === 1 ? 'd MMM H:mm' : 'd MMM',
        },
        // y: [
        //   {
        //     title: {
        //       formatter: function (val) {
        //         return val + ' (mins)';
        //       },
        //     },
        //   },
        //   {
        //     title: {
        //       formatter: function (val) {
        //         return val + ' per session';
        //       },
        //     },
        //   },
        //   {
        //     title: {
        //       formatter: function (val) {
        //         return val;
        //       },
        //     },
        //   },
        // ],
        // shared: true,
        // intersect: true,
        // y: {
        //   formatter: function (val) {
        //     return (val / 1000000).toFixed(0);
        //   },
        // },
      },
    };

    setChartOptions(options);
  }, [data, orgFilter]);

  useEffect(() => {
    const get = async () => {
      const result = await getViewsCounters(daysBackwards);
      if (!result) return;
      setData(result.counters);
    };
    get();
  }, [daysBackwards]);

  useEffect(() => {
    try {
      const getOrgs = async () => {
        const orgs = await getAllOrganizations();
        setOrgs([
          {
            name: 'All Organizations',
            id: undefined,
          },
          ...orgs,
        ]);
      };

      getOrgs();
    } catch (error: any) {
      console.error(error);
    }
  }, []);

  return (
    <div className='card card-xl-stretch mb-xl-8'>
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Models Statistics</span>
          <span className='text-muted fw-semibold fs-7'>Models creation chart by date</span>
        </h3>
        <div className='card-toolbar'></div>
      </div>
      <div className='card-body pt-0'>
        <div className='filters'>
          {(currentUser?.is_super_admin ||
            hasPermission('menu.models', PermissionLevelEnum.SYSTEM)) && (
            <div>
              <span className='w-100px d-inline-block'>Organization:</span>
              <Dropdown
                className='w-250px ms-5 border-0'
                value={orgFilter}
                onChange={(e: any) => setOrgFilter(e.value)}
                options={orgs}
                optionLabel='name'
                optionValue='id'
                placeholder={orgFilter === null ? 'No Organization' : 'Filter by organization'}
                showClear
              />
            </div>
          )}
          <div>
            <span className='w-100px d-inline-block'>Period:</span>
            <Dropdown
              className='w-250px ms-5 border-0'
              value={daysBackwards}
              onChange={(e: any) => setDaysBackwards(e.value)}
              options={periodFilterOptions}
              optionLabel='label'
              optionValue='value'
            />
          </div>
        </div>

        <div className=' h-180px'>
          <div className='apexcharts-canvas w-100 apexcharts-theme-light'>
            <div id='chart'>
              {chartOptions && (
                <>
                  <ReactApexChart
                    options={chartOptions}
                    series={chartOptions.series}
                    type='line'
                    height={350}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountersWidget;

type CounterData = {
  creation_date: string;
  models_count: number;
  org_id: number | null;
};

type Counters = {
  approved: CounterData[];
  not_rated: CounterData[];
  rejected: CounterData[];
};

function sortByProperty(arr: CounterData[]): CounterData[] {
  return arr.slice().sort((a, b) => {
    const valueA = dayjs(a.creation_date);
    const valueB = dayjs(b.creation_date);
    if (valueA < valueB) {
      return -1;
    }
    if (valueA > valueB) {
      return 1;
    }
    return 0;
  });
}
