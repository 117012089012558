import axios, {AxiosResponse} from 'axios';
import {AppLocator} from '../../../app-locator';
import {CreatePaymentResponse, Package, Payment, PaymentsFilter} from './types';
import {addPrefixToProperties, objectToQueryString} from '../../../shared/utils';

const API_URL =
  AppLocator.forceUsingRealApi || process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_DEV_API_URL;

export const fetchPackages = () => {
  return axios.get(`${API_URL}/packages`);
};

export const createPackage = (pckg: Package) => {
  return axios.post(`${API_URL}/create_package`, pckg);
};

export const editPackage = async (pckg: Package) => {
  const json = JSON.stringify({
    ...pckg,
  });
  const res = await axios.put(`${API_URL}/update_package`, json, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return res;
};

export const deactivatePackage = async (pckg: Package) => {
  const res = await axios.put(`${API_URL}/deactivate_package?id=${pckg.id}`);
  return res;
};

export const reactivatePackage = async (pckg: Package) => {
  const res = await axios.put(`${API_URL}/activate_package?id=${pckg.id}`);
  return res;
};

export const createPayment = (packageId: number) => {
  const body = {
    package_id: packageId,
  };
  return axios
    .post(`${API_URL}/create_payment`, body)
    .then((d: AxiosResponse<CreatePaymentResponse>) => d.data);
};

export const updatePaymentStatus = (
  paymentId: number,
  status: number,
  externalTransactionId: string = ''
) => {
  const body = {
    payment_id: paymentId,
    status,
    external_transaction_id: externalTransactionId,
  };
  return axios.post(`${API_URL}/update_payment`, body);
};

export const fetchPayments = () => {
  return axios.get<any>(`${API_URL}/get_payments`).then((d) => d.data?.payments);
};

export const fetchFilteredPayments = (filters: Partial<PaymentsFilter>) => {
  const obj = addPrefixToProperties(filters, 'filter_');

  const urlParams = objectToQueryString(obj);

  const urlParamsWithoutUndefined = urlParams.replaceAll('undefined', '');

  return axios
    .get<any>(`${API_URL}/get_payments?${urlParamsWithoutUndefined}`)
    .then((d) => d.data?.payments);
};
