import {useCallback, useState} from 'react';

type Point = {x: number; y: number; z: number};
export const usePoint3D = (param: Point | undefined) => {
  const {x, y, z} = param || {x: 0, y: 0, z: 0};
  const [point, setPoint] = useState<Point>({x, y, z});

  const updatePoint = useCallback((newPoint: Partial<Point>) => {
    setPoint((prev) => ({
      ...prev,
      ...newPoint,
    }));
  }, []);

  const array = [x, y, z];

  return [point, updatePoint, array] as const;
};
