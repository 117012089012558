import React from 'react';
import {Routes, Route, Outlet, Navigate} from 'react-router-dom';
import {RolesList} from './components/RolesList';
import ViewRole from './components/ViewRole';

const Roles = () => {
  return (
    <Routes>
      <Route path='list' element={<RolesList />} />
      <Route path=':id' element={<ViewRole />} />

      <Route index element={<Navigate to='list' replace />} />
    </Routes>
  );
};

export default Roles;
