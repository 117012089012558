import {ReactNode} from 'react';

interface Props {
  children: ReactNode;
}
export const FormFieldErrorMessage = ({children}: Props) => {
  return (
    <div className='fv-plugins-message-container'>
      <div className='fv-help-block'>
        <span role='alert'>{children}</span>
      </div>
    </div>
  );
};
