/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import {Link} from 'react-router-dom';
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components';
import {useAuth} from '../core/Auth';
import {FormFieldErrorMessage} from '../../../../shared/components/FormFieldErrorMessage';
import {ConfirmEmail} from './ConfirmEmail';
import {ApiError} from '../core/apiModels';
import {AuthApi} from '../api/auth-api';
import {AuthApiFake} from '../api/auth-api.fake';
import {AppLocator} from '../../../../app-locator';
import {PasswordRegEx} from '../../app/types';

const api =
  AppLocator.forceUsingRealApi || process.env.NODE_ENV === 'production'
    ? new AuthApi()
    : new AuthApiFake();

const initialValues = {
  firstname: '',
  lastname: '',
  company: '',
  email: '',
  password: '',
  changepassword: '',
  job_title: '',
  acceptTerms: false,
  referral: '',
};

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/,
      'Email should contain only letters, numbers, dots, hyphens, and underscores'
    )
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  lastname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
  password: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .matches(PasswordRegEx, 'Password must include a mix of letters & numbers')
    .required('Password is required'),
  changepassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
  acceptTerms: Yup.bool().required('You must accept the terms'),
});

export function Registration() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [step, setStep] = useState(1);

  const {saveAuth} = useAuth();
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setEmail(values.email);

      setLoading(true);

      try {
        const {data} = await api.register(
          values.email,
          values.firstname,
          values.lastname,
          values.password,
          values.changepassword,
          values.company,
          values.job_title,
          values.referral
        );
        setSubmitted(true);
      } catch (error: any) {
        console.error(error);
        saveAuth(undefined);
        setSubmitting(false);
        setLoading(false);

        const responseData: ApiError | undefined = error?.response?.data;
        if (responseData?.msg) setStatus(responseData?.msg);
        else setStatus('The registration details is incorrect');
      }
    },
  });

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
  }, []);

  const resetFormSubmitting = () => {
    formik.setTouched({changepassword: false});
    formik.setFieldValue('changepassword', '');
    return setSubmitted(false);
  };

  if (submitted)
    return <ConfirmEmail email={email} onTryAgainClick={() => resetFormSubmitting()} />;

  // RENDERING
  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>Create an Account</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4'>
          Already have an account?
          <Link to='/auth/login' className='link-primary fw-bolder ms-1'>
            Sign In
          </Link>
        </div>
        {/* end::Link */}
      </div>
      {/* end::Heading */}

      {/* <h3>step: {step}</h3> */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {step === 1 && (
        <section className='page'>
          {/* begin::Form group Firstname */}
          <div className='row fv-row mb-7'>
            <div className='col-xl-6'>
              <label className='class="form-label fw-bolder text-dark fs-6'>First name</label>
              <input
                placeholder='First name'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('firstname')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.firstname && formik.errors.firstname,
                  },
                  {
                    'is-valid': formik.touched.firstname && !formik.errors.firstname,
                  }
                )}
              />
              {formik.touched.firstname && formik.errors.firstname && (
                <FormFieldErrorMessage>{formik.errors.firstname}</FormFieldErrorMessage>
              )}
            </div>
            <div className='col-xl-6'>
              {/* begin::Form group Lastname */}
              <div className='fv-row '>
                <label className='form-label fw-bolder text-dark fs-6'>Last name</label>
                <input
                  placeholder='Last name'
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('lastname')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.lastname && formik.errors.lastname,
                    },
                    {
                      'is-valid': formik.touched.lastname && !formik.errors.lastname,
                    }
                  )}
                />
                {formik.touched.lastname && formik.errors.lastname && (
                  <FormFieldErrorMessage>{formik.errors.lastname}</FormFieldErrorMessage>
                )}
              </div>
              {/* end::Form group */}
            </div>
          </div>
          {/* end::Form group */}

          {/* begin::Form group Organization */}
          <div className='row fv-row mb-7'>
            <div className='col-xl-6'>
              <label className='form-label fw-bolder text-dark fs-6'>Company</label>
              <input
                placeholder='Company name'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('company')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {'is-invalid': formik.touched.company && formik.errors.company},
                  {
                    'is-valid': formik.touched.company && !formik.errors.company,
                  }
                )}
              />
              {formik.touched.company && formik.errors.company && (
                <FormFieldErrorMessage>{formik.errors.company}</FormFieldErrorMessage>
              )}
            </div>
            <div className='col-xl-6'>
              <label className='form-label fw-bolder text-dark fs-6'>Job Title</label>
              <input
                placeholder='Job Title'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('job_title')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {'is-invalid': formik.touched.job_title && formik.errors.job_title},
                  {
                    'is-valid': formik.touched.job_title && !formik.errors.job_title,
                  }
                )}
              />
              {formik.touched.job_title && formik.errors.job_title && (
                <FormFieldErrorMessage>{formik.errors.job_title}</FormFieldErrorMessage>
              )}
            </div>
          </div>
          {/* end::Form group */}

          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>
              How did you hear about us?
            </label>
            <input
              type='text'
              placeholder='Type your answer '
              {...formik.getFieldProps('referral')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {'is-invalid': formik.touched.referral && formik.errors.referral},
                {
                  'is-valid': formik.touched.referral && !formik.errors.referral,
                }
              )}
            />
          </div>

          {/* begin::Form group */}
          <div className='text-center'>
            <button
              type='button'
              disabled={
                !!formik.errors.firstname ||
                !!formik.errors.lastname ||
                !formik.touched.firstname ||
                !formik.touched.lastname
              }
              className='btn btn-lg btn-primary w-100 mb-5'
              onClick={() => {
                setStep(2);
              }}
            >
              {!loading && <span className='indicator-label'>Continue</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <Link to='/auth/login'>
              <button
                type='button'
                id='kt_login_signup_form_cancel_button'
                className='btn btn-lg btn-light-primary w-100 mb-5'
              >
                Cancel
              </button>
            </Link>
          </div>
          {/* end::Form group */}
        </section>
      )}

      {step === 2 && (
        <section className='page'>
          {/* begin::Form group Email */}
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>Email</label>
            <input
              placeholder='Email'
              type='email'
              autoComplete='off'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <FormFieldErrorMessage>{formik.errors.email}</FormFieldErrorMessage>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Password */}
          <div className='mb-10 fv-row' data-kt-password-meter='true'>
            <div className='mb-1'>
              <label className='form-label fw-bolder text-dark fs-6'>Password</label>
              <div className='position-relative mb-3'>
                <input
                  type='password'
                  placeholder='Password'
                  autoComplete='off'
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.password && formik.errors.password,
                    },
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                />
                {formik.touched.password && formik.errors.password && (
                  <FormFieldErrorMessage>{formik.errors.password}</FormFieldErrorMessage>
                )}
              </div>
              {/* begin::Meter */}
              <div
                className='d-flex align-items-center mb-3'
                data-kt-password-meter-control='highlight'
              >
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
              </div>
              {/* end::Meter */}
            </div>
            <div className='text-muted'>
              Use 8 or more characters with a mix of letters, numbers & symbols.
            </div>
          </div>
          {/* end::Form group */}

          {/* begin::Form group Confirm password */}
          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
            <input
              type='password'
              placeholder='Password confirmation'
              autoComplete='off'
              {...formik.getFieldProps('changepassword')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
                },
                {
                  'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
                }
              )}
            />
            {formik.touched.changepassword && formik.errors.changepassword && (
              <FormFieldErrorMessage>{formik.errors.changepassword}</FormFieldErrorMessage>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <div className='form-check form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                type='checkbox'
                id='kt_login_toc_agree'
                {...formik.getFieldProps('acceptTerms')}
              />
              <label
                className='form-check-label fw-bold text-gray-700 fs-6'
                htmlFor='kt_login_toc_agree'
              >
                I Agree to the{' '}
                <a
                  href='https://vree.ai/terms.html'
                  className='ms-1 link-primary'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  terms and conditions
                </a>
                .
              </label>
              {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                <FormFieldErrorMessage>{formik.errors.acceptTerms}</FormFieldErrorMessage>
              )}
            </div>
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
              disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
            >
              {!loading && <span className='indicator-label'>Submit</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            {/* <Link to='/auth/login'> */}
            <button
              type='button'
              onClick={() => {
                setStep(1);
              }}
              // id='kt_login_signup_form_cancel_button'
              className='btn btn-lg btn-light-primary w-100 mb-5'
            >
              Back
            </button>
            {/* </Link> */}
          </div>
          {/* end::Form group */}
        </section>
      )}
    </form>
  );
}
