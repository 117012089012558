import {ConfirmDialogProps} from 'primereact/confirmdialog';
import {ColorRepresentation} from 'three';

export const warningDialogProps: Partial<ConfirmDialogProps> = {
  header: 'Warning',
  icon: 'pi pi-warning-circle',
  acceptClassName: 'p-button-warning btn btn-sm',
  rejectClassName: 'btn btn-sm btn-bg-light',
  contentClassName: 'text-gray-800 fw-semibold ps-7 pe-15 pb-7',
  acceptLabel: 'Continue',
  rejectLabel: 'Cancel',
  className: 'mw-400px',
  headerClassName: 'ps-4',
};

export const redirectDialogProps: Partial<ConfirmDialogProps> = {
  header: 'For Your information',
  icon: 'pi pi-warning-circle',
  acceptClassName: 'p-button-warning btn btn-sm',
  rejectClassName: 'btn btn-sm btn-bg-light',
  contentClassName: 'text-gray-800 fw-semibold ps-7 pe-15 pb-7',
  acceptLabel: 'Redirect Me',
  rejectLabel: 'Cancel',
  className: 'mw-400px',
  headerClassName: 'ps-4',
};

export const deleteDialogProps: Partial<ConfirmDialogProps> = {
  icon: 'pi pi-info-circle',
  acceptClassName: 'btn-danger btn btn-sm',
  rejectClassName: 'btn btn-sm btn-bg-light',
  contentClassName: 'text-gray-800 fw-semibold ps-7 pe-15 pb-7',
  header: 'Confirm delete',
  acceptLabel: 'Delete',
  rejectLabel: 'Cancel',
};

type Position = {
  x: number;
  y: number;
  z: number;
};

type Camera = {
  position: Position;
  fov?: number;
  aspect?: number;
  near?: number;
  far?: number;
};

type HemiLight = {
  skyColor: ColorRepresentation;
  groundColor: ColorRepresentation;
  intensity?: number;
  position: Position;
};

export type DirLight = {
  color: ColorRepresentation;
  intensity?: number;
  position: Position;
  castShadow: boolean;
  shadow?: {
    camera: {
      top: number;
      bottom: number;
      left: number;
      right: number;
      near: number;
      far: number;
    };
  };
};

export type ViewerConfig = {
  camera: Camera;
  hemiLight?: HemiLight;
  ambientLightIntensity?: number;
  dirLights: DirLight[];
  envMapPath: string;
  enableAnimation: boolean;
  controls?: {
    position?: any;
    minDistance: number;
    maxDistance: number;
    minPolarAngle: number;
    maxPolarAngle: number;
    enableRotate: boolean;
    enableZoom: boolean;
    enablePan: boolean;
  };
  rotation?: any;
  centerObject?: boolean;
  obj_position?: any;
  scale?: any;
  showTable?: boolean;
  material?: any;
  occluderPath?: string;
  occluderPosition?: any;
  occluderScale?: any;
  occluderRotation?: any;
  occluderMaterial?: any;
};
