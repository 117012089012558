/* eslint-disable */

/**
 * Copyright 2021 WebAR.rocks ( https://webar.rocks )
 *
 * WARNING: YOU SHOULD NOT MODIFY THIS FILE OTHERWISE WEBAR.ROCKS
 * WON'T BE RESPONSIBLE TO MAINTAIN AND KEEP YOUR ADDED FEATURES
 * WEBAR.ROCKS WON'T BE LIABLE FOR BREAKS IN YOUR ADDED FUNCTIONNALITIES
 *
 * WEBAR.ROCKS KEEP THE RIGHT TO WORK ON AN UNMODIFIED VERSION OF THIS SCRIPT.
 *
 * THIS FILE IS A HELPER AND SHOULD NOT BE MODIFIED TO IMPLEMENT A SPECIFIC USER SCENARIO
 * OR TO ADDRESS A SPECIFIC USE CASE.
 */
import * as THREE from 'three';
import WebARRocksLMStabilizer from './landmarksStabilizers/WebARRocksLMStabilizer.js';
import WEBARROCKSFEET from './WebARRocksFeet.module.js';
// import WEBARROCKSFEET from './WebARRocksHand.module.js';

const WebARRocksFeetThreeHelper = (function () {
  // private variables:
  const _defaultSpec = {
    feetTrackerCanvas: null,
    VTOCanvas: null,

    threshold: 0.92, // detection threshold, in [0,1] 1 -> hard, 0 -> easy
    NNPath: null,

    cameraMinVideoDimFov: 33, // vertical camera FoV in degrees
    cameraFovRange: [30, 60], // vertical camera FoV range in degrees
    cameraZoom: 1,
    posePointLabels: [],

    poseLandmarksLabels: [
      'ankleBack',
      'ankleOut',
      'ankleIn',
      'ankleFront',
      'heelBackOut',
      'heelBackIn',
      'pinkyToeBaseTop',
      'middleToeBaseTop',
      'bigToeBaseTop',
    ],
    objectPositionTweaker: null,

    callbackTrack: null,
    hideTrackerIfDetectionLost: true,

    // debug flags - should be all false:
    debugDisablePoseOrientation: false,
    debugDisplayLandmarks: false,
  };
  let _spec = null;
  let _landmarksStabilizers = null;

  let _gl = null,
    _videoElement = null;

  let _warFeet = null;

  const _three = {
    renderer: null,
    occluderMat: null,
    scene: null,
    loadingManager: null,
    camera: null,
    trackerParentRight: null,
    trackerRight: null,
    trackerParentLeft: null,
    trackerLeft: null,
  };
  const _shps = {
    displayLandmarks: null,
  };
  const _poseEstimation = {
    focal: 0,
    objPointsRight: null,
    objPointsLeft: null,
    imgPointsPx: [],
    poseLandmarksIndices: [],
    matMov: null,
  };
  const _debugDisplayLandmarks = {
    glIndicesVBO: null,
    glVerticesVBO: null,
  };

  const _deg2rad = Math.PI / 180;
  let _cameraFoVY = -1;

  // private methods:

  // compile a shader:
  function compile_shader(source, glType, typeString) {
    const glShader = _gl.createShader(glType);
    _gl.shaderSource(glShader, source);
    _gl.compileShader(glShader);
    if (!_gl.getShaderParameter(glShader, _gl.COMPILE_STATUS)) {
      alert('ERROR IN ' + typeString + ' SHADER: ' + _gl.getShaderInfoLog(glShader));
      console.log('Buggy shader source: \n', source);
      return null;
    }
    return glShader;
  }

  // build the shader program:
  function build_shaderProgram(shaderVertexSource, shaderFragmentSource, id) {
    // compile both shader separately:
    const GLSLprecision = 'precision lowp float;';
    const glShaderVertex = compile_shader(shaderVertexSource, _gl.VERTEX_SHADER, 'VERTEX ' + id);
    const glShaderFragment = compile_shader(
      GLSLprecision + shaderFragmentSource,
      _gl.FRAGMENT_SHADER,
      'FRAGMENT ' + id
    );

    const glShaderProgram = _gl.createProgram();
    _gl.attachShader(glShaderProgram, glShaderVertex);
    _gl.attachShader(glShaderProgram, glShaderFragment);

    // start the linking stage:
    _gl.linkProgram(glShaderProgram);
    const aPos = _gl.getAttribLocation(glShaderProgram, 'position');
    _gl.enableVertexAttribArray(aPos);

    return {
      program: glShaderProgram,
      uniforms: {},
    };
  }

  // build shader programs:
  function init_shps() {
    if (_spec.debugDisplayLandmarks) {
      _shps.displayLandmarks = build_shaderProgram(
        'attribute float position;\n\
        uniform vec2 uun_lmPosition;\n\
        void main(void){\n\
          gl_PointSize = 4.0;\n\
          gl_Position = vec4(position*uun_lmPosition, 0., 1.);\n\
        }',
        'void main(void){\n\
          gl_FragColor = vec4(0.,1.,0.,1.);\n\
        }',
        'DISPLAY LANDMARK'
      );
      _shps.displayLandmarks.uniforms.lmPosition = _gl.getUniformLocation(
        _shps.displayLandmarks.program,
        'uun_lmPosition'
      );
    }
  }

  function init_debugDisplayLandmarks() {
    // create vertex buffer objects:
    _debugDisplayLandmarks.glVerticesVBO = _gl.createBuffer();
    _gl.bindBuffer(_gl.ARRAY_BUFFER, _debugDisplayLandmarks.glVerticesVBO);
    _gl.bufferData(_gl.ARRAY_BUFFER, new Float32Array([1]), _gl.STATIC_DRAW);

    _debugDisplayLandmarks.glIndicesVBO = _gl.createBuffer();
    _gl.bindBuffer(_gl.ELEMENT_ARRAY_BUFFER, _debugDisplayLandmarks.glIndicesVBO);
    _gl.bufferData(_gl.ELEMENT_ARRAY_BUFFER, new Uint16Array([0]), _gl.STATIC_DRAW);
  }

  function init_three() {
    // init renderer:
    _three.renderer = new THREE.WebGLRenderer({
      canvas: _spec.VTOCanvas,
      alpha: true,
      antialias: true,
      preserveDrawingBuffer: true,
    });
    _three.renderer.setClearAlpha(0);

    // init scene:
    _three.scene = new THREE.Scene();

    // init loading manager:
    _three.loadingManager = new THREE.LoadingManager();

    // init camera:
    const viewAspectRatio = _spec.VTOCanvas.width / _spec.VTOCanvas.height;
    _three.camera = new THREE.PerspectiveCamera(_cameraFoVY, viewAspectRatio, 0.1, 1000);

    // init tracker object:
    const create_trackerParent = function (threeChild) {
      const tp = new THREE.Object3D();
      tp.frustumCulled = false;
      tp.matrixAutoUpdate = false;
      tp.add(threeChild);
      _three.scene.add(tp);
      return tp;
    };
    _three.trackerRight = new THREE.Object3D();
    _three.trackerLeft = new THREE.Object3D();
    _three.trackerParentRight = create_trackerParent(_three.trackerRight);
    _three.trackerParentLeft = create_trackerParent(_three.trackerLeft);

    // occluder material:
    _three.occluderMat = new THREE.ShaderMaterial({
      vertexShader: THREE.ShaderLib.basic.vertexShader,
      fragmentShader:
        'precision lowp float;\n void main(void){\n gl_FragColor = vec4(1., 0., 0., 1.);\n }',
      uniforms: THREE.ShaderLib.basic.uniforms,
      side: THREE.DoubleSide,
      colorWrite: false,
    });
  }

  function init_objPoints(poseLandmarksIndices, landmarksInfo) {
    const mean = new THREE.Vector3();

    const points = poseLandmarksIndices.map(function (ind) {
      const pos = landmarksInfo[ind].position.slice(0);
      const threePos = new THREE.Vector3().fromArray(pos);
      mean.add(threePos);
      return pos;
    });
    mean.divideScalar(poseLandmarksIndices.length);

    // substract mean:
    points.forEach(function (pos, ind) {
      (pos[0] -= mean.x), (pos[1] -= mean.y), (pos[2] -= mean.z);

      if (_spec.objectPositionTweaker) {
        _spec.objectPositionTweaker(pos, landmarksInfo[ind].label);
      }
    });

    return {
      points: points,
      mean: mean,
    };
  }

  function init_poseEstimation() {
    // find indices of landmarks used for pose estimation:
    _poseEstimation.poseLandmarksIndices = _spec.poseLandmarksLabels.map(function (label) {
      const ind = _warFeet.get_LMLabels().indexOf(label);
      if (ind === -1) {
        throw new Error('Cannot find landmark label ' + label);
      }
      return ind;
    });

    // init objPoints:
    const landmarksInfo = _warFeet.get_LM();
    _poseEstimation.objPointsRight = init_objPoints(
      _poseEstimation.poseLandmarksIndices,
      landmarksInfo.right
    );
    _poseEstimation.objPointsLeft = init_objPoints(
      _poseEstimation.poseLandmarksIndices,
      landmarksInfo.left
    );

    // init imgPoints:
    _poseEstimation.imgPointsPx = _poseEstimation.poseLandmarksIndices.map(function () {
      return [0, 0];
    });

    // init THREE stuffs:
    if (!_poseEstimation.matMov) {
      _poseEstimation.matMov = new THREE.Matrix4();
    }
  }

  function process_foot(
    detectState,
    objPoints,
    threeTrackerParentObj,
    threeTrackerObj,
    stabilizer
  ) {
    if (detectState.isDetected) {
      threeTrackerParentObj.visible = true;

      // stabilize landmarks:
      const dpr = window.devicePixelRatio || 1.0;
      const vWidth = that.get_viewWidth(),
        vHeight = that.get_viewHeight();
      const landmarksStabilized = stabilizer.update(
        detectState.landmarks,
        vWidth / dpr,
        vHeight / dpr
      );

      // compute pose:
      const isValidPose = compute_pose(
        landmarksStabilized,
        objPoints,
        threeTrackerParentObj,
        threeTrackerObj
      );
    } else if (threeTrackerParentObj.visible) {
      // detection is lost:
      stabilizer.reset();
      if (_spec.hideTrackerIfDetectionLost) {
        threeTrackerParentObj.visible = false;
      }
    }
  }

  function render_three(detectState) {
    process_foot(
      detectState.right,
      _poseEstimation.objPointsRight,
      _three.trackerParentRight,
      _three.trackerRight,
      _landmarksStabilizers.right
    );
    process_foot(
      detectState.left,
      _poseEstimation.objPointsLeft,
      _three.trackerParentLeft,
      _three.trackerLeft,
      _landmarksStabilizers.left
    );

    _three.renderer.render(_three.scene, _three.camera);
  }

  function update_focal() {
    // COMPUTE CAMERA PARAMS (FOCAL LENGTH)
    // see https://docs.opencv.org/3.0-beta/modules/calib3d/doc/camera_calibration_and_3d_reconstruction.html?highlight=projectpoints
    // and http://ksimek.github.io/2013/08/13/intrinsic/

    const halfFovYRad = 0.5 * _cameraFoVY * _deg2rad;
    const cotanHalfFovY = 1.0 / Math.tan(halfFovYRad);

    // settings with EPnP:
    const fy = 0.5 * that.get_viewHeight() * cotanHalfFovY;
    console.log('INFO in WebARRocksFeetThreeHelper - update_focal(): fy =', fy);
    _poseEstimation.focal = fy;
  }

  function compute_pose(landmarks, objPoints, threeTrackerParentObj, threeTrackerObj) {
    // update image points:
    const imgPointsPx = _poseEstimation.imgPointsPx;
    const w2 = that.get_viewWidth() / 2;
    const h2 = that.get_viewHeight() / 2;

    _poseEstimation.poseLandmarksIndices.forEach(function (ind, i) {
      const imgPointPx = imgPointsPx[i];
      (imgPointPx[0] = -(1 / _spec.cameraZoom) * landmarks[ind][0] * w2), // X in pixels
        (imgPointPx[1] = -(1 / _spec.cameraZoom) * landmarks[ind][1] * h2); // Y in pixels
    });

    // compute pose:
    const solved = _warFeet.compute_pose(objPoints.points, imgPointsPx, _poseEstimation.focal);

    threeTrackerObj.visible = true;
    threeTrackerObj.position.copy(objPoints.mean).multiplyScalar(-1);

    if (!solved) {
      return false;
    }

    // copy pose to THREE.js matrix:
    const m = _poseEstimation.matMov.elements;
    const r = solved.rotation,
      t = solved.translation;
    if (isNaN(t[0])) {
      return false;
    }

    // set translation part:
    (m[12] = -t[0]), (m[13] = -t[1]), (m[14] = -t[2]);

    // set rotation part:
    if (!_spec.debugDisablePoseOrientation) {
      (m[0] = -r[0][0]),
        (m[4] = -r[0][1]),
        (m[8] = r[0][2]),
        (m[1] = -r[1][0]),
        (m[5] = -r[1][1]),
        (m[9] = r[1][2]),
        (m[2] = -r[2][0]),
        (m[6] = -r[2][1]),
        (m[10] = r[2][2]);
    }

    // move THREE follower object:
    threeTrackerParentObj.matrix.copy(_poseEstimation.matMov);

    return true;
  }

  function inverse_facesIndexOrder(geom) {
    if (geom.faces) {
      // geometry
      geom.faces.forEach(function (face) {
        // change rotation order:
        const b = face.b,
          c = face.c;
        (face.c = b), (face.b = c);
      });
    } else {
      // buffer geometry
      const arr = geom.index.array;
      const facesCount = arr.length / 3;
      for (let i = 0; i < facesCount; ++i) {
        const b = arr[i * 3 + 1],
          c = arr[i * 3 + 2];
        (arr[i * 3 + 2] = b), (arr[i * 3 + 1] = c);
      }
    }
    geom.computeVertexNormals();
  }

  function animate() {
    const feet = _warFeet.detect();
    _warFeet.render_video();

    render_three(feet);

    if (_spec.callbackTrack !== null) {
      _spec.callbackTrack(feet);
    }

    if (_spec.debugDisplayLandmarks) {
      draw_landmarks(feet);
    }
    window.requestAnimationFrame(animate);
  }

  function draw_landmarks(feet) {
    _gl.useProgram(_shps.displayLandmarks.program);

    _gl.bindBuffer(_gl.ARRAY_BUFFER, _debugDisplayLandmarks.glVerticesVBO);
    _gl.bindBuffer(_gl.ELEMENT_ARRAY_BUFFER, _debugDisplayLandmarks.glIndicesVBO);
    _gl.vertexAttribPointer(0, 1, _gl.FLOAT, false, 4, 0);

    if (feet.right.isDetected) {
      feet.right.landmarks.forEach(draw_landmark);
    }
    if (feet.left.isDetected) {
      feet.left.landmarks.forEach(draw_landmark);
    }
  }

  function draw_landmark(lmXy) {
    _gl.uniform2fv(_shps.displayLandmarks.uniforms.lmPosition, lmXy);
    _gl.drawElements(_gl.POINTS, 1, _gl.UNSIGNED_SHORT, 0);
  }

  // public methods:
  const that = {
    init: function (spec) {
      _spec = Object.assign({}, _defaultSpec, spec);

      // init landmarks stabilizers:
      _landmarksStabilizers = {
        right: WebARRocksLMStabilizer.instance({}),
        left: WebARRocksLMStabilizer.instance({}),
      };

      return new Promise(function (accept, reject) {
        _warFeet = new WEBARROCKSFEET({
          canvas: _spec.feetTrackerCanvas,
          NNPath: _spec.NNPath,
          detectionThreshold: _spec.threshold,
        });

        console.log(
          'INFO iN WebARRocksFeetThreeHelper - init(): WebAR.rocks.feet will be initialized through the THREE helper. WebAR.rocks.feet version = ',
          _warFeet.VERSION
        );

        _warFeet
          .init()
          .then(function (spec) {
            console.log('INFO: WEBARROCKSFEET IS READY. spec =', spec);
            _gl = spec.GL;
            _videoElement = spec.video;

            init_shps();
            init_three();
            init_poseEstimation();
            if (_spec.debugDisplayLandmarks) {
              init_debugDisplayLandmarks();
            }
            that.update_threeCamera();
            update_focal();

            animate(0);

            accept(Object.assign({}, _three));
          })
          .catch(reject);
      }); //end returned promise
    }, // end init()

    resize: function (w, h) {
      if (_gl) {
        // Fix a bug with IOS14.7 and WebGL2
        _gl.bindFramebuffer(_gl.FRAMEBUFFER, null);
      }

      // resize feetTracker canvas:
      _spec.feetTrackerCanvas.width = w;
      _spec.feetTrackerCanvas.height = h;
      if (_warFeet) {
        _warFeet.resize();
      }

      // resize THREE renderer:
      _spec.VTOCanvas.width = w;
      _spec.VTOCanvas.height = h;
      that.update_threeCamera();
      update_focal();
    },

    get_sourceWidth: function () {
      return _videoElement.videoWidth;
    },

    get_sourceHeight: function () {
      return _videoElement.videoHeight;
    },

    get_viewWidth: function () {
      return _spec.VTOCanvas.width;
    },

    get_viewHeight: function () {
      return _spec.VTOCanvas.height;
    },

    add_threeOccluder: function (threeMesh) {
      threeMesh.userData.isOccluder = true;
      threeMesh.material = _three.occluderMat;
      threeMesh.renderOrder = -1e12; // render first
      that.add_threeObject(threeMesh);
    },

    add_threeObject: function (threeObject) {
      const add_threeObjectToParent = function (threeParent, threeChild) {
        let isChildContainsSkinnedMesh = false;
        threeChild.traverse(function (threeStuff) {
          isChildContainsSkinnedMesh = isChildContainsSkinnedMesh || threeStuff.isSkinnedMesh;
        });

        if (isChildContainsSkinnedMesh) {
          throw new Error('Skinned mesh are not supported by the THREE Helper yet');
        }

        const threeChildCopy = threeChild.clone();
        threeParent.add(threeChildCopy);
      };

      add_threeObjectToParent(_three.trackerRight, threeObject);

      // compute the left handed object by inverting X
      // we could just invert X scale but then the handyness of the transform would be inverted
      // and it would trigger culling and lighting error

      const inverse_ObjectRecursive = function (threeObject) {
        threeObject.frustumCulled = false;
        threeObject.updateMatrixWorld(true);

        if (threeObject.isMesh) {
          // compute matrix to apply to the geometry, K
          const M = threeObject.matrixWorld;
          const invXMatrix = new THREE.Matrix4().makeScale(-1, 1, 1);
          const K = new THREE.Matrix4().copy(M).invert().multiply(invXMatrix).multiply(M);

          // clone and invert the mesh:
          const threeMeshLeft = threeObject.clone();
          threeMeshLeft.geometry = threeObject.geometry.clone();

          threeMeshLeft.geometry.applyMatrix4(K);
          inverse_facesIndexOrder(threeMeshLeft.geometry);

          return threeMeshLeft;
        } else {
          const threeObjectLeft = threeObject.clone();
          threeObjectLeft.children.splice(0);
          for (let i = 0; i < threeObject.children.length; ++i) {
            const child = threeObject.children[i];
            threeObjectLeft.remove(child);
            threeObjectLeft.add(inverse_ObjectRecursive(child));
          }
          return threeObjectLeft;
        }
      };

      const threeObjectLeft = inverse_ObjectRecursive(threeObject);

      // add objects to tracker left:
      add_threeObjectToParent(_three.trackerLeft, threeObjectLeft);
    },

    clear_threeObjects: function (clearOccluders) {
      const clear_threeObject = function (threeObject) {
        for (let i = threeObject.children.length - 1; i >= 0; --i) {
          const child = threeObject.children[i];
          if (clearOccluders || !child.userData.isOccluder) {
            threeObject.remove(threeObject.children[i]);
          }
        }
      };
      clear_threeObject(_three.trackerRight);
      clear_threeObject(_three.trackerLeft);
    },

    update_threeCamera: function () {
      // compute aspectRatio:
      const cvw = that.get_viewWidth();
      const cvh = that.get_viewHeight();
      const canvasAspectRatio = cvw / cvh;

      // compute vertical field of view:
      const vw = that.get_sourceWidth();
      const vh = that.get_sourceHeight();
      const videoAspectRatio = vw / vh;
      let fovFactor = vh > vw ? 1.0 / videoAspectRatio : 1.0;
      let fov = _spec.cameraMinVideoDimFov * fovFactor;

      if (canvasAspectRatio > videoAspectRatio) {
        const scale = cvw / vw;
        const cvhs = vh * scale;
        fov = (2 * Math.atan((cvh / cvhs) * Math.tan(0.5 * fov * _deg2rad))) / _deg2rad;
      }

      fov = Math.min(Math.max(fov, _spec.cameraFovRange[0]), _spec.cameraFovRange[1]);

      _cameraFoVY = fov;
      console.log('INFO in update_threeCamera(): camera vertical estimated FoV is', fov, 'deg');

      // update projection matrix:
      _three.camera.aspect = canvasAspectRatio;
      _three.camera.zoom = _spec.cameraZoom;
      _three.camera.fov = fov;
      _three.camera.updateProjectionMatrix();

      // update drawing area:
      _three.renderer.setSize(cvw, cvh, false);
      _three.renderer.setViewport(0, 0, cvw, cvh);
    },

    destroy: function () {
      if (_warFeet) {
        _warFeet.destroy();
        _warFeet = null;
      }
      return that.reset();
    },

    reset: function () {
      _landmarksStabilizers = null;
      (_gl = null), (_videoElement = null);
      Object.assign(_three, {
        trackerRight: null,
        trackerLeft: null,
        trackerParentRight: null,
        trackerParentLeft: null,
      });
      return Promise.resolve();
    },
  }; //end that
  return that;
})();

export default WebARRocksFeetThreeHelper;
