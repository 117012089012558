import {ViewerConfig} from '../shared/types';

export const viewerConfig: {[key: string]: ViewerConfig} = {
  eyewear: {
    camera: {
      position: {
        x: 0,
        y: 0,
        z: 4,
      },
      fov: 30,
      aspect: 16 / 9,
      near: 1,
      far: 100,
    },
    ambientLightIntensity: undefined,

    controls: {
      minDistance: 3,
      maxDistance: 10,
      minPolarAngle: 0,
      maxPolarAngle: Math.PI,
      enableRotate: true,
      enableZoom: true,
      enablePan: false,
    },
    dirLights: [
      {
        color: 0xffffff,
        position: {x: 4, y: 4, z: 10},
        castShadow: true,
        shadow: {
          camera: {
            top: 2,
            bottom: -2,
            left: -2,
            right: 2,
            near: 0.1,
            far: 40,
          },
        },
      },
      {
        color: 0xffffff,
        castShadow: false,
        position: {
          x: -4,
          y: 4,
          z: 10,
        },
      },
      {
        color: 0xffffff,
        castShadow: false,
        position: {
          x: 8,
          y: 0,
          z: 0,
        },
      },
      {
        color: 0xffffff,
        castShadow: false,
        position: {
          x: -8,
          y: 0,
          z: 0,
        },
      },
    ],
    envMapPath: '/media/3dmodels/royal_esplanade_1k.hdr',
    hemiLight: {
      groundColor: 0x444444,
      skyColor: 0xffffff,
      intensity: 0.2,
      position: {
        x: 0,
        y: 20,
        z: 0,
      },
    },
    enableAnimation: true,
    showTable: false,
    material: null,
    rotation: null,
    obj_position: null,
    scale: null,
  },
  watches: {
    camera: {
      position: {
        x: 0,
        y: 20,
        z: 50,
      },
      fov: 40,
      aspect: 16 / 9,
      near: 1,
      far: 100,
    },
    ambientLightIntensity: undefined,
    controls: {
      position: [0, 2, 0],

      minDistance: 5,
      maxDistance: 10,
      minPolarAngle: 0,
      maxPolarAngle: 2 * Math.PI,
      enableRotate: true,
      enableZoom: true,
      enablePan: false,
    },
    dirLights: [
      {
        color: 0xffffff,
        castShadow: false,
        intensity: 0.1,
        position: {
          x: -2,
          y: 20,
          z: 6,
        },
      },
      {
        color: 0xffffff,
        castShadow: false,
        intensity: 0.1,
        position: {
          x: -2,
          y: 20,
          z: 0,
        },
      },
    ],
    envMapPath: '/media/3dmodels/potsdamer_platz_4k.hdr',
    hemiLight: {
      groundColor: 0xffffff,
      skyColor: 0xffffff,
      intensity: 0.3,
      position: {
        x: 0,
        y: 10,
        z: 0,
      },
    },

    enableAnimation: false,
    showTable: false,
    material: null,
    rotation: {
      x: 0,
      y: Math.PI / 2,
      z: 1.2,
    },
    obj_position: {
      x: 0,
      y: Math.PI / 2 + 0.4,
      z: 0,
    },
    scale: null,
  },
  footwear: {
    camera: {
      position: {
        x: 3,
        y: 0,
        z: 3,
      },
      fov: 20,
      aspect: 16 / 9,
      near: 0.1,
      far: 30,
    },
    ambientLightIntensity: 2,
    controls: {
      position: [0, -0.2, 0],
      minDistance: 2,
      maxDistance: 3.8,
      minPolarAngle: -Math.PI / 2 + 0.4,
      maxPolarAngle: Math.PI / 2 - 0.4,
      enableRotate: true,
      enableZoom: true,
      enablePan: false,
    },
    dirLights: [],
    envMapPath: '/media/3dmodels/autumn_field_puresky_4k.exr',
    occluderPath: '/media/webrocks-feet/occluder_long.glb',
    occluderScale: {
      x: 0.8,
      y: 0.8,
      z: 0.8,
    },
    occluderPosition: {
      x: 0.01,
      y: -0.4,
      z: 0,
    },
    occluderRotation: {
      x: -Math.PI / 2 + 0.1,
      y: 0,
      z: (-2 * Math.PI) / 2 + (5 * Math.PI) / 180,
    },
    occluderMaterial: {color: 0x5a5a5d},
    hemiLight: undefined,
    showTable: false,
    enableAnimation: false,
    rotation: {
      x: -Math.PI / 2,
      y: 0,
      z: Math.PI / 2,
    },
    obj_position: {
      x: 0,
      y: -0.48,
      z: 0,
    },
    scale: {
      x: 1,
      y: 1,
      z: 1,
    },
  },
  anything: {
    camera: {
      position: {
        x: 0,
        y: 0,
        z: 1,
      },
      fov: 20,
      aspect: 16 / 9,
      near: 10,
      far: 30,
    },
    ambientLightIntensity: 2,
    controls: {
      position: [0, 0.15, 0],
      minDistance: 0.5,
      maxDistance: 1.2,
      minPolarAngle: -Math.PI,
      maxPolarAngle: Math.PI,
      enableRotate: true,
      enableZoom: true,
      enablePan: false,
    },
    dirLights: [],
    envMapPath: '/media/3dmodels/autumn_field_puresky_4k.exr',
    occluderPath: '/media/webrocks-feet/occluder_long.glb',
    occluderScale: {
      x: 0.8,
      y: 0.8,
      z: 0.8,
    },
    occluderPosition: {
      x: 0.01,
      y: -0.4,
      z: 0,
    },
    occluderRotation: {
      x: -Math.PI / 2 + 0.1,
      y: 0,
      z: (-2 * Math.PI) / 2 + (5 * Math.PI) / 180,
    },
    occluderMaterial: {color: 0x5a5a5d},
    hemiLight: undefined,
    showTable: false,
    enableAnimation: false,
    rotation: {
      x: 0,
      // x: -Math.PI / 2,
      y: 0,
      z: 0,
    },
    obj_position: {
      x: 0,
      y: 0.15,
      z: 0,
    },
    scale: {
      x: 0.2,
      y: 0.2,
      z: 0.2,
    },
    centerObject: true,
  },
};
