import clsx from 'clsx';
import {useFormik} from 'formik';
import {useState} from 'react';
import {toast} from 'react-toastify';
import * as Yup from 'yup';
import {FormFieldErrorMessage} from '../../../../../../shared/components/FormFieldErrorMessage';
import {ApiError} from '../../../../auth/core/apiModels';
import {ModelHeaderLayout} from '../../../../shared/components/ModalLayout';
import {addExtraCreditForUser} from '../../../api/users-api';
interface Props {
  submitComplete: (data: 'ok' | 'cancel') => void;
  usersIds: number[];
}

const initialValues = {
  extraCredits: 0,
};

const updateMaxCreditsSchema = Yup.object().shape({
  extraCredits: Yup.number()
    .required('Enter credits number')
    .min(1, 'Enter credits number')
    .max(9999, 'Credits number is too high'),
});

const IncreaseCredits = ({usersIds, submitComplete}: Props) => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: updateMaxCreditsSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true);

      try {
        await addExtraCreditForUser(usersIds, values.extraCredits);
        toast.success('Updated successfully');
        submitComplete('ok');
      } catch (error: any) {
        setSubmitting(false);
        setLoading(false);

        const responseData: ApiError | undefined = error?.response?.data;
        if (responseData) setStatus(responseData);
        else setStatus('Failed!');
      }
    },
  });

  return (
    <div>
      <ModelHeaderLayout
        title={`Increase Credits`}
        showCloseButton
        onClose={() => submitComplete('cancel')}
      ></ModelHeaderLayout>

      <main
        className={clsx('modal-body p-5 px-6 scroll', {
          'overlay overlay-block': loading,
        })}
      >
        <div className='text-gray-700 fs-5 mb-5'>
          Please Enter the extra credits number you want to increase for the selected{' '}
          <strong>{usersIds.length}</strong> users.
        </div>

        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_signup_form'
          onSubmit={formik.handleSubmit}
        >
          {formik.status && (
            <div className='mb-5 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}

          {/* begin::Form group Max Credits */}
          <div className='row fv-row mb-4'>
            <div className='col-xl-6'>
              <label className='form-label fw-bolder text-dark fs-6'>Credits</label>
              <input
                placeholder='Credits Number'
                type='number'
                autoComplete='off'
                {...formik.getFieldProps('extraCredits')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.extraCredits && formik.errors.extraCredits,
                  },
                  {
                    'is-valid': formik.touched.extraCredits && !formik.errors.extraCredits,
                  }
                )}
              />
              {formik.touched.extraCredits && formik.errors.extraCredits && (
                <FormFieldErrorMessage>{formik.errors.extraCredits}</FormFieldErrorMessage>
              )}
            </div>
          </div>
        </form>
      </main>

      <footer className='modal-footer p-5 border-top'>
        <button
          type='button'
          onClick={() => submitComplete('cancel')}
          className='btn btn-light me-2'
        >
          Close
        </button>
        <button
          type='button'
          onClick={() => formik.submitForm()}
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </footer>
    </div>
  );
};

export default IncreaseCredits;
