export const CountryCode: any = {
  AX: 'aland-islands',
  AL: 'albania',
  DZ: 'algeria',
  AS: 'american-samoa',
  AD: 'andorra',
  AO: 'angola',
  AI: 'anguilla',
  AG: 'antigua-and-barbuda',
  AR: 'argentina',
  AM: 'armenia',
  AW: 'aruba',
  AU: 'australia',
  AT: 'austria',
  AZ: 'azerbaijan',
  PT: 'azores-islands',
  BS: 'bahamas',
  BH: 'bahrain',
  BD: 'bangladesh',
  BB: 'barbados',
  BY: 'belarus',
  BE: 'belgium',
  BZ: 'belize',
  BJ: 'benin',
  BM: 'bermuda',
  BT: 'bhutan',
  BO: 'bolivia',
  BQ: 'bonaire',
  BA: 'bosnia-and-herzegovina',
  BW: 'botswana',
  BR: 'brazil',
  IO: 'british-indian-ocean-territory',
  VG: 'british-virgin-islands',
  BN: 'brunei',
  BG: 'bulgaria',
  BF: 'burkina-faso',
  BI: 'burundi',
  KH: 'cambodia',
  CM: 'cameroon',
  CA: 'canada',
  CV: 'cape-verde',
  KY: 'cayman-islands',
  CF: 'central-african-republic',
  TD: 'chad',
  CL: 'chile',
  CN: 'china',
  CX: 'christmas-island',
  CC: 'cocos-island',
  CO: 'colombia',
  KM: 'comoros',
  CK: 'cook-islands',
  CR: 'costa-rica',
  HR: 'croatia',
  CU: 'cuba',
  CW: 'curacao',
  CZ: 'czech-republic',
  CD: 'democratic-republic-of-congo',
  DK: 'denmark',
  DJ: 'djibouti',
  DM: 'dominica',
  DO: 'dominican-republic',
  TL: 'east-timor',
  EC: 'ecuador',
  EG: 'egypt',
  SV: 'el-salvador',
  'GB-ENG': 'england',
  GQ: 'equatorial-guinea',
  ER: 'eritrea',
  EE: 'estonia',
  ET: 'ethiopia',
  EU: 'european-union',
  FK: 'falkland-islands',
  FJ: 'fiji',
  FI: 'finland',
  FR: 'france',
  PF: 'french-polynesia',
  GA: 'gabon',
  GM: 'gambia',
  GE: 'georgia',
  DE: 'germany',
  GH: 'ghana',
  GI: 'gibraltar',
  GR: 'greece',
  GL: 'greenland',
  GD: 'grenada',
  GU: 'guam',
  GT: 'guatemala',
  GG: 'guernsey',
  GW: 'guinea-bissau',
  GN: 'guinea',
  HT: 'haiti',
  'US-HI': 'hawaii',
  HN: 'honduras',
  HK: 'hong-kong',
  HU: 'hungary',
  IS: 'iceland',
  IN: 'india',
  ID: 'indonesia',
  IR: 'iran',
  IQ: 'iraq',
  IE: 'ireland',
  IM: 'isle-of-man',
  IL: 'israel',
  IT: 'italy',
  CI: 'ivory-coast',
  JM: 'jamaica',
  JP: 'japan',
  JE: 'jersey',
  JO: 'jordan',
  KZ: 'kazakhstan',
  KE: 'kenya',
  KI: 'kiribati',
  XK: 'kosovo',
  KW: 'kuwait',
  KG: 'kyrgyzstan',
  LA: 'laos',
  LV: 'latvia',
  LB: 'lebanon',
  LS: 'lesotho',
  LR: 'liberia',
  LY: 'libya',
  LI: 'liechtenstein',
  LT: 'lithuania',
  LU: 'luxembourg',
  MO: 'macao',
  MG: 'madagascar',
  MW: 'malawi',
  MY: 'malaysia',
  MV: 'maldives',
  ML: 'mali',
  MT: 'malta',
  MH: 'marshall-island',
  MQ: 'martinique',
  MR: 'mauritania',
  MU: 'mauritius',
  MX: 'mexico',
  FM: 'micronesia',
  MD: 'moldova',
  MC: 'monaco',
  MN: 'mongolia',
  ME: 'montenegro',
  MS: 'montserrat',
  MA: 'morocco',
  MZ: 'mozambique',
  MM: 'myanmar',
  NA: 'namibia',
  NR: 'nauru',
  NP: 'nepal',
  NL: 'netherlands',
  NZ: 'new-zealand',
  NI: 'nicaragua',
  NE: 'niger',
  NG: 'nigeria',
  NU: 'niue',
  NF: 'norfolk-island',
  KP: 'north-korea',
  CY: 'northern-cyprus',
  MP: 'northern-mariana-islands',
  NO: 'norway',
  OM: 'oman',
  PK: 'pakistan',
  PW: 'palau',
  PS: 'palestine',
  PA: 'panama',
  PG: 'papua-new-guinea',
  PY: 'paraguay',
  PE: 'peru',
  PH: 'philippines',
  PN: 'pitcairn-islands',
  PL: 'poland',
  PR: 'puerto-rico',
  QA: 'qatar',
  MK: 'republic-of-macedonia',
  CG: 'republic-of-the-congo',
  RO: 'romania',
  RU: 'russia',
  RW: 'rwanda',
  EH: 'sahrawi-arab-democratic-republic',
  KN: 'saint-kitts-and-nevis',
  WS: 'samoa',
  SM: 'san-marino',
  ST: 'sao-tome-and-prince',
  SA: 'saudi-arabia',
  'GB-SCT': 'scotland',
  SN: 'senegal',
  RS: 'serbia',
  SC: 'seychelles',
  SL: 'sierra-leone',
  SG: 'singapore',
  SX: 'sint-maarten',
  SK: 'slovakia',
  SI: 'slovenia',
  SB: 'solomon-islands',
  SO: 'somalia',
  ZA: 'south-africa',
  KR: 'south-korea',
  SS: 'south-sudan',
  ES: 'spain',
  LK: 'sri-lanka',
  BL: 'st-barts',
  LC: 'st-lucia',
  VC: 'st-vincent-and-the-grenadines',
  SD: 'sudan',
  SR: 'suriname',
  SZ: 'swaziland',
  SE: 'sweden',
  CH: 'switzerland',
  SY: 'syria',
  TW: 'taiwan',
  TJ: 'tajikistan',
  TZ: 'tanzania',
  TH: 'thailand',
  TG: 'togo',
  TK: 'tokelau',
  TO: 'tonga',
  TT: 'trinidad-and-tobago',
  TN: 'tunisia',
  TR: 'turkey',
  TM: 'turkmenistan',
  TC: 'turks-and-caicos',
  TV: 'tuvalu',
  UG: 'uganda',
  GB: 'uk',
  UA: 'ukraine',
  AE: 'united-arab-emirates',
  US: 'united-states',
  UY: 'uruguay',
  UZ: 'uzbekistan',
  VU: 'vanuatu',
  VA: 'vatican-city',
  VE: 'venezuela',
  VN: 'vietnam',
  VI: 'virgin-islands',
  GBWLS: 'wales',
  YE: 'yemen',
  ZM: 'zambia',
};
