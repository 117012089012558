import {useFormik} from 'formik';
import {toast} from 'react-toastify';
import * as Yup from 'yup';
import {createModel} from '../../../../models-management/api/models-api.fake';
import {createModelAdmin} from '../../../api/users-api';
import clsx from 'clsx';
import {useNavigate} from 'react-router-dom';
import useOrgStore from '../organization.store';
import {updateOrganization} from '../../../api/organizations-api';

const initialModel = {
  name: '',
  window_title: '',
  footer_text: '',
  about_link: '',
  terms_link: '',
  contact_link: '',
  privacy_link: '',
  welcome_text: '',
  done_button_color: '',
  format_name: '',
  logo: '',
  favicon: '',
  light_logo: '',
  dark_logo: '',
};
const newModelSchema = Yup.object().shape({
  // name: Yup.string().required('Category is required'),
  // window_title: Yup.string(),
  // footer_text: Yup.string(),
  // about_link: Yup.string(),
  // terms_link: Yup.string(),
  // contact_link: Yup.string(),
  // privacy_link: Yup.string(),
  // welcome_text: Yup.string(),
  // done_button_color: Yup.string(),
  // favicon: Yup.string(),
  // dark_logo: Yup.string(),
  // logo: Yup.string(),
  // light_logo: Yup.string(),
});

export function BrandingForm() {
  const navigate = useNavigate();
  const {org, setOrg} = useOrgStore();

  const clearForm = () => {
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {...initialModel, ...org},
    enableReinitialize: true,
    // validationSchema: newModelSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setSubmitting(true);
      setStatus();
      try {
        //api submit
        await updateOrganization({...org, ...values});

        toast.success('Saved!');

        // navigate();

        clearForm();
      } catch (error: any) {
        console.error(error);
        setStatus(error?.response?.data?.msg ?? 'Failed to submit');
        toast.error('Submit Failed');
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <form className='form' onSubmit={formik.handleSubmit} noValidate>
      <div className='card pt-4 mb-6 mb-xl-9'>
        <div className='card-header border-0'>
          <div className='card-title'>
            <h2>Branding Data</h2>
          </div>
          <div className='card-toolbar'>
            <button type='submit' className='btn btn-sm btn-light-primary'>
              {!formik.isSubmitting && (
                <>
                  {' '}
                  <i className='ki-duotone ki-check-circle fs-3'>
                    <span className='path1' />
                    <span className='path2' />
                  </i>
                  Save Changes
                </>
              )}
              {formik.isSubmitting && (
                <span className='indicator-progress d-block'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
        <div className='card-body py-0'>
          {formik.status && (
            <div className='mb-lg-10 mb-sm-5 alert alert-danger mw-500px m-auto'>
              <div className='alert-text font-weight-bold capitalized'>{formik.status}</div>
            </div>
          )}

          {/* window_title */}
          <fieldset className='row mb-7'>
            <label className='py-2 col-lg-3  fw-bold fs-6 mb-2'>window_title</label>

            <div className='col-lg-7 col-xl-5'>
              <input
                placeholder='Type text'
                {...formik.getFieldProps('window_title')}
                type='text'
                name='window_title'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
                disabled={formik.isSubmitting}
              />
            </div>
          </fieldset>

          {/* format_name */}
          <fieldset className='row mb-7'>
            <label className='py-2 col-lg-3  fw-bold fs-6 mb-2'>format_name</label>

            <div className='col-lg-7 col-xl-5'>
              <input
                placeholder='Type text'
                {...formik.getFieldProps('format_name')}
                type='text'
                name='format_name'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
                disabled={formik.isSubmitting}
              />
            </div>
          </fieldset>

          {/* footer_text */}
          <fieldset className='row mb-7'>
            <label className='py-2 col-lg-3  fw-bold fs-6 mb-2'>footer_text</label>

            <div className='col-lg-7 col-xl-5'>
              <input
                placeholder='Type text'
                {...formik.getFieldProps('footer_text')}
                type='text'
                name='footer_text'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
                disabled={formik.isSubmitting}
              />
            </div>
          </fieldset>

          {/* about_link */}
          <fieldset className='row mb-7'>
            <label className='py-2 col-lg-3  fw-bold fs-6 mb-2'>about_link</label>

            <div className='col-lg-7 col-xl-5'>
              <input
                placeholder='Type text'
                {...formik.getFieldProps('about_link')}
                type='text'
                name='about_link'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
                disabled={formik.isSubmitting}
              />
            </div>
          </fieldset>

          {/* terms_link */}
          <fieldset className='row mb-7'>
            <label className='py-2 col-lg-3  fw-bold fs-6 mb-2'>terms_link</label>

            <div className='col-lg-7 col-xl-5'>
              <input
                placeholder='Type text'
                {...formik.getFieldProps('terms_link')}
                type='text'
                name='terms_link'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
                disabled={formik.isSubmitting}
              />
            </div>
          </fieldset>

          {/* contact_link */}
          <fieldset className='row mb-7'>
            <label className='py-2 col-lg-3  fw-bold fs-6 mb-2'>contact_link</label>

            <div className='col-lg-7 col-xl-5'>
              <input
                placeholder='Type text'
                {...formik.getFieldProps('contact_link')}
                type='text'
                name='contact_link'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
                disabled={formik.isSubmitting}
              />
            </div>
          </fieldset>

          {/* privacy_link */}
          <fieldset className='row mb-7'>
            <label className='py-2 col-lg-3  fw-bold fs-6 mb-2'>privacy_link</label>

            <div className='col-lg-7 col-xl-5'>
              <input
                placeholder='Type text'
                {...formik.getFieldProps('privacy_link')}
                type='text'
                name='privacy_link'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
                disabled={formik.isSubmitting}
              />
            </div>
          </fieldset>

          {/* welcome_text */}
          <fieldset className='row mb-7'>
            <label className='py-2 col-lg-3  fw-bold fs-6 mb-2'>welcome_text</label>

            <div className='col-lg-7 col-xl-5'>
              <input
                placeholder='Type text'
                {...formik.getFieldProps('welcome_text')}
                type='text'
                name='welcome_text'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
                disabled={formik.isSubmitting}
              />
            </div>
          </fieldset>

          {/* done_button_color */}

          <fieldset className='row mb-7'>
            <label className='py-2 col-lg-3  fw-bold fs-6 mb-2'>done_button_color</label>

            <div className='col-lg-7 col-xl-5'>
              <input
                placeholder='Type text'
                {...formik.getFieldProps('done_button_color')}
                type='text'
                name='done_button_color'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0 ')}
                autoComplete='off'
                disabled={formik.isSubmitting}
              />
            </div>
          </fieldset>

          <h2 className='py-3'>Logos</h2>

          <fieldset className='row mb-7'>
            <label className='py-2 col-lg-3  fw-bold fs-6 mb-2'>favicon</label>

            <div className='col-lg-7 col-xl-5'>
              <input
                placeholder='Base64 image code'
                {...formik.getFieldProps('favicon')}
                type='text'
                name='favicon'
                multiple={true}
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
                disabled={formik.isSubmitting}
              />
            </div>
          </fieldset>

          <fieldset className='row mb-7'>
            <label className='py-2 col-lg-3  fw-bold fs-6 mb-2'>logo</label>

            <div className='col-lg-7 col-xl-5'>
              <input
                placeholder='Base64 image code'
                {...formik.getFieldProps('logo')}
                type='text'
                name='logo'
                multiple={true}
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
                disabled={formik.isSubmitting}
              />
            </div>
          </fieldset>

          <fieldset className='row mb-7'>
            <label className='py-2 col-lg-3  fw-bold fs-6 mb-2'>dark_logo</label>

            <div className='col-lg-7 col-xl-5'>
              <input
                placeholder='Base64 image code'
                {...formik.getFieldProps('dark_logo')}
                type='text'
                name='dark_logo'
                multiple={true}
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
                disabled={formik.isSubmitting}
              />
            </div>
          </fieldset>

          <fieldset className='row mb-7'>
            <label className='py-2 col-lg-3  fw-bold fs-6 mb-2'>light_logo</label>

            <div className='col-lg-7 col-xl-5'>
              <input
                placeholder='Base64 image code'
                {...formik.getFieldProps('light_logo')}
                type='text'
                name='light_logo'
                multiple={true}
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
                disabled={formik.isSubmitting}
              />
            </div>
          </fieldset>
        </div>
      </div>
    </form>
  );
}
