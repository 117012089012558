import React from 'react';
import {useAuth} from '../../auth';
import {UserRolesEnum} from '../../models-management/types';

const useRealUserRole = () => {
  const {currentUser} = useAuth();

  if (!currentUser?.role_id) return -1;

  if (currentUser.role_id === UserRolesEnum.USER && currentUser.org_id)
    return UserRolesEnum.RESELLER_CUSTOMER;

  if (currentUser.role_id === UserRolesEnum.USER && !currentUser.org_id) return UserRolesEnum.USER;

  return currentUser.role_id;
};

export default useRealUserRole;
