interface Props {
  label?: string;
}
export const LoadingPlaceholder = ({label}: Props) => {
  return (
    <div className='h-100 d-flex flex-column flex-center text-center p-10'>
      <div className='spinner'>
        <div className='cube1'></div>
        <div className='cube2'></div>
      </div>
      <div className='capitalized'>{label || 'Loading'}...</div>
    </div>
  );
};
