import {CameraControls, ContactShadows, Environment, useGLTF} from '@react-three/drei';
import {Canvas} from '@react-three/fiber';
import {useThemeMode} from '../../../../../_metronic/partials';
import React, {ReactNode} from 'react';
import {EffectComposer, Outline, Selection} from '@react-three/postprocessing';
import {EditorModel} from './EditorModel';
import {ACESFilmicToneMapping, ToneMapping, Vector3} from 'three';
import {viewerConfig} from '../../../model-viewer/viewer-config';
import {Controls} from '../../../model-viewer/components/NewViewer/components/Controls';
import * as THREE from 'three';
import {Text} from '@react-three/drei';
import {AxesWithLabels} from '../../../model-viewer/components/NewViewer/components/AxesWithLabels';

const MODEL_URL = '/media/3dmodels/glasses-sample.glb';

const EditorViewer = ({path}: any) => {
  const {mode} = useThemeMode();
  const opacity = mode === 'light' ? 0.4 : 0.15;

  return (
    <Camera>
      <Controls />

      <Lights />

      {/* <AxesWithLabels /> */}

      <group>
        {path && <EditorModel path={path} />}

        <ContactShadows
          position={[0, -0.6, 0]}
          opacity={mode === 'light' ? 0.1 : 0.15}
          blur={0.5}
          far={2}
          scale={5}
        />

        <Environment preset='apartment' />
      </group>
    </Camera>
  );
};

export default EditorViewer;

interface Props {
  children: ReactNode;
}

export const Camera = ({children}: Props) => {
  const config = viewerConfig.eyewear;
  return (
    <Canvas
      gl={{antialias: true, toneMappingExposure: 0.4, toneMapping: ACESFilmicToneMapping}}
      shadows
      camera={{
        position: [config.camera.position.x, config.camera.position.y, config.camera.position.z],
        fov: config.camera.fov,
      }}
    >
      {children}
    </Canvas>
  );
};

export const Lights = () => {
  return (
    <>
      {[
        {
          color: 0xffffff,
          position: {x: 4, y: 4, z: 10},
          castShadow: true,
          shadow: {
            camera: {
              top: 2,
              bottom: -2,
              left: -2,
              right: 2,
              near: 0.1,
              far: 40,
            },
          },
        },
        {
          color: 0xffffff,
          castShadow: false,
          position: {
            x: -4,
            y: 4,
            z: 10,
          },
        },
        {
          color: 0xffffff,
          castShadow: false,
          position: {
            x: 8,
            y: 0,
            z: 0,
          },
        },
        {
          color: 0xffffff,
          castShadow: false,
          position: {
            x: -8,
            y: 0,
            z: 0,
          },
        },
      ].map((light: any) => (
        <directionalLight
          position={new Vector3(light.position.x, light.position.y, light.position.z)}
        />
      ))}

      <hemisphereLight intensity={0.4} position={[0, 20, 0]} />
    </>
  );
};

export const OutlineComponent = ({children}: any) => {
  return (
    <Selection>
      <EffectComposer renderPriority={1} multisampling={8} autoClear={false}>
        <Outline
          selectionLayer={1}
          edgeStrength={2}
          visibleEdgeColor={0xa673ff}
          // kernelSize={KernelSize.VERY_SMALL}
          blur={true}
          xRay={false}
        />
      </EffectComposer>
      {children}
    </Selection>
  );
};
