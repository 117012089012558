import React, {useEffect, useRef, useState, Suspense} from 'react';
import {Canvas, useFrame, useThree, useLoader} from '@react-three/fiber';
import {EffectComposer, Bloom} from '@react-three/postprocessing';
import {useAuth} from '../../auth';

import '../../../../../src/reactThreeFiberDemos/styles/index.scss';
// import GLTF loader - originally in examples/jsm/loaders/
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';

// import neural network model:
import NN from '../../../../reactThreeFiberDemos/js/contrib/WebARRocksFace/neuralNets/NN_GLASSES_9.json';

// import WebARRocksMirror, a helper
// This helper is not minified, feel free to customize it (and submit pull requests bro):
import mirrorHelper from '../../../../reactThreeFiberDemos/js/contrib/WebARRocksFace/helpers/WebARRocksMirror.js';
import {Object3D} from 'three';
import {toAbsoluteUrl} from '../../../../_metronic/helpers';

import {getModelFile} from '../../../../shared/utils';

// import occluder
const GLTFOccluderModel = toAbsoluteUrl('/media/3dmodels/occluder.glb');

// import envMap:
const envMap = toAbsoluteUrl('/media/3dmodels/royal_esplanade_1k.hdr');

let _threeFiber = null;

const GLTFModel = '/media/3dmodels/final_model.glb';

const get_pauseButtonText = (isPaused: boolean) => {
  return isPaused ? 'Resume' : 'Pause';
};

// fake component, display nothing
// just used to get the Camera and the renderer used by React-fiber:
const ThreeGrabber = (props: any) => {
  const threeFiber: any = useThree();
  _threeFiber = threeFiber;

  useFrame(mirrorHelper.update.bind(null, props.sizing, threeFiber.camera));
  mirrorHelper.set_lighting(threeFiber.gl, threeFiber.scene, props.lighting);

  return null;
};

const compute_sizing = () => {
  // compute  size of the canvas:
  const height = window.innerHeight || 300;
  const width = height / (16 / 9) || window.innerWidth || 600;

  // compute position of the canvas:
  const top = 0;
  const left = 0;
  return {width, height, top, left};
};

const VTOModelContainer = (props: any) => {
  const objRef = useRef<Object3D<Event>>(null);
  mirrorHelper.clean();

  useEffect(() => {
    const threeObject3DParent: any = objRef.current;
    if (threeObject3DParent.children.length === 0) return;
    const threeObject3D = threeObject3DParent.children[0];
    if (threeObject3D.children.length === 0) return;
    const model = threeObject3D.children[0];

    mirrorHelper.set_glassesPose(model);
    mirrorHelper.tweak_materials(model, props.glassesBranches);
    mirrorHelper.set_faceFollower(threeObject3DParent, threeObject3D, props.faceIndex);
    //return mirrorHelper.clean;
  }, [props.GLTFModel, props.sizing]);

  // import main model:
  const gltf: any = useLoader(GLTFLoader, props.GLTFModel, (loader) => {
    if (props.token) {
      loader.setRequestHeader({
        ...loader.requestHeader,
        Authorization: `Bearer ${props.token}`,
      });
    }
  });
  const model = gltf.scene.clone();

  // import and create occluder:
  const isDebugOccluder = false; // true to debug the occluder
  const gltfOccluder: any = useLoader(GLTFLoader, props.GLTFOccluderModel);
  const occluderModel = gltfOccluder.scene.clone();
  const occluderMesh = mirrorHelper.create_occluderMesh(occluderModel, isDebugOccluder);

  return (
    <object3D ref={objRef}>
      <object3D>
        <primitive object={model} />
        <primitive object={occluderMesh} />
      </object3D>
    </object3D>
  );
};

const DebugCube = (props: any) => {
  const s = props.size || 1;
  return (
    <mesh name='debugCube'>
      <boxBufferGeometry args={[s, s, s]} />
      <meshNormalMaterial />
    </mesh>
  );
};
export interface Props {
  model_path: string;
}

// TODO take path from parent componetn
const VTOGlassesViewer = ({model_path}: Props) => {
  // state:
  const [sizing, setSizing] = useState(compute_sizing());
  const [isInitialized] = useState(true);
  const [model, setModel] = useState(model_path);
  const [loading, setLoading] = useState(false);
  const auth = useAuth();
  // refs:
  const canvasFaceRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    setLoading(true);
    getModelFile(model_path, auth.auth?.api_token).then((updatedGlpFile) => {
      setModel(updatedGlpFile);
      setLoading(false);
    });
  }, [model_path]);
  // misc private vars:
  const _settings = {
    glassesBranches: {
      // Branch fading parameters (branch become transparent near the ears)
      fadingZ: -0.9, // where to start branch fading. - -> to the back
      fadingTransition: 0.6, // 0 -> hard transition

      // Branch bending (glasses branches are always bent to slightly tighten the head):
      bendingAngle: 5, //in degrees. 0 -> no bending
      bendingZ: 0, //start brench bending at this position. - -> to the back
    },

    lighting: {
      envMap,
      pointLightIntensity: 0.8, // intensity of the point light. Set to 0 to disable
      pointLightY: 200, // larger -> move the pointLight to the top
      hemiLightIntensity: 0, // intensity of the hemispheric light. Set to 0 to disable (not really useful if we use an envmap)
    },

    // occluder 3D model:
    GLTFOccluderModel,

    bloom: {
      threshold: 0.5, // apply bloom is light intensity is above this threshold
      intensity: 8, // intensity of the effect
      kernelSizeLevel: 0, // 0 -> SMALL kernel
      computeScale: 0.5, // 0.5 -> compute using half resolution
      luminanceSmoothing: 0.7,
    },
  };
  let _timerResize: any = null;

  const handle_resize = () => {
    // do not resize too often:
    if (_timerResize) {
      clearTimeout(_timerResize);
    }
    _timerResize = setTimeout(do_resize, 200);
  };

  const do_resize = () => {
    _timerResize = null;
    const newSizing = compute_sizing();
    setSizing(newSizing);
  };

  useEffect(() => {
    if (_timerResize === null) {
      mirrorHelper.resize();
    }
  }, [sizing]);

  useEffect(() => {
    // init WEBARROCKSFACE through the helper:
    mirrorHelper
      .init({
        NN,
        scanSettings: {
          threshold: 0.8, // detection threshold, between 0 and 1
        },
        landmarksStabilizerSpec: {
          beta: 10,
          minCutOff: 0.001,
          freqRange: [2, 144],
          forceFilterNNInputPxRange: [2.5, 6], //[1.5, 4],
        },
        solvePnPImgPointsLabels: [
          //'chinLeft', 'chinRight',

          'leftEarBottom',
          'rightEarBottom',
          'noseBottom',
          'noseLeft',
          'noseRight',
          'leftEyeExt',
          'rightEyeExt',
        ],
        canvasFace: canvasFaceRef.current,
        maxFacesDetected: 1,
      })
      .then(() => {
        // handle resizing / orientation change:
        window.addEventListener('resize', handle_resize);
        window.addEventListener('orientationchange', handle_resize);

        console.log('WEBARROCKSMIRROR helper has been initialized');
      });

    return () => {
      _threeFiber = null;
      mirrorHelper.destroy();
    };
  }, [isInitialized]);

  return (
    <div>
      {/* Canvas managed by three fiber, for AR: */}
      <Canvas
        className='mirrorX mx-auto'
        style={{
          position: 'absolute',
          zIndex: 2,
          ...sizing,
        }}
        gl={{
          preserveDrawingBuffer: true, // allow image capture
        }}

        // updateDefaultCamera={false}
      >
        <ThreeGrabber sizing={sizing} lighting={_settings.lighting} />
        {!loading && (
          <Suspense fallback={<DebugCube />}>
            <VTOModelContainer
              sizing={sizing}
              GLTFModel={model}
              token={auth.auth?.api_token}
              GLTFOccluderModel={_settings.GLTFOccluderModel}
              faceIndex={0}
              glassesBranches={_settings.glassesBranches}
            />
          </Suspense>
        )}
      </Canvas>

      {/* Canvas managed by WebAR.rocks, just displaying the video (and used for WebGL computations) */}
      <canvas
        className='mirrorX'
        ref={canvasFaceRef}
        style={{
          position: 'absolute',
          zIndex: 1,
          ...sizing,
        }}
        width={sizing.width}
        height={sizing.height}
      />
    </div>
  );
};

export default VTOGlassesViewer;
