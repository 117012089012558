import {Dialog} from 'primereact/dialog';
import {useState} from 'react';
import ReportIssue from '../../../../app/modules/app/ReportIssue';
import {KTSVG} from '../../../helpers';

export const AsideReportIssueButton = () => {
  const [showWindow, setShowWindow] = useState(false);

  return (
    <>
      <button className='btn btn-custom btn-primary w-100' onClick={() => setShowWindow(true)}>
        <span className='btn-label'>Contact Support</span>

        <KTSVG
          path='/media/icons/duotune/maps/map001.svg'
          className='svg-icon btn-icon svg-icon-2'
        />
      </button>

      <Dialog
        modal
        dismissableMask
        closable={false}
        visible={showWindow}
        onHide={() => setShowWindow(false)}
      >
        <ReportIssue
          submitComplete={() => {
            setShowWindow(false);
          }}
        />
      </Dialog>
    </>
  );
};
